import { AddIcon } from '@chakra-ui/icons';
import { Box, Flex, Heading, Icon, IconButton } from '@chakra-ui/react';

interface SectionProps {
  title: string;
  onAddClick: () => void;
  show?: boolean;
  addText?: string;
  isDisabled?: boolean;
}

export const Section: React.FC<SectionProps> = ({
  title,
  onAddClick,
  children,
  show,
  addText,
  isDisabled = false,
}) => {
  if (!show) return null;
  const addButtonTitle = addText ?? `Add new ${title}`;
  return (
    <Box my={4}>
      <Flex justifyContent="space-between" alignItems="center">
        <Heading fontWeight="700" size="sm" mb={4}>
          {title}
        </Heading>
        <IconButton
          aria-label={addButtonTitle}
          title={addButtonTitle}
          onClick={() => {
            if (isDisabled) return;
            onAddClick();
          }}
          icon={<Icon as={AddIcon} fontSize="14px" />}
          variant="ghost"
          _hover={{ bg: 'none' }}
          isDisabled={isDisabled}
        />
      </Flex>
      {children}
    </Box>
  );
};
