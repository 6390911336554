import { Action, action } from 'easy-peasy';
import { v4 as generate } from 'uuid';

interface StoreError {
  id: string;
  message: string;
}

export interface ErrorStoreModel {
  errors: StoreError[];
  addError: Action<ErrorStoreModel, string>;
  removeError: Action<ErrorStoreModel, string>;
}

const errorStoreModel: ErrorStoreModel = {
  errors: [],
  addError: action((state, message) => {
    state.errors.push({ id: generate(), message });
  }),
  removeError: action((state, id) => {
    const errorIndex = state.errors.findIndex((error) => error.id === id);
    state.errors.splice(errorIndex, 1);
  }),
};

export default errorStoreModel;
