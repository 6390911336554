import { createIcon } from '@chakra-ui/react';

export const UndoArrowIcon = createIcon({
  path: (
    <path
      d="M13.5333 8.73335C10.7066 8.73335 8.14658 9.78935 6.17325 11.5067L2.33325 7.66669V17.2667H11.9333L8.07192 13.4054C9.55458 12.168 11.4426 11.4 13.5333 11.4C17.3093 11.4 20.5199 13.864 21.6399 17.2667L24.1679 16.4347C22.6852 11.9654 18.4933 8.73335 13.5333 8.73335Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 26 26',
});

export const RedoArrowIcon = createIcon({
  path: (
    <path
      d="M19.8267 11.5067C17.8533 9.78935 15.2933 8.73335 12.4667 8.73335C7.50665 8.73335 3.31465 11.9654 1.84265 16.4347L4.35998 17.2667C5.47998 13.864 8.67999 11.4 12.4667 11.4C14.5467 11.4 16.4453 12.168 17.928 13.4054L14.0667 17.2667H23.6667V7.66669L19.8267 11.5067Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 26 26',
});

export const TimeTravelIcon = createIcon({
  path: (
    <path
      d="M17.24 7.76C16.07 6.59 14.54 6 13 6V12L8.76 16.24C11.1 18.58 14.9 18.58 17.25 16.24C19.59 13.9 19.59 10.1 17.24 7.76ZM13 2C7.48 2 3 6.48 3 12C3 17.52 7.48 22 13 22C18.52 22 23 17.52 23 12C23 6.48 18.52 2 13 2ZM13 20C8.58 20 5 16.42 5 12C5 7.58 8.58 4 13 4C17.42 4 21 7.58 21 12C21 16.42 17.42 20 13 20Z"
      fill="white"
    />
  ),
  viewBox: '0 0 26 26',
});

export const MouseClickIcon = createIcon({
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5417 12.8541C18.5417 19.6311 17.25 25.125 9.50004 25.125C1.75004 25.125 0.458374 19.6311 0.458374 12.8541C0.458374 12.6375 0.46251 12.4222 0.470681 12.2083H8.20839H10.7917H18.5294C18.5376 12.4222 18.5417 12.6375 18.5417 12.8541ZM10.7917 9.625H18.2254C17.2907 4.96257 14.3813 1.40441 10.7917 0.707583V9.625ZM9.51666 0.583333C9.51112 0.58332 9.50558 0.583313 9.50004 0.583313C9.4945 0.583313 9.48896 0.58332 9.48342 0.583333L9.51666 0.583333ZM8.2084 0.707576C4.61881 1.40439 1.70936 4.96255 0.774714 9.625H8.20839L8.2084 0.707576Z"
        fill="#C4C4C4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.20835 0.707581V9.62498H0.774719C1.70936 4.96256 4.6188 1.40441 8.20835 0.707581Z"
        fill="white"
      />
    </>
  ),
  viewBox: '0 0 19 26',
});

export const MouseWheelIcon = createIcon({
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5417 12.8541C18.5417 19.6311 17.25 25.125 9.50004 25.125C1.75004 25.125 0.458374 19.6311 0.458374 12.8541C0.458374 12.6375 0.46251 12.4222 0.470681 12.2083H8.20839H10.7917H18.5294C18.5376 12.4222 18.5417 12.6375 18.5417 12.8541ZM10.7917 9.625H18.2254C17.2907 4.96257 14.3813 1.40442 10.7917 0.707583L10.7917 9.625ZM9.51666 0.583333C9.51112 0.58332 9.50558 0.583313 9.50004 0.583313C9.4945 0.583313 9.48896 0.58332 9.48342 0.583333L9.51666 0.583333ZM8.2084 0.707575C4.61881 1.40439 1.70936 4.96255 0.774714 9.625H8.20839L8.2084 0.707575Z"
        fill="#C4C4C4"
      />
      <rect x="8" y="5" width="3" height="11" rx="1.5" fill="white" />
    </>
  ),
  viewBox: '0 0 19 26',
});

export const HistoryStartIcon = createIcon({
  path: (
    <g fill="#ffffff">
      <circle cx="10" cy="10" r="9" stroke="#3E5372" strokeWidth="2" />
      <circle cx="10" cy="10" r="5" fill="#3E5372" />
    </g>
  ),
  viewBox: '0 0 20 20',
});

export const HistoryDefaultIcon = createIcon({
  path: (
    <g fill="#ffffff">
      <circle cx="10" cy="10" r="5" fill="#3E5372" />
    </g>
  ),
  viewBox: '0 0 20 20',
});

export const ArrowDownIcon = createIcon({
  path: (
    <svg width="14" height="6" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 6L0.937822 1.14193e-06L13.0622 8.1987e-08L7 6Z" />
    </svg>
  ),
  viewBox: '0 0 14 6',
});

export const CloseRoundIcon = createIcon({
  path: (
    <svg width="42" height="42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="21" cy="21" r="20" stroke="#3E5372" strokeWidth="2" />
      <path
        d="M28 15.41L26.59 14L21 19.59L15.41 14L14 15.41L19.59 21L14 26.59L15.41 28L21 22.41L26.59 28L28 26.59L22.41 21L28 15.41Z"
        fill="#3E5372"
      />
    </svg>
  ),
  viewBox: '0 0 42 42',
});

export const BoardIcon = createIcon({
  path: (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.3335 9.33333H14.6668V17.3333H25.3335V9.33333ZM28.0002 4H4.00016C2.5335 4 1.3335 5.2 1.3335 6.66667V25.3333C1.3335 26.8 2.5335 27.9733 4.00016 27.9733H28.0002C29.4668 27.9733 30.6668 26.8 30.6668 25.3333V6.66667C30.6668 5.2 29.4668 4 28.0002 4ZM28.0002 25.3467H4.00016V6.64H28.0002V25.3467Z"
        fill="currentColor"
      />
    </svg>
  ),
  viewBox: '0 0 32 32',
});

export const BinIcon = createIcon({
  path: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM8 9H16V19H8V9ZM15.5 4L14.5 3H9.5L8.5 4H5V6H19V4H15.5Z"
        fill="#3E5372"
      />
    </svg>
  ),
  viewBox: '0 0 24 24',
});

export const CommunityIcon = createIcon({
  path: (
    <svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 6C16.66 6 17.99 4.66 17.99 3C17.99 1.34 16.66 0 15 0C13.34 0 12 1.34 12 3C12 4.66 13.34 6 15 6ZM7 6C8.66 6 9.99 4.66 9.99 3C9.99 1.34 8.66 0 7 0C5.34 0 4 1.34 4 3C4 4.66 5.34 6 7 6ZM7 8C4.67 8 0 9.17 0 11.5V14H14V11.5C14 9.17 9.33 8 7 8ZM15 8C14.71 8 14.38 8.02 14.03 8.05C15.19 8.89 16 10.02 16 11.5V14H22V11.5C22 9.17 17.33 8 15 8Z"
        fill="#363636"
      />
    </svg>
  ),
  viewBox: '0 0 22 14',
});

export const ShareIcon = createIcon({
  path: (
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 14.08C14.24 14.08 13.56 14.38 13.04 14.85L5.91 10.7C5.96 10.47 6 10.24 6 10C6 9.76 5.96 9.53 5.91 9.3L12.96 5.19C13.5 5.69 14.21 6 15 6C16.66 6 18 4.66 18 3C18 1.34 16.66 0 15 0C13.34 0 12 1.34 12 3C12 3.24 12.04 3.47 12.09 3.7L5.04 7.81C4.5 7.31 3.79 7 3 7C1.34 7 0 8.34 0 10C0 11.66 1.34 13 3 13C3.79 13 4.5 12.69 5.04 12.19L12.16 16.35C12.11 16.56 12.08 16.78 12.08 17C12.08 18.61 13.39 19.92 15 19.92C16.61 19.92 17.92 18.61 17.92 17C17.92 15.39 16.61 14.08 15 14.08Z"
        fill="#363636"
      />
    </svg>
  ),
  viewBox: '0 0 18 20',
});

export const EditIcon = createIcon({
  path: (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 20V25H5L19.7467 10.2534L14.7467 5.25337L0 20ZM23.6133 6.3867C24.1333 5.8667 24.1333 5.0267 23.6133 4.5067L20.4933 1.3867C19.9733 0.866704 19.1333 0.866704 18.6133 1.3867L16.1733 3.8267L21.1733 8.8267L23.6133 6.3867Z"
        fill="#FFA800"
      />
    </svg>
  ),
  viewBox: '0 0 25 25',
});

export const EditColumnIcon = createIcon({
  path: (
    <path
      d="M10 2.33334C10.2652 2.06813 10.6249 1.91913 11 1.91913C11.1857 1.91913 11.3696 1.95571 11.5412 2.02678C11.7128 2.09785 11.8687 2.20202 12 2.33334C12.1313 2.46466 12.2355 2.62057 12.3066 2.79215C12.3776 2.96373 12.4142 3.14762 12.4142 3.33334C12.4142 3.51906 12.3776 3.70296 12.3066 3.87454C12.2355 4.04612 12.1313 4.20202 12 4.33334L3.66667 12.6667L1 13.3333L1.66667 10.6667L10 2.33334Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  viewBox: '0 0 14 15',
});

export const CopyShareIcon = createIcon({
  path: (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.27259 16.7276C4.06344 15.5184 4.06344 13.5526 5.27259 12.3435L8.10102 9.51506L6.75752 8.17156L3.92909 11C1.97747 12.9516 1.97747 16.1194 3.92909 18.0711C5.8807 20.0227 9.04854 20.0227 11.0002 18.0711L13.8286 15.2426L12.4851 13.8991L9.65665 16.7276C8.4475 17.9367 6.48174 17.9367 5.27259 16.7276ZM8.87884 14.5355L14.5357 8.87867L13.1215 7.46445L7.46462 13.1213L8.87884 14.5355ZM11.0002 3.92892L8.17173 6.75735L9.51523 8.10085L12.3437 5.27242C13.5528 4.06327 15.5186 4.06327 16.7277 5.27242C17.9369 6.48158 17.9369 8.44733 16.7277 9.65649L13.8993 12.4849L15.2428 13.8284L18.0712 11C20.0228 9.04837 20.0228 5.88054 18.0712 3.92892C16.1196 1.97731 12.9518 1.97731 11.0002 3.92892Z"
        fill="#3E5372"
      />
    </svg>
  ),
  viewBox: '0 0 22 22',
});

export const CursorIcon = createIcon({
  path: (
    <svg width="17" height="17" viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.5104 8.00266L16.0743 13.5666L13.8949 15.746L8.33098 10.182L7.97742 9.82849L7.62387 10.182L4.42986 13.3761L1.03523 0.707108L13.7042 4.10174L10.5104 7.29556L10.1568 7.64911L10.5104 8.00266Z" />
    </svg>
  ),
  viewBox: '0 0 17 17',
});

export const PointerIcon = createIcon({
  path: (
    <g fill="none">
      <path
        d="M3 3l7.07 16.97 2.51-7.39 7.39-2.51L3 3z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 13l6 6"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
});

export const HandIcon = createIcon({
  path: (
    <svg width="21" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.9998 24H10.4498C9.36977 24 8.30977 23.55 7.55977 22.77L0.259766 15.16L2.32977 13.33C2.94977 12.78 3.85977 12.67 4.58977 13.06L6.99977 14.34V4.79001C6.99977 3.41001 8.11977 2.29001 9.49977 2.29001C9.66977 2.29001 9.83977 2.31001 10.0098 2.34001C10.0998 1.04001 11.1798 0.0100098 12.4998 0.0100098C13.3598 0.0100098 14.1098 0.44001 14.5598 1.10001C14.8498 0.98001 15.1698 0.92001 15.4998 0.92001C16.8798 0.92001 17.9998 2.04001 17.9998 3.42001V3.70001C18.1598 3.67001 18.3298 3.65001 18.4998 3.65001C19.8798 3.65001 20.9998 4.77001 20.9998 6.15001V20C20.9998 22.21 19.2098 24 16.9998 24ZM3.13977 15.28L8.99977 21.38C9.37977 21.77 9.89977 22 10.4398 22H16.9998C18.0998 22 18.9998 21.1 18.9998 20V6.15001C18.9998 5.87001 18.7798 5.65001 18.4998 5.65001C18.2198 5.65001 17.9998 5.87001 17.9998 6.15001V12H15.9998V3.42001C15.9998 3.14001 15.7798 2.92001 15.4998 2.92001C15.2198 2.92001 14.9998 3.14001 14.9998 3.42001V12H12.9998V2.51001C12.9998 2.23001 12.7798 2.01001 12.4998 2.01001C12.2198 2.01001 11.9998 2.23001 11.9998 2.51001V12H9.99977V4.79001C9.99977 4.51001 9.77977 4.29001 9.49977 4.29001C9.21977 4.29001 8.99977 4.52001 8.99977 4.79001V17.66L3.64977 14.83L3.13977 15.28Z"
        fill="currentColor"
      />
    </svg>
  ),
  viewBox: '0 0 21 24',
});

export const CardIcon = createIcon({
  path: (
    <g fill="none">
      <rect x="1" y="1" width="20" height="16" rx="2" stroke="currentColor" strokeWidth="2" />
      <rect x="4" y="12" width="9" height="2" rx="1" fill="currentColor" />
    </g>
  ),
  viewBox: '0 0 22 18',
});

export const NoteIcon = createIcon({
  path: (
    <g fill="none">
      <path
        d="M23 17V3.75c0-.73-.232-1.429-.644-1.945C21.943 1.29 21.383 1 20.8 1H3.2c-.583 0-1.143.29-1.556.805C1.232 2.321 1 3.021 1 3.75v16.5c0 .73.232 1.429.644 1.945.413.515.973.805 1.556.805h12.1l7.7-6z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23 16.5h-8V23"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
  viewBox: '0 0 24 24',
});

export const ColumnIcon = createIcon({
  path: (
    <g fill="none">
      <path
        d="M25.3333 4H16H6.66667C5.95942 4 5.28115 4.28095 4.78105 4.78105C4.28095 5.28115 4 5.95942 4 6.66667V25.3333C4 26.0406 4.28095 26.7189 4.78105 27.219C5.28115 27.719 5.95942 28 6.66667 28H16H25.3333C26.0406 28 26.7189 27.719 27.219 27.219C27.719 26.7189 28 26.0406 28 25.3333V6.66667C28 5.95942 27.719 5.28115 27.219 4.78105C26.7189 4.28095 26.0406 4 25.3333 4Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="7" y="8" width="14" height="7" rx="2" fill="currentColor" />
      <rect x="11" y="17" width="14" height="7" rx="2" fill="currentColor" />
    </g>
  ),
  viewBox: '0 0 32 32',
});

export const ListIcon = createIcon({
  path: (
    <g fill="none">
      <path
        d="M25.3333 4H16H6.66667C5.95942 4 5.28115 4.28095 4.78105 4.78105C4.28095 5.28115 4 5.95942 4 6.66667V25.3333C4 26.0406 4.28095 26.7189 4.78105 27.219C5.28115 27.719 5.95942 28 6.66667 28H16H25.3333C26.0406 28 26.7189 27.719 27.219 27.219C27.719 26.7189 28 26.0406 28 25.3333V6.66667C28 5.95942 27.719 5.28115 27.219 4.78105C26.7189 4.28095 26.0406 4 25.3333 4Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="9" y="8" width="14" height="7" rx="2" fill="currentColor" />
      <rect x="9" y="17" width="14" height="7" rx="2" fill="currentColor" />
    </g>
  ),
  viewBox: '0 0 32 32',
});

export const SwimlaneIcon = createIcon({
  path: (
    <g fill="none">
      <rect
        x="25"
        y="1"
        width="12"
        height="24"
        rx="2"
        transform="rotate(90 25 1)"
        stroke="currentcolor"
        strokeWidth="2"
      />
    </g>
  ),
  viewBox: '0 0 26 14',
});

export const PenIcon = createIcon({
  path: (
    <g fill="none">
      <path
        d="M16 2.00006C16.2626 1.73741 16.5744 1.52907 16.9176 1.38693C17.2608 1.24479 17.6286 1.17163 18 1.17163C18.3714 1.17163 18.7392 1.24479 19.0824 1.38693C19.4256 1.52907 19.7374 1.73741 20 2.00006C20.2626 2.2627 20.471 2.57451 20.6131 2.91767C20.7553 3.26083 20.8284 3.62862 20.8284 4.00006C20.8284 4.37149 20.7553 4.73929 20.6131 5.08245C20.471 5.42561 20.2626 5.73741 20 6.00006L6.5 19.5001L1 21.0001L2.5 15.5001L16 2.00006Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
  viewBox: '0 0 22 22',
});

export const CheckIcon = createIcon({
  path: (
    <g fill="none">
      <path
        d="M17 1L6 12L1 7"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
  viewBox: '0 0 18 13',
});

export const DotsIcon = createIcon({
  path: (
    <g fill="none">
      <path
        d="M9 3C9.55228 3 10 2.55228 10 2C10 1.44772 9.55228 1 9 1C8.44772 1 8 1.44772 8 2C8 2.55228 8.44772 3 9 3Z"
        stroke="#3F5474"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 3C16.5523 3 17 2.55228 17 2C17 1.44772 16.5523 1 16 1C15.4477 1 15 1.44772 15 2C15 2.55228 15.4477 3 16 3Z"
        stroke="#3F5474"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 3C2.55228 3 3 2.55228 3 2C3 1.44772 2.55228 1 2 1C1.44772 1 1 1.44772 1 2C1 2.55228 1.44772 3 2 3Z"
        stroke="#3F5474"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
  viewBox: '0 0 18 4',
});

export const MetricsIcon = createIcon({
  path: (
    <g stroke="currentColor">
      <path d="M24 26.6666V13.3333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16 26.6666V5.33331" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8 26.6667V18.6667" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
  ),
  viewBox: '0 0 32 32',
});

export const FrameIcon = createIcon({
  path: (
    <path
      d="M3.33317 12.6667H0.666504V19.3333H7.33317V16.6667H3.33317V12.6667ZM0.666504 7.33332H3.33317V3.33332H7.33317V0.666656H0.666504V7.33332ZM16.6665 16.6667H12.6665V19.3333H19.3332V12.6667H16.6665V16.6667ZM12.6665 0.666656V3.33332H16.6665V7.33332H19.3332V0.666656H12.6665Z"
      fill="white"
    />
  ),
  viewBox: '0 0 20 20',
});

export const FileIcon = createIcon({
  path: (
    <path
      d="M13.6667 0.666748H3.00001C1.53334 0.666748 0.346677 1.86675 0.346677 3.33341L0.333344 24.6667C0.333344 26.1334 1.52001 27.3334 2.98668 27.3334H19C20.4667 27.3334 21.6667 26.1334 21.6667 24.6667V8.66675L13.6667 0.666748ZM3.00001 24.6667V3.33341H12.3333V10.0001H19V24.6667H3.00001Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 28 28',
});

export const AddReactionIcon = createIcon({
  path: (
    <g clipPath="url(#clip0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.03613 1.56055C4.34826 2.03077 1.5 5.18406 1.5 9.00004C1.5 13.14 4.8525 16.5 8.9925 16.5C12.9776 16.5 16.2357 13.398 16.4847 9.48254C11.0447 9.28826 8 7.44694 8 2.50004C8 2.18822 8.0121 1.87462 8.03613 1.56055ZM7.5 7.12504C7.5 6.50254 6.9975 6.00004 6.375 6.00004C5.7525 6.00004 5.25 6.50254 5.25 7.12504C5.25 7.74754 5.7525 8.25004 6.375 8.25004C6.9975 8.25004 7.5 7.74754 7.5 7.12504ZM5.25 10.5C5.835 12.255 7.29 13.5 9 13.5C10.71 13.5 12.165 12.255 12.75 10.5H5.25Z"
        fill="#5C5C5C"
      />
      <rect x="17" y="3" width="2" height="8" rx="1" transform="rotate(90 17 3)" fill="#5C5C5C" />
      <rect x="17" y="3" width="2" height="8" rx="1" transform="rotate(90 17 3)" fill="#5C5C5C" />
      <rect x="12" width="2" height="8" rx="1" fill="#5C5C5C" />
      <rect x="12" width="2" height="8" rx="1" fill="#5C5C5C" />
    </g>
  ),
  viewBox: '0 0 18 18',
});

export const ReceiveNotificationsInactiveIcon = createIcon({
  path: (
    <path
      d="M8 20C9.1 20 10 19.1 10 18H6C6 19.1 6.89 20 8 20ZM14 14V9C14 5.93 12.36 3.36 9.5 2.68V2C9.5 1.17 8.83 0.5 8 0.5C7.17 0.5 6.5 1.17 6.5 2V2.68C3.63 3.36 2 5.92 2 9V14L0 16V17H16V16L14 14Z"
      fill="#3F5474"
    />
  ),
  viewBox: '0 0 16 20',
});

export const ReceiveNotificationsActiveIcon = createIcon({
  path: (
    <path
      d="M5.58 2.08L4.15 0.65C1.75 2.48 0.169999 5.3 0.0299988 8.5H2.03C2.18 5.85 3.54 3.53 5.58 2.08ZM17.97 8.5H19.97C19.82 5.3 18.24 2.48 15.85 0.65L14.43 2.08C16.45 3.53 17.82 5.85 17.97 8.5ZM16 9C16 5.93 14.36 3.36 11.5 2.68V2C11.5 1.17 10.83 0.5 10 0.5C9.17 0.5 8.5 1.17 8.5 2V2.68C5.63 3.36 4 5.92 4 9V14L2 16V17H18V16L16 14V9ZM10 20C10.14 20 10.27 19.99 10.4 19.96C11.05 19.82 11.58 19.38 11.84 18.78C11.94 18.54 11.99 18.28 11.99 18H7.99C8 19.1 8.89 20 10 20Z"
      fill="#257BFF"
    />
  ),
  viewBox: '0 0 20 20',
});

export const NotificationsIcon = createIcon({
  path: (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.93717 14.2448C5.73102 14.7031 6.75554 14.4286 7.21388 13.6347L4.32712 11.9681C3.86879 12.7619 4.13609 13.7823 4.93717 14.2448ZM11.7673 12.4147L13.8506 8.80621C15.1298 6.59063 15.0171 4.05256 13.2364 2.37014L13.5197 1.8794C13.8655 1.2804 13.6612 0.517698 13.0622 0.171865C12.4632 -0.173969 11.7005 0.0303954 11.3546 0.629396L11.0713 1.12014C8.71673 0.415059 6.47371 1.58341 5.19037 3.80621L3.10704 7.41465L0.830332 8.02469L0.413665 8.74638L11.9607 15.413L12.3773 14.6914L11.7673 12.4147Z"
        fill="white"
      />
    </svg>
  ),
  viewBox: '0 0 15 16',
});

export const TrashIcon = createIcon({
  path: (
    <path
      d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM3 6H11V16H3V6ZM10.5 1L9.5 0H4.5L3.5 1H0V3H14V1H10.5Z"
      fill="#3E5372"
    />
  ),
  viewBox: '0 0 14 18',
});

export const DownloadIcon = createIcon({
  path: (
    <path
      d="M14 11V14H2V11H0V14C0 15.1 0.9 16 2 16H14C15.1 16 16 15.1 16 14V11H14ZM13 7L11.59 5.59L9 8.17V0H7V8.17L4.41 5.59L3 7L8 12L13 7Z"
      fill="white"
    />
  ),
  viewBox: '0 0 16 16',
});

export const DragHandle = createIcon({
  path: (
    <path
      d="M4 14C4 15.1 3.1 16 2 16C0.9 16 0 15.1 0 14C0 12.9 0.9 12 2 12C3.1 12 4 12.9 4 14ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0ZM8 4C9.1 4 10 3.1 10 2C10 0.9 9.1 0 8 0C6.9 0 6 0.9 6 2C6 3.1 6.9 4 8 4ZM8 6C6.9 6 6 6.9 6 8C6 9.1 6.9 10 8 10C9.1 10 10 9.1 10 8C10 6.9 9.1 6 8 6ZM8 12C6.9 12 6 12.9 6 14C6 15.1 6.9 16 8 16C9.1 16 10 15.1 10 14C10 12.9 9.1 12 8 12Z"
      fill="#868686"
    />
  ),
  viewBox: '0 0 10 16',
});

export const SearchIcon = createIcon({
  path: (
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.6667 14.6667H15.6133L15.24 14.3067C16.5467 12.7867 17.3333 10.8133 17.3333 8.66667C17.3333 3.88 13.4533 0 8.66667 0C3.88 0 0 3.88 0 8.66667C0 13.4533 3.88 17.3333 8.66667 17.3333C10.8133 17.3333 12.7867 16.5467 14.3067 15.24L14.6667 15.6133V16.6667L21.3333 23.32L23.32 21.3333L16.6667 14.6667ZM8.66667 14.6667C5.34667 14.6667 2.66667 11.9867 2.66667 8.66667C2.66667 5.34667 5.34667 2.66667 8.66667 2.66667C11.9867 2.66667 14.6667 5.34667 14.6667 8.66667C14.6667 11.9867 11.9867 14.6667 8.66667 14.6667Z"
        fill="currentColor"
      />
    </svg>
  ),
  viewBox: '0 0 24 24',
});

export const DiscordIcon = createIcon({
  path: (
    <svg width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.4705 1.60291C18.0045 0.923365 16.4325 0.422703 14.7889 0.135953C14.759 0.130419 14.7291 0.144249 14.7137 0.17191C14.5115 0.535187 14.2875 1.00911 14.1307 1.38161C12.3629 1.11423 10.6041 1.11423 8.87256 1.38161C8.7157 1.00083 8.48363 0.535187 8.28055 0.17191C8.26513 0.145172 8.23523 0.131342 8.2053 0.135953C6.56258 0.421787 4.99058 0.922448 3.52373 1.60291C3.51103 1.60844 3.50015 1.61767 3.49292 1.62965C0.511167 6.13011 -0.305661 10.5199 0.0950476 14.8554C0.0968607 14.8766 0.108646 14.8969 0.124965 14.9098C2.09224 16.3693 3.99789 17.2554 5.86814 17.8427C5.89808 17.852 5.92979 17.8409 5.94884 17.816C6.39125 17.2056 6.78562 16.5621 7.12375 15.8853C7.1437 15.8456 7.12466 15.7986 7.08387 15.7829C6.45834 15.5432 5.8627 15.2509 5.28974 14.919C5.24442 14.8922 5.24079 14.8268 5.28249 14.7954C5.40306 14.7041 5.52366 14.6092 5.63879 14.5133C5.65962 14.4958 5.68865 14.4921 5.71314 14.5031C9.47723 16.2393 13.5523 16.2393 17.272 14.5031C17.2965 14.4911 17.3255 14.4948 17.3472 14.5123C17.4624 14.6082 17.583 14.7041 17.7045 14.7954C17.7462 14.8268 17.7434 14.8922 17.6981 14.919C17.1252 15.2574 16.5295 15.5432 15.9031 15.782C15.8623 15.7977 15.8442 15.8456 15.8641 15.8853C16.2095 16.5611 16.6039 17.2047 17.0381 17.8151C17.0562 17.8409 17.0889 17.852 17.1188 17.8427C18.9981 17.2554 20.9038 16.3693 22.871 14.9098C22.8883 14.8969 22.8992 14.8775 22.901 14.8563C23.3805 9.84407 22.0977 5.49023 19.5004 1.63057C19.494 1.61767 19.4832 1.60844 19.4705 1.60291ZM7.68586 12.2155C6.5526 12.2155 5.61884 11.1644 5.61884 9.87359C5.61884 8.58272 6.53449 7.53162 7.68586 7.53162C8.84625 7.53162 9.77098 8.59195 9.75284 9.87359C9.75284 11.1644 8.83718 12.2155 7.68586 12.2155ZM15.3283 12.2155C14.1951 12.2155 13.2613 11.1644 13.2613 9.87359C13.2613 8.58272 14.1769 7.53162 15.3283 7.53162C16.4887 7.53162 17.4134 8.59195 17.3953 9.87359C17.3953 11.1644 16.4887 12.2155 15.3283 12.2155Z"
        fill="currentColor"
      />
    </svg>
  ),
  viewBox: '0 0 23 18',
});

export const MenuDots = createIcon({
  path: (
    <svg width="16" height="4" viewBox="0 0 16 4" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0ZM14 0C12.9 0 12 0.9 12 2C12 3.1 12.9 4 14 4C15.1 4 16 3.1 16 2C16 0.9 15.1 0 14 0ZM8 0C6.9 0 6 0.9 6 2C6 3.1 6.9 4 8 4C9.1 4 10 3.1 10 2C10 0.9 9.1 0 8 0Z"
        fill="#3F5474"
      />
    </svg>
  ),
  viewBox: '0 0 16 4',
});

export const ExpandIcon = createIcon({
  path: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.59 8.58997L12 13.17L7.41 8.58997L6 9.99997L12 16L18 9.99997L16.59 8.58997Z"
        fill="#32435D"
      />
    </svg>
  ),
  viewBox: '0 0 24 24',
});
