import fetchClient from 'lib/Fetch';
import { getPersistedPreviewImageForBoard, PREVIEW_IMAGE_PREFIX } from './helpers';
import logger from './logger';

type PreviewCandidate = {
  boardId: string;
  // flag that is set if that board already has a preview image
  // and needs to check whether the image is stale
  isRevalidate: boolean;
};

export enum PreviewImageStatus {
  'STALE',
  'GENERATING',
  'REVALIDATING',
}

type SubscribeFunc = (newStatus: PreviewImageStatus) => void;

/**
 * Class that handles the generation of the preview images
 * that are shown on the /myboards page
 */
class PreviewImageService {
  private iframe: HTMLIFrameElement | null = null;

  private queue = new Set<string>();

  private revalidateQueue = new Set<string>();

  private subscribers: Record<string, Array<SubscribeFunc>> = {};

  initialize(): HTMLIFrameElement {
    if (!this.iframe) {
      this.iframe = document.createElement('iframe');
      this.iframe.width = String(window.innerWidth);
      this.iframe.height = String(window.innerHeight);
      this.iframe.style.position = 'fixed';
      this.iframe.style.zIndex = '-100';
      this.iframe.style.top = '0';
      this.iframe.style.left = '0';
    }
    return this.iframe;
  }

  private unmountIframe() {
    this.iframe?.remove();
  }

  /**
   * Function that allows the user to subscribe to the status of the preview image
   * for the given boardId
   * @param boardId the id of the board
   * @param handler the function that will be called when the status changes
   * @returns the unsubscribe function
   */
  subscribe(boardId: string, handler: SubscribeFunc): () => void {
    this.subscribers[boardId] = [...(this.subscribers[boardId] ?? []), handler];
    return () => {
      this.subscribers[boardId] = this.subscribers[boardId]?.filter(
        (subscriber) => subscriber !== handler
      );
    };
  }

  /**
   * Function that returns the next preview candidate that needs to be processed
   * This is either the first item of the normal queue
   * or if that is empty the first item of the regeneration queue
   * @returns the next preview candidate
   */
  private getNextPreviewCandidate(): PreviewCandidate | null {
    let next = this.queue.values().next();

    if (next.done) {
      next = this.revalidateQueue.values().next();
      if (next.done) {
        return null;
      }
      logger.debug(
        'PreviewImageService.getNextPreviewCandidate(): Revalidating boardId:',
        next.value
      );
      return { boardId: next.value, isRevalidate: true };
    }

    logger.debug('PreviewImageService.getNextPreviewCandidate(): Processing boardId:', next.value);
    return { boardId: next.value, isRevalidate: false };
  }

  private removeFromQueue(boardId: string) {
    this.revalidateQueue.delete(boardId);
    this.queue.delete(boardId);
  }

  /**
   * Fetches the checksum of the given board
   * @param boardId id of the board
   * @returns the checksum of the board
   */
  // eslint-disable-next-line class-methods-use-this
  private async getServerHash(boardId: string) {
    const { serverCheckSum } = await fetchClient<{ serverCheckSum: string }>(
      `boards/${boardId}/checksum`,
      {
        method: 'GET',
      }
    );
    return serverCheckSum;
  }

  removeBoardFromQueue(boardId: string): void {
    this.removeFromQueue(boardId);
  }

  /**
   * Add one or many boards to the queue to be processed
   * @param boardIds the ids of one or many boards
   */
  enqueue(boardIds: string | string[]): void {
    const ids = Array.isArray(boardIds) ? boardIds : [boardIds];
    ids.forEach((boardId) => {
      this.queue.add(boardId);
      logger.debug('PreviewImageService.enqueue(): Enqueued boardId:', boardId);
    });
    this.processNextQueueItem();
  }

  private moveToRevalidateQueue(boardId: string) {
    this.queue.delete(boardId);
    this.revalidateQueue.add(boardId);
  }

  /**
   * Function that finishes and cleans up the current image generation
   * as well as starting the next image generation
   * @param boardId the id of the currently processed board
   */
  private transitionToNextQueueItem(boardId: string) {
    this.notifySubscriber(boardId, PreviewImageStatus.STALE);
    this.unmountIframe();
    this.removeFromQueue(boardId);
    this.processNextQueueItem();
  }

  private notifySubscriber(boardId: string, newStatus: PreviewImageStatus) {
    const handlers = this.subscribers[boardId];
    handlers.forEach((handler) => handler(newStatus));
  }

  private async processNextQueueItem() {
    const nextValue = this.getNextPreviewCandidate();

    // no more items in the queue, we're done
    if (!nextValue) return;

    const { boardId, isRevalidate } = nextValue;

    logger.debug('PreviewImageService.process(): Requesting processing:', {
      boardId,
      isRevalidate,
    });

    this.iframe = this.initialize();

    const { previewImageHash } = getPersistedPreviewImageForBoard(boardId);
    // if we already have stored a hash for this board, we can check whether it's stale
    if (previewImageHash) {
      // if the current board isn't already marked as revalidating
      // we move it to the (lower priority) revalidation queue
      if (!isRevalidate) {
        this.moveToRevalidateQueue(boardId);

        // move to the next item without cleanup (nothing happened yet)
        this.processNextQueueItem();
        return;
      }

      const serverHash = await this.getServerHash(boardId);
      // if the hash on the server is the same as the hash we have stored
      // we can just call it a day and move on
      if (serverHash === previewImageHash) {
        this.transitionToNextQueueItem(boardId);
        return;
      }
    }

    // if we get here, we either need to
    // 1. generate a new preview image
    // 2. or revalidate the current preview image

    this.notifySubscriber(
      boardId,
      isRevalidate ? PreviewImageStatus.REVALIDATING : PreviewImageStatus.GENERATING
    );

    // The iframe will only create the screenshot if its mounted to the DOM
    const url = `${window.location.origin}/board/${boardId}?screenshot=true`;
    this.iframe.src = url;
    document.body.appendChild(this.iframe);

    logger.debug('PreviewImageService.process(): Creating screenshot for boardId:', boardId);

    const handler = (e: StorageEvent) => {
      const { key } = e;
      // check if the change was actually a updated preview
      if (!key?.startsWith(PREVIEW_IMAGE_PREFIX)) return;
      const eventBoardId = key.split(PREVIEW_IMAGE_PREFIX)[1];
      if (eventBoardId !== boardId) return;
      // we know that that exact storage event was caused because the preview
      // for this board got updated

      const updatedHash = getPersistedPreviewImageForBoard(boardId);

      if (updatedHash.previewImageHash !== previewImageHash && updatedHash.previewImage) {
        logger.debug(
          'PreviewImageService.process(): Successfully created image for board:',
          boardId
        );

        window.removeEventListener('storage', handler, false);

        this.transitionToNextQueueItem(boardId);
      }
    };

    // the storage event (https://developer.mozilla.org/en-US/docs/Web/API/Window/storage_event)
    // is fired when the localStorage changes
    // this is the case if the screenshot was taken and stored in the iframe
    window.addEventListener('storage', handler, false);
  }
}

export const previewImageService = new PreviewImageService();
