import { BoardElement, BoardElementLink, BoardElementLinkType } from 'shared';
import { Box, Flex, Stack, Heading, Text, IconButton, Icon, Button } from '@chakra-ui/react';
import { getCardColorByName } from 'lib/colors';
import { groupBy } from 'lib/helpers';
import { getLinkDescription, getVisualLinksThatHaveBoardElement } from 'lib/Links.helper';
import { useStoreActions, useStoreState } from 'store/hooks';
import { BinIcon } from 'lib/icons';
import nonUpdatingStore from 'store/index';
import { MdOutlineVisibility } from 'react-icons/md';
import { zoomAndFocusElement } from 'lib/zoom';
import { useRouter } from 'next/router';
import { Section } from './Section';
import { trackPlausibleEvent } from '../../../lib/plausible';
import { CLIENT_PLAUSIBLE_EVENTS } from '../../../lib/Types';

type CardLinkProps = BoardElementLink<BoardElement> & {
  onDelete: () => void;
  isDisabled?: boolean;
};

export function getGroupedLinks(links: BoardElementLink<BoardElement>[]) {
  return Object.entries(groupBy(links, (link) => link.type)) as unknown as [
    [BoardElementLinkType, BoardElementLink[]]
  ];
}

function CardLink({ targetElement, onDelete, isDisabled }: CardLinkProps) {
  const targetElementId = targetElement.id;
  const currentElement = useStoreState((state) =>
    state.board.boardElements.find((el) => el.id === targetElementId)
  );
  const router = useRouter();
  const isInArchive = router.asPath.includes('/archive');

  return (
    <Flex
      role="button"
      alignItems="center"
      w="100%"
      justifyContent="space-between"
      bg="taggleGray.300"
      _hover={{ bg: 'taggleGray.500' }}
      borderRadius="sm"
      px={2}
      py={1}
      onClick={() => {
        nonUpdatingStore.getActions().widgets.closeDetailView();
        if (isInArchive) {
          // No currentElement means that the element is archived
          // and the lookup in the boardelements was not possible
          if (!currentElement || currentElement.isArchived) {
            router.push({
              ...router,
              query: { element: targetElementId },
            });
          } else {
            router.push(`/board/${router.query.boardId}?element=${targetElementId}`);
          }
          // see above
        } else if (!currentElement || currentElement.isArchived) {
          router.push(`/board/${router.query.boardId}/archive?element=${targetElementId}`);
        } else {
          zoomAndFocusElement(currentElement);
        }
      }}
    >
      <Flex>
        <Box
          h="20px"
          w="20px"
          boxShadow="layer1.css"
          borderRadius="sm"
          bg={getCardColorByName(currentElement?.color).value}
          mr={2}
        />
        <Text fontWeight="400">
          {currentElement ? currentElement.title : <Text as="i">Archived Card</Text>}
        </Text>
      </Flex>
      <IconButton
        variant="ghost"
        color="yoBrand.500"
        fontSize={22}
        icon={<BinIcon />}
        title="Delete link"
        aria-label="Delete link"
        isDisabled={isDisabled}
        onClick={(e) => {
          if (isDisabled) return;
          e.stopPropagation();
          onDelete();
        }}
        ml={3}
        mr={1}
        _hover={{ bg: 'taggleOceanBlue.100' }}
        _active={{ bg: 'taggleOceanBlue.100', border: 'none' }}
        _focus={{ bg: 'taggleOceanBlue.100', border: 'none' }}
      />
    </Flex>
  );
}

interface BoardElementLinkSectionProps {
  links: BoardElementLink<BoardElement>[] | undefined;
  cardId: string;
  isDisabled?: boolean;
}

export function BoardElementLinkSection({
  links,
  cardId,
  isDisabled = false,
}: BoardElementLinkSectionProps) {
  const { setIsLinkPopoverOpen: openLinkSelection, setActiveLinkCardId } = useStoreActions(
    (actions) => actions.app
  );
  const boardElements = useStoreState((store) => store.board.boardElements);
  return (
    <Section
      title="Linked Cards"
      onAddClick={() => {
        trackPlausibleEvent(CLIENT_PLAUSIBLE_EVENTS.OPENED_LINK_SEARCH, {
          location: 'DetailView - LinkSection',
        });
        openLinkSelection(true);
      }}
      show={links && links.length > 0}
      addText="Add Link"
      isDisabled={isDisabled}
    >
      <Stack>
        {getGroupedLinks(links ?? []).map(([type, subLink]) => (
          <Box key={type}>
            <Heading fontWeight="700" size="sm" mb={4}>
              {getLinkDescription(type)}
            </Heading>
            <Stack>
              {subLink.map((link) => (
                <CardLink
                  key={link._id}
                  onDelete={() =>
                    nonUpdatingStore.getActions().board.deleteLink({ linkToDelete: link, cardId })
                  }
                  isDisabled={isDisabled}
                  {...link}
                />
              ))}
            </Stack>
          </Box>
        ))}
      </Stack>
      {!isDisabled && getVisualLinksThatHaveBoardElement(links, boardElements).length > 0 && (
        <Button
          mt={4}
          pl={0}
          leftIcon={<Icon as={MdOutlineVisibility} fontSize="24px" color="yoBrand.500" />}
          variant="ghost"
          color="yoBrand.500"
          fontSize="16px"
          fontWeight="700"
          title="Show all links"
          aria-label="Show all links"
          _hover={{ bg: 'transparent' }}
          _active={{ bg: 'transparent', border: 'none' }}
          onClick={() => {
            trackPlausibleEvent(CLIENT_PLAUSIBLE_EVENTS.DISPLAY_LINKS, {
              location: 'DetailView',
            });
            setActiveLinkCardId(cardId);
            nonUpdatingStore.getActions().widgets.closeDetailView({ focusAfterClose: true });
          }}
          mr="auto"
        >
          Show all links on the board
        </Button>
      )}
    </Section>
  );
}
