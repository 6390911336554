/* eslint-disable import/prefer-default-export */
import Konva from 'konva';
import { Position } from 'store/app';
import { Stage } from 'konva/types/Stage';
import { useEffect, useState } from 'react';
import { Image as KonvaImage } from 'konva/types/shapes/Image';
import { previewImageService, PreviewImageStatus } from '../lib/BoardImageService';
import { getProxiedAttachmentURL } from './useImage';

export function getPreviewImage(
  stage: Stage,
  canvasRef: HTMLDivElement,
  offset: Position,
  zoomLevel: number
) {
  const previewStage = new Konva.Stage({
    container: canvasRef,
    width: window.innerWidth,
    height: window.innerHeight,
    scaleX: zoomLevel,
    scaleY: zoomLevel,
  });

  previewStage.position({ x: offset.x * zoomLevel, y: offset.y * zoomLevel });
  previewStage.scale({ x: zoomLevel, y: zoomLevel });

  stage.getLayers().each((layer) => {
    const previewLayer = layer.clone({ listening: false });
    previewStage.add(previewLayer);

    // we just need to update ALL nodes in the preview
    layer.children.each((shape) => {
      // find cloned node
      const clone = previewLayer.findOne(`.${shape.name()}`);
      // update its position from the original
      if (clone) {
        clone.position(shape.position());
      }
    });
  });

  // we need to update the images in the cloned stage
  // because of the tainted canvas issue.
  // for more see the getProxiedAttachmentURL function
  previewStage.find('Image').each((image) => {
    const img = image as KonvaImage;
    const currentImage = img.image() as HTMLImageElement;

    currentImage.src = getProxiedAttachmentURL(currentImage.src, () => true);
    img.image(currentImage);
  });
  previewStage.batchDraw();

  const previewImage = previewStage.toDataURL({
    pixelRatio: 0.2,
  });

  // Clean up
  previewStage.removeChildren();
  previewStage.remove();

  return previewImage;
}

export function usePreviewImageStatus(boardId: string) {
  const [status, setStatus] = useState<PreviewImageStatus>(PreviewImageStatus.STALE);
  useEffect(() => {
    const unsubscribe = previewImageService.subscribe(boardId, setStatus);
    return () => {
      unsubscribe();
    };
  }, [boardId]);

  return status;
}
