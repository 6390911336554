import { STAGE_NAME } from 'components/canvas/Canvas/Canvas';
import { animate } from 'framer-motion';
import { MIN_SCALE, MAX_SCALE, STAGE_REPOSITION } from 'hooks/index';
import { zoomToElement } from 'hooks/useCardDeepLink';
import { Stage } from 'konva/types/Stage';
import { Position } from 'store/app';
import { ZoomAndOffset } from 'store/board/settings';
import { BoardElementState, UIBoardElement } from 'types/index';
import nonUpdatingStore from 'store/index';
import { BoardElement } from 'shared';
import logger from './logger';

export const SCALE_STAGE_BY = 1.25;

const animateValue = (from: number, to: number, onUpdate: (value: number) => void) =>
  new Promise<void>((resolve) => {
    animate<number>(from, to, {
      type: 'tween',
      ease: 'easeInOut',
      duration: 0.5,
      onUpdate: (v) => {
        onUpdate(v);
      },
      onComplete: () => {
        resolve();
      },
    });
  });

export async function animateZoomTo({
  position,
  zoomLevel,
  stage,
  setZoomLevel,
  setOffset,
}: {
  // the new Position to pan to
  position: Position;
  // the target Zoomlevel
  zoomLevel: number;
  // The Konva stage which should be zoomed
  stage: Stage;
  // Function to update the zoomLevel in the store
  setZoomLevel: (newZoomLevel: number) => void;
  // Function to update the stageOffset in the store
  setOffset: (newOffset: Position) => void;
  // Should there be a increment on the zoom instead of multiplication
}) {
  const currentPosition = stage.position();
  const currentScale = stage.scaleX();

  const animationPosition = {
    x: currentPosition.x,
    y: currentPosition.y,
  };

  await Promise.all([
    animateValue(currentPosition.x, position.x * zoomLevel, (value) => {
      animationPosition.x = value;
      stage.position(animationPosition);
      stage.batchDraw();
    }),
    animateValue(currentPosition.y, position.y * zoomLevel, (value) => {
      animationPosition.y = value;
      stage.position(animationPosition);
      stage.batchDraw();
    }),
    animateValue(currentScale, zoomLevel, (value) => {
      stage.scale({ x: value, y: value });
      stage.batchDraw();
    }),
  ]);

  setZoomLevel(zoomLevel);
  setOffset({ x: position.x * zoomLevel, y: position.y * zoomLevel });
}

export function scaleWithBounds(newScale: number) {
  return Math.min(Math.max(newScale, MIN_SCALE), MAX_SCALE);
}

/**
 * Function to zoom the Stage including
 * updating the store and rerendering elements
 * @param options the options
 */
export function zoomStage({
  zoomIn,
  zoomTo,
  addToScale,
  stage,
  setToOne = false,
  multiplyScaleBy = SCALE_STAGE_BY,
  setZoomLevelAndOffset,
}: {
  // The Konva stage which should be zoomed
  stage: Stage;
  // Position to zoom to
  zoomTo?: Position;
  // Boolean whether it should zoom in or out
  zoomIn?: boolean;
  // Should there be a increment on the zoom instead of multiplication
  addToScale?: number;
  // Option to instantly zoom to 1 / 100%
  setToOne?: boolean;
  // Multiply the scaleBy using the factor
  multiplyScaleBy?: number;
  // Function to update the stageOffset and the zoomLevel in the store
  setZoomLevelAndOffset: ({ zoomLevel, offset }: ZoomAndOffset) => void;
}) {
  const currentScale = stage.scaleX();
  let newScale = 0;

  const zoomToPosition = zoomTo ?? {
    x: window.innerWidth / 2,
    y: window.innerHeight / 2,
  };

  const mousePointTo = {
    x: ((zoomToPosition.x || 0) - stage.x()) / currentScale,
    y: ((zoomToPosition.y || 0) - stage.y()) / currentScale,
  };

  if (addToScale) {
    newScale = zoomIn ? currentScale + addToScale : currentScale - addToScale;
  } else if (setToOne) {
    const scaleBy = 1 / currentScale;
    newScale = currentScale * scaleBy;
  } else {
    newScale = currentScale * multiplyScaleBy;
  }

  newScale = scaleWithBounds(newScale);

  // Round values around 100% to 100%
  if (newScale <= 1.05 && newScale >= 0.95) {
    newScale = 1;
  }

  stage.scale({ x: newScale, y: newScale });
  const newPos = {
    x: (zoomToPosition.x || 0) - mousePointTo.x * newScale,
    y: (zoomToPosition.y || 0) - mousePointTo.y * newScale,
  };
  stage.position(newPos);
  window.dispatchEvent(new Event(STAGE_REPOSITION));
  stage.batchDraw();
  setZoomLevelAndOffset({ offset: newPos, zoomLevel: newScale });
}

/**
 * Helper function to get the current konva stage
 * from the window
 */
export function getStage(): Stage | undefined {
  if (typeof window === 'undefined') return undefined;
  const allStages = (window as any).Konva.stages as Stage[];
  if (allStages?.length === 0) return undefined;
  return allStages.find((stage) => stage.id() === STAGE_NAME);
}

/**
 * Function to get the next zoom increment depending
 * on the current zoom level
 * @param currentZoom the current zoom level
 */
export function getNextZoomIncrement(currentZoom: number) {
  return currentZoom < 1.5 ? 0.25 : 0.5;
}

/**
 * Helper function to reset the zoomLevel to 1 (100%)
 */
export function resetZoom({
  stage,
  setZoomLevelAndOffset,
}: {
  stage: Stage;
  setZoomLevelAndOffset: ({ zoomLevel, offset }: { zoomLevel: number; offset: Position }) => void;
}) {
  zoomStage({
    stage,
    setZoomLevelAndOffset,
    setToOne: true,
  });
}

export const MOVE_INCREMENT = 20;

export function getStagePositionForElementDrag({
  currentElementPosition: currentPosition,
  currentElement,
  offset,
  zoomLevel,
  screenHeight,
  screenWidth,
}: {
  offset: Position;
  currentElementPosition: Position;
  currentElement: UIBoardElement;
  zoomLevel: number;
  screenWidth: number;
  screenHeight: number;
}): { newPos: Position; moveVector: Position } | null {
  const elementWidth = currentElement.shape?.width ?? 0;
  const elementHeight = currentElement.shape?.height ?? 0;

  const rightPos = currentPosition.x + elementWidth * zoomLevel;
  const bottomPos = currentPosition.y + elementHeight * zoomLevel;

  const scrollVector: Position = {
    x: 0,
    y: 0,
  };

  if (rightPos > screenWidth) {
    scrollVector.x = -MOVE_INCREMENT;
  }
  if (currentPosition.x < 0) {
    scrollVector.x = MOVE_INCREMENT;
  }
  if (screenHeight < bottomPos) {
    scrollVector.y = -MOVE_INCREMENT;
  }
  if (currentPosition.y < 0) {
    scrollVector.y = MOVE_INCREMENT;
  }

  if (scrollVector.x === 0 && scrollVector.y === 0) {
    return null;
  }

  // Direction is either 1, -1 or 0. 1 means move left, -1 means move right and 0 means no horizontal movement
  const xDirection = Math.sign(scrollVector.x);
  // Direction is either 1, -1 or 0. 1 means move to the top, -1 means move to the bottom and 0 means no vertical movement
  const yDirection = Math.sign(scrollVector.y);

  let speedFactor = 1;

  // moving right
  if (xDirection === -1) {
    const rightDiff = rightPos - screenWidth;
    if (rightDiff > elementWidth / 2) {
      speedFactor = 2;
    }
  }

  // moving left
  if (xDirection === 1) {
    const leftDiff = Math.abs(currentPosition.x);
    if (leftDiff > elementWidth / 2) {
      speedFactor = 2;
    }
  }

  // moving down
  if (yDirection === -1) {
    const bottomDiff = bottomPos - screenHeight;
    if (bottomDiff > elementHeight / 2) {
      speedFactor = 2;
    }
  }
  // moving up
  if (yDirection === 1) {
    const topDiff = Math.abs(currentPosition.y);
    if (topDiff > elementHeight / 2) {
      speedFactor = 2;
    }
  }

  logger.debug('[StageMove] Speed factor: ', speedFactor);

  return {
    newPos: {
      x: offset.x * zoomLevel + scrollVector.x * speedFactor,
      y: offset.y * zoomLevel + scrollVector.y * speedFactor,
    },
    moveVector: {
      x: scrollVector.x * speedFactor,
      y: scrollVector.y * speedFactor,
    },
  };
}

// taken from https://danburzo.ro/dom-gestures/
export function normalizeWheelEvent(event: WheelEvent) {
  let dx = event.deltaX;
  let dy = event.deltaY;

  if (dx === 0 && event.shiftKey) {
    [dx, dy] = [dy, dx];
  }

  if (event.deltaMode === WheelEvent.DOM_DELTA_LINE) {
    dx *= 8;
    dy *= 8;
  } else if (event.deltaMode === WheelEvent.DOM_DELTA_PAGE) {
    dx *= 24;
    dy *= 24;
  }

  dy = Math.sign(dy) * Math.min(24, Math.abs(dy));

  return [dx, dy];
}

export function zoomAndFocusElement(element: Pick<BoardElement, 'shape' | 'id'>) {
  zoomToElement({
    element,
    setOffset: nonUpdatingStore.getActions().boardSettings.setOffset,
    setZoomLevel: nonUpdatingStore.getActions().boardSettings.setZoomLevel,
  });

  nonUpdatingStore.getActions().board.setElementState({
    id: element.id,
    state: BoardElementState.FOCUSED,
  });
}
