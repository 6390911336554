/* eslint-disable react/jsx-one-expression-per-line */
import { useState, useEffect } from 'react';
import { Flex, IconButton, Button, Select, Stack } from '@chakra-ui/react';
import { FiX } from 'react-icons/fi';
import { useStoreActions, useStoreState } from 'store/hooks';
import { BoardMode } from 'store/board/settings';
import useInterval from 'hooks/Interval';
import { formatDate } from 'lib/helpers';

const REPLAY_DELAY = 300;

function TimeTravelUI() {
  const setMode = useStoreActions((actions) => actions.boardSettings.setMode);
  const events = useStoreState((state) => state.board.events);
  const applyEvent = useStoreActions((actions) => actions.board.applyEventsToId);
  const [currentIndex, setCurrentIndex] = useState(0);
  const currentEvent = events[currentIndex];

  const [isReplaying, setIsReplaying] = useState(false);
  const makeReplayStep = () => {
    if (currentIndex + 1 < events.length) {
      setCurrentIndex(currentIndex + 1);
    } else {
      setIsReplaying(false);
    }
  };
  useInterval(makeReplayStep, isReplaying ? REPLAY_DELAY : 0);
  const startReplay = () => {
    setIsReplaying(true);
  };
  const stopReplay = () => {
    setIsReplaying(false);
  };

  useEffect(() => {
    if (currentEvent && currentEvent._id) {
      applyEvent(currentEvent._id);
    }
  }, [applyEvent, currentEvent]);

  return (
    <Flex bottom={6} position="fixed" right={16} width="calc(100vw - 8rem)">
      <Stack direction="row" spacing={4} width="100%">
        <Select
          isDisabled={events.length === 0 || isReplaying}
          value={currentIndex}
          onChange={(e) => {
            setCurrentIndex(Number(e.currentTarget.value));
          }}
        >
          {events.map((event, i) => (
            <option key={event._id} value={i}>
              {formatDate(event.createdAt, true)}({event.type})
            </option>
          ))}
        </Select>
        {!isReplaying ? (
          <Button colorScheme="back" onClick={() => startReplay()} isDisabled={events.length <= 1}>
            Replay Events
          </Button>
        ) : (
          <Button colorScheme="back" onClick={() => stopReplay()}>
            Stop Replay
          </Button>
        )}

        <IconButton
          ml={4}
          fontSize="24px"
          colorScheme="back"
          onClick={() => setMode(BoardMode.MOVE)}
          aria-label="Leave Time Travel mode"
          icon={<FiX />}
        >
          Go to Travel
        </IconButton>
      </Stack>
    </Flex>
  );
}

export default TimeTravelUI;
