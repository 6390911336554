import { Box, Icon, Text, Flex, useToast } from '@chakra-ui/react';
import { BoardElementType } from 'shared';
import { isImageFile } from 'lib/Attachment.helper';
import { getStage } from 'lib/zoom';
import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { MdFileUpload } from 'react-icons/md';

import { useStoreState } from 'store/hooks';
import { nonUpdatingStore } from 'store/index';

export function BoardDropZone() {
  const toast = useToast();

  const [isDragging, setIsDragging] = useState(false);
  const hasDetailViewOpen = useStoreState((state) => state.widgets.hasDetailView);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (!file) {
      toast({ status: 'error', title: 'No file selected' });
      return;
    }
    nonUpdatingStore.getActions().app.setFloatingBoardElement({
      type: BoardElementType.CARD,
      file,
      ...(isImageFile(file) && { thumbnailUrl: URL.createObjectURL(file) }),
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    maxFiles: 1,
    // the detail view has it's own dropzone
    disabled: hasDetailViewOpen,
    onDropAccepted: () => {
      setIsDragging(false);
      const stage = getStage();
      if (stage) {
        stage.container().focus();
      }
    },
    onDragLeave: () => {
      setIsDragging(false);
    },
  });

  useEffect(() => {
    const onDragStart = (e: DragEvent) => {
      if (hasDetailViewOpen) return;

      // The mouse position is not tracked while dragging a file into the browser,
      // therefore we need to track it ourselves.
      nonUpdatingStore.getActions().app.setMousePosition({
        x: e.clientX,
        y: e.clientY,
      });

      setIsDragging(true);
    };

    document.addEventListener('dragover', onDragStart);

    return () => {
      document.removeEventListener('dragover', onDragStart);
    };
  }, [hasDetailViewOpen]);

  return (
    <Box
      left="0"
      top="0"
      height="100vh"
      width="100vw"
      position="absolute"
      zIndex={isDragActive ? 2500 : 0}
      pointerEvents={isDragging ? 'all' : 'none'}
      {...(getRootProps() as any)}
    >
      {isDragActive && (
        <Flex
          borderRadius="md"
          left="0"
          top="0"
          height="100vh"
          width="100vw"
          position="fixed"
          bg="rgba(255, 255, 255, 0.7)"
          borderWidth="4px"
          borderColor="yoBrand.500"
          justifyContent="center"
          alignItems="center"
        >
          <Flex color="yoBrand.500">
            <Icon as={MdFileUpload} fontSize={22} />
            <Text fontWeight="bold">Drop files to upload</Text>
          </Flex>
          <input {...getInputProps()} />
        </Flex>
      )}
    </Box>
  );
}
