import React, { useCallback } from 'react';
import { BoardElement, ValidationRules } from 'shared';
import { useParam } from 'hooks/useParam';
import { updateUrlToBoardElementStateChange } from 'lib/urlUtils';
import { animateZoomTo, getStage } from 'lib/zoom';
import { useStoreActions, useStoreState } from 'store/hooks';
import { BoardElementState } from 'types/index';
import BasePopover from './BasePopover';

const SearchPopover = () => {
  const isSearchOpen = useStoreState((store) => store.app.boardSearchOpen);
  const setIsSearchOpen = useStoreActions((store) => store.app.setBoardSearchOpen);
  const { updateElement, resetElementsState } = useStoreActions((store) => store.board);
  const closeWidgets = useStoreActions((store) => store.widgets.closeWidgets);
  const { setOffset, setZoomLevel } = useStoreActions((store) => store.boardSettings);

  const boardId = useParam('boardId')!;

  const handleSelect = useCallback(
    (element: BoardElement) => {
      closeWidgets();
      resetElementsState();
      updateElement({ id: element.id, state: BoardElementState.FOCUSED });
      updateUrlToBoardElementStateChange(boardId, element.id, BoardElementState.FOCUSED);
      animateZoomTo({
        position: {
          x:
            -element.shape.x +
            (window.innerWidth / 2 - ValidationRules.LayoutRules.Card.CARD_MIN_WIDTH / 2),
          y:
            -element.shape.y +
            (window.innerHeight / 2 - ValidationRules.LayoutRules.Card.CARD_MIN_HEIGHT / 2),
        },
        stage: getStage()!,
        setOffset,
        setZoomLevel,
        zoomLevel: 1,
      });
    },
    [boardId, closeWidgets, resetElementsState, setOffset, setZoomLevel, updateElement]
  );
  return (
    <BasePopover
      key="search"
      isOpen={isSearchOpen}
      onClose={() => setIsSearchOpen(false)}
      onSelect={handleSelect}
    />
  );
};

export default SearchPopover;
