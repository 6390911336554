import { Comment } from 'shared';
import { Thunk, thunk } from 'easy-peasy';
import fetchClient from 'lib/Fetch';
import { BoardStoreModel } from '.';
import { PublicStoreModel } from '..';

export interface CommentsStoreModel {
  updateComment: Thunk<BoardStoreModel, { comment: Partial<Comment> }, null, PublicStoreModel>;
  addComment: Thunk<BoardStoreModel, { comment: Partial<Comment> }, null, PublicStoreModel>;
  deleteComment: Thunk<BoardStoreModel, { id: string; cardId: string }, null, PublicStoreModel>;
}

export const commentsStore: CommentsStoreModel = {
  updateComment: thunk(async (actions, payload, { getStoreActions }) => {
    const { comment } = payload;
    await fetchClient<Comment>(
      `comments/${comment._id}`,
      {
        method: 'PATCH',
        body: {
          comment: { comment: comment.comment },
        },
      },
      getStoreActions().errors.addError,
      comment._id
    );
  }),
  addComment: thunk(async (actions, payload, { getStoreState, getStoreActions }) => {
    const { comment } = payload;
    const { boardId } = getStoreState().board;
    if (!comment.cardId) {
      throw new Error("The Comment doesn't exist");
    }
    getStoreActions().board.incrementCommentCount({ boardElementId: comment.cardId });
    const newComment = await fetchClient<Comment>(
      `board/${boardId}/cards/${comment.cardId}/comments`,
      {
        method: 'POST',
        body: {
          comment: { comment: comment.comment },
        },
      },
      (message) => {
        getStoreActions().errors.addError(message);
        // optimistic approach: decrement count again if error is encountered
        getStoreActions().board.decrementCommentCount({ boardElementId: comment.cardId! });
      },
      comment.cardId
    );
    return newComment;
  }),
  deleteComment: thunk(async (actions, payload, { getStoreActions }) => {
    const { id, cardId } = payload;
    getStoreActions().board.decrementCommentCount({ boardElementId: cardId });
    fetchClient<Comment>(
      `comments/${id}`,
      {
        method: 'DELETE',
        body: {},
      },
      (message) => {
        getStoreActions().errors.addError(message);
        // optimistic approach: increment count again if error is encountered
        getStoreActions().board.incrementCommentCount({ boardElementId: cardId });
      },
      id
    );
  }),
};
