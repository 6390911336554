import { isTakingScreenshot } from 'lib/screenshot';
import { useEffect, useState } from 'react';

export function getFallbackUrl(name: string) {
  return `/api/avatars/${escape(name).replace('.', '')}.svg?w=200&h=200`;
}

function isTaggleAttachment(url: string) {
  return (
    url.includes(process.env.NODE_ENV === 'production' ? 'taggle.so' : 'localhost') &&
    // boards/attachments is a part segment of the attachment controller
    url.includes('boards/attachments/')
  );
}

/**
 * There is a thing called tainted canvas. This happens when images are requested
 * from a different origin. This is a problem because we can't render the image
 * and therefore not create proper preview images. We fix this by proxying requests
 * through an api route using next.js
 * the tainted canvas is only a problem when we are creating images (aka for previews)
 */
export function getProxiedAttachmentURL(url: string, isScreenShot = isTakingScreenshot) {
  // if the url is not a taggle attachment or we aren't taking a screenshot
  // we don't need to proxy the request
  if (!isTaggleAttachment(url) || !isScreenShot()) return url;

  // replace the url of the api (where the attachments are hosted)
  // with the url of the proxy (where the attachments are proxied)
  return url.replace(
    `${process.env.NEXT_PUBLIC_API_URL}/`,
    `${window.location.origin}/attachments/`
  );
}

export function useImage(url?: string, name?: string) {
  const [image, setImage] = useState<HTMLImageElement | undefined>();
  const [imageToTest, setImageToTest] = useState<HTMLImageElement | undefined>();
  useEffect(() => {
    if (!url) return () => {};

    const proxiedURL = getProxiedAttachmentURL(url);

    const isCrossOrigin = !(proxiedURL.includes(window.location.hostname) || url.startsWith('/'));
    const newImage = new Image();
    const fallBackImage = new Image();

    if (isTaggleAttachment(url)) {
      newImage.crossOrigin = null;
    } else if (isCrossOrigin) {
      newImage.crossOrigin = 'Anonymous';
    }
    // add a timestamp to the url to prevent caching issues, but this doesn't work for blob urls
    newImage.src = isTaggleAttachment(url) ? `${proxiedURL}?d=${Date.now()}` : proxiedURL;
    setImageToTest(newImage);

    const onError = () => {
      if (isCrossOrigin) {
        fallBackImage.crossOrigin = 'Anonymous';
      }
      fallBackImage.src = getFallbackUrl(name ?? 'T');
      setImageToTest(fallBackImage);
    };

    const onFallbackLoad = () => {
      setImage(fallBackImage);
    };
    const onLoad = () => {
      setImage(newImage);
    };

    newImage.addEventListener('error', onError);
    newImage.addEventListener('load', onLoad);
    fallBackImage.addEventListener('load', onFallbackLoad);
    return () => {
      newImage.removeEventListener('error', onError);
      newImage.removeEventListener('load', onLoad);
      fallBackImage.removeEventListener('load', onFallbackLoad);
    };
  }, [url, name]);
  return [image, imageToTest];
}
