"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.ts
var src_exports = {};
__export(src_exports, {
  AccountType: () => AccountType,
  BoardElementAction: () => BoardElementAction,
  BoardElementLinkType: () => BoardElementLinkType,
  BoardElementType: () => BoardElementType,
  CardEventType: () => CardEventType,
  CardIntegrationType: () => CardIntegrationType,
  ErrorMessages: () => ErrorMessages,
  EventTypes: () => EventTypes,
  SOCKET_EVENTS: () => SOCKET_EVENTS,
  SOCKET_TYPE: () => SOCKET_TYPE,
  ValidationRules: () => ValidationRules,
  assertUnreachable: () => assertUnreachable,
  boardElementLinkTypes: () => boardElementLinkTypes,
  createBoardElementLink: () => createBoardElementLink,
  getOppositeLinkType: () => getOppositeLinkType,
  isCheckLink: () => isCheckLink
});
module.exports = __toCommonJS(src_exports);

// src/lib/assertUnreachable.ts
function assertUnreachable(whatever) {
  throw new Error("this cannot be reached because TS compiler will complain");
}

// src/boardElement.ts
var BoardElementType = /* @__PURE__ */ ((BoardElementType2) => {
  BoardElementType2["CARD"] = "CARD";
  BoardElementType2["COLUMN"] = "COLUMN";
  BoardElementType2["SWIMLANE"] = "SWIMLANE";
  BoardElementType2["STICKY"] = "STICKY";
  return BoardElementType2;
})(BoardElementType || {});
var CardIntegrationType = /* @__PURE__ */ ((CardIntegrationType2) => {
  CardIntegrationType2["LOOM"] = "LOOM";
  return CardIntegrationType2;
})(CardIntegrationType || {});
var BoardElementAction = /* @__PURE__ */ ((BoardElementAction2) => {
  BoardElementAction2["ADD"] = "ADD";
  BoardElementAction2["UPDATE"] = "UPDATE";
  BoardElementAction2["DELETE"] = "DELETE";
  return BoardElementAction2;
})(BoardElementAction || {});

// src/lib/enumHelpers.ts
function getNumberEnumKeys(en) {
  return Object.values(en).filter((v) => typeof v !== "string");
}

// src/boardElementLinks.ts
var import_uuid = require("uuid");
var BoardElementLinkType = /* @__PURE__ */ ((BoardElementLinkType2) => {
  BoardElementLinkType2[BoardElementLinkType2["BLOCKS"] = 0] = "BLOCKS";
  BoardElementLinkType2[BoardElementLinkType2["BLOCKED_BY"] = 1] = "BLOCKED_BY";
  BoardElementLinkType2[BoardElementLinkType2["PARENT"] = 2] = "PARENT";
  BoardElementLinkType2[BoardElementLinkType2["CHILD"] = 3] = "CHILD";
  BoardElementLinkType2[BoardElementLinkType2["RELATED"] = 4] = "RELATED";
  BoardElementLinkType2[BoardElementLinkType2["CHECK"] = 5] = "CHECK";
  return BoardElementLinkType2;
})(BoardElementLinkType || {});
function createBoardElementLink({ linkType, targetElementId }) {
  return {
    _id: (0, import_uuid.v4)(),
    type: linkType,
    targetElement: { id: targetElementId },
    createdAt: /* @__PURE__ */ new Date()
  };
}
function isCheckLink(link) {
  return link.type === 5 /* CHECK */;
}
var boardElementLinkTypes = getNumberEnumKeys(BoardElementLinkType);
function getOppositeLinkType(type) {
  switch (type) {
    case 0 /* BLOCKS */:
      return 1 /* BLOCKED_BY */;
    case 1 /* BLOCKED_BY */:
      return 0 /* BLOCKS */;
    case 2 /* PARENT */:
      return 3 /* CHILD */;
    case 3 /* CHILD */:
      return 2 /* PARENT */;
    case 4 /* RELATED */:
      return 4 /* RELATED */;
    case 5 /* CHECK */:
      return 5 /* CHECK */;
    default:
      assertUnreachable(type);
  }
}

// src/errorMessages.ts
var ErrorMessages = /* @__PURE__ */ ((ErrorMessages2) => {
  ErrorMessages2["GENERIC_ERROR"] = "Something went wrong";
  ErrorMessages2["UNABLE_TO_DELETE_USER"] = "Couldn't delete user";
  ErrorMessages2["USER_EMAIL_DOESNT_MATCH"] = "The email addresses do not match";
  ErrorMessages2["UNABLE_TO_UPDATE_USER"] = "Couldn't update user";
  ErrorMessages2["REGISTRATION_FAILED"] = "Registration failed";
  ErrorMessages2["UNABLE_TO_CHANGE_PASSWORD"] = "Couldn't update the password";
  ErrorMessages2["UNABLE_TO_CHANGE_PASSWORD_SOCIAL"] = "Users authenticated via a social provider can't change their password";
  ErrorMessages2["UNABLE_TO_GET_EVENTS"] = "Couldn't find events for that board";
  ErrorMessages2["UNABLE_TO_GET_BOARD"] = "Couldn't get data for that board";
  ErrorMessages2["UNABLE_TO_CREATE_BOARD"] = "Couldn't create board";
  ErrorMessages2["UNABLE_TO_UPDATE_BOARD"] = "Couldn't update board";
  ErrorMessages2["UNABLE_TO_DELETE_BOARD"] = "Couldn't delete board";
  ErrorMessages2["UNABLE_TO_CREATE_MULTIPLE_BLOCKERS"] = "Multiple active Blockers are not allowed on a Card";
  ErrorMessages2["UNABLE_TO_UPDATE_BOOKMARK"] = "Couldn't update bookmark";
  ErrorMessages2["UNABLE_TO_CREATE_BOOKMARK"] = "Couldn't create bookmark";
  ErrorMessages2["UNABLE_TO_GET_BOOKMARKS"] = "Couldn't get bookmarks";
  ErrorMessages2["UNABLE_TO_DELETE_BOOKMARK"] = "Couldn't delete bookmark";
  ErrorMessages2["UNABLE_TO_CREATE_SERVICE_ACCOUNT"] = "Couldn't create service account";
  ErrorMessages2["UNABLE_TO_DELETE_SERVICE_ACCOUNT"] = "Couldn't delete service account";
  ErrorMessages2["JWT_NOT_VALID_MISSING_ISS"] = 'JWT is not valid. "iss" is missing';
  ErrorMessages2["JWT_NOT_DECODABLE"] = "JWT is not decodable";
  ErrorMessages2["JWT_NOT_VERIFIABLE"] = "JWT could not be verified";
  ErrorMessages2["ACCESS_TOKEN_NOT_VALID"] = "AccessToken is not valid";
  ErrorMessages2["ACCESS_TOKEN_NOT_VERIFIABLE"] = "AccessToken could not be verified";
  ErrorMessages2["ACCESS_INSUFFICIENT_SCOPE"] = "Insufficient scope permissions";
  ErrorMessages2["FORBIDDEN"] = "Insufficient permissions";
  ErrorMessages2["INVALID_SERVICE_ACCOUNT"] = "The service account is invalid";
  ErrorMessages2["AUTH_MISSING_SCOPE_IN_REQUEST"] = "No scope definition in request";
  ErrorMessages2["AUTH_SCOPE_DOES_NOT_MATCH_ROUTE_SCOPE"] = "Scope in request does not match route scope";
  ErrorMessages2["AUTH_UNSUPPORTED_SCOPE"] = "Unsupported scope";
  ErrorMessages2["AUTH_UNSUPPORTED_GRANT_TYPE"] = "Unsupported grant_type";
  ErrorMessages2["INVALID_PASSWORD"] = "Invalid password";
  ErrorMessages2["INVALID_USERNAME_OR_PASSWORD"] = "Invalid username or password";
  ErrorMessages2["AUTH_INVALID_SIGNATURE"] = "Invalid signature";
  ErrorMessages2["WEBHOOK_URL_MISSING"] = "Webhook URL missing";
  ErrorMessages2["INVALID_FILE_FORMAT"] = "The file has an invalid format";
  ErrorMessages2["UNABLE_TO_IMPORT"] = "Unable to import the file";
  ErrorMessages2["USER_ALREADY_EXISTS"] = "User with this email already exists";
  ErrorMessages2["TOS_NOT_ACCEPTED"] = "Terms of Service and Privacy Policy need to be accepted";
  ErrorMessages2["PASSWORD_REQUIREMENT_NOT_MET"] = "The password must have a minimum length of 8 characters.";
  ErrorMessages2["FILE_TOO_BIG"] = "The file exceeds the file limit";
  ErrorMessages2["FILE_AMOUNT_LIMIT_EXCEEDED"] = "You are only allowed to upload one file at once";
  ErrorMessages2["COULD_NOT_UPLOAD_FILE"] = "The file could not be uploaded";
  ErrorMessages2["INVALID_FIELD_MATCHES"] = "The column titles are invalid";
  ErrorMessages2["INVALID_IMPORT_COLUMN"] = "The selected column is invalid";
  ErrorMessages2["UNABLE_TO_IMPORT_FILE"] = "The selected file could not be imported";
  ErrorMessages2["LIMIT_MAX_BOARDS_REACHED"] = "The maximum number of boards is reached. Please contact the support.";
  ErrorMessages2["LIMIT_MAX_COMMENTS_REACHED"] = "The maximum number of comments is reached. Please contact the support.";
  ErrorMessages2["LIMIT_MAX_SERVICE_ACCOUNTS_REACHED"] = "The maximum number of service accounts is reached. Please contact the support.";
  ErrorMessages2["LIMIT_MAX_BOARD_ELEMENTS_PER_MONTH_REACHED"] = "The maximum number of board elements that can be created within a single month is reached. Please contact the support.";
  ErrorMessages2["INVALID_REACTIONS"] = "A reaction must only be one single emoji.";
  ErrorMessages2["UNABLE_TO_PARSE_FIELDMATCHES"] = "The fieldmatches were invalid";
  ErrorMessages2["ATTACHMENT_NOT_FOUND"] = "Attachment does not exist";
  ErrorMessages2["UNAUTHORIZED"] = "UNAUTHORIZED";
  ErrorMessages2["COULD_NOT_DELETE_ATTACHMENT"] = "Could not delete the attachment";
  ErrorMessages2["NOT_ENOUGH_COLUMNS_SELECTED"] = "At least one column must be selected in order to export the board metrics data";
  ErrorMessages2["UNABLE_TO_SET_MARKETINGINFO"] = "Couldn't set marketing information for user";
  ErrorMessages2["UNABLE_TO_GET_MARKETINGINFO"] = "Couldn't get marketing information for user";
  ErrorMessages2["SIGNUP_NOT_COMPLETED"] = "You haven't finished the sign up proccess yet";
  ErrorMessages2["EMPTY_COMMENT"] = "You can't add empty comments";
  ErrorMessages2["CANT_CREATE_EXISTING_LINK"] = "You can't create multiple Links to the same Card";
  return ErrorMessages2;
})(ErrorMessages || {});

// src/user.ts
var AccountType = /* @__PURE__ */ ((AccountType2) => {
  AccountType2["EMAIL"] = "EMAIL";
  AccountType2["GOOGLE"] = "GOOGLE";
  return AccountType2;
})(AccountType || {});

// src/validationRules.ts
var sanitizeHtml = __toESM(require("sanitize-html"));
var ValidationRules = {
  LayoutRules: {
    Sticky: {
      STICKY_MIN_SIZE: 38,
      STICKY_MAX_SIZE: 600
    },
    Card: {
      CARD_MIN_HEIGHT: 140,
      CARD_MAX_HEIGHT: 140,
      CARD_MIN_WIDTH: 250,
      CARD_MAX_WIDTH: 250
    },
    Column: {
      COLUMN_MIN_SIZE: 300
    },
    Swimlane: {
      SWIMLANE_MIN_SIZE: 300
    }
  },
  SanitizeHtmlRules: {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(["iframe", "label", "input"]),
    allowedClasses: {
      div: ["iframe-wrapper"]
    },
    allowedAttributes: {
      ...sanitizeHtml.defaults.allowedAttributes,
      iframe: ["src", "width", "height", "frameborder", "allowfullscreen"],
      ul: ["data-type"],
      li: ["data-type", "data-checked", "data-linkid"],
      input: ["type"],
      span: ["data-mention", "data-id", "data-userid"]
    },
    allowedIframeHostnames: ["www.loom.com"]
  }
};

// src/cardDetails.ts
var CardEventType = /* @__PURE__ */ ((CardEventType2) => {
  CardEventType2["MOVE"] = "MOVE";
  CardEventType2["CARD_CREATED"] = "CARD_CREATED";
  CardEventType2["CARD_ARCHIVED"] = "CARD_ARCHIVED";
  CardEventType2["CARD_UNARCHIVED"] = "CARD_UNARCHIVED";
  CardEventType2["TITLE_CHANGED"] = "TITLE_CHANGED";
  CardEventType2["DESCRIPTION_CHANGED"] = "DESCRIPTION_CHANGED";
  CardEventType2["ATTACHMENT_ADDED"] = "ATTACHMENT_ADDED";
  CardEventType2["ATTACHMENT_DELETED"] = "ATTACHMENT_DELETED";
  CardEventType2["BLOCKER_CREATED"] = "BLOCKER_CREATED";
  CardEventType2["BLOCKER_RESOLVED"] = "BLOCKER_RESOLVED";
  CardEventType2["BLOCKER_DELETED"] = "BLOCKER_DELETED";
  CardEventType2["BLOCKER_REOPENED"] = "BLOCKER_REOPENED";
  CardEventType2["BLOCKER_EDITED"] = "BLOCKER_EDITED";
  CardEventType2["CARD_ASSIGNED"] = "CARD_ASSIGNED";
  CardEventType2["CARD_UNASSIGNED"] = "CARD_UNASSIGNED";
  CardEventType2["CARD_WATCHED"] = "CARD_WATCHED";
  CardEventType2["CARD_UNWATCHED"] = "CARD_UNWATCHED";
  CardEventType2["CARD_DELETED"] = "CARD_DELETED";
  CardEventType2["CARD_COMMENT"] = "CARD_COMMENT";
  CardEventType2["CARD_UPDATED"] = "CARD_UPDATED";
  CardEventType2["DESCRIPTION_MENTION"] = "DESCRIPTION_MENTION";
  CardEventType2["COMMENT_MENTION"] = "COMMENT_MENTION";
  return CardEventType2;
})(CardEventType || {});

// src/index.ts
var SOCKET_EVENTS = /* @__PURE__ */ ((SOCKET_EVENTS2) => {
  SOCKET_EVENTS2["JOIN"] = "JOIN";
  SOCKET_EVENTS2["USER_JOIN"] = "USER_JOIN";
  SOCKET_EVENTS2["JOIN_SUCCESS"] = "JOIN_SUCCESSS";
  SOCKET_EVENTS2["LEAVE"] = "LEAVE";
  SOCKET_EVENTS2["USER_LEAVE"] = "USER_LEAVE";
  SOCKET_EVENTS2["LEAVE_SUCCESS"] = "LEAVE_SUCCESS";
  SOCKET_EVENTS2["EVENT"] = "EVENT";
  SOCKET_EVENTS2["POSITION"] = "POSITION";
  SOCKET_EVENTS2["NEW_NOTIFICATION"] = "NEW_NOTIFICATION";
  SOCKET_EVENTS2["DETAIL_VIEW_OPEN"] = "DETAIL_VIEW_OPEN";
  SOCKET_EVENTS2["UPDATE_COMMENTS"] = "UPDATE_COMMENTS";
  SOCKET_EVENTS2["EDITING_CARD"] = "EDITING_CARD";
  SOCKET_EVENTS2["END_EDIT_CARD"] = "END_EDIT_CARD";
  SOCKET_EVENTS2["FORCE_REMOVE_USER"] = "FORCE_REMOVE_USER";
  return SOCKET_EVENTS2;
})(SOCKET_EVENTS || {});
var SOCKET_TYPE = /* @__PURE__ */ ((SOCKET_TYPE2) => {
  SOCKET_TYPE2["MYBOARD"] = "MYBOARD";
  SOCKET_TYPE2["BOARD"] = "BOARD";
  SOCKET_TYPE2["SCREENSHOT"] = "SCREENSHOT";
  return SOCKET_TYPE2;
})(SOCKET_TYPE || {});
var EventTypes = /* @__PURE__ */ ((EventTypes2) => {
  EventTypes2["ADD"] = "ADD";
  EventTypes2["UPDATE"] = "UPDATE";
  EventTypes2["REMOVE"] = "REMOVE";
  EventTypes2["BULK"] = "BULK";
  return EventTypes2;
})(EventTypes || {});
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  AccountType,
  BoardElementAction,
  BoardElementLinkType,
  BoardElementType,
  CardEventType,
  CardIntegrationType,
  ErrorMessages,
  EventTypes,
  SOCKET_EVENTS,
  SOCKET_TYPE,
  ValidationRules,
  assertUnreachable,
  boardElementLinkTypes,
  createBoardElementLink,
  getOppositeLinkType,
  isCheckLink
});
