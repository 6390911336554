import { CheckCircleIcon, WarningIcon } from '@chakra-ui/icons';
import {
  Button,
  Flex,
  FormControl,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { useParam } from 'hooks/useParam';
import { CLIENT_PLAUSIBLE_EVENTS } from 'lib/Types';
import { usePlausible } from 'next-plausible';
import { useEffect, useRef, useState } from 'react';
import { useStoreActions } from 'store/hooks';
import fetchClient from '../../../lib/Fetch';

enum AddTeamMemberBoxStatus {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}

const AddTeamMemberBox: React.FC<{ boardId?: string }> = ({ boardId }) => {
  const plausible = usePlausible();
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState(AddTeamMemberBoxStatus.IDLE);
  const emailInputRef = useRef<HTMLInputElement>();
  const getUsers = useStoreActions((actions) => actions.board.getUsers);
  const toast = useToast();
  const boardIdParam = useParam('boardId');
  const boardIdToAddTo = boardId ?? boardIdParam;
  const handleShare = async () => {
    if (email !== '') {
      setStatus(AddTeamMemberBoxStatus.LOADING);
      await fetchClient(
        `boards/${boardIdToAddTo}/users`,
        {
          method: 'PATCH',
          body: { email },
        },
        () => {
          setStatus(AddTeamMemberBoxStatus.ERROR);
        }
      );
      plausible(CLIENT_PLAUSIBLE_EVENTS.INVITE_USER);
      setStatus(AddTeamMemberBoxStatus.SUCCESS);
    } else {
      setStatus(AddTeamMemberBoxStatus.ERROR);
    }
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setStatus(AddTeamMemberBoxStatus.IDLE);
  };

  useEffect(() => {
    const timeouts: any[] = [];
    const HIDE_ERROR_DELAY = 2000;
    // Clear input und update Users in SUCCESS case
    if (status === AddTeamMemberBoxStatus.SUCCESS) {
      getUsers(boardIdToAddTo!);
      toast({
        title: `${email} added successfully`,
        duration: 2000,
        status: 'success',
        position: 'bottom',
      });
      setEmail('');
      setStatus(AddTeamMemberBoxStatus.IDLE);
    }
    // remove the ERROR indicator with delay
    if (status === AddTeamMemberBoxStatus.ERROR) {
      toast({
        title: email ? `${email} could not be added` : 'Something went wrong',
        duration: 2000,
        status: 'error',
        position: 'bottom',
      });
      timeouts.push(
        setTimeout(() => {
          setStatus(AddTeamMemberBoxStatus.IDLE);
        }, HIDE_ERROR_DELAY)
      );
    }
    return () => {
      timeouts.forEach((timeout) => {
        clearTimeout(timeout);
      });
    };
  }, [status]);

  return (
    <Stack spacing={4} isInline>
      <FormControl>
        <InputGroup>
          <Input
            isInvalid={status === AddTeamMemberBoxStatus.ERROR}
            id="email"
            type="email"
            value={email}
            ref={emailInputRef as any}
            onChange={handleEmailChange}
            placeholder="Add by email..."
            borderRadius="sm"
            bg="transparent"
            color="yoGray.900"
            borderColor="yoGray.border"
            _placeholder={{ color: 'placeholder' }}
          />
          <InputRightElement>
            {status === AddTeamMemberBoxStatus.LOADING && <Spinner color="yoBrand.500" />}
            {status === AddTeamMemberBoxStatus.ERROR && (
              <WarningIcon animation="rotate" color="red.500" />
            )}
            {status === AddTeamMemberBoxStatus.SUCCESS && <CheckCircleIcon color="green.500" />}
          </InputRightElement>
        </InputGroup>
      </FormControl>
      <Flex justify="flex-end">
        <Button
          colorScheme="yoBrand"
          onClick={handleShare}
          isDisabled={status === AddTeamMemberBoxStatus.ERROR}
        >
          Add Team Member
        </Button>
      </Flex>
    </Stack>
  );
};

export default AddTeamMemberBox;
