import { AvatarProps } from '@chakra-ui/react';
import { useParam } from 'hooks/useParam';
import { useEffect } from 'react';
import { useStoreActions, useStoreState } from 'store/hooks';
import fetchClient from '../../../lib/Fetch';
import { ITeamMember as TeamMember } from './TeamMembers';

export const useRemoveTeamMember = (currentBoardId?: string) => {
  const currentUserId = useStoreState((store) => store.user.user?._id);
  const getUsers = useStoreActions((actions) => actions.board.getUsers);
  const boardIdParam = useParam('boardId');
  const boardId = currentBoardId ?? boardIdParam;
  const removeTeamMember = async (userId: string) => {
    await fetchClient(`boards/${boardId}/users`, {
      method: 'DELETE',
      body: { userId },
    });
    // If the userId is the same as the current users' id
    // it means he left the board. Therefore no reload
    if (userId !== currentUserId) {
      await getUsers(boardId!);
    }
  };
  return removeTeamMember;
};

export const useTeamMembers = (
  currentBoardId?: string
): {
  teamMembers: TeamMember[];
  removeTeamMember: (id: string) => void;
} => {
  const getUsers = useStoreActions((actions) => actions.board.getUsers);
  const currentUser = useStoreState((store) => store.user);
  const users = useStoreState((store) => store.board.users);
  const removeTeamMember = useRemoveTeamMember();
  const boardIdParam = useParam('boardId');
  const boardId = currentBoardId ?? boardIdParam;
  const boardOwner = currentUser?.user?.boards.find((board) => board._id === boardId)?.createdBy;
  const teamMembers =
    users?.map((user) => ({
      ...user,
      name: `${user.firstName} ${user.lastName}`.trim(),
      isOwner: user._id === boardOwner?._id,
    })) || [];

  useEffect(() => {
    getUsers(boardId!);
  }, []);

  return { teamMembers, removeTeamMember };
};

export const useVirtualTeamMembers = (
  currentBoardId?: string
): {
  virtualTeamMembers: AvatarProps[];
  removeVirtualTeamMember: (id: string) => void;
} => {
  const getAvatars = useStoreActions((actions) => actions.board.getAvatars);
  const avatars = useStoreState((store) => store.board.avatars);
  const boardIdParam = useParam('boardId');
  const boardId = currentBoardId ?? boardIdParam;
  const virtualTeamMembers = avatars.filter((currentAvatar) => currentAvatar.isVirtual);

  useEffect(() => {
    getAvatars(boardId!);
  }, []);

  // TODO: eventually move to custom hook as useRemoveTeamMember()
  const removeVirtualTeamMember = async (avatarId: string) => {
    await fetchClient(`boards/${boardId}/avatars/${avatarId}`, {
      method: 'DELETE',
      body: null,
    });
    getAvatars(boardId!);
  };

  return { virtualTeamMembers, removeVirtualTeamMember };
};
