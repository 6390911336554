import { useCallback, useEffect, useRef } from 'react';
import { KonvaEventObject } from 'konva/types/Node';
import { MOUSE_BUTTON } from 'lib/Types';
import { isTouchEvent } from 'lib/helpers';
import { BoardElementType, Rectangle } from 'shared';
import useStage from 'hooks/useStage';
import { useStoreActions, useStoreState } from 'store/hooks';
import { useSpawnElements } from 'hooks/Elements';
import { useFloatingElement } from 'hooks/useFloatingElement';
import { FloatingElement } from 'store/app';
import { getDefaultElementWidthAndHeight } from 'lib/Elements.helper';
import {
  clearDragging,
  moveDragElement,
  startDragging,
  stopDragging,
} from '../drag/DragHandlerManager';
import { DragElement } from '../drag/DragModel';

export const useDragFloatingElement = (floatingElement: FloatingElement) => {
  const stage = useStage();
  const { updateElement, setElementState } = useStoreActions((actions) => actions.board);
  const { spawnCard, spawnColumn, spawnSticky, spawnSwimlane } = useSpawnElements();
  const mousePosition = useStoreState((store) => store.app.mousePosition);
  const { setFloatingElement } = useFloatingElement();

  const dragElement: DragElement = {
    ...floatingElement,
    shape: {
      ...floatingElement.shape,
      ...getDefaultElementWidthAndHeight(floatingElement.type, floatingElement.meta?.isSorted),
    },
  };
  if (mousePosition) {
    dragElement.shape.x = mousePosition.x;
    dragElement.shape.y = mousePosition.y;
  }
  const dragElementRef = useRef<DragElement>(dragElement);

  const handleClickSpawnElement = useCallback(
    (e: KonvaEventObject<MouseEvent> | KonvaEventObject<TouchEvent>) => {
      if (isTouchEvent(e) || e.evt.button === MOUSE_BUTTON.LEFT) {
        if (dragElementRef.current?.type === BoardElementType.CARD) {
          stopDragging();
        }
        const spawnByElementType = {
          [BoardElementType.CARD]: spawnCard,
          [BoardElementType.COLUMN]: spawnColumn,
          [BoardElementType.STICKY]: spawnSticky,
          [BoardElementType.SWIMLANE]: spawnSwimlane,
        };

        if (!stage) return;
        if (!floatingElement) {
          throw new Error(
            'useDragFloatingElement() handleClickSpawnElement: invalid floatingElement'
          );
        }
        if (!dragElementRef.current) {
          throw new Error(
            'useDragFloatingElement() handleClickSpawnElement: invalid dragElementRef.current'
          );
        }
        const currentOffset = stage.position();
        const currentZoomlevel = stage.scaleX();

        const spawnPosition: Rectangle = isTouchEvent(e)
          ? {
              ...dragElementRef.current.shape,
              x: (e.evt.changedTouches[0].clientX - currentOffset.x) / currentZoomlevel,
              y: (e.evt.changedTouches[0].clientY - currentOffset.y) / currentZoomlevel,
            }
          : { ...dragElementRef.current.shape };

        spawnByElementType[dragElementRef.current.type](spawnPosition, {
          ...floatingElement,
          parentColumnId: dragElementRef.current.parentColumnId,
        });
        setFloatingElement(null);
      }
    },
    [floatingElement, setFloatingElement, spawnCard, spawnColumn, spawnSticky, spawnSwimlane, stage]
  );

  const handleRightClickAbort = useCallback(
    (e: KonvaEventObject<MouseEvent>) => {
      if (e.evt.button === MOUSE_BUTTON.RIGHT) {
        setFloatingElement(null);
      }
    },
    [setFloatingElement]
  );

  useEffect(() => {
    if (!stage) {
      return () => {};
    }
    if (!dragElementRef.current) {
      return () => {};
    }
    if (dragElementRef.current.type) {
      // assign event handlers for placing the element or aborting it
      stage.on('click', (e) => {
        handleClickSpawnElement(e);
        handleRightClickAbort(e);
      });
      stage.on('tap', (e) => {
        handleClickSpawnElement(e);
        handleRightClickAbort(e);
      });
    }
    return () => {
      stage.removeEventListener('click');
      stage.removeEventListener('tap');
    };
  }, [stage, floatingElement, handleRightClickAbort, handleClickSpawnElement]);

  // start drag
  useEffect(() => {
    startDragging({
      element: dragElementRef.current,
      canBeResized: false,
      updateElement,
      setElementState,
    });
    return () => clearDragging();
  }, []);

  // move drag
  useEffect(() => {
    if (mousePosition && floatingElement) {
      moveDragElement({ position: mousePosition, absolutePosition: mousePosition });
    } else {
      clearDragging();
    }
  }, [mousePosition, floatingElement]);

  return { dragElementRef };
};
