import { Rect } from 'react-konva';
import theme from 'lib/theme';
import { BoardElementState } from 'types/index';

type FocusBorderProps = {
  state: BoardElementState;
  width: number;
  height: number;
  cornerRadius: number | number[];
  strokeWidth?: number;
};

const FocusBorder: React.FC<FocusBorderProps> = ({
  state,
  width,
  height,
  cornerRadius,
  strokeWidth = 4,
}) => (
  <>
    {state !== BoardElementState.DEFAULT && state !== BoardElementState.FLOATING ? (
      <Rect
        fill="transparent"
        y={-1}
        x={-1}
        cornerRadius={cornerRadius}
        width={width + 2}
        height={height + 2}
        stroke={theme.colors.yoBrand[300]}
        strokeWidth={strokeWidth}
        listening={false}
      />
    ) : null}
  </>
);

export default FocusBorder;
