import socket from 'socket.io-client';

const io = socket(process.env.NEXT_PUBLIC_API_URL!, {
  reconnection: true,
  reconnectionDelay: 1000,
  secure: process.env.NODE_ENV === 'production',
  transports: ['websocket'],
});

export default io;
