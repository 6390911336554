import { useToast } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useStoreActions } from 'store/hooks';

const useRemoveUser = () => {
  const { removeBoard } = useStoreActions((store) => store.user);
  const toast = useToast();
  const router = useRouter();
  const removeFromBoard = (boardId: string) => {
    if (router.asPath === '/myboards') {
      removeBoard(boardId);
      toast({
        description: 'Your boards have been updated!',
        duration: 1000 * 10,
        position: 'bottom',
        status: 'info',
        isClosable: true,
      });
    } else {
      router.push('/');
      toast({
        description: 'You were removed from the board!',
        duration: null,
        position: 'bottom',
        status: 'info',
        isClosable: true,
      });
    }
  };
  return removeFromBoard;
};

export default useRemoveUser;
