import { Text, Box, Button, IconButton, ButtonProps } from '@chakra-ui/react';
import { Reaction } from 'shared';
import React, { useState, useRef } from 'react';
import { useStoreState } from 'store/hooks';
import useWindowDimensions from 'hooks/useWindowDimensions';
import dynamic from 'next/dynamic';
import { AddReactionIcon } from 'lib/icons';
import { groupBy } from 'lib/helpers';
import ReactionTooltip from '../ReactionTooltip';

const Picker = dynamic(() => import('../EmojiPicker'), { ssr: false });

const emojiPickerHeight = 420;
const offsetBuffer = 20;

interface ReactionListProps {
  reactions?: Reaction[];
  commentId: string;
  handleAddReaction: (emoji: string, userId: string) => void;
  handleRemoveReaction: (emoji: string, userId: string) => void;
  pickerPositionStartingWidth?: number;
  isDisabled?: boolean;
}

const ReactionList: React.FC<ReactionListProps> = ({
  reactions,
  handleAddReaction,
  handleRemoveReaction,
  isDisabled = false,
}) => {
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);
  const toggleEmojiPicker = () => setIsEmojiPickerOpen(!isEmojiPickerOpen);
  const user = useStoreState((store) => store.user.user);
  const emojiPickerButtonRef = useRef<HTMLButtonElement>(null) as any;
  const { height } = useWindowDimensions();

  const onAddUserToEmojiClick = (emoji: any) => {
    handleAddReaction(emoji, user!._id);
  };

  const onEmojiSelect = (newEmoji: string) => {
    onAddUserToEmojiClick(newEmoji);
    toggleEmojiPicker();
  };

  const onRemoveEmojiClick = (emoji: any) => {
    handleRemoveReaction(emoji, user!._id);
  };

  const buttonStyles: ButtonProps = {
    mr: '3',
    flexShrink: 0,
    flexGrow: 0,
    fontWeight: 400,
    mb: 3,
    borderRadius: '6px',
    minWidth: '0',
    color: 'yoGray.850',
    variant: 'link',
    _hover: { textDecoration: 'none' },
  };

  const groupedReactions = groupBy<Reaction>(reactions!, (props: any) => props.emoji);

  const emojiPickerButtonLeft = emojiPickerButtonRef?.current?.offsetLeft;

  const openPickerAbove =
    emojiPickerButtonRef?.current?.getBoundingClientRect()?.top + emojiPickerHeight + offsetBuffer >
    height;
  const pickerAboveOffset =
    emojiPickerHeight +
    emojiPickerButtonRef?.current?.getBoundingClientRect()?.height +
    offsetBuffer;

  return (
    <>
      {Object.keys(groupedReactions).map((emoji) => (
        <ReactionTooltip
          emoji={emoji}
          userIds={groupedReactions[emoji].map((reaction) => reaction.userId.toString())}
        >
          <Button
            {...buttonStyles}
            key={groupedReactions[emoji][0]._id}
            background={
              groupedReactions[emoji]?.some((reaction) => reaction.userId.toString() === user!._id)
                ? 'lightBlue.350'
                : 'yoGray.300'
            }
            onClick={() =>
              groupedReactions[emoji]?.some((reaction) => reaction.userId.toString() === user!._id)
                ? onRemoveEmojiClick(emoji)
                : onAddUserToEmojiClick(emoji)
            }
            isDisabled={isDisabled}
          >
            <Box marginLeft="6px" paddingTop="3px" paddingBottom="3px" paddingRight="2px">
              <Text className="reaction">{emoji}</Text>
            </Box>
            <Box paddingRight="7px">
              <Text className="reaction">{groupedReactions[emoji]?.length || 0}</Text>
            </Box>
          </Button>
        </ReactionTooltip>
      ))}
      <IconButton
        ref={emojiPickerButtonRef}
        {...buttonStyles}
        icon={<AddReactionIcon />}
        aria-label="Add Reaction"
        title="Add Reaction"
        onClick={toggleEmojiPicker}
        key="addNewEmojiButton"
        bg="yoGray.300"
        padding="5px"
        isDisabled={isDisabled}
      />
      {isEmojiPickerOpen && (
        <Picker
          onClose={toggleEmojiPicker}
          onSelect={onEmojiSelect}
          left={emojiPickerButtonLeft}
          {...(openPickerAbove && { top: -pickerAboveOffset })}
        />
      )}
    </>
  );
};

export default ReactionList;
