/* eslint-disable import/prefer-default-export */
import { useEffect } from 'react';

export type TAWK_STATUS = 'online' | 'offline' | 'away';

declare global {
  interface Window {
    // eslint-disable-next-line camelcase
    Tawk_API?: {
      onLoad: () => void;
      hideWidget: () => void;
      setAttributes: (attributes: { email?: string; name?: string }) => void;
      popup: () => void;
      onStatusChange: (newStatus: TAWK_STATUS) => void;
    };
  }
}

export function useLiveChat() {
  useEffect(() => {
    const s1 = document.createElement('script');
    const s0 = document.getElementsByTagName('script')[0];
    s1.async = true;
    s1.src = 'https://embed.tawk.to/607ed3c962662a09efc05b00/1f3nmmjec';
    s1.charset = 'UTF-8';
    s1.setAttribute('crossorigin', '*');
    s0.parentNode!.insertBefore(s1, s0);

    window.Tawk_API = window.Tawk_API || ({} as any);
    if (window.Tawk_API) {
      window.Tawk_API.onLoad = () => {
        window.Tawk_API?.hideWidget();
      };
    }
  }, []);
}
