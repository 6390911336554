import { memo, useRef, useEffect } from 'react';
import { useStoreState, useStoreActions } from 'store/hooks';
import Card from 'components/canvas/Card';
import Column from 'components/canvas/Column';
import { Layer, Group } from 'react-konva';
import { Group as IGroup } from 'konva/types/Group';
import Sticky from 'components/canvas/Sticky';
import MultiSelect from 'components/canvas/MultiSelect';
import { BoardMode } from 'store/board/settings';
import { BoardElementType, assertUnreachable } from 'shared';
import { MOUSE_BUTTON } from 'lib/Types';
import { SNAP_TO_GRID_VALUE } from 'hooks/Elements';
import { getNextSnappingPosition, isCtrlOrMetaKey } from 'lib/helpers';
import { UIBoardElement } from 'types/index';
import { BoardElementLinks } from '../BoardElementLinks/BoardElementLinks';
import Swimlane from '../Swimlane';
import { useBoardElements, useSelectedBoardElements } from './BoardElement.helper';

export const SELECTED_AREA_ID = 'selectedArea';

function getComponentByElementType(element: UIBoardElement) {
  switch (element.type) {
    case BoardElementType.CARD:
      return <Card key={element.id} {...element} />;
    case BoardElementType.COLUMN:
      return <Column key={element.id} {...element} />;
    case BoardElementType.SWIMLANE:
      return <Swimlane key={element.id} {...element} />;
    case BoardElementType.STICKY:
      return <Sticky key={element.id} {...element} />;
    default:
      return assertUnreachable(element.type);
  }
}

const BoardElements: React.FC = () => {
  const selectedRef = useRef<IGroup>(null);
  const posRef = useRef({ x: 0, y: 0 });
  const { updateElement, resetElementsState } = useStoreActions((state) => state.board);
  const mode = useStoreState((state) => state.boardSettings.mode);
  const {
    selectedCards,
    selectedStickyNotes,
    selectedSwimlanesAndColumns,
    selectedElements,
    hasSelectedElements,
  } = useSelectedBoardElements();

  const { cards, stickyNotes, swimlanesAndColumns, draggedElements } = useBoardElements();
  const snapToGrid = useStoreState((store) => store.boardSettings.snapToGrid);
  const activeCard = useStoreState((store) => store.app.activeLinkCard);
  const isShowingBoardElementLinks = activeCard != null;

  useEffect(() => {
    if (selectedRef.current) {
      selectedRef.current.position({ x: 0, y: 0 });
    }
  }, [selectedElements.length]);

  // For some reason it does not work if you put the components directly
  // in the return statement.
  // This is not used during multiselection.
  const boardElementComponents = isShowingBoardElementLinks
    ? [
        ...swimlanesAndColumns.map(getComponentByElementType),
        ...stickyNotes.map(getComponentByElementType),
        <BoardElementLinks activeLinkCard={activeCard!} />,
        ...cards.map(getComponentByElementType),
        ...draggedElements.map(getComponentByElementType),
      ]
    : [
        ...swimlanesAndColumns.map(getComponentByElementType),
        ...cards.map(getComponentByElementType),
        ...stickyNotes.map(getComponentByElementType),
        ...draggedElements.map(getComponentByElementType),
      ];

  return (
    <Layer listening>
      <Group>
        {boardElementComponents}
        {hasSelectedElements && (
          <Group
            ref={selectedRef}
            draggable={mode !== BoardMode.HAND}
            onMouseDown={(e) => e.evt.stopPropagation()}
            onDragStart={(e) => {
              posRef.current = e.target.getAbsolutePosition();
            }}
            onClick={(e) => {
              if (
                e.target.id() === SELECTED_AREA_ID &&
                e.evt.button === MOUSE_BUTTON.LEFT &&
                !isCtrlOrMetaKey(e)
              ) {
                (resetElementsState as any)();
              }
            }}
            onDragEnd={(e) => {
              const newPos = e.target.getAbsolutePosition();
              const scale = e.target.getStage()!.scaleX();
              const diff = { x: posRef.current.x - newPos.x, y: posRef.current.y - newPos.y };
              selectedRef.current!.position({ x: 0, y: 0 });

              updateElement(
                selectedElements.map((selectedElement) => {
                  const position = {
                    x: selectedElement.shape!.x! - diff.x / scale,
                    y: selectedElement.shape!.y! - diff.y / scale,
                  };
                  return {
                    id: selectedElement.id,
                    shape: {
                      ...(snapToGrid
                        ? getNextSnappingPosition({ position, snappingValue: SNAP_TO_GRID_VALUE })
                        : position),
                    },
                  };
                })
              );
            }}
          >
            {isShowingBoardElementLinks ? (
              <>
                {selectedSwimlanesAndColumns.map(getComponentByElementType)}
                {selectedStickyNotes.map(getComponentByElementType)}
                {selectedCards.map(getComponentByElementType)}
              </>
            ) : (
              <>
                {selectedSwimlanesAndColumns.map(getComponentByElementType)}
                {selectedCards.map(getComponentByElementType)}
                {selectedStickyNotes.map(getComponentByElementType)}
              </>
            )}

            <MultiSelect selectedElements={selectedElements} />
          </Group>
        )}
      </Group>
    </Layer>
  );
};

export default memo(BoardElements);
