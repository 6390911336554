/* eslint-disable import/prefer-default-export */
import { useRouter } from 'next/router';

export function useParam(name: string): string | undefined {
  const router = useRouter();
  let param = router.query[name];
  if (Array.isArray(param)) {
    [param] = param;
  }
  if (param === '') {
    return undefined;
  }
  return param;
}
