import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  ModalProps,
  Spinner,
} from '@chakra-ui/react';
import { Key } from 'ts-key-enum';
import { isCtrlOrMetaKey } from '../../../lib/helpers';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  onCancel?: () => void;
  title: string;
  confirmText?: string;
  cancelText?: string;
  hasCancel?: boolean;
  hasConfirm?: boolean;
  isDeleteConfirmAction?: boolean;
  hasCloseButton?: boolean;
  closeOnOverlayClick?: boolean;
  size?: string;
  confirmDisabled?: boolean;
  disableCtrlEnter?: boolean;
  initialFocusRef?: ModalProps['initialFocusRef'];
  loadingSpinner?: boolean;
}

const YoModal: React.FC<Props> = ({
  children,
  isOpen,
  onClose,
  onCancel,
  onConfirm,
  title,
  cancelText = 'Cancel',
  confirmText = 'Confirm',
  hasCancel = true,
  hasConfirm = true,
  isDeleteConfirmAction = false,
  hasCloseButton = true,
  closeOnOverlayClick = true,
  size,
  confirmDisabled,
  disableCtrlEnter,
  initialFocusRef,
  loadingSpinner = false,
}) => (
  <Modal
    closeOnOverlayClick={closeOnOverlayClick}
    isOpen={isOpen}
    onClose={onClose}
    scrollBehavior="outside"
    size={size}
    initialFocusRef={initialFocusRef}
  >
    <ModalOverlay />
    <ModalContent
      borderRadius={3}
      onKeyDown={(e) => {
        if (!onConfirm || !hasConfirm || confirmDisabled || disableCtrlEnter) return;
        if (e.key === Key.Enter && isCtrlOrMetaKey(e)) {
          e.stopPropagation();
          onConfirm();
        }
      }}
    >
      <ModalHeader color="yoBrand.600">{title}</ModalHeader>
      {hasCloseButton && <ModalCloseButton />}
      <ModalBody>{children}</ModalBody>
      <ModalFooter justifyContent="flex-start">
        {loadingSpinner && (
          <>
            <Spinner size="sm" ml={2} mr={5} />
            Deleting may take a second!
          </>
        )}
        {!loadingSpinner && hasConfirm && (
          <Button
            colorScheme={isDeleteConfirmAction ? 'red' : 'yoBrand'}
            mr={3}
            onClick={onConfirm}
            isDisabled={confirmDisabled}
          >
            {confirmText}
          </Button>
        )}
        {!loadingSpinner && hasCancel && (
          <Button
            variant={hasConfirm ? 'ghost' : 'solid'}
            colorScheme="yoBrand"
            onClick={onCancel}
            {...(hasConfirm
              ? {
                  _active: { bg: 'transparent' },
                  _hover: { bg: 'transparent' },
                }
              : {})}
          >
            {cancelText}
          </Button>
        )}
      </ModalFooter>
    </ModalContent>
  </Modal>
);

export default YoModal;
