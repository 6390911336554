import { UIBoardColumn, UIBoardElement } from 'types/index';
import { BoardElementType, Rectangle } from 'shared';
import nonUpdatingStore from 'store/index';
import { isElementInElement } from 'lib/helpers';
import { isRectInColumn } from '../konva/KonvaBoardElementHelper';
import { ATTACHED_TO_COLUMN_TOLERANCE, SNAP_TO_GRID_VALUE } from '../drag/DragConfig';

export const isRectInExtendedColumn = (rect: Rectangle, column: UIBoardColumn) => {
  // use a column shape (virtually) containing the element (and thus being taller)
  const virtualColumnShape = {
    ...column.shape,
    height: column.shape.height + 3 * SNAP_TO_GRID_VALUE,
  };
  return isElementInElement(virtualColumnShape, rect, {
    tolerance: ATTACHED_TO_COLUMN_TOLERANCE,
  });
};

export const getColumnWithHighestY = (
  cols: UIBoardColumn[],
  rect: Rectangle
): UIBoardColumn | undefined =>
  cols.filter((c) => isRectInColumn(rect, c, c.isSorted)).sort((a, b) => b.shape.y - a.shape.y)[0];

export const getSortedColumnOfElement = (element: UIBoardElement): UIBoardColumn | undefined => {
  const allBoardElements = nonUpdatingStore.getState().board.boardElements;
  const allColumns = allBoardElements.filter(
    (e) => e.type === BoardElementType.COLUMN && e.isSorted === true
  ) as UIBoardColumn[];

  return getColumnWithHighestY(allColumns, element.shape);
};

export const getExtendedColumnWithHighestY = (
  cols: UIBoardColumn[],
  rect: Rectangle
): UIBoardColumn | undefined =>
  cols.filter((c) => isRectInExtendedColumn(rect, c)).sort((a, b) => b.shape.y - a.shape.y)[0];
