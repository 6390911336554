/* eslint-disable no-bitwise */
import { CardIntegrationType } from 'shared';
import theme from './theme';

type ColorTuple = {
  name: string;
  value: string;
};

const COLORS: ColorTuple[] = [
  {
    name: 'White',
    value: '#FFFFFF',
  },
  {
    name: 'Orange',
    value: theme.colors.yoOrange[500],
  },
  {
    name: 'Green',
    value: theme.colors.yoGreen[500],
  },
  {
    name: 'Blue',
    value: theme.colors.yoBlue[500],
  },
  {
    name: 'Pink',
    value: theme.colors.yoPink[500],
  },
  {
    name: 'Yellow',
    value: theme.colors.yoYellow[500],
  },
];

const COLORS_LOOKUP = new Map<string, ColorTuple>();

COLORS.forEach((currentColor) => {
  COLORS_LOOKUP.set(currentColor.name, currentColor);
});

function getDefaultCardColor() {
  return COLORS[0];
}

function getDefaultStickyColor() {
  return COLORS[5];
}

export function getColorByName(name: string) {
  return COLORS_LOOKUP.get(name)!;
}

export function getCardColorByName(name: string | undefined) {
  if (!name) {
    return getDefaultCardColor();
  }
  return getColorByName(name) || getDefaultCardColor();
}

export function getStickyColorByName(name: string) {
  return getColorByName(name) || getDefaultStickyColor();
}

export const CURSOR_COLORS = [
  { color: '#54478c', stroke: 'white' },
  { color: '#048ba8', stroke: 'white' },
  { color: '#16db93', stroke: 'white' },
  { color: '#b9e769', stroke: 'black' },
  { color: '#f29e4c', stroke: 'black' },
  { color: '#2c699a', stroke: 'white' },
  { color: '#0db39e', stroke: 'white' },
  { color: '#83e377', stroke: 'black' },
  { color: '#efea5a', stroke: 'black' },
  { color: '#f1c453', stroke: 'black' },
];

export function getNumericHashFromId(userId: string) {
  let hash = 0;
  const len = userId.length;
  for (let i = 0; i < len; i += 1) {
    hash = (hash << 5) - hash + userId.charCodeAt(i);
    hash |= 0; // to 32bit integer
  }
  return Math.abs(hash);
}

/**
 * We need to get a "unique" color for each user
 * therefore we generate a number according to the user's id
 * @param userId the id from the user
 */
export function getCursorColor(userId: string) {
  const index = getNumericHashFromId(userId || '');
  return CURSOR_COLORS[index % CURSOR_COLORS.length];
}

type SwimlaneColorTuple = {
  contrast: string;
  light: string;
} & ColorTuple;

const SWIMLANE_COLORS: SwimlaneColorTuple[] = [
  {
    name: 'Brand',
    value: theme.colors.yoBrand[500],
    contrast: 'white',
    light: theme.colors.yoBrand[300],
  },
  {
    ...getColorByName('Orange'),
    contrast: '#000000',
    light: theme.colors.yoOrange[300],
  },
  {
    ...getColorByName('Green'),
    contrast: '#000000',
    light: theme.colors.yoGreen[300],
  },
  {
    ...getColorByName('Blue'),
    contrast: '#ffffff',
    light: theme.colors.yoBlue[300],
  },
  {
    ...getColorByName('Pink'),
    contrast: '#ffffff',
    light: theme.colors.yoPink[300],
  },
  {
    ...getColorByName('Yellow'),
    contrast: '#000000',
    light: theme.colors.yoYellow[300],
  },
];

function getDefaultSwimlaneColor() {
  return SWIMLANE_COLORS[0];
}

const SWIMLANE_COLORS_LOOKUP = new Map<string, SwimlaneColorTuple>();

SWIMLANE_COLORS.forEach((currentColor) => {
  SWIMLANE_COLORS_LOOKUP.set(currentColor.name, currentColor);
});

export function getSwimlaneColorByName(name: string) {
  return SWIMLANE_COLORS_LOOKUP.get(name)!;
}

export type CardIntegrationColorPair = {
  foreground: string;
  background: string;
  icons?: string;
};

export function getIntegrationColor(integration?: CardIntegrationType): CardIntegrationColorPair {
  switch (integration) {
    case CardIntegrationType.LOOM: {
      return {
        foreground: 'white',
        background: '#625DF5',
      };
    }
    default: {
      return {
        foreground: 'black',
        background: 'rgba(0,0,0,0.08)',
      };
    }
  }
}

export function getImageColorPair(color?: string): CardIntegrationColorPair {
  const backgroundColor = color ? getColorByName(color).value : getDefaultCardColor().value;
  return {
    foreground: 'black',
    background: backgroundColor,
    icons: 'white',
  };
}

export {
  COLORS,
  COLORS_LOOKUP,
  getDefaultCardColor,
  getDefaultStickyColor,
  SWIMLANE_COLORS,
  SWIMLANE_COLORS_LOOKUP,
  getDefaultSwimlaneColor,
};
