import { useRef, useEffect } from 'react';

export * from './Events';

function useFocusOnMountRef(selectAllValue?: string) {
  const nodeRef = useRef<HTMLInputElement & HTMLTextAreaElement>(null);
  useEffect(() => {
    const node = nodeRef.current as HTMLInputElement;
    if (node) {
      node.focus();
      // if the selectAllValue is set and matches the node value start selection at 0
      const selectionStartPos =
        selectAllValue && selectAllValue === node.value ? 0 : node.value.length;
      node.setSelectionRange(selectionStartPos, node.value.length);
    }
  }, []);
  return nodeRef;
}

export { useFocusOnMountRef };
