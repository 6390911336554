/* eslint-disable import/prefer-default-export */
import { Avatar, Text, Flex, Grid, Tooltip } from '@chakra-ui/react';
import { CardEventDTO, CardEventType } from 'shared';
import { getFullnameFromUser } from 'lib/helpers';
import DateTimeTooltip from '../DateTimeTooltip';

type Props = CardEventDTO & { hasCustomText?: boolean; title?: string };

const BaseHistoryEntry: React.FC<Props> = ({
  createdAt,
  createdBy,
  children,
  hasCustomText,
  title,
}) => {
  const userName = getFullnameFromUser(createdBy);

  return (
    <Grid alignItems="center" gridTemplateColumns="auto 1fr auto" columnGap={2}>
      <Flex alignItems="center">
        <Avatar src={createdBy?.avatarUrl} name={userName || 'y o'} size="sm" mr={2} />
        <Text as="span" fontWeight="bold" noOfLines={1} display={{ base: 'none', md: 'inline' }}>
          {userName || 'Someone'}
        </Text>
      </Flex>
      <Tooltip label={title} hidden={!title}>
        <Flex alignItems="center" textOverflow="ellipsis" overflowX="hidden" ml={-1}>
          {hasCustomText ? (
            children
          ) : (
            <Text as="span" noOfLines={1} isTruncated>
              {children}
            </Text>
          )}
        </Flex>
      </Tooltip>
      <DateTimeTooltip datetime={createdAt} textAlign="right" />
    </Grid>
  );
};

export const CardHistoryMoveEvent = (event: CardEventDTO) => (
  <BaseHistoryEntry {...event}>
    moved this card {event.newValues?.text ? 'into' : 'to'}{' '}
    <b>{event.newValues?.text || 'the Board'}</b>
  </BaseHistoryEntry>
);

export const CardHistoryAssignEvent = (event: CardEventDTO) => (
  <BaseHistoryEntry {...event}>
    {event.type === CardEventType.CARD_ASSIGNED ? 'assigned' : 'unassigned'}{' '}
    <b>{event.newValues?.text} </b>
    {event.type === CardEventType.CARD_ASSIGNED ? 'to' : 'from'} this card
  </BaseHistoryEntry>
);

export const CardHistoryWatchEvent = (event: CardEventDTO) => (
  <BaseHistoryEntry {...event}>
    {event.type === CardEventType.CARD_WATCHED
      ? 'is now receiving notifications for this card'
      : 'is not receiving notifications for this card anymore'}{' '}
  </BaseHistoryEntry>
);

export const CardCreationEvent = (event: CardEventDTO) => (
  <BaseHistoryEntry {...event}>
    created this Card{' '}
    {event.newValues?.text && (
      <>
        in Column <b>{event.newValues.text}</b>
      </>
    )}
  </BaseHistoryEntry>
);

export const CardArchivedEvent = (event: CardEventDTO) => (
  <BaseHistoryEntry {...event}>
    {event.type === CardEventType.CARD_ARCHIVED ? 'archived' : 'unarchived'} this card
  </BaseHistoryEntry>
);

export const CardTitleChangeEvent = (event: CardEventDTO) => (
  <BaseHistoryEntry
    title={
      event.oldValues?.text
        ? `${event.oldValues.text} → ${event.newValues?.text}`
        : `set to ${event.newValues?.text ?? "' '"} `
    }
    {...event}
  >
    {
      // change text when oldValue is empty --> applies when title is set initially and when setting title after deleting it
      !event.oldValues?.text ? 'set the title' : 'changed the title from '
    }
    <b>{event.oldValues?.text ?? ''}</b> to <b>{event.newValues?.text ?? "' '"}</b>
  </BaseHistoryEntry>
);

const substringExtended = (str: string, begin: number, end: number) => {
  let substr = str.substring(begin, end);
  if (begin > 0) substr = `...${substr}`;
  if (end < str.length) substr = `${substr}...`;
  return substr;
};

export const CardDescriptionChangeEvent = (event: CardEventDTO) => {
  const htmlElement = document.createElement('div');
  htmlElement.innerHTML = event.oldValues?.text ?? '';
  const plainTextOld = htmlElement.textContent ?? '';
  htmlElement.innerHTML = event.newValues?.text ?? '';
  const plainTextNew = htmlElement.textContent ?? '';

  let firstDiff = -1;
  for (let i = 0; i < plainTextOld.length && i < plainTextNew.length; i += 1) {
    if (plainTextOld.charAt(i) !== plainTextNew.charAt(i)) {
      firstDiff = i;
      break;
    }
  }

  let textOld = substringExtended(plainTextOld, 0, 15);
  let textNew = substringExtended(plainTextNew, 0, 15);
  const lengthOld = plainTextOld.length;
  const lengthNew = plainTextNew.length;
  if (firstDiff === -1) {
    if (lengthOld > lengthNew) {
      textOld = substringExtended(plainTextOld, lengthNew - 5, lengthNew + 10);
      textNew = substringExtended(plainTextNew, lengthNew - 5, lengthNew);
    } else if (lengthNew > lengthOld) {
      textOld = substringExtended(plainTextOld, lengthOld - 5, lengthOld);
      textNew = substringExtended(plainTextNew, lengthOld - 5, lengthOld + 10);
    }
  } else if (firstDiff > 5) {
    textOld = substringExtended(plainTextOld, firstDiff - 5, firstDiff + 10);
    textNew = substringExtended(plainTextNew, firstDiff - 5, firstDiff + 10);
  }

  return (
    <BaseHistoryEntry
      title={
        event.oldValues?.text
          ? `${plainTextOld} → ${plainTextNew}`
          : `set to ${plainTextNew ?? "' '"} `
      }
      {...event}
    >
      {
        // change text when oldValue is empty --> applies when title is set initially and when setting title after deleting it
        !event.oldValues?.text ? 'set the description' : 'changed the description from '
      }
      {textOld !== '' ? <b>{textOld}</b> : '" "'} to {textNew !== '' ? <b>{textNew}</b> : '" "'}
    </BaseHistoryEntry>
  );
};

export const CardAttachmentChangeEvent = (event: CardEventDTO) => (
  <BaseHistoryEntry title={event.newValues?.text} {...event}>
    {event.type === CardEventType.ATTACHMENT_ADDED ? 'added' : 'deleted'} the attachment{' '}
    <Text as="span" title={event.newValues?.text} fontWeight="bold">
      {event.newValues?.text}
    </Text>
  </BaseHistoryEntry>
);

function getBlockerText(type: CardEventType) {
  switch (type) {
    case CardEventType.BLOCKER_CREATED:
      return 'created the Blocker: ';
    case CardEventType.BLOCKER_REOPENED:
      return 'reopened the Blocker: ';
    case CardEventType.BLOCKER_RESOLVED:
      return 'resolved the Blocker: ';
    case CardEventType.BLOCKER_DELETED:
      return 'deleted the Blocker: ';
    case CardEventType.BLOCKER_EDITED:
      return 'changed the Blocker to: ';
    default:
      return '';
  }
}

export const CardHistoryBlockerEvent = (event: CardEventDTO) => {
  const text =
    event.type === CardEventType.BLOCKER_DELETED ? event.oldValues?.text : event.newValues?.text;
  return (
    <BaseHistoryEntry title={text} hasCustomText {...event}>
      <Text as="span" mr={1} noOfLines={1} flexShrink={0}>
        {getBlockerText(event.type)}
      </Text>
      <Text as="span" mr={1} flexGrow={1} fontWeight="bold" isTruncated>
        {text}
      </Text>
    </BaseHistoryEntry>
  );
};
