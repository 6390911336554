import { Avatar, Input, Stack, Flex, Box, useToast } from '@chakra-ui/react';
import { CommentDTO } from 'shared';
import dynamic from 'next/dynamic';
import { useState, useEffect } from 'react';
import { useStoreState, useStoreActions } from 'store/hooks';
import { getFullnameFromUser } from 'lib/helpers';
import fetchClient from 'lib/Fetch';
import { v4 as generateId } from 'uuid';
import { useCommentsEmitting } from 'hooks/Sockets';
import CommentDetail from './Detail';

const BaseEditor = dynamic(() => import('../BaseEditor'), {
  ssr: false,
  loading: () => <textarea />,
});

interface CommentBoxProps {
  comments?: CommentDTO[];
  cardId: string;
  setIsNewCommentEditing: (isEditing: boolean) => void;
  setIsAnyCommentEditing: (isEditing: boolean) => void;
  isDisabled?: boolean;
}

interface NewCommentBoxProps {
  handleSave: (newValue: string) => void;
  setIsEditing: (isEditing: boolean) => void;
  isDisabled?: boolean;
}

interface CommentListProps {
  comments?: ReadonlyArray<CommentDTO>;
  isDisabled?: boolean;
  handleRemove: (id: string) => void;
  handleAddReaction: (commentId: string, emoji: string, userId: string) => void;
  handleRemoveReaction: (commentId: string, emoji: string, userId: string) => void;
  setAnyCommentIsInEdit: (anyIsInEdit: boolean) => void;
}

const CommentList: React.FC<CommentListProps> = ({
  comments,
  handleRemove,
  handleAddReaction,
  handleRemoveReaction,
  setAnyCommentIsInEdit,
  isDisabled = false,
}) => {
  const [commentsInEdit, setCommentsInEdit] = useState<string[]>([]);

  // this method is being called for a single comment inside the CommentList
  // and will add or remove it from the lists of comments that are currently in edit
  const setSingleCommentInEdit = (id: string, isInEdit: boolean) => {
    let newCommentsInEdit = commentsInEdit;

    if (!isInEdit) {
      newCommentsInEdit = newCommentsInEdit.filter((commentId) => commentId !== id);
      setCommentsInEdit(newCommentsInEdit);
    } else {
      newCommentsInEdit.push(id);
      setCommentsInEdit(newCommentsInEdit);
    }

    setAnyCommentIsInEdit(newCommentsInEdit && newCommentsInEdit.length > 0);
  };

  return (
    <>
      {comments?.map((comment) => (
        <Box key={comment._id}>
          <CommentDetail
            removeComment={handleRemove}
            handleAddReaction={handleAddReaction}
            handleRemoveReaction={handleRemoveReaction}
            id={comment._id}
            comment={comment.comment}
            commentReactions={comment.reactions || []}
            createdBy={comment.createdBy}
            createdAt={comment.createdAt ?? new Date()}
            cardId={comment.cardId}
            setCommentIsEditing={setSingleCommentInEdit}
            isDisabled={isDisabled}
          />
        </Box>
      ))}
    </>
  );
};

const NewCommentBox: React.FC<NewCommentBoxProps> = ({
  handleSave,
  setIsEditing,
  isDisabled = false,
}) => {
  const user = useStoreState((store) => store.user.user);
  const [isEditing, setIsEditingState] = useState(false);
  const userName = getFullnameFromUser(user);

  const setIsEditingInternal = (newIsEditing: boolean) => {
    setIsEditingState(newIsEditing);
    setIsEditing(newIsEditing);
  };

  return (
    <Flex alignItems="top" mb={4}>
      <Avatar src={user?.avatarUrl} name={userName || 'y o'} size="sm" mr={2} mt={1} />
      <Box
        width="100%"
        _after={{
          content: "''",
          display: 'table',
        }}
      >
        {!isEditing ? (
          <Input
            key="CommentInputControl"
            float="left"
            width="100%"
            marginRight="-100%"
            borderRadius="3px"
            borderColor="yoGray.border"
            placeholder="Write a comment..."
            _placeholder={{ color: 'yoGray.800' }}
            _hover={{ borderColor: 'yoGray.border' }}
            isDisabled={isDisabled}
            onFocus={() => {
              if (isDisabled) return;
              setIsEditingState(true);
            }}
          />
        ) : (
          <Box
            flexGrow={1}
            float="left"
            width="100%"
            marginRight="-100%"
            key="CommentInputEditorBox"
            height="auto"
            overflow="hidden"
          >
            <Box>
              <BaseEditor
                setIsEditing={setIsEditingInternal}
                onSave={handleSave}
                initialValue=""
                isEditing={isEditing}
                disableSavingEmpty
                setHasContentChanged={setIsEditing}
                isCommentEditor
              />
            </Box>
          </Box>
        )}
      </Box>
    </Flex>
  );
};

const CommentBox: React.FC<CommentBoxProps> = ({
  comments,
  cardId,
  setIsNewCommentEditing,
  setIsAnyCommentEditing,
  isDisabled = false,
}) => {
  const { addComment } = useStoreActions((store) => store.board);
  const [displayComments, setDisplayComments] = useState<CommentDTO[]>([]);
  const toast = useToast();
  const { updateCommentCount } = useCommentsEmitting();

  useEffect(() => {
    setDisplayComments(
      [...(comments ?? [])].sort(
        (a, b) =>
          new Date(b.createdAt ?? new Date())?.getTime() -
          new Date(a.createdAt ?? new Date())?.getTime()
      )
    );
  }, [comments]);

  const handleRemove = (id: string) => {
    const newCommentList = displayComments.filter((comment) => comment._id !== id);
    setDisplayComments(newCommentList);
    updateCommentCount({ cardId });
  };

  const handleSave = async (newValue: string) => {
    const newComment = await addComment({ comment: { cardId, comment: newValue } });
    setDisplayComments([newComment, ...displayComments]);
    updateCommentCount({ cardId });
  };

  const removeReactionFromState = (commentId: string, emoji: string, userId: string) => {
    const comment = displayComments.find((displayComment) => displayComment._id === commentId);

    const updatedReactions = comment?.reactions?.filter(
      (reaction) => !(reaction.userId.toString() === userId.toString() && reaction.emoji === emoji)
    );

    if (updatedReactions?.length !== comment?.reactions?.length) {
      const updatedComments = displayComments.map((displayComment) =>
        displayComment._id === commentId
          ? { ...displayComment, reactions: updatedReactions }
          : displayComment
      );
      setDisplayComments(updatedComments);
    }
  };

  const addReactionToState = (commentId: string, emoji: string, userId: string) => {
    const comment = displayComments.find((displayComment) => displayComment._id === commentId);
    if (
      !comment?.reactions?.some(
        (reaction) => reaction.emoji === emoji && reaction.userId.toString() === userId.toString()
      )
    ) {
      const updatedComments = displayComments.map((displayComment) =>
        displayComment._id === commentId
          ? {
              ...displayComment,
              reactions: (comment?.reactions || []).concat({ emoji, userId, _id: generateId() }),
            }
          : displayComment
      );
      setDisplayComments(updatedComments);
    }
  };

  const handleAddReaction = (commentId: string, emoji: string, userId: string) => {
    addReactionToState(commentId, emoji, userId);

    try {
      fetchClient(`comments/${commentId}/reaction`, {
        method: 'PUT',
        body: { emoji },
      });
    } catch (e) {
      toast({
        position: 'bottom',
        status: 'error',
        title: 'Reaction could not be saved. Please try again.',
      });
      removeReactionFromState(commentId, emoji, userId);
    }
  };

  const handleRemoveReaction = (commentId: string, emoji: string, userId: string) => {
    removeReactionFromState(commentId, emoji, userId);

    try {
      fetchClient(`comments/${commentId}/reaction`, {
        method: 'DELETE',
        body: { emoji },
      });
    } catch (e) {
      toast({
        position: 'bottom',
        status: 'error',
        title: 'Reaction could not be saved. Please try again.',
      });
      addReactionToState(commentId, emoji, userId);
    }
  };

  return (
    <Stack spacing={4}>
      <NewCommentBox
        handleSave={handleSave}
        setIsEditing={setIsNewCommentEditing}
        isDisabled={isDisabled}
      />
      <CommentList
        comments={displayComments}
        handleAddReaction={handleAddReaction}
        handleRemoveReaction={handleRemoveReaction}
        handleRemove={handleRemove}
        setAnyCommentIsInEdit={setIsAnyCommentEditing}
        isDisabled={isDisabled}
      />
    </Stack>
  );
};

export default CommentBox;
