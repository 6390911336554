import { Textarea } from '@chakra-ui/react';
import { Blocker } from 'shared';
import { useState } from 'react';
import { useStoreActions } from 'store/hooks';
import { v4 as generateUuid } from 'uuid';
import YoModal from '../YoModal';

interface CreateBlockerModalProps {
  isOpen: boolean;
  cardId: string;
  blockers: Blocker[];
  onClose: () => void;
}

export default function CreateBlockerModal({
  isOpen,
  onClose,
  cardId,
  blockers,
}: CreateBlockerModalProps) {
  const updateElement = useStoreActions((actions) => actions.board.updateElement);
  const [description, setDescription] = useState('');
  const handleCreateBlocker = () => {
    updateElement({
      id: cardId,
      blockers: [
        ...(blockers ?? []),
        {
          _id: generateUuid(),
          description,
          createdAt: new Date(),
          cardId,
        },
      ],
    });
    onClose();
  };
  return (
    <YoModal
      title="Create Blocker"
      confirmText="Create Blocker"
      isOpen={isOpen}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={handleCreateBlocker}
    >
      Why is this Card blocked?
      <Textarea
        mt={2}
        value={description}
        onChange={(event) => {
          setDescription(event.target.value);
        }}
        mb={3}
        autoFocus
      />
    </YoModal>
  );
}
