import { IconButton, Button, Box, BoxProps, EASINGS, Text } from '@chakra-ui/react';
import { useStoreState, useStoreActions } from 'store/hooks';
import { getNextZoomIncrement, getStage, zoomStage, resetZoom } from 'lib/zoom';
import { BoardMode } from 'store/board/settings';
import theme from 'lib/theme';
import { useEffect, useRef } from 'react';
import { Stage } from 'konva/types/Stage';
import { PointerIcon, UndoArrowIcon, RedoArrowIcon, HandIcon } from 'lib/icons';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import { motion } from 'framer-motion';
import { BiTargetLock } from 'react-icons/bi';
import nonUpdatingStore from 'store/index';
import TooltipWithShortcut, { YoKbd } from '../TooltipWithShortcut';

const MotionBox = motion<BoxProps>(Box);

export const styles = {
  default: 'gray',
  active: 'yoDarkBlue',
  shadow: theme.shadows.layer3.css,
};

function useStage() {
  const stage = useRef<Stage>();
  useEffect(() => {
    stage.current = getStage();
  }, []);
  return stage;
}

/**
 * Simply returns the user to the middle of all content
 * @returns void
 */

function goToSpawnPoint() {
  const stage = getStage();
  if (!stage) return;
  const elements = nonUpdatingStore.getState().board.boardElements;

  if (elements.length === 0) return;

  const elementShapes = elements.map((element) => element.shape);
  const xMin = Math.min(...elementShapes.map((shape) => shape.x));
  const yMin = Math.min(...elementShapes.map((shape) => shape.y));
  const yMax = Math.max(...elementShapes.map((shape) => shape.y + shape.height));
  const xMax = Math.max(...elementShapes.map((shape) => shape.x + shape.width));
  const box = {
    x: xMin,
    y: yMin,
    width: xMax - xMin,
    height: yMax - yMin,
  };

  const paddingOnFocus = {
    top: stage.width() * 0.2,
    left: stage.height() * 0.2,
  };
  const scale = Math.min(
    (stage.width() - paddingOnFocus.top) / box.width,
    (stage.height() - paddingOnFocus.left) / box.height
  );

  const centeredScale = {
    x: (stage.width() - box.width * scale) / 2,
    y: (stage.height() - box.height * scale) / 2,
  };

  stage.to({
    x: centeredScale.x + -box.x * scale,
    y: centeredScale.y + -box.y * scale,
    scaleX: scale,
    scaleY: scale,
  });
}

function BottomBar() {
  const stage = useStage();
  const mode = useStoreState((state) => state.boardSettings.mode);
  const zoomLevelPercent = useStoreState((state) => state.boardSettings.zoomLevelPercent);
  const zoomLevel = useStoreState((state) => state.boardSettings.zoomLevel);
  const showBookmarks = useStoreState((state) => state.boardSettings.showBookmarks);
  const { redo, undo } = useStoreActions((actions) => actions.board);
  const floatingBoardElement = useStoreState((state) => state.app.floatingBoardElement);
  const canRedo = useStoreState((state) => state.board.canRedo);
  const canUndo = useStoreState((state) => state.board.canUndo);
  const { setMode, setZoomLevelAndOffset } = useStoreActions((actions) => actions.boardSettings);

  const incrementOrDecrementZoom = (zoomIn: boolean) => {
    zoomStage({
      stage: stage.current!,
      addToScale: getNextZoomIncrement(zoomLevel),
      setZoomLevelAndOffset,
      zoomIn,
    });
  };

  return (
    <MotionBox
      bottom={4}
      left={{ base: 2, md: 4 }}
      variants={{
        initial: {
          x: 0,
          transition: {
            duration: 0.15,
            ease: EASINGS.easeInOut,
          },
        },
        moved: {
          x: '24rem',
          transition: {
            type: 'spring',
            damping: 25,
            stiffness: 180,
          },
        },
      }}
      initial="initial"
      animate={showBookmarks ? 'moved' : 'initial'}
      display="flex"
      position="fixed"
      zIndex={theme.zIndices.DOM_ELEMENTS}
      bg="white"
      shadow="topBar"
      borderRadius={6}
      opacity={floatingBoardElement ? 0.3 : 1}
    >
      <TooltipWithShortcut
        text={
          <Text>
            <b>Move tool:</b> Select elements, move elements around & change the viewport.
          </Text>
        }
        shortcut={<YoKbd>V</YoKbd>}
        offset={[125, 10]}
        aria-label="Enter Move-Mode"
      >
        <IconButton
          aria-label="Enter Move-Mode"
          variant="bottomBar"
          fontSize="22px"
          colorScheme={mode === BoardMode.MOVE ? styles.active : styles.default}
          icon={<PointerIcon />}
          borderLeftRadius={6}
          onClick={() => setMode(BoardMode.MOVE)}
        />
      </TooltipWithShortcut>
      <TooltipWithShortcut
        text={
          <Text>
            <b>Hand tool:</b> Change the viewport, move around on the canvas.
          </Text>
        }
        shortcut={<YoKbd>V</YoKbd>}
        offset={[100, 10]}
        aria-label="Enter Hand-Mode"
      >
        <IconButton
          aria-label="Toggle Hand-Mode"
          variant="bottomBar"
          fontSize="22px"
          colorScheme={mode === BoardMode.HAND ? styles.active : styles.default}
          icon={<HandIcon />}
          onClick={() => setMode(BoardMode.HAND)}
        />
      </TooltipWithShortcut>
      <Box w="1px" marginInlineStart={0} mt={1} flex="1" bg="yoGray.divider" my={1} mx={1} />
      <IconButton
        aria-label="Undo Change"
        title="Undo Change"
        fontSize="26px"
        variant="bottomBar"
        icon={<UndoArrowIcon />}
        onClick={() => undo()}
        isDisabled={!canUndo}
        colorScheme="yoBrand"
      />
      <IconButton
        aria-label="Redo Change"
        title="Redo Change"
        fontSize="26px"
        variant="bottomBar"
        icon={<RedoArrowIcon />}
        onClick={() => redo()}
        isDisabled={!canRedo}
        colorScheme="yoBrand"
      />
      <Box w="1px" flex="1" bg="yoGray.divider" my={1} mx={1} />
      <IconButton
        display={{ base: 'none', md: 'block' }}
        aria-label="Zoom Out"
        title="Zoom Out"
        fontSize="20px"
        variant="bottomBar"
        icon={<MinusIcon />}
        onClick={() => incrementOrDecrementZoom(false)}
        colorScheme="yoBrand"
      />
      <Button
        minW="2rem"
        variant="bottomBar"
        h="2.5rem"
        bg="transparent"
        colorScheme="yoBrand"
        fontSize={15}
        justifyContent="center"
        title="Zoom to 100%"
        onClick={() => resetZoom({ stage: stage.current!, setZoomLevelAndOffset })}
      >
        <Text top="2px">{zoomLevelPercent}</Text>
      </Button>
      <IconButton
        display={{ base: 'none', md: 'block' }}
        aria-label="Zoom In"
        title="Zoom In"
        fontSize="20px"
        variant="bottomBar"
        icon={<AddIcon />}
        onClick={() => incrementOrDecrementZoom(true)}
        colorScheme="yoBrand"
      />
      <IconButton
        display={{ base: 'none', md: 'block' }}
        aria-label="Go to Content"
        title="Go to Content"
        fontSize="26px"
        mr={0}
        pl={1}
        variant="bottomBar"
        icon={<BiTargetLock />}
        borderRightRadius={6}
        onClick={() => goToSpawnPoint()}
        colorScheme="yoBrand"
      />
    </MotionBox>
  );
}

export default BottomBar;
