import {
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Box,
  IconButton,
  Text,
  Flex,
  Tag,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { useUser } from 'hooks/User';
import { getFullnameFromUser } from 'lib/helpers';
import theme from 'lib/theme';
import { RiQuestionMark } from 'react-icons/ri';
import { useStoreActions } from 'store/hooks';
import { YoKbd } from '../TooltipWithShortcut';
import { DISCORD_INVITE_URL } from '../DiscordButton';

const LAST_RELEASES = [
  {
    title: 'List Columns',
    id: '27th_of_October',
    releaseDate: new Date('2022-10-27'),
  },
  {
    title: 'Taskslists',
    id: '14th_of_April',
    releaseDate: new Date('2022-04-14'),
  },
  {
    title: 'Links',
    id: '22nd_of_March',
    releaseDate: new Date('2022-02-22'),
  },
];

function useNewBubble() {
  const [hasClickedLatestEntry, setHasClickedLatestEntry] = useState(false);

  useEffect(() => {
    const lastEntry = localStorage.getItem('taggle-latest-entry');
    if (lastEntry === LAST_RELEASES[0].id) {
      setHasClickedLatestEntry(true);
    }
  }, []);

  return {
    hasClickedLatestEntry,
    onClickLatestEntry: () => {
      localStorage.setItem('taggle-latest-entry', LAST_RELEASES[0].id);
      setHasClickedLatestEntry(true);
    },
  };
}

export function BoardHelp() {
  const user = useUser();
  const openHelpModal = useStoreActions((actions) => actions.app.setIsShortcutPopoverOpen);
  const { hasClickedLatestEntry, onClickLatestEntry } = useNewBubble();

  return (
    <Box position="fixed" bottom={4} right={4} zIndex={theme.zIndices.DOM_ELEMENTS}>
      {!hasClickedLatestEntry && (
        <Box
          position="fixed"
          bottom="3.25rem"
          right={4}
          bg="red.300"
          boxShadow="md"
          rounded="full"
          w={3.5}
          h={3.5}
          zIndex={theme.zIndices.DOM_ELEMENTS + 1}
        />
      )}
      <Menu variant="yo">
        <MenuButton
          as={IconButton}
          rounded="full"
          aria-label="Help"
          icon={<RiQuestionMark />}
          fontSize={24}
          height={12}
          width={12}
          bg="yoBrand.500"
          color="white"
          boxShadow="topBar"
          _active={{ bg: 'yoOrange.500' }}
          _hover={{
            boxShadow: 'circleHover',
            bg: 'yoBrand.800',
          }}
        />

        <MenuList>
          <MenuGroup>
            {LAST_RELEASES.map((release, i) => (
              <MenuItem key={release.title}>
                <Box
                  as="a"
                  target="_blank"
                  display="flex"
                  justifyContent="space-between"
                  w="100%"
                  onClick={() => {
                    if (i === 0) {
                      onClickLatestEntry();
                    }
                  }}
                  href={`https://taggle.so/changelog#${release.id}?ref=taggle`}
                >
                  <Box flex={1}>
                    <Text as="span" display="block">
                      {release.title}
                    </Text>
                    <Text as="span" display="block" fontSize={10} color="gray.300">
                      {dayjs(release.releaseDate).format('MMM D')} release notes
                    </Text>
                  </Box>
                  {i === 0 && !hasClickedLatestEntry && (
                    <Flex justifyContent="center" alignItems="center">
                      <Tag size="sm" colorScheme="green">
                        New
                      </Tag>
                    </Flex>
                  )}
                </Box>
              </MenuItem>
            ))}
            <MenuItem as="a" href="https://taggle.so/changelog?ref=taggle">
              View more...
            </MenuItem>
          </MenuGroup>
          <MenuDivider />
          <MenuGroup>
            <MenuItem as="a" target="_blank" href={DISCORD_INVITE_URL}>
              Join our Discord
            </MenuItem>
            <MenuItem as="a" target="_blank" href="https://twitter.com/gettaggle">
              Follow @gettaggle on Twitter
            </MenuItem>
          </MenuGroup>
          <MenuDivider />
          <MenuGroup>
            <MenuItem
              as={Flex}
              cursor="pointer"
              justifyContent="space-between"
              onClick={() => openHelpModal(true)}
            >
              Keyboard Shortcuts
              <YoKbd fontSize={10}>?</YoKbd>
            </MenuItem>
            <MenuDivider />
            <MenuItem
              as={Flex}
              cursor="pointer"
              justifyContent="space-between"
              fontWeight="bold"
              onClick={() => {
                if (user) {
                  window.Tawk_API?.setAttributes?.({
                    name: getFullnameFromUser(user),
                    email: user.email,
                  });
                }
                window.Tawk_API?.popup?.();
              }}
            >
              Chat with us{' '}
              <Tag size="sm" colorScheme="green">
                LIVE
              </Tag>
            </MenuItem>
          </MenuGroup>
        </MenuList>
      </Menu>
    </Box>
  );
}
