import { useStoreState, useStoreActions } from 'store/hooks';
import { Select, Box, Tooltip } from '@chakra-ui/react';
import {
  BoardElement,
  BoardElementLinkType,
  BoardElementType,
  createBoardElementLink,
} from 'shared';
import { useState } from 'react';
import { BoardElementState } from 'types/index';
import { LINK_DESCRIPTIONS } from 'lib/Links.helper';
import nonUpdatingStore from 'store/index';
import BasePopover from './BasePopover';

export function LinkSearchPopover() {
  const selectedElements = useStoreState((state) =>
    state.board.boardElements.filter(
      (el) => el.state === BoardElementState.FOCUSED || el.state === BoardElementState.INDETAIL
    )
  );

  const currentElement = selectedElements.length === 1 ? selectedElements[0] : undefined;
  const [selectedRelation, setSelectedRelation] = useState<BoardElementLinkType>(
    BoardElementLinkType.RELATED
  );
  const isLinkPopoverOpen = useStoreState((store) => store.app.isLinkPopoverOpen);
  const { setIsLinkPopoverOpen, setActiveLinkCardId } = useStoreActions((store) => store.app);
  const setFloatingBoardElement = useStoreActions((actions) => actions.app.setFloatingBoardElement);

  const handleSelect = async (selectedElement?: BoardElement) => {
    if (!selectedElement || !currentElement) return;

    await nonUpdatingStore.getActions().board.addLink({
      currentElement,
      targetElement: selectedElement,
      linkType: Number(selectedRelation),
    });
    setActiveLinkCardId(currentElement.id);
  };

  return (
    <BasePopover
      key="links"
      isOpen={isLinkPopoverOpen}
      onClose={() => setIsLinkPopoverOpen(false)}
      onSelect={handleSelect}
      omitResultsWithId={currentElement?.id ? [currentElement.id] : []}
      onAdd={(newTitle) => {
        if (!currentElement) return;

        setFloatingBoardElement({
          type: BoardElementType.CARD,
          title: newTitle,
          links: [
            createBoardElementLink({
              linkType: selectedRelation,
              targetElementId: currentElement.id,
            }),
          ],
        });
      }}
      newElementText="Create new Card"
      leftAddon={
        <Tooltip label="Select the type of the relation">
          <Select
            border="none"
            outline="none"
            value={selectedRelation}
            bg="yoDark.400"
            height="60px"
            borderRightRadius={0}
            onChange={(e) => setSelectedRelation(Number(e.target.value))}
            title="Select link type"
          >
            {Object.entries(LINK_DESCRIPTIONS).map(([value, label]) => (
              <Box as="option" color="black" key={value} value={value}>
                {label}
              </Box>
            ))}
          </Select>
        </Tooltip>
      }
    />
  );
}
