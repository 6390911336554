import { Editable, EditablePreview, EditableInput } from '@chakra-ui/react';
import theme from 'lib/theme';
import EditButtons from '../EditButtons';

export const TITLE_EDIT_BUTTON_ID = 'TitleEditButtons';

interface Props {
  title: string;
  onSave: (newTitle: string) => void;
  isDisabled?: boolean;
  setIsEditing: (newValue: boolean) => void;
}

const TitleEdit: React.FC<Props> = ({ title, onSave, isDisabled, setIsEditing }) => (
  <Editable
    fontSize="xl"
    w="100%"
    color="yoGray.900"
    fontWeight={700}
    defaultValue={title || ''}
    onSubmit={(newTitle) => {
      setIsEditing(false);
      onSave(newTitle);
    }}
    onChange={(newTitle) => {
      setIsEditing(newTitle.trim() !== title.trim());
    }}
    submitOnBlur={false}
    placeholder="Enter Title..."
    startWithEditView={false}
    selectAllOnFocus={false}
    mb={4}
    position="relative"
    isDisabled={isDisabled}
    pointerEvents={isDisabled ? 'none' : 'all'}
  >
    {({ isEditing, onCancel, onSubmit }) => (
      <>
        <EditablePreview
          w="100%"
          _hover={{ backgroundColor: theme.colors.yoGray[200] }}
          _focus={{ backgroundColor: theme.colors.yoGray[200] }}
          borderRadius="sm"
          pl={2}
          ml={-2}
        />

        <EditableInput
          borderWidth="1px"
          _focus={{ borderColor: 'yoGray.border' }}
          borderColor="yoGray.border"
          mb={3}
          pl={2}
          ml={-2}
        />
        {isEditing && !isDisabled ? (
          <EditButtons
            ml={-2}
            onSave={() => {
              setIsEditing(false);
              onSubmit();
            }}
            onCancel={() => {
              setIsEditing(false);
              onCancel();
            }}
            id={TITLE_EDIT_BUTTON_ID}
          />
        ) : null}
      </>
    )}
  </Editable>
);

export default TitleEdit;
