import { BoardElementType, ValidationRules, assertUnreachable } from 'shared';

export function getDefaultElementHeight(type: BoardElementType): number {
  switch (type) {
    case BoardElementType.COLUMN:
      return ValidationRules.LayoutRules.Column.COLUMN_MIN_SIZE;
    case BoardElementType.SWIMLANE:
      return ValidationRules.LayoutRules.Swimlane.SWIMLANE_MIN_SIZE;
    case BoardElementType.CARD:
      return ValidationRules.LayoutRules.Card.CARD_MIN_HEIGHT;
    case BoardElementType.STICKY:
      return ValidationRules.LayoutRules.Sticky.STICKY_MIN_SIZE;
    default: {
      return assertUnreachable(type);
    }
  }
}

export function getDefaultElementWidth(type: BoardElementType): number {
  switch (type) {
    case BoardElementType.COLUMN:
      return ValidationRules.LayoutRules.Column.COLUMN_MIN_SIZE;
    case BoardElementType.SWIMLANE:
      return ValidationRules.LayoutRules.Swimlane.SWIMLANE_MIN_SIZE;
    case BoardElementType.CARD:
      return ValidationRules.LayoutRules.Card.CARD_MIN_WIDTH;
    case BoardElementType.STICKY:
      return ValidationRules.LayoutRules.Sticky.STICKY_MIN_SIZE;
    default: {
      return assertUnreachable(type);
    }
  }
}
