import { Tooltip } from '@chakra-ui/react';
import { useUser } from 'hooks/User';
import { formatNames } from 'lib/formatting.helper';
import { ReceiveNotificationsActiveIcon, ReceiveNotificationsInactiveIcon } from 'lib/icons';
import { useMemo } from 'react';
import nonUpdatingStore from 'store/index';
import { ActionButton } from './ActionButton';

interface Props {
  watchers: string[];
  isDisabled?: boolean;
  cardId: string;
}

export function WatchButton({ watchers, cardId, isDisabled = false }: Props) {
  const user = useUser();

  const isWatchingCard = watchers?.some((watcher) => watcher === user?._id);

  const populatedWatchers = useMemo(() => {
    const { avatars } = nonUpdatingStore.getState().board;

    return watchers.flatMap((userId) => {
      const watcherUser = avatars.find((currentUser) => currentUser.id === userId);
      if (userId === user?._id) {
        return ['You'];
      }
      if (!watcherUser) {
        return [];
      }
      return [watcherUser.name];
    });
  }, [user, watchers]);

  return (
    <Tooltip
      label={`${formatNames(populatedWatchers)} ${
        populatedWatchers.length > 1 || populatedWatchers[0] === 'You' ? 'are' : 'is'
      } recieving notifications`}
      shouldWrapChildren
      textAlign="center"
      placement="top"
    >
      <ActionButton
        onClick={() => {
          nonUpdatingStore.getActions().board.toggleWatchCard({ cardId });
        }}
        leftIcon={
          isWatchingCard ? (
            <ReceiveNotificationsActiveIcon mb="2px" />
          ) : (
            <ReceiveNotificationsInactiveIcon mb="2px" />
          )
        }
        isDisabled={isDisabled}
      >
        <u>R</u>eceive Notifications
      </ActionButton>
    </Tooltip>
  );
}
