import { Box, Button, ButtonProps, Text } from '@chakra-ui/react';
import { forwardRef } from 'react';

interface Props {
  onClick?: ButtonProps['onClick'];
  leftIcon: ButtonProps['leftIcon'];
  title?: string;
  isDisabled?: boolean;
  children: React.ReactNode;
}

export const ActionButton = forwardRef<HTMLButtonElement, Props>(
  ({ children, onClick, leftIcon, title, isDisabled }, ref) => (
    <Button
      justifyContent="flex-start"
      iconSpacing="17px"
      leftIcon={<Box color="yoBrand.500">{leftIcon}</Box>}
      aria-label={title}
      title={title}
      padding="5px 5px 5px 18px"
      width="100%"
      background="yoGray.300"
      onClick={onClick}
      _hover={{ background: 'yoGray.400' }}
      isDisabled={isDisabled}
    >
      <Text color="yoBrand.600" fontWeight="700" ref={ref as any}>
        {children}
      </Text>
    </Button>
  )
);
