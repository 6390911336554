/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { useEffect, useMemo, useRef, useState } from 'react';
import { Flex, Divider, Icon } from '@chakra-ui/react';
import { FiUserPlus, FiXCircle } from 'react-icons/fi';
import { FaRegCopy, FaPaste } from 'react-icons/fa';
import { BiArchiveIn } from 'react-icons/bi';
import { MdFlipToBack, MdFlipToFront, MdLink, MdOutlineVisibility } from 'react-icons/md';
import { pasteElements } from 'lib/Events';
import { useStoreActions, useStoreState } from 'store/hooks';
import { useOrdering } from 'hooks/Ordering';
import { getColorByName, getSwimlaneColorByName } from 'lib/colors';
import { Position } from 'store/app';
import { BoardElementType, Rectangle } from 'shared';
import { NotAllowedIcon } from '@chakra-ui/icons';
import { useAssignUser } from 'hooks/useAssignUser';
import { trackPlausibleEvent } from 'lib/plausible';
import { CLIENT_PLAUSIBLE_EVENTS } from 'lib/Types';
import { getVisualLinks } from 'lib/Links.helper';
import ColorDot from './ColorDot';
import MenuItem from './MenuItem';
import CreateBlockerModal from './CreateBlockerModal';

/**
 * This enum holds possible values for the options in the context menu
 * This means the context menu has different entries
 * depending on the type of the underlaying BoardElement
 */
export enum ContextMenuType {
  // Default Options
  DEFAULT = 'DEFAULT',
  // Default Options + Ability to change the background color of the BoardElement
  COLORABLE = 'COLORABLE',
  // special cards
  DEFAULT_WITH_BLOCKERS = 'COLORABLE_WITH_BLOCKERS',
}

interface ContextMenuProps {
  id: string;
  refId?: string;
  refIds?: string[];
  shape?: Partial<Rectangle>;
  menuType?: ContextMenuType;
}

export const CONTEXT_MENU_WIDTH = 200;

export function getAvailableSpawnPosition({
  boundingBox,
  viewport,
}: {
  boundingBox: DOMRect;
  viewport: { width: number; height: number };
}): Position {
  const x = boundingBox.right > viewport.width ? boundingBox.x - boundingBox.width : boundingBox.x;
  const y =
    boundingBox.bottom > viewport.height ? boundingBox.y - boundingBox.height : boundingBox.y;

  // the context menu should always be positioned inside the viewport
  return { x, y: y < 0 ? 25 : y };
}

export function useContextMenuPosProps(pos: Position) {
  // open to bottom right by default
  const [hasEnsuredPosition, setHasEnsuredPosition] = useState(false);
  const [position, setPosition] = useState(pos);
  const contextMenuWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (
      contextMenuWrapperRef.current &&
      contextMenuWrapperRef.current.clientWidth &&
      contextMenuWrapperRef.current.clientHeight
    ) {
      const viewport = { width: window.innerWidth, height: window.innerHeight };
      const boundingBox = contextMenuWrapperRef.current.getBoundingClientRect();
      const availableSpawnPosition = getAvailableSpawnPosition({
        boundingBox,
        viewport,
      });
      setPosition(availableSpawnPosition);
      setHasEnsuredPosition(true);
    }
  }, [contextMenuWrapperRef.current?.clientWidth]);

  return {
    contextMenuWrapperRef,
    contextMenuPosProps: {
      left: `${position.x}px`,
      top: `${position.y}px`,
      visibility: (hasEnsuredPosition ? 'visible' : 'hidden') as any,
    },
  };
}

const ContextMenu: React.FC<ContextMenuProps> = ({
  id,
  refId,
  refIds,
  shape: { x, y } = { x: 0, y: 0 },
  menuType,
}: any) => {
  const timesPastedRef = useRef(0);
  const [currentOpen, setOpen] = useState<string | null>(null);
  const { removeElements, setClipboard, archiveElement } = useStoreActions(
    (actions) => actions.board
  );
  const currentUserId = useStoreState((state) => state.user.user?._id);
  const canPaste = useStoreState((state) => state.board.hasPastableElements);
  const removeWidget = useStoreActions((actions) => actions.widgets.removeWidget);
  const boardElements = useStoreState((state) => state.board.boardElements);
  const { setIsLinkPopoverOpen, setActiveLinkCardId } = useStoreActions((actions) => actions.app);
  const currentItem = useMemo(
    () => boardElements.find((e) => e.id === refId),
    [boardElements, refId]
  );

  const { isInBack, isOnTop, sendBackward, sendForward, sendToBack, sendToTop } =
    useOrdering(currentItem);

  const isCard = useMemo(() => currentItem?.type === BoardElementType.CARD, [currentItem]);

  const hasActiveBlocker = useMemo(() => {
    if (!currentItem?.blockers) {
      return false;
    }
    return currentItem?.blockers.filter((blocker) => !blocker.resolvedAt)?.length > 0;
  }, [currentItem?.blockers]);
  const [showCreateBlocker, setShowCreateBlocker] = useState<boolean>(false);

  const canArchive = useMemo(() => {
    const currentElementIds = refIds ?? [refId];
    const hasCards = currentElementIds.some(
      (currentId: any) =>
        boardElements.find((currentElement) => currentElement.id === currentId)?.type ===
        BoardElementType.CARD
    );
    return hasCards;
  }, [boardElements, refIds, refId]);

  const { contextMenuWrapperRef, contextMenuPosProps } = useContextMenuPosProps({ x, y });

  const canAssign = !currentItem?.assignedAvatars?.some((avatar) => avatar === currentUserId);
  const handleAssign = useAssignUser();

  const isSwimlane = currentItem?.type === BoardElementType.SWIMLANE;
  const currentColor = isSwimlane
    ? getSwimlaneColorByName((currentItem as any)?.color)?.value
    : getColorByName((currentItem as any)?.color)?.value;

  return (
    <Flex
      ref={contextMenuWrapperRef}
      position="absolute"
      width={CONTEXT_MENU_WIDTH}
      bg="gray.700"
      borderRadius={4}
      color="white"
      zIndex="CONTEXT_MENU"
      px={2}
      py={2}
      direction="column"
      {...contextMenuPosProps}
    >
      {(menuType === ContextMenuType.COLORABLE ||
        menuType === ContextMenuType.DEFAULT_WITH_BLOCKERS) && (
        <>
          {menuType === ContextMenuType.COLORABLE && (
            <MenuItem
              refId={refId}
              id="2"
              expandable
              open={currentOpen}
              setOpen={setOpen}
              swimlaneColors={isSwimlane}
            >
              <ColorDot color={currentColor} />
              Change Color
            </MenuItem>
          )}
          {isCard ? (
            <>
              <MenuItem
                icon={NotAllowedIcon}
                disabled={hasActiveBlocker}
                refId={refId}
                id="createBlocker"
                open={currentOpen}
                setOpen={setOpen}
                onClick={() => {
                  setShowCreateBlocker(true);
                }}
                title={
                  hasActiveBlocker
                    ? 'resolve the active Blocker to create a new one'
                    : 'create Blocker'
                }
              >
                Create Blocker
              </MenuItem>
              <CreateBlockerModal
                isOpen={showCreateBlocker}
                blockers={currentItem!.blockers!}
                cardId={refId}
                onClose={() => {
                  setShowCreateBlocker(false);
                  removeWidget(id);
                }}
              />
              <MenuItem
                icon={() => <Icon as={MdLink} fontSize="22px" mr={1} />}
                refId={refId}
                id="createLink"
                open={currentOpen}
                setOpen={setOpen}
                onClick={() => {
                  trackPlausibleEvent(CLIENT_PLAUSIBLE_EVENTS.OPENED_LINK_SEARCH, {
                    location: 'ContextMenu',
                  });
                  setIsLinkPopoverOpen(true);
                  removeWidget(id);
                }}
                title="Create a Link for this Card"
              >
                Create Link
              </MenuItem>
              <MenuItem
                icon={() => <Icon as={MdOutlineVisibility} fontSize="22px" mr={1} />}
                refId={refId}
                id="showLinks"
                open={currentOpen}
                disabled={getVisualLinks(currentItem?.links).length === 0}
                setOpen={setOpen}
                onClick={() => {
                  trackPlausibleEvent(CLIENT_PLAUSIBLE_EVENTS.DISPLAY_LINKS, {
                    location: 'ContextMenu',
                  });
                  removeWidget(id);
                  setActiveLinkCardId(refId);
                }}
                title="Show Links for this Card"
              >
                Show Links
              </MenuItem>
            </>
          ) : null}
          <Divider w="90%" alignSelf="center" my={2} />
        </>
      )}
      <MenuItem
        refId={refId}
        id="1"
        open={currentOpen}
        setOpen={setOpen}
        icon={FiXCircle}
        onClick={() => {
          removeWidget(id);
          if (refId) {
            removeElements(refId);
          }
          if (refIds) {
            removeElements(refIds);
          }
        }}
        hoverColor="red.400"
      >
        Delete
      </MenuItem>
      <MenuItem
        refId={refId}
        id="1"
        open={currentOpen}
        setOpen={setOpen}
        icon={FaRegCopy}
        onClick={() => {
          timesPastedRef.current = 1;
          if (refId) {
            setClipboard([refId]);
          }
          if (refIds) {
            setClipboard(refIds);
          }
        }}
      >
        Copy
      </MenuItem>
      <MenuItem
        refId={refId}
        id="1"
        open={currentOpen}
        setOpen={setOpen}
        icon={FaPaste}
        onClick={() => {
          timesPastedRef.current += 1;
          pasteElements(timesPastedRef.current);
        }}
        disabled={!canPaste}
      >
        Paste
      </MenuItem>
      {canArchive && (
        <MenuItem
          refId={refId}
          id="1"
          open={currentOpen}
          setOpen={setOpen}
          icon={BiArchiveIn}
          onClick={() => {
            removeWidget(id);
            archiveElement(refId ?? refIds);
          }}
        >
          Archive
        </MenuItem>
      )}
      {isCard && canAssign && (
        <MenuItem
          refId={refId}
          id="1"
          open={currentOpen}
          setOpen={setOpen}
          icon={FiUserPlus}
          onClick={() => {
            handleAssign(currentItem!);
            removeWidget(id);
          }}
        >
          Assign to me
        </MenuItem>
      )}
      {!refIds && refId && (
        <>
          <Divider w="90%" alignSelf="center" my={2} />
          <MenuItem
            refId={refId}
            id="1"
            open={currentOpen}
            setOpen={setOpen}
            icon={MdFlipToBack}
            onClick={sendToBack!}
            disabled={isInBack!}
          >
            Send to Back
          </MenuItem>
          <MenuItem
            refId={refId}
            id="1"
            open={currentOpen}
            setOpen={setOpen}
            icon={MdFlipToBack}
            onClick={sendBackward!}
            disabled={isInBack!}
          >
            Send Backwards
          </MenuItem>
          <MenuItem
            refId={refId}
            id="1"
            open={currentOpen}
            setOpen={setOpen}
            icon={MdFlipToFront}
            onClick={sendForward!}
            disabled={isOnTop!}
          >
            Bring Forward
          </MenuItem>
          <MenuItem
            refId={refId}
            id="1"
            open={currentOpen}
            setOpen={setOpen}
            icon={MdFlipToFront}
            onClick={sendToTop!}
            disabled={isOnTop!}
          >
            Bring to Front
          </MenuItem>
        </>
      )}
    </Flex>
  );
};

export default ContextMenu;
