/* eslint-disable no-fallthrough */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { useRef, memo, useEffect, useState, useMemo } from 'react';
import { Rect, Text, Group, Circle } from 'react-konva';
import { Text as IText } from 'konva/types/shapes/Text';
import { useStoreActions, useStoreState } from 'store/hooks';
import { useGlobalWidgetResize } from 'hooks/index';
import { BoardElementState, UIBoardElement } from 'types/index';
import { Rect as IRect } from 'konva/types/shapes/Rect';
import { useHighlightTargetColumn, useBoardElementProps } from 'hooks/Elements';
import { handleLeftDblClick, isCtrlOrMetaKey, isTouchEvent } from 'lib/helpers';
import { getCardColorByName, getImageColorPair, getIntegrationColor } from 'lib/colors';
import theme from 'lib/theme';
import { MOUSE_BUTTON } from 'lib/Types';
import { useImage } from 'hooks/useImage';
import { CardIntegrationType, ValidationRules } from 'shared';
import { useLoomThumbnail } from 'hooks/useLoom';
import { KonvaEventObject } from 'konva/types/Node';
import nonUpdatingStore from 'store/index';
import { useLiveIndicatorEmitting } from 'hooks/Sockets';
import { CardBackgroundImage } from '../CardImage';
import { getCornerRadius } from './Card.helpers';
import { BoardElementLinkLabel } from '../BoardElementLinks/BoardElementLinkLabel';
import { CardFooter } from './CardFooter';
import { getTaskLinks, getVisualLinks } from '../../../lib/Links.helper';
import { measureTextHeight } from '../../../konva/KonvaHelper';
import FocusBorder from '../FocusBorder/FocusBorder';

const DEFAULT_CARD_BOX_SHADOW = theme.shadows.layer1;
const FLOATING_CARD_BOX_SHADOW = theme.shadows.floating;
const CARD_TITLE_MIN_HEIGHT = 38.75; // equivalent of 1 line of text
const CARD_TITLE_MAX_HEIGHT = 100; // equivalent of 4 lines of text
const CARD_WIDTH = ValidationRules.LayoutRules.Card.CARD_MIN_WIDTH;
const CARD_HEIGHT = ValidationRules.LayoutRules.Card.CARD_MIN_HEIGHT;
const CARD_FONT_FAMILY = theme.fonts.boardElements;
const CARD_BORDER_RADIUS = parseInt(theme.radii.sm, 10);
const CARD_AVATAR_IMG_SIZE = 28;
const CARD_FONT_SIZE = parseInt(theme.fontSizes.md, 10); // default unit px removed
const CARD_LINE_HEIGHT = 1.171875; // 18.75px in em
const CARD_PADDING = 12;
const CARD_BLOCKER_HEIGHT = 90;
const CARD_BLOCKER_SPACING = 10;
const FOOTER_HEIGHT = CARD_AVATAR_IMG_SIZE + CARD_PADDING;

export const SHARED_STYLES = {
  width: CARD_WIDTH,
  height: CARD_HEIGHT,
  fontSize: CARD_FONT_SIZE,
  padding: CARD_PADDING,
  lineHeight: CARD_LINE_HEIGHT,
  footerHeight: FOOTER_HEIGHT,
  assignedAvatarSize: CARD_AVATAR_IMG_SIZE,
  borderRadius: CARD_BORDER_RADIUS,
};

export const SHOW_DETAILS_NAME = 'SHOW_DETAILS';

interface CardSummary {
  commentCount?: number;
  attachmentCount?: number;
  isWatching?: boolean;
}

function usePrevious(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

const Card: React.FC<UIBoardElement & CardSummary> = ({
  id,
  color,
  title = '',
  assignedAvatars,
  shape: { x = 0, y = 0, width = SHARED_STYLES.width, height },
  state,
  blockers,
  commentCount,
  attachmentCount,
  watchers,
  cardIntegrationType,
  cardIntegrationMeta,
  links,
  thumbnailUrl,
}) => {
  const cardRef = useRef<IRect>(null);
  const titleTextRef = useRef<IText | null>(null);
  const [textArray, setTextArray] = useState<IText['textArr']>([]);
  const [titleHeight, setTitleHeight] = useState<number | null>(null);
  const { resetElementsState, setElementState, setCardHeight } = useStoreActions(
    (actions) => actions.board
  );

  const user = useStoreState((store) => store.user.user);
  const [stripes] = useImage('/stripes.png', '');

  useGlobalWidgetResize(id, titleTextRef);
  const loomThumbnailUrl = useLoomThumbnail(cardIntegrationType, cardIntegrationMeta?.videoUrl);
  const activeLinkCard = useStoreState((store) =>
    store.board.boardElements.find((el) => el.id === store.app.activeLinkCardId)
  );
  const activeLinkCardLink = activeLinkCard?.links?.find((link) => link.targetElement.id === id);

  const {
    position,
    draggable,
    handleClick,
    handleDragEnd,
    handleDragMove,
    handleDragStart,
    handleMouseDown,
    handleDoubleClickOrTap,
    handleTouchStart,
    elementRef,
  } = useBoardElementProps({
    state,
    elementId: id,
    initialPosition: { x, y },
    canBeResized: false,
    // integrated cards have fixed colors
    canChangeColor: !cardIntegrationType,
    // but should still be able to add blockers
    canAddBlockers: !!cardIntegrationType,
  });
  const { getColumns, resetColumns, highlightTargetColumn } = useHighlightTargetColumn();
  const unreadNotificationsForCard = useStoreState(
    (store) =>
      store.notifications.boardNotifications.filter(
        (notification) => notification.element?._id === id && !notification.isRead
      ),
    (prev, next) => prev.length === next.length
  );
  const focusAndHandleClick = async (
    e: KonvaEventObject<MouseEvent> | KonvaEventObject<TouchEvent>
  ) => {
    handleClick(e);
    if (!isCtrlOrMetaKey(e)) {
      // interacting with a card should mark all notifications as read
      if (unreadNotificationsForCard.length > 0) {
        await nonUpdatingStore.getActions().notifications.markAllNotificationsForCardAsRead(id);
      }
      if (
        e.target.attrs.name === SHOW_DETAILS_NAME &&
        (isTouchEvent(e) || e.evt.button === MOUSE_BUTTON.LEFT)
      ) {
        setElementState({ id, state: BoardElementState.INDETAIL });
      }
    }
  };
  const activeBlockers = blockers?.filter((blocker: any) => !blocker.resolvedAt);
  const blockerHeight =
    (activeBlockers?.length ?? 0) * (CARD_BLOCKER_HEIGHT + CARD_BLOCKER_SPACING) || 0;
  const blockerTextColor =
    !!color && color !== 'White' && color !== '#FFFFFF'
      ? theme.colors.yoWhite[500]
      : theme.colors.yoGray[900];

  const { liveIndicatorStartEdit, liveIndicatorEndEdit } = useLiveIndicatorEmitting();
  const prevState = usePrevious(state);
  useEffect(() => {
    if (!user) return;

    if (state === BoardElementState.INEDIT && prevState !== BoardElementState.INEDIT) {
      liveIndicatorStartEdit({ userId: user._id, isEditing: true });
    } else if (state !== BoardElementState.INEDIT && prevState === BoardElementState.INEDIT) {
      liveIndicatorEndEdit({ userId: user._id, isEditing: false });
    }
  }, [state]);

  const titleTextRenderingOptions = {
    wrap: 'word',
    fontSize: SHARED_STYLES.fontSize,
    lineHeight: SHARED_STYLES.lineHeight,
    fontFamily: CARD_FONT_FAMILY,
    width: SHARED_STYLES.width,
    padding: CARD_PADDING - 2,
    ellipsis: true,
  };

  // handle height of the Card, considering title text and blocker height
  useEffect(() => {
    if (!elementRef.current) {
      throw new Error('Card() useEffect handling height: elementRef.current is undefined');
    }
    if (!titleTextRef.current) {
      throw new Error('Card() useEffect handling height: titleTextRef.current is undefined');
    }

    // calculate the title height required to render the whole title text in the canvas
    const measuredHeight =
      measureTextHeight(elementRef.current.getLayer(), title, titleTextRenderingOptions) || 0;

    // limit the title height to min and max
    const limitedHeight = Math.min(
      CARD_TITLE_MAX_HEIGHT,
      Math.max(CARD_TITLE_MIN_HEIGHT, measuredHeight)
    );

    // update title konva element height
    if (titleTextRef.current.height() !== limitedHeight) {
      titleTextRef.current.height(limitedHeight);
    }

    // update title height of this react component
    if (titleHeight !== limitedHeight) {
      setTitleHeight(limitedHeight);
    }

    // update card element height
    const newHeight = Math.max(CARD_HEIGHT, limitedHeight + blockerHeight + FOOTER_HEIGHT);
    if (newHeight !== height) {
      setCardHeight({
        id,
        state,
        height: newHeight,
      });
    }
  }, [elementRef, titleTextRef, title, activeBlockers, blockerHeight, id, state]);

  const [image] = useImage(thumbnailUrl ?? loomThumbnailUrl ?? undefined);

  const hasImage = cardIntegrationType === CardIntegrationType.LOOM || thumbnailUrl != null;

  const colorPair = thumbnailUrl
    ? getImageColorPair(color)
    : getIntegrationColor(cardIntegrationType);

  useEffect(() => {
    if (title.length > 0 && titleTextRef.current) setTextArray(titleTextRef.current.textArr);
  }, [title]);

  const baseCardProps = useMemo(() => {
    const cardBoxShadow =
      state === BoardElementState.FLOATING ? FLOATING_CARD_BOX_SHADOW : DEFAULT_CARD_BOX_SHADOW;
    return {
      ref: cardRef,
      id,
      key: id,
      fill: getCardColorByName(color).value,
      shadowColor: cardBoxShadow.color,
      shadowBlur: cardBoxShadow.blurRadius,
      shadowOffsetX: cardBoxShadow.offsetX,
      shadowOffsetY: cardBoxShadow.offsetY,
      width,
      height,
      cornerRadius: activeLinkCardLink
        ? [0, CARD_BORDER_RADIUS, CARD_BORDER_RADIUS, CARD_BORDER_RADIUS]
        : CARD_BORDER_RADIUS,
      onDblClick: (evt: KonvaEventObject<MouseEvent>) =>
        handleLeftDblClick(evt, handleDoubleClickOrTap),
      onDblTap: (evt: KonvaEventObject<TouchEvent>) =>
        handleLeftDblClick(evt, handleDoubleClickOrTap),
      transformsEnabled: 'position',
      isBoardElement: true,
      getGroup: () => elementRef.current,
      getId: () => id,
    };
  }, [activeLinkCardLink, color, elementRef, handleDoubleClickOrTap, id, state, width, height]);

  return (
    <>
      <Group
        listening
        ref={elementRef as any}
        {...position}
        draggable={draggable}
        onTouchStart={handleTouchStart}
        onDragStart={(e) => {
          (resetElementsState as any)();
          handleDragStart();
          getColumns(e);
        }}
        onMouseDown={handleMouseDown}
        onDragEnd={() => {
          resetColumns();
          handleDragEnd();
        }}
        onDragMove={(e) => {
          handleDragMove(e);
          highlightTargetColumn(e);
        }}
        onTap={focusAndHandleClick}
        onClick={focusAndHandleClick}
      >
        {activeLinkCardLink && <BoardElementLinkLabel type={activeLinkCardLink.type} />}
        {state !== BoardElementState.DEFAULT && state !== BoardElementState.FLOATING && (
          <FocusBorder
            state={state}
            width={width}
            height={height}
            cornerRadius={CARD_BORDER_RADIUS}
          />
        )}
        {hasImage && state !== BoardElementState.INEDIT ? (
          <CardBackgroundImage image={image} {...baseCardProps} />
        ) : (
          <Rect {...baseCardProps} />
        )}
        {unreadNotificationsForCard.length > 0 && (
          <Group x={width} width={20} height={20} listening={false}>
            <Circle radius={10} fill="#257BFF" />
            <Text
              x={-10}
              y={-9}
              text={unreadNotificationsForCard.length.toString()}
              fill="white"
              fontFamily={theme.fonts.boardElements}
              fontVariant="bold"
              fontSize={14}
              width={20}
              height={20}
              align="center"
              verticalAlign="middle"
            />
          </Group>
        )}

        {hasImage &&
          titleTextRef.current &&
          textArray.map((textPart, i) => (
            <Rect
              key={textPart.text}
              fill={colorPair.background}
              width={Math.min(textPart.width + CARD_PADDING - 2, SHARED_STYLES.width)}
              height={
                (titleTextRef.current?.textHeight ?? 0) +
                CARD_PADDING -
                (textArray.length > 1 ? 6 : 4)
              }
              y={
                CARD_PADDING / 2 +
                i * ((titleTextRef.current?.textHeight ?? 0) + CARD_PADDING / 3) -
                (i > 0 ? 1 : 0)
              }
              x={CARD_PADDING / 2}
              cornerRadius={getCornerRadius(textArray)[i]}
              listening={false}
            />
          ))}
        <Text
          ref={titleTextRef}
          text={title}
          wrap={titleTextRenderingOptions.wrap}
          ellipsis
          fill={colorPair.foreground}
          fontFamily={titleTextRenderingOptions.fontFamily}
          width={titleTextRenderingOptions.width}
          fontSize={titleTextRenderingOptions.fontSize}
          lineHeight={titleTextRenderingOptions.lineHeight}
          y={0}
          x={0}
          padding={titleTextRenderingOptions.padding}
          onDblClick={(evt) => handleLeftDblClick(evt, handleDoubleClickOrTap)}
          onDblTap={(evt) =>
            handleLeftDblClick(evt as KonvaEventObject<TouchEvent>, handleDoubleClickOrTap)
          }
        />

        {titleTextRef.current &&
          activeBlockers?.map((blocker: any, index: number) => (
            <Group
              x={CARD_PADDING}
              y={titleHeight! + index * (CARD_BLOCKER_HEIGHT + CARD_BLOCKER_SPACING)}
              key={blocker._id}
              listening={false}
            >
              {cardIntegrationType && (
                <Rect
                  fill={colorPair.background}
                  cornerRadius={3}
                  width={SHARED_STYLES.width - CARD_PADDING * 2}
                  height={CARD_BLOCKER_HEIGHT}
                  x={0}
                  y={0}
                />
              )}
              <Rect
                fillPatternImage={stripes}
                cornerRadius={3}
                width={SHARED_STYLES.width - CARD_PADDING * 2}
                height={CARD_BLOCKER_HEIGHT}
                x={0}
                y={0}
              />
              <Text
                text={blocker.description}
                fontFamily={CARD_FONT_FAMILY}
                fontSize={SHARED_STYLES.fontSize}
                lineHeight={1.3}
                fontStyle="bold"
                padding={CARD_PADDING}
                fill={cardIntegrationType ? colorPair.foreground : blockerTextColor}
                height={SHARED_STYLES.fontSize * 1.3 * 2 + CARD_PADDING * 2 + 2}
                wrap="word"
                ellipsis
                width={CARD_WIDTH - 2 * CARD_PADDING}
              />
              <Text
                text={`Blocked since ${new Date(blocker.createdAt).toLocaleDateString()}`}
                fontFamily={theme.fonts.body}
                width={SHARED_STYLES.width}
                fontSize={SHARED_STYLES.fontSize}
                lineHeight={SHARED_STYLES.lineHeight}
                padding={CARD_PADDING}
                fontStyle="italic"
                fill={cardIntegrationType ? colorPair.foreground : blockerTextColor}
                y={CARD_BLOCKER_HEIGHT - 30 - CARD_PADDING + 5}
              />
            </Group>
          ))}
        <CardFooter
          state={state}
          assignedAvatars={assignedAvatars ?? []}
          attachmentCount={attachmentCount ?? 0}
          cardHeight={height}
          commentCount={commentCount ?? 0}
          integrationColorPair={colorPair}
          isWatching={!!watchers?.some((watcher: string) => watcher.toString() === user?._id)}
          linkCount={getVisualLinks(links).length}
          onDblClick={handleDoubleClickOrTap}
          hasImage={hasImage}
          checkLinks={getTaskLinks(links)}
        />
      </Group>
    </>
  );
};

export default memo(Card);
