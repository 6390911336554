import React from 'react';
import { Box, useTheme } from '@chakra-ui/react';

interface ColorDotProps {
  color: string;
}

const ColorDot: React.FC<ColorDotProps> = ({ color }) => {
  const theme = useTheme();
  return <Box bg={color || theme.colors.white} borderRadius="50%" h={5} w={5} mr={1} />;
};
export default ColorDot;
