/* eslint-disable no-param-reassign */
import { Thunk, Action, thunk, action } from 'easy-peasy';
import Router from 'next/router';
import { MarketingSettings, User as BackendUser } from 'shared';
import { previewImageService } from 'lib/BoardImageService';
import fetchClient from '../lib/Fetch';
import { PublicStoreModel } from '.';

export type Board = {
  _id: string;
  name: string;
  createdBy: BackendUser;
  users: BackendUser[];
};

export interface UIUser extends BackendUser {
  isSocialUser: boolean;
  boards: Array<{
    _id: string;
    name: string;
    createdBy: BackendUser;
    users: BackendUser[];
  }>;
}

export interface UserStoreModel {
  user: UIUser | undefined;
  marketingSettings: MarketingSettings | undefined;
  getUser: Thunk<UserStoreModel, void, null, PublicStoreModel>;
  updateMarketingSettings: Thunk<UserStoreModel, MarketingSettings, null, PublicStoreModel>;
  getMarketingSettings: Thunk<UserStoreModel, void, null, PublicStoreModel>;
  setUser: Action<UserStoreModel, UIUser>;
  setMarketingSettings: Action<UserStoreModel, MarketingSettings>;
  setCurrentBoard: Action<UserStoreModel, string>;
  addBoard: Action<UserStoreModel, Board>;
  removeBoard: Action<UserStoreModel, string>;
}

const userStoreModel: UserStoreModel = {
  user: undefined,
  marketingSettings: undefined,
  setUser: action((state, payload) => {
    state.user = payload;
  }),
  setMarketingSettings: action((state, payload) => {
    state.marketingSettings = payload;
  }),
  getUser: thunk(async (actions, _, { getStoreActions }) => {
    const user = await fetchClient<UIUser>(
      'user',
      null,
      getStoreActions().errors.addError,
      Router?.query?.boardId as string
    );
    actions.setUser(user);
  }),
  updateMarketingSettings: thunk(async (actions, payload, { getStoreActions }) => {
    await fetchClient<UIUser>(
      'user/marketingSettings',
      {
        method: 'PATCH',
        body: {
          isDialogHandled: true,
          hasAcceptedMarketingEmails: payload.hasAcceptedMarketingEmails,
        },
      },
      getStoreActions().errors.addError
    );
  }),
  getMarketingSettings: thunk(async (actions, _, { getStoreActions }) => {
    const marketingSettings = await fetchClient<MarketingSettings>(
      'user/marketingSettings',
      null,
      getStoreActions().errors.addError
    );
    actions.setMarketingSettings(marketingSettings);
  }),
  setCurrentBoard: action((state, boardId) => {
    state.user!.lastBoardId = boardId;
  }),
  addBoard: action((state, board) => {
    if (!state.user) return;
    state.user.boards.unshift(board);
  }),
  removeBoard: action((state, boardId) => {
    if (!state.user) return;
    previewImageService.removeBoardFromQueue(boardId);
    state.user.boards = state.user.boards.filter((board) => board._id !== boardId);
  }),
};

export default userStoreModel;
