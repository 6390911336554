import { EventTypes, BoardElementDTO, CardDetailDTO } from 'shared';

//------------------------------------
// Events which are used locally in the client
// which are also sent to the server
export type ClientElementEvent = ClientSingleElementEvent | ClientBulkElementEvent;
export type ClientSingleElementEvent = Omit<SingleServerElementEvent, MissingProps>;
export type ClientBulkElementEvent = Omit<
  { events: ClientSingleElementEvent[] } & Omit<BulkServerElementEvent, 'events'>,
  MissingProps
>;
type MissingProps = 'boardId' | 'createdAt' | 'createdBy' | '_id';

//------------------------------------

// Incoming events from the server
export type ServerElementEvent = BulkServerElementEvent | SingleServerElementEvent;

export interface BulkServerElementEvent extends BaseServerElementEvent {
  type: EventTypes.BULK;

  /** The single events wrapped together inside a bulk event. */
  events: SingleServerElementEvent[];
}

export interface SingleServerElementEvent extends BaseServerElementEvent {
  type: Exclude<EventTypes, EventTypes.BULK>;

  elementId: string;
  // Old Values CAN be null. This occurs when the element is newly created.
  oldValues: (BoardElementDTO & Partial<Pick<CardDetailDTO, 'content' | 'metrics'>>) | null;
  newValues: BoardElementDTO & Partial<Pick<CardDetailDTO, 'content' | 'metrics'>>;
}

interface BaseServerElementEvent {
  /** The type of event (add, update, remove, bulk) */
  type: EventTypes;

  /** The boardId where the Event has been triggered. */
  boardId: string;

  /** The timestamp when the Event has been parsed on server-side. */
  createdAt: Date;

  /** User id */
  createdBy: string;

  /** The version of the client software */
  softwareVersion: string;

  /** MongodDB id */
  _id: string;
}

export function isServerElementEvent(whatever: any): whatever is ServerElementEvent {
  return Boolean(
    whatever &&
      typeof whatever === 'object' &&
      whatever.softwareVersion &&
      whatever.type in EventTypes &&
      whatever.createdBy &&
      whatever._id
  );
}
