/* eslint-disable no-param-reassign */
import { Thunk, Action, thunk, action } from 'easy-peasy';
import { ServiceAccount } from 'shared';
import fetchClient from '../lib/Fetch';
import { PublicStoreModel } from '.';

export interface ServiceAccountStoreModel {
  serviceAccounts: Array<ServiceAccount>;
  getServiceAccounts: Thunk<ServiceAccountStoreModel, null, null, PublicStoreModel>;
  setServiceAccountsAction: Action<ServiceAccountStoreModel, Array<ServiceAccount>>;
}

const serviceAccountStoreModel: ServiceAccountStoreModel = {
  serviceAccounts: [],
  getServiceAccounts: thunk(async (actions, _, { getStoreActions }) => {
    const serviceAccounts = await fetchClient<ServiceAccount[]>(
      'service-accounts',
      null,
      getStoreActions().errors.addError
    );
    actions.setServiceAccountsAction(serviceAccounts);
  }),
  setServiceAccountsAction: action((state, payload) => {
    state.serviceAccounts = payload;
  }),
};

export default serviceAccountStoreModel;
