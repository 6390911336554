import type { Text } from 'konva/types/shapes/Text';

const TEXT_BACKGROUND_CORNER_RADIUS = 2;
type CorderRadius = {
  topLeft: number;
  topRight: number;
  bottomRight: number;
  bottomLeft: number;
};

export function getCornerRadius(lines: Text['textArr']) {
  return lines.map((currentLine, i) => {
    const currentRadius: CorderRadius = {
      topLeft: 0,
      topRight: 0,
      bottomRight: 0,
      bottomLeft: 0,
    };
    if (i === 0) {
      currentRadius.topLeft = TEXT_BACKGROUND_CORNER_RADIUS;
      currentRadius.topRight = TEXT_BACKGROUND_CORNER_RADIUS;
    } else if (lines[i - 1].width < currentLine.width) {
      currentRadius.topRight = TEXT_BACKGROUND_CORNER_RADIUS;
    }

    if (i === lines.length - 1) {
      currentRadius.bottomLeft = TEXT_BACKGROUND_CORNER_RADIUS;
      currentRadius.bottomRight = TEXT_BACKGROUND_CORNER_RADIUS;
    } else if (lines[i + 1].width < currentLine.width) {
      currentRadius.bottomRight = TEXT_BACKGROUND_CORNER_RADIUS;
    }

    return [
      currentRadius.topLeft,
      currentRadius.topRight,
      currentRadius.bottomRight,
      currentRadius.bottomLeft,
    ];
  });
}
