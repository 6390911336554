import { UIBoardColumn, UIBoardElement } from 'types/index';
import { Position } from 'store/app';

import { Container } from 'konva/types/Container';
import { Node } from 'konva/types/Node';
import { BoardElementType } from 'shared';
import nonUpdatingStore from 'store/index';
import { getNextSnappingPosition } from 'lib/helpers';
import { Stage } from 'konva/types/Stage';
import { Group } from 'konva/types/Group';
import { Rect } from 'konva/types/shapes/Rect';
import { DragContext, DragHandler, DragModel, DragMoveArgs } from './DragModel';
import { SNAP_TO_GRID_VALUE } from './DragConfig';
import { getBoardElementGroup, getKonvaElement, getStageUnsafe } from '../konva/KonvaHelper';
import { isRectInColumn } from '../konva/KonvaBoardElementHelper';

interface DragGroupState {
  stage: Stage;
  sourcePostion: Position;
  children: {
    element: UIBoardElement;
    group: Group;
  }[];
  sharedParent: Container<Node> | null | undefined;
}

export class DragColumnHandler implements DragHandler {
  private dragState: DragModel | undefined;

  private dragGroupState: DragGroupState | undefined;

  constructor(private readonly context: DragContext) {}

  start(dragState: DragModel): void {
    this.dragState = dragState;

    // create `DragGroupState`
    const allBoardElements = nonUpdatingStore.getState().board.boardElements;
    const column = this.dragState.element as UIBoardColumn;

    const childElements = allBoardElements.filter(
      (e) =>
        // only cards
        e.type === BoardElementType.CARD &&
        // that are in the same column
        e.parentColumnId === column.id &&
        isRectInColumn(e.shape, column)
    );

    const children: {
      element: UIBoardElement;
      group: Group;
    }[] = [];

    let sharedParent: Container<Node> | null | undefined;
    childElements.forEach((e) => {
      const element = getKonvaElement<Rect>(e.id);
      const group = getBoardElementGroup(element);
      if (group) {
        children.push({ element: e, group });
        if (!sharedParent) {
          sharedParent = group.parent;
        }
      }
    });

    this.dragGroupState = {
      stage: getStageUnsafe(),
      sourcePostion: { ...column.shape },
      children,
      sharedParent,
    };
  }

  move({ position }: DragMoveArgs) {
    if (!this.dragState || !this.dragGroupState) {
      return;
    }

    // patch new position into our local state (so that we can order all elements correctly)
    this.dragState.element.shape = { ...this.dragState.element.shape, ...position };

    // dragging a column with children
    const offset = {
      x: this.dragState.element.shape.x - this.dragGroupState.sourcePostion.x,
      y: this.dragState.element.shape.y - this.dragGroupState.sourcePostion.y,
    };

    this.dragGroupState.children.forEach((child) => {
      if (!this.dragState?.element.id) {
        throw new Error(`${DragColumnHandler.name}.move() invalid dragState`);
      }
      // if the layer of the child is different from the layer of the column,
      // we should ignore this action because it means that the column is the floating element
      if (child.group.getLayer() === getKonvaElement(this.dragState.element.id).getLayer()) {
        child.group.position({
          x: child.element.shape.x + offset.x,
          y: child.element.shape.y + offset.y,
        });
      }
    });
    this.dragGroupState.sharedParent?.draw();
  }

  stop() {
    if (!this.dragState || !this.dragGroupState) {
      return;
    }

    const snapOffset = { x: 0, y: 0 };

    if (this.dragState.snapToGrid) {
      const snapPosition = getNextSnappingPosition({
        position: this.dragState.element.shape,
        snappingValue: SNAP_TO_GRID_VALUE,
      });
      snapOffset.x = snapPosition.x - this.dragState.element.shape.x;
      snapOffset.y = snapPosition.y - this.dragState.element.shape.y;

      this.dragState.element.shape = {
        ...this.dragState.element.shape,
        ...snapPosition,
      };
      getBoardElementGroup(getKonvaElement(this.dragState.element.id))
        ?.position(this.dragState.element.shape)
        .draw();
    }

    // update group and its children
    this.context.updateElement([
      {
        id: this.dragState.element.id,
        shape: this.dragState.element.shape,
      },
      ...this.dragGroupState.children.map((child) => {
        const childPosition = child.group.position();
        return {
          id: child.element.id,
          shape: { x: childPosition.x + snapOffset.x, y: childPosition.y + snapOffset.y },
        };
      }),
    ]);

    this.dragGroupState = undefined;
  }
}
