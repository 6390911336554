/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { memo, useRef } from 'react';
import { Rect, Group } from 'react-konva';
import { BoardElementState, UIBoardElement } from 'types/index';
import StatefulTransformer from 'components/canvas/Transformer';
import { useBoardElementProps, useCountElementsAttached, useTransformer } from 'hooks/Elements';
import theme from 'lib/theme';
import { BoardElementType, ValidationRules } from 'shared';
import { getSwimlaneColorByName } from 'lib/colors';
import { useGlobalWidgetResize } from 'hooks/Events';
import Konva from 'konva';
import { useMergeRefs } from '@chakra-ui/react';
import Header from '../Column/Header';
import FocusBorder from '../FocusBorder/FocusBorder';
import HitZone from './HitZone';

export const SWIMLANE_WIDTH = 800;
export const SWIMLANE_HEIGHT = 350;

const HEADER_WIDTH = 280;
const HEADER_HEIGHT = 31;

const FLOATING_SWIMLANE_BOX_SHADOW = theme.shadows.floating;

const SWIMLANE_BACKGROUND_COLOR = theme.colors.yoGray[200];
const SWIMLANE_HIGHLIGHT_BACKGROUND_COLOR = theme.colors.yoGray[100];
const SWIMLANE_BORDER_COLOR = theme.colors.yoBrand[600];
const SWIMLANE_BORDER_RADIUS = parseInt(theme.radii.modal, 10);

const WIP_LIMIT_EXCEEDED_CHIP_BACKGROUND = theme.colors.yoRed[300];

const WIP_LIMIT_EXCEEDED_COLOR = theme.colors.yoRed[400];

export const SHARED_STYLES = {
  minWidthAndHeight: ValidationRules.LayoutRules.Swimlane.SWIMLANE_MIN_SIZE,
  borderWidth: 2,
  borderColor: SWIMLANE_BORDER_COLOR,
  backgroundColor: SWIMLANE_BACKGROUND_COLOR,
  highlightBackgroundColor: SWIMLANE_HIGHLIGHT_BACKGROUND_COLOR,
  width: SWIMLANE_WIDTH,
  height: SWIMLANE_HEIGHT,
  text: {
    fontSize: parseInt(theme.fontSizes.md, 10), // remove unit px from default fontSize
    height: 50,
    padding: 20,
  },
};

const Swimlane: React.FC<UIBoardElement> = ({
  id,
  emoji,
  title = '',
  shape: { x = 0, y = 0, height = SWIMLANE_HEIGHT, width = SWIMLANE_WIDTH },
  state,
  wipLimit = 0,
  color,
  isLocked,
}: any) => {
  const { elementsAttachedCount } = useCountElementsAttached(
    { x, y, height, width },
    {
      excludeBoardElementsTypes: [
        BoardElementType.COLUMN,
        BoardElementType.STICKY,
        BoardElementType.SWIMLANE,
      ],
    }
  );
  const { rectRef, rectProps, transformerRef, localWidthAndHeight } = useTransformer({
    width,
    height,
    state,
    id,
    isLocked,
  });

  const groupRef = useRef<Konva.Group>(null);

  const currentColor = getSwimlaneColorByName(color);

  const wipLimitExceeded = wipLimit > 0 && wipLimit < elementsAttachedCount;

  const {
    position,
    draggable,
    handleClick,
    handleDragEnd,
    handleDragMove,
    handleDragStart,
    handleMouseDown,
    handleDoubleClickOrTap,
    handleTouchStart,
    elementRef,
  } = useBoardElementProps({ state, elementId: id, initialPosition: { x, y }, isLocked });

  useGlobalWidgetResize(id, groupRef);

  const floatingSwimlaneShadowProps = {
    shadowColor: FLOATING_SWIMLANE_BOX_SHADOW.color,
    shadowBlur: FLOATING_SWIMLANE_BOX_SHADOW.blurRadius,
    shadowOffsetX: FLOATING_SWIMLANE_BOX_SHADOW.offsetX,
    shadowOffsetY: FLOATING_SWIMLANE_BOX_SHADOW.offsetY,
  };

  return (
    <Group
      // TODO: handleSwimlane
      // name="column"
      ref={useMergeRefs(groupRef, elementRef)}
      // we need this wipLimitExceeded prop for useHighlightTargetSwimlane hook
      wipLimitExceeded={wipLimitExceeded}
      draggable={draggable && !isLocked}
      onDragEnd={handleDragEnd}
      onDragMove={handleDragMove}
      onDragStart={handleDragStart}
      onDblClick={handleDoubleClickOrTap}
      onDblTap={handleDoubleClickOrTap}
      onMouseDown={handleMouseDown}
      onClick={handleClick}
      onTap={handleClick}
      onTouchStart={handleTouchStart}
      {...position}
    >
      <FocusBorder
        state={state}
        width={localWidthAndHeight.width}
        height={localWidthAndHeight.height}
        cornerRadius={SWIMLANE_BORDER_RADIUS}
        strokeWidth={5}
      />
      <HitZone id={id} width={width} height={height} />
      <Rect
        id={id}
        ref={rectRef}
        key={id}
        height={height}
        width={width}
        stroke={wipLimitExceeded ? WIP_LIMIT_EXCEEDED_COLOR : currentColor?.value}
        strokeWidth={SHARED_STYLES.borderWidth}
        cornerRadius={SWIMLANE_BORDER_RADIUS}
        listening={false}
        getGroup={() => groupRef.current}
        getId={() => id}
        {...(state === BoardElementState.FLOATING ? floatingSwimlaneShadowProps : {})}
        {...rectProps}
      />
      <Rect
        height={HEADER_HEIGHT}
        width={HEADER_WIDTH}
        fill={wipLimitExceeded ? WIP_LIMIT_EXCEEDED_COLOR : currentColor?.value}
        cornerRadius={[SWIMLANE_BORDER_RADIUS, 0, SWIMLANE_BORDER_RADIUS, 0]}
        {...rectProps}
        {...(state === BoardElementState.FLOATING ? floatingSwimlaneShadowProps : {})}
        listening
        id={id}
        getGroup={() => groupRef.current}
        getId={() => id}
        name={isLocked ? 'locked' : undefined}
        isBoardElement
      />
      <Header
        x={6}
        y={5}
        emoji={emoji}
        title={title}
        wipLimit={wipLimit}
        elementsAttachedCount={elementsAttachedCount}
        wipLimitExceeded={wipLimitExceeded}
        state={state}
        width={HEADER_WIDTH}
        chipBackground={wipLimitExceeded ? WIP_LIMIT_EXCEEDED_CHIP_BACKGROUND : currentColor?.light}
        titleColor={wipLimitExceeded ? 'white' : currentColor?.contrast}
        chipSmall
        isLocked={isLocked}
      />
      {state === BoardElementState.RESIZABLE && (
        <StatefulTransformer
          state={state}
          transformerRef={transformerRef}
          minValue={SHARED_STYLES.minWidthAndHeight}
        />
      )}
    </Group>
  );
};

export default memo(Swimlane);
