import { Box, Flex, Heading, Progress, Text, Tooltip } from '@chakra-ui/react';
import { useMemo } from 'react';
import { MdInfoOutline } from 'react-icons/md';
import { CheckLink } from 'shared';

interface Props {
  checkedLinks: CheckLink[];
}

export function CheckProgress({ checkedLinks }: Props) {
  const tasks = useMemo(() => {
    const checked = checkedLinks.filter((link) => link.data === true).length;
    const taskCount = checkedLinks.length;
    return {
      taskCount,
      taskChecked: checked,
      taskPercentage: checked / taskCount,
    };
  }, [checkedLinks]);

  if (tasks.taskCount === 0) {
    return null;
  }

  return (
    <>
      <Flex alignItems="center" justifyContent="space-between">
        <Tooltip
          label={`${tasks.taskChecked} / ${tasks.taskCount} tasks completed`}
          placement="top"
          hasArrow
        >
          <Heading size="sm" mb={2} mt={4}>
            Tasks{' '}
            <Text as="span" fontSize={12} color="yoGray.800">
              ({(tasks.taskPercentage * 100).toFixed(0)}% completed)
            </Text>
          </Heading>
        </Tooltip>
        <Tooltip label="If you add a task item in the description of a card it will be displayed as a task here.">
          <Box as="span" cursor="help">
            <MdInfoOutline />
          </Box>
        </Tooltip>
      </Flex>
      <Progress
        mb={4}
        size="sm"
        rounded="sm"
        value={tasks.taskPercentage * 100}
        colorScheme="taggleOceanBlue"
      />
    </>
  );
}
