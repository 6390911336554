import { Shape } from 'react-konva';

/**
 * Creates an invisible hitzone for click detection
 */
const HitZone: React.FC<{ id: string; width: number; height: number }> = ({
  id,
  width,
  height,
}) => (
  <Shape
    id={id}
    isBoardElement
    stroke="transparent"
    strokeWidth={15}
    sceneFunc={(context, shape) => {
      context.beginPath();
      context.moveTo(0, 0);
      context.lineTo(width, 0);
      context.lineTo(width, height);
      context.lineTo(0, height);
      context.lineTo(0, 0);
      context.strokeShape(shape);
    }}
  />
);

export default HitZone;
