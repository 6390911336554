import { useRef, useEffect, RefObject } from 'react';
import { KonvaEventObject } from 'konva/types/Node';
import Konva from 'konva';
import { Stage } from 'konva/types/Stage';
import { normalizeWheelEvent, zoomStage } from 'lib/zoom';
import nonUpdatingStore from 'store/index';
import { Position } from 'store/app';
import throttle from 'lodash.throttle';
import { useStoreActions, useStoreState } from '../store/hooks';

Konva.hitOnDragEnabled = true;

const throttledSetOffset = throttle(
  ({ x, y }: { x: number; y: number }) => {
    nonUpdatingStore.getActions().boardSettings.setOffset({
      x,
      y,
    });
  },
  200,
  {
    leading: true,
  }
);

function getDistance(p1: Position, p2: Position) {
  // eslint-disable-next-line no-restricted-properties
  return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
}

function getCenter(p1: Position, p2: Position) {
  return {
    x: (p1.x + p2.x) / 2,
    y: (p1.y + p2.y) / 2,
  };
}

export const MIN_SCALE = 0.25;
export const MAX_SCALE = 4;

const STAGE_REPOSITION = 'stageReposition';

const WHEEL_SCALE_SPEEDUP = 2.5;

function useCanvasListeners() {
  const stageRef = useRef<Stage>(null);
  const cmdKeyPressedRef = useRef<boolean>(false);

  useEffect(() => {
    if (stageRef.current) {
      const stage = stageRef.current.getStage();

      window.addEventListener('keydown', (e) => {
        if (e.metaKey) {
          cmdKeyPressedRef.current = true;
        }
      });

      window.addEventListener('keyup', (e) => {
        if (e.metaKey) {
          cmdKeyPressedRef.current = false;
        }
      });

      stage.on('wheel', (e: KonvaEventObject<WheelEvent>) => {
        e.evt.preventDefault();
        // (pinch) zoom logic found here https://danburzo.ro/dom-gestures/

        // browsers will add e.metaKey to the event object
        // if it's a pinch-zoom. Therefore we need to keep track
        // if the metaKey was pressed otherwise
        const isRealMetaKey = e.evt.metaKey && cmdKeyPressedRef.current;
        const isPinchZoom = e.evt.ctrlKey || isRealMetaKey;

        const [dx, dy] = normalizeWheelEvent(e.evt);

        if (isPinchZoom) {
          // pinch-zoom
          const factor =
            dy <= 0
              ? 1 - (WHEEL_SCALE_SPEEDUP * dy) / 100
              : 1 / (1 + (WHEEL_SCALE_SPEEDUP * dy) / 100);
          zoomStage({
            zoomIn: e.evt.deltaY < 0,
            zoomTo: stage.getPointerPosition() as any,
            stage,
            setZoomLevelAndOffset:
              nonUpdatingStore.getActions().boardSettings.setZoomLevelAndOffset,
            multiplyScaleBy: factor,
          });
        } else {
          const newOffset = {
            // dx seems a bit over-tuned if we are panning
            x: stage.position().x - WHEEL_SCALE_SPEEDUP * (dx * 0.75),
            y: stage.position().y - WHEEL_SCALE_SPEEDUP * dy,
          };

          stage.position(newOffset);
          window.dispatchEvent(new Event(STAGE_REPOSITION));
          stage.batchDraw();
          throttledSetOffset(newOffset);
        }
      });

      let lastCenter: Position | null = null;
      let lastDist = 0;

      const throttledPosUpdate = throttle(
        () => {
          nonUpdatingStore.getActions().boardSettings.setZoomLevelAndOffset({
            offset: stage.position(),
            zoomLevel: stage.scaleX(),
          });
        },
        100,
        {
          leading: true,
        }
      );

      stage.on('touchmove', (e) => {
        e.evt.preventDefault();
        const touch1 = e.evt.touches[0];
        const touch2 = e.evt.touches[1];

        if (touch1 && touch2) {
          nonUpdatingStore.getActions().app.setIsPiching(true);
          // if the stage was under Konva's drag&drop
          // we need to stop it, and implement our own pan logic with two pointers
          if (stage.isDragging()) {
            stage.stopDrag();
          }

          const p1 = {
            x: touch1.clientX,
            y: touch1.clientY,
          };
          const p2 = {
            x: touch2.clientX,
            y: touch2.clientY,
          };

          if (!lastCenter) {
            lastCenter = getCenter(p1, p2);
            return;
          }
          const newCenter = getCenter(p1, p2);

          const dist = getDistance(p1, p2);

          if (!lastDist) {
            lastDist = dist;
          }

          // local coordinates of center point
          const pointTo = {
            x: (newCenter.x - stage.x()) / stage.scaleX(),
            y: (newCenter.y - stage.y()) / stage.scaleX(),
          };

          const zoomFactor = dist / lastDist;

          const scale = stage.scaleX() * zoomFactor;

          stage.scaleX(scale);
          stage.scaleY(scale);

          // calculate new position of the stage
          const dx = newCenter.x - lastCenter.x;
          const dy = newCenter.y - lastCenter.y;

          const newPos = {
            x: newCenter.x - pointTo.x * scale + dx,
            y: newCenter.y - pointTo.y * scale + dy,
          };

          stage.position(newPos);
          throttledPosUpdate();
          lastDist = dist;
          lastCenter = newCenter;
        }
      });

      stage.on('touchend', () => {
        nonUpdatingStore.getActions().app.setIsPiching(false);
        lastDist = 0;
        lastCenter = null;
      });
    }
  }, []);
  return stageRef;
}

function useGlobalWidgetResize(id: string, elementRef: RefObject<any>) {
  const hasWidget = useStoreState(
    (state) => state.widgets.data.find((widget) => widget.refId === id),
    (prevWidget, nextWidget) => prevWidget?.id === nextWidget?.id
  );
  const reposition = useStoreActions((actions) => actions.widgets.repositionWidget);
  useEffect(() => {
    const handleResize = () => {
      if (hasWidget) {
        reposition({ id, ...elementRef.current.getAbsolutePosition() });
      }
    };
    window.addEventListener(STAGE_REPOSITION, handleResize);
    return () => {
      window.removeEventListener(STAGE_REPOSITION, handleResize);
    };
  }, [hasWidget]);
}

export { useCanvasListeners, STAGE_REPOSITION, useGlobalWidgetResize };
