import { PathConfig } from 'konva/types/shapes/Path';
import { Group, Line, Path } from 'react-konva';

export const getShowDetailsIconProps = (color: string, opacity = 1): PathConfig => ({
  data: 'M18 5.01L0 5V7H18V5.01ZM0 10H12V12H0V10ZM18 0H0V2.01L18 2V0Z',
  fill: color,
  opacity,
  width: 18,
  height: 12,
});

export const CommentsIcon = (color: string, opacity = 1): PathConfig => ({
  data: 'M17.5 9.58333C17.5029 10.6832 17.2459 11.7682 16.75 12.75C16.162 13.9264 15.2581 14.916 14.1395 15.6077C13.021 16.2995 11.7319 16.6661 10.4167 16.6667C9.31678 16.6695 8.23176 16.4125 7.25 15.9167L2.5 17.5L4.08333 12.75C3.58744 11.7682 3.33047 10.6832 3.33333 9.58333C3.33384 8.26812 3.70051 6.97904 4.39227 5.86045C5.08402 4.74187 6.07355 3.83797 7.25 3.24999C8.23176 2.7541 9.31678 2.49713 10.4167 2.49999H10.8333C12.5703 2.59582 14.2109 3.32896 15.4409 4.55904C16.671 5.78912 17.4042 7.4297 17.5 9.16666V9.58333Z',
  stroke: color,
  width: 20,
  height: 20,
  opacity,
  strokeWidth: 2,
  lineCap: 'round',
  lineJoin: 'round',
});

export const NotificationsIcon = (color: string, opacity = 1): PathConfig => ({
  data: 'M5.3169 1.4L4.12523 0.208333C2.12523 1.73333 0.808561 4.08333 0.691895 6.75H2.35856C2.48356 4.54167 3.61689 2.60833 5.3169 1.4ZM15.6419 6.75H17.3086C17.1836 4.08333 15.8669 1.73333 13.8752 0.208333L12.6919 1.4C14.3752 2.60833 15.5169 4.54167 15.6419 6.75ZM14.0002 7.16667C14.0002 4.60833 12.6336 2.46667 10.2502 1.9V1.33333C10.2502 0.641666 9.6919 0.083333 9.00023 0.083333C8.30856 0.083333 7.75023 0.641666 7.75023 1.33333V1.9C5.35856 2.46667 4.00023 4.6 4.00023 7.16667V11.3333L2.33356 13V13.8333H15.6669V13L14.0002 11.3333V7.16667ZM9.00023 16.3333C9.11689 16.3333 9.22523 16.325 9.33356 16.3C9.87523 16.1833 10.3169 15.8167 10.5336 15.3167C10.6169 15.1167 10.6586 14.9 10.6586 14.6667H7.32523C7.33356 15.5833 8.07523 16.3333 9.00023 16.3333Z',
  fill: color,
  opacity,
  width: 20,
  height: 20,
});

export const AttachmentsIcon = (color: string, opacity = 1): PathConfig => ({
  data: 'M10.404 5.11l-1.015-1.014-5.075 5.074c-0.841 0.841-0.841 2.204 0 3.044s2.204 0.841 3.045 0l6.090-6.089c1.402-1.401 1.402-3.673 0-5.074s-3.674-1.402-5.075 0l-6.394 6.393c-0.005 0.005-0.010 0.009-0.014 0.013-1.955 1.955-1.955 5.123 0 7.077s5.123 1.954 7.078 0c0.004-0.004 0.008-0.009 0.013-0.014l0.001 0.001 4.365-4.364-1.015-1.014-4.365 4.363c-0.005 0.004-0.009 0.009-0.013 0.013-1.392 1.392-3.656 1.392-5.048 0s-1.392-3.655 0-5.047c0.005-0.005 0.009-0.009 0.014-0.013l-0.001-0.001 6.395-6.393c0.839-0.84 2.205-0.84 3.045 0s0.839 2.205 0 3.044l-6.090 6.089c-0.28 0.28-0.735 0.28-1.015 0s-0.28-0.735 0-1.014l5.075-5.075z',
  fill: color,
  opacity,
  width: 20,
  height: 20,
});

export const EyeIcon = (color: string, scale: number): PathConfig => ({
  data: 'm 1.3219885,7.9388818 c 0,0 1.2880136,-1.2778713 3.2109148,-2.2488642 C 5.7031088,5.0991078 7.1084439,4.6218499 8.601707,4.6154149 12.579068,4.5982751 15.876,7.9370836 15.876,7.9370836 c 0,0 -3.308954,3.3274794 -7.2582902,3.3165324 C 4.6285547,11.266043 1.3219885,7.9388818 1.3219885,7.9388818 Z M 8.6004446,4.7591763 a 2 2 0 1 1 0,4 2 2 0 1 1 0,-4',
  stroke: color,
  strokeWidth: 1.2,
  width: 10,
  height: 10,
  scale: { x: scale, y: scale },
  lineJoin: 'round',
  lineCap: 'round',
});

export const CursorIcon = (color: string, stroke: string, scale: number): PathConfig => ({
  data: 'M3.79271 6.11084C3.4416 4.53039 5.01583 3.20944 6.5113 3.82967L24.4718 11.2785C26.1254 11.9643 26.1142 14.3106 24.4542 14.9806L16.9133 18.0243C16.5212 18.1826 16.1894 18.461 15.9654 18.8197L11.6585 25.7175C10.7104 27.2359 8.39785 26.8395 8.00962 25.092L3.79271 6.11084Z',
  fill: color,
  stroke,
  width: 70,
  height: 70,
  strokeWidth: 2,
  scale: { x: scale, y: scale },
  shadowColor: 'rgba(0, 0, 0, 0.25)',
  shadowBlur: 3,
});

export const LinkIcon = (color: string, opacity = 1): PathConfig => ({
  data: 'M1.9 5C1.9 3.29 3.29 1.9 5 1.9H9V0H5C2.24 0 0 2.24 0 5C0 7.76 2.24 10 5 10H9V8.1H5C3.29 8.1 1.9 6.71 1.9 5ZM6 6H14V4H6V6ZM15 0H11V1.9H15C16.71 1.9 18.1 3.29 18.1 5C18.1 6.71 16.71 8.1 15 8.1H11V10H15C17.76 10 20 7.76 20 5C20 2.24 17.76 0 15 0Z',
  fill: color,
  width: 20,
  height: 20,
  opacity,
});

export const CheckListIcon = ({
  color,
  x,
  y,
  opacity = 1,
}: {
  color: string;
  x: number;
  y: number;
  opacity: number;
}) => {
  const scale = 0.75;
  return (
    <Group x={x} y={y}>
      <Line
        points={[9, 11, 12, 14, 22, 4]}
        stroke={color}
        opacity={opacity}
        scale={{ x: scale, y: scale }}
      />
      <Path
        data="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"
        stroke={color}
        opacity={opacity}
        scale={{ x: scale, y: scale }}
      />
    </Group>
  );
};
