import { useState, KeyboardEvent } from 'react';
import { Input as ChakraInput, Box, useOutsideClick } from '@chakra-ui/react';
import { Key } from 'ts-key-enum';
import { useStoreActions, useStoreState } from 'store/hooks';
import { useFocusOnMountRef } from 'hooks/index';
import { BoardElementState } from 'types/index';
import { BoardElementType, Rectangle } from 'shared';
import {
  DEFAULT_COLUMN_TITLE,
  DEFAULT_SWIMLANE_TITLE,
  useCountElementsAttached,
} from 'hooks/Elements';
import { getSwimlaneColorByName } from 'lib/colors';
import theme from 'lib/theme';
import { isCtrlOrMetaKey } from '../../../lib/helpers';

interface HeaderWidgetProps {
  id: string;
  widgetHeight?: number;
  widgetWidth?: number;
  refId: string;
  isSwimlane?: boolean;
  shape?: Partial<Rectangle>;
  fontSize?: number;
  backgroundColor?: string;
}

const HeaderWidget: React.FC<HeaderWidgetProps> = ({
  id,
  refId,
  shape: { x, y, width } = { x: 0, y: 0, width: 100 },
  fontSize = 16,
  isSwimlane = false,
  widgetHeight = 52,
  widgetWidth,
  backgroundColor = '#fff',
  ...props
}) => {
  const currentBoardElement = useStoreState((store) =>
    store.board.boardElements.find((e) => e.id === refId)
  );
  const [localTitle, setLocalTitle] = useState(currentBoardElement?.title || '');
  const zoomLevel = useStoreState((state) => state.boardSettings.zoomLevel);
  const removeItem = useStoreActions((actions) => actions.widgets.removeWidget);
  const { updateElement, setElementState } = useStoreActions((actions) => actions.board);
  const inputRef = useFocusOnMountRef(isSwimlane ? DEFAULT_SWIMLANE_TITLE : DEFAULT_COLUMN_TITLE);
  const { elementsAttachedCount } = useCountElementsAttached(currentBoardElement?.shape || {}, {
    excludeBoardElementsTypes: [
      BoardElementType.COLUMN,
      BoardElementType.STICKY,
      BoardElementType.SWIMLANE,
    ],
  });

  const updateTitle = () => {
    if (localTitle !== currentBoardElement?.title) {
      updateElement({ id: refId, title: localTitle });
    }
  };

  const handleExitTitle = () => {
    updateTitle();
    setElementState({ id, state: BoardElementState.FOCUSED });
    removeItem(id);
  };

  useOutsideClick({
    ref: inputRef,
    handler: handleExitTitle,
  });

  const wipLimit = (currentBoardElement?.wipLimit as any) > -1 ? currentBoardElement!.wipLimit : 0;
  const wipLimitExceeded = (wipLimit as any) > 0 && (wipLimit as any) < elementsAttachedCount;
  const scaledfontSize = fontSize ? fontSize * zoomLevel : 'auto';
  const absoluteWidth = isSwimlane ? widgetWidth : width;
  const color =
    isSwimlane && currentBoardElement?.color
      ? getSwimlaneColorByName(currentBoardElement.color).contrast
      : 'yoBrand.600';
  return (
    <Box
      position="absolute"
      width={absoluteWidth ? absoluteWidth * zoomLevel : 'auto'}
      top={y}
      left={x}
      display="flex"
    >
      <Box
        height="1px"
        fontWeight="bold"
        fontSize={scaledfontSize}
        px={`${(isSwimlane ? 18 : 24) * zoomLevel}px`}
        visibility="hidden"
      >
        {elementsAttachedCount}
        {wipLimit! > 0 && `/${wipLimit}`}
      </Box>
      <ChakraInput
        mt="-1px"
        flexGrow={1}
        minWidth={0}
        pl={0}
        pr={`${(isSwimlane ? 40 : 52) * zoomLevel}px`}
        width="auto"
        display="inline-block"
        ref={inputRef}
        border="none"
        height={`${widgetHeight * zoomLevel}px`}
        value={localTitle}
        _focus={{ border: 'none' }}
        onChange={(e: any) => setLocalTitle(e.target.value)}
        fontSize={scaledfontSize}
        fontFamily={theme.fonts.boardElements}
        onKeyDown={(e: KeyboardEvent<HTMLElement>) => {
          if ((e.key === Key.Enter && !isCtrlOrMetaKey(e)) || e.key === Key.Escape) {
            handleExitTitle();
          }
        }}
        color={wipLimitExceeded ? 'white' : color}
        backgroundColor={backgroundColor}
        transition="none"
        {...props}
      />
    </Box>
  );
};
export default HeaderWidget;
