import { getNextSnappingPosition } from 'lib/helpers';
import { DragContext, DragHandler, DragModel, DragMoveArgs } from './DragModel';
import { SNAP_TO_GRID_VALUE } from './DragConfig';

export class DragDefaultHandler implements DragHandler {
  private dragState: DragModel | undefined;

  constructor(private readonly context: DragContext) {}

  start(dragState: DragModel): void {
    this.dragState = dragState;
  }

  // eslint-disable-next-line no-unused-vars,class-methods-use-this
  move(args: DragMoveArgs) {
    // nothing to do
  }

  stop() {
    if (!this.dragState) {
      throw new Error(`${DragDefaultHandler.name}.stop() no dragState`);
    }

    const snapOffset = { x: 0, y: 0 };
    if (this.dragState.snapToGrid) {
      const snapPosition = getNextSnappingPosition({
        position: this.dragState.element.shape,
        snappingValue: SNAP_TO_GRID_VALUE,
      });
      snapOffset.x = snapPosition.x - this.dragState.element.shape.x;
      snapOffset.y = snapPosition.y - this.dragState.element.shape.y;
      this.dragState.element.shape = {
        ...this.dragState.element.shape,
        ...snapPosition,
      };
    }
    this.context.updateElement({
      id: this.dragState.element.id,
      shape: this.dragState.element.shape,
    });
  }
}
