import { Input, useToast } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useStoreActions } from 'store/hooks';
import YoModal from '../YoModal';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  boardName: string;
  updateBoardName: (name: string) => void;
}

function RenameBoardModal({ isOpen, onClose, boardName, updateBoardName }: Props) {
  const updateStoreBoardName = useStoreActions((actions) => actions.board.updateBoardName);
  const [localBoardName, setLocalBoardName] = useState(boardName);
  const toast = useToast();
  return (
    <YoModal
      isOpen={isOpen}
      onClose={onClose}
      title="Rename Board"
      onCancel={onClose}
      onConfirm={async () => {
        const { didUpdate, oldName, newName } = await updateStoreBoardName(localBoardName);
        if (didUpdate) {
          updateBoardName(newName);
          toast({
            title: `Renamed Board '${oldName}' to '${newName}'`,
            position: 'bottom',
            status: 'success',
            duration: 2000,
          });
        }
        onClose();
      }}
      confirmText="Save Changes"
    >
      <Input value={localBoardName} onChange={(e) => setLocalBoardName(e.target.value)} />
    </YoModal>
  );
}

export default RenameBoardModal;
