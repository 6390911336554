import { Path, Rect } from 'react-konva';
import { getShowDetailsIconProps } from 'lib/canvasIcons';
import { SHOW_DETAILS_NAME, SHARED_STYLES } from '.';
import { STYLES } from './CardFooter';

interface Props {
  x: number;
  y: number;
  hasImage: boolean;
  onClick?: () => void;
  foregroundColor: string;
}

export const ShowDetailsIcon = ({ foregroundColor, hasImage, x, y, onClick }: Props) => (
  <>
    <Path
      x={x}
      y={y}
      {...getShowDetailsIconProps(foregroundColor, hasImage ? 1 : 0.7)}
      listening={false}
    />
    {/* this Rect acts as the hitbox for the icon because the path is just way to small */}
    <Rect
      name={SHOW_DETAILS_NAME}
      width={STYLES.CARD_LINK_WIDTH_COLLAPSED}
      height={SHARED_STYLES.footerHeight}
      onClick={onClick}
    />
  </>
);
