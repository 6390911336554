/* eslint-disable import/prefer-default-export */
import { useCallback } from 'react';
import { Position } from 'store/app';
import { WidgetTypes } from 'store/widgets';
import { useStoreActions } from '../store/hooks';
import { ContextMenuType } from '../components/dom/ContextMenu';

interface SpawnArgs {
  id: string;
  pos: Position;
  width?: number;
  height?: number;
  fontSize?: number;
  [key: string]: any;
}

interface SpawnContextMenuArgs extends SpawnArgs {
  ids?: string[];
  type?: ContextMenuType;
}

function useSpawnContextMenu() {
  const { addWidget, closeContextMenu } = useStoreActions((actions) => actions.widgets);
  const spawnMenu = useCallback(
    ({ id, ids, pos, height, width, type = ContextMenuType.DEFAULT }: SpawnContextMenuArgs) => {
      closeContextMenu();
      addWidget({
        type: WidgetTypes.CONTEXTMENU,
        shape: {
          ...pos,
          height,
          width,
        },
        menuType: type,
        refId: id,
        refIds: ids,
      });
    },
    []
  );

  return spawnMenu;
}

export { useSpawnContextMenu };
