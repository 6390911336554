import Router from 'next/router';
import * as Sentry from '@sentry/browser';
import { parse } from 'querystring';
import { handleUtmUrl } from './urlUtils';

// eslint-disable-next-line
interface CustomRequestInit extends RequestInit {
  body?: any;
  sendFiles?: boolean;
}

// This function parses query params (as string) to a ParsedUrlQuery object.
// ? is included in the first query param key after parse, therefore, if it exists, we must trim it before parsing
const trimAndParseQueryParam = (query: string) =>
  parse(query.startsWith('?') ? query?.substr(1, query.length - 1) : query);

async function client<T>(
  endpoint: string,
  customConfig?: CustomRequestInit | null,
  errorHandler?: (message: string) => void,
  currentBoardId?: string | null
) {
  const handler = errorHandler || (() => {});
  const config: CustomRequestInit = {
    ...customConfig,
    credentials: 'include',
    headers: {
      ...customConfig?.headers,
      ...(!customConfig?.sendFiles && { 'Content-Type': 'application/json' }),
    },
    method: customConfig?.method || 'GET',
    ...(customConfig?.body && {
      body: customConfig.sendFiles ? customConfig.body : JSON.stringify(customConfig.body),
    }),
  };
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_API_URL || 'http://localhost:4000'}/${endpoint}`,
    config
  );

  if (response.ok) {
    const { data } = await response.json();
    return data as T;
  }

  const contentType = response.headers.get('Content-Type');

  let error: Error | Record<string, string> | undefined;
  let errorMessage: string | undefined;

  // If the error is a YoError extract the message from it
  if (contentType?.startsWith('application/json')) {
    error = await response.json();
    if (error?.message) {
      errorMessage = error.message;
    }
  }
  switch (response.status) {
    case 401: {
      if (typeof window !== 'undefined') {
        // If the path is / we don't want to show an error message
        if (errorMessage && window.location.pathname !== '/') {
          handler(errorMessage);
        } else if (window.location.pathname !== '/') {
          handler('You have been logged out. Please login again!');
        }
      }

      // if we currently have utm params in the url we must make sure that these are forwarded when redirecting
      // otherwise the params might get lost before a user registers and we cannot evaluate campaign success anymore
      if (currentBoardId) {
        Router.replace(
          handleUtmUrl(
            `/login?boardId=${currentBoardId}`,
            trimAndParseQueryParam(window.location.search)
          )
        );
      } else {
        Router.replace(handleUtmUrl('/login', trimAndParseQueryParam(window.location.search)));
      }
      break;
    }
    case 403: {
      const msg = errorMessage ?? 'Forbidden!';
      handler(msg);
      return Promise.reject(msg);
    }
    default: {
      const errorMsg = errorMessage ?? 'Something went wrong. Please check your connection!';
      // Try to attach the error message from the server and send it to sentry
      if (error) {
        Sentry.captureException(error);
        if (errorMessage) {
          handler(errorMsg);
          return Promise.reject(errorMsg);
        }
      }

      handler(errorMsg);
    }
  }

  return Promise.reject();
}

export default client;
