import { useState, KeyboardEvent, ChangeEvent } from 'react';
import { BoardElementState, UIBoardElement } from 'types/index';
import { useStoreActions, useStoreState } from 'store/hooks';
import { useFocusOnMountRef } from 'hooks/index';
import { Key } from 'ts-key-enum';
import { getColorByName } from 'lib/colors';
import { BoardElementType, Rectangle } from 'shared';
import { useOutsideClick } from '@chakra-ui/react';
import theme from 'lib/theme';
import { isCtrlOrMetaKey } from '../../../lib/helpers';

interface TextAreaProps {
  id: string;
  refId: string;
  fontSize?: number;
  shape?: Partial<Rectangle>;
  lineHeight?: number;
  textAlign?: string;
  verticalAlign?: string;
  padding?: number;
  cornerRadius?: number;
}

const TextArea: React.FC<TextAreaProps> = ({
  id,
  shape,
  refId,
  fontSize,
  lineHeight,
  textAlign = 'left',
  verticalAlign = 'top',
  padding,
  cornerRadius,
}) => {
  const { title, color, type } = useStoreState(
    (store) =>
      // if you instantly undo adding an element the element becomes null and then it throws an error
      store.board.boardElements.find((e) => e.id === refId) || ({} as UIBoardElement)
  );
  const [val, setVal] = useState(title || '');
  const areaRef = useFocusOnMountRef();
  const zoomLevel = useStoreState((state) => state.boardSettings.zoomLevel);
  const removeItem = useStoreActions((actions) => actions.widgets.removeWidget);
  const { updateElement, setElementState } = useStoreActions((actions) => actions.board);

  const handleExit = (aferSaveAction?: 'focus' | 'detail') => {
    updateElement({
      id: refId,
      title: val,
      state: BoardElementState.DEFAULT,
    });

    if (aferSaveAction) {
      setElementState({
        id: refId,
        state: aferSaveAction === 'focus' ? BoardElementState.FOCUSED : BoardElementState.INDETAIL,
      });
    }
    removeItem(id);
  };

  useOutsideClick({ ref: areaRef, handler: () => handleExit() });

  const { x, y, height, width, angle } = shape || {};
  return (
    <textarea
      style={{
        backgroundColor: getColorByName(color!)?.value || '#fff',
        color: '#000',
        position: 'absolute',
        top: y,
        left: x,
        fontFamily: theme.fonts.boardElements,
        width: width ? width * zoomLevel - 2 : 'auto',
        height: height ? height * zoomLevel - 2 : 'auto',
        borderRadius: cornerRadius ? cornerRadius * zoomLevel : 'auto',
        fontSize: fontSize ? fontSize * zoomLevel : 'auto',
        padding: padding ? `${padding * zoomLevel}px` : 'auto',
        lineHeight: lineHeight ?? 'auto',
        verticalAlign,
        textAlign: textAlign as any,
        resize: 'none',
        transform: `rotate(${angle}deg)`,
        transformOrigin: 'top left',
        margin: '1px',
        outline: 'none',
      }}
      ref={areaRef}
      value={val}
      onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
        setVal(e.target.value);
      }}
      onKeyPress={(e: KeyboardEvent<HTMLElement>) => {
        // Newlines aren't allowed in the title of a CARD
        if (type === BoardElementType.CARD && e.key === Key.Enter && e.shiftKey) {
          e.preventDefault();
        }

        // This prevents pressing Enter from inserting a new line on spawn
        if (e.key === Key.Enter && !e.shiftKey) {
          e.preventDefault();
          e.stopPropagation();
        }
      }}
      onKeyDown={(e: KeyboardEvent<HTMLElement>) => {
        e.stopPropagation();
        if ((e.key === Key.Enter && !e.shiftKey) || e.key === Key.Escape) {
          handleExit(type === BoardElementType.CARD && isCtrlOrMetaKey(e) ? 'detail' : 'focus');
        }
      }}
    />
  );
};
export default TextArea;
