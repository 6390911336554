import { memo, MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Flex,
  Stack,
  Box,
  Button,
  Avatar,
  useDisclosure,
  IconButton,
  Text,
  As,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { useStoreActions, useStoreState } from 'store/hooks';
import { useRouter } from 'next/router';
import theme from 'lib/theme';
import { useSocketActions } from 'hooks/Sockets';
import { getCursorColor } from 'lib/colors';
import fetchClient from 'lib/Fetch';
import { AiOutlineCheck } from 'react-icons/ai';
import {
  CardIcon,
  ColumnIcon,
  NoteIcon,
  SearchIcon,
  SwimlaneIcon,
  ExpandIcon,
  ListIcon,
} from 'lib/icons';
import { HamburgerIcon } from '@chakra-ui/icons';
import { NextSeo } from 'next-seo';
import { BoardElementType } from 'shared';
import { usePlausible } from 'next-plausible';
import { CLIENT_PLAUSIBLE_EVENTS } from 'lib/Types';
import { getFullnameFromUser } from '../../../lib/helpers';
import CurrentAvatars from './CurrentAvatars';
import PopoverMenu from '../PopoverMenu';
import PopoverItem from '../PopoverMenu/PopoverItem';
import ShareModal from './ShareModal';
import RenameBoardModal from './RenameBoardModal';
import TooltipWithShortcut, { YoKbd } from '../TooltipWithShortcut';
import { LoomButton } from './LoomButton';
import { NotificationsButton } from '../Notifications/NotificationsButton';

export const SEARCH_BUTTON_ID = 'search-button';

type SpawnElement = {
  testId: string;
  content: React.ReactNode;
  onClick: (e: MouseEvent<HTMLElement>) => void;
  icon: React.ReactNode;
  tooltip: { text: string; shortcut: React.ReactNode };
  dropdownEntries?: Array<SpawnElement>;
  isDisabled?: boolean;
};

function Navbar() {
  const { leaveBoard } = useSocketActions();
  const router = useRouter();
  const plausible = usePlausible();

  const boardName = useStoreState((store) => store.board.name);
  const { toggleSnapToGrid, setShowBookmarks } = useStoreActions(
    (actions) => actions.boardSettings
  );
  const isLoomSupported = useStoreState((store) => store.app.isLoomSupported);
  const isRecordingLoomVideo = useStoreState((store) => store.app.isRecordingLoom);
  const snapToGrid = useStoreState((store) => store.boardSettings.snapToGrid);
  const showBookmarks = useStoreState((store) => store.boardSettings.showBookmarks);
  const { isOpen: isUserOpen, onOpen: onUserOpen, onClose: onUserClose } = useDisclosure();
  const { isOpen: isPopoverOpen, onOpen: onPopoverOpen, onClose: onPopoverClose } = useDisclosure();
  const { isOpen: isRenameOpen, onOpen: onRenameOpen, onClose: onRenameClose } = useDisclosure();
  const { isOpen: isShareOpen, onOpen: onShareOpen, onClose: onShareClose } = useDisclosure();
  const toggleBoardSearch = useStoreActions((actions) => actions.app.toggleBoardSearch);
  const dropDownRef = useRef<HTMLDivElement>(null);
  const burgerRef = useRef<HTMLButtonElement>(null);
  const user = useStoreState((state) => state.user.user);

  const boardId = useStoreState((state) => state.board.boardId);
  const [localBoardName, setLocalBoardName] = useState(boardName || '');
  const setFloatingBoardElement = useStoreActions((actions) => actions.app.setFloatingBoardElement);
  const floatingElement = useStoreState((store) => store.app.floatingBoardElement);

  useEffect(() => {
    setLocalBoardName(boardName!);
  }, [boardName]);

  const handleLogout = useCallback(async () => {
    await fetchClient('auth/logout');
    router.push('/login');
  }, [router]);

  const handleOpenMetrics = useCallback(() => {
    router.push(`/board/${router.query.boardId}/metrics`);
  }, [router]);

  const DROP_DOWN_ENTRIES = useMemo(
    () => [
      {
        content: 'Go to Overview',
        onClick: () => {
          leaveBoard(boardId!);
          router.push('/myboards');
        },
        hasDivider: true,
      },
      {
        content: 'Open Archive',
        onClick: () => {
          router.push(`/board/${router.query.boardId}/archive`);
        },
      },
      {
        content: 'Open Metrics',
        onClick: handleOpenMetrics,
      },
      {
        content: 'Open Bookmarks',
        onClick: () => {
          if (!showBookmarks) {
            plausible(CLIENT_PLAUSIBLE_EVENTS.BOOKMARKS_OPENED);
          }
          setShowBookmarks(!showBookmarks);
        },
        hasDivider: true,
      },
      {
        content: 'Rename Board',
        onClick: () => {
          onRenameOpen();
        },
      },
      {
        content: 'Share Board',
        onClick: onShareOpen,
      },
      {
        content: 'Board Settings',
        onClick: () => router.push(`/myboards/${boardId}`),
      },
      {
        onClick: () => toggleSnapToGrid(),
        content: (
          <>
            Snap to Grid
            {snapToGrid && <Box as={AiOutlineCheck} display="inline" ml="auto" />}
          </>
        ),
      },
    ],
    [
      handleOpenMetrics,
      onShareOpen,
      snapToGrid,
      leaveBoard,
      boardId,
      router,
      showBookmarks,
      setShowBookmarks,
      onRenameOpen,
      toggleSnapToGrid,
    ]
  );

  const ACCOUNT_MENU_ENTRIES = useMemo(
    () => [
      {
        content: 'Account Settings',
        onClick: () => {
          router.push('/account');
        },
      },
      {
        content: 'Logout',
        onClick: handleLogout,
      },
    ],
    [handleLogout, router]
  );

  const SPAWN_ELEMENTS = useMemo(
    () =>
      [
        {
          testId: 'newCard',
          content: (
            <>
              <Text as="u">C</Text>ard
            </>
          ),
          onClick: (e: MouseEvent<HTMLElement>) => {
            setFloatingBoardElement({
              type: BoardElementType.CARD,
            });
            (e.target as HTMLElement).blur();
          },
          icon: CardIcon,
          tooltip: { text: 'Create a new Card', shortcut: <YoKbd>C</YoKbd> },
          dropdownEntries: [
            {
              content: <LoomButton />,
              onClick: (e: MouseEvent<HTMLElement>) => {
                (e.target as HTMLElement).blur();
              },
              tooltip: {
                text: isLoomSupported
                  ? 'Create a new Loom Video'
                  : 'Loom is not supported in your browser',
              },
              isDisabled: isRecordingLoomVideo || !isLoomSupported,
            },
          ],
        },
        {
          testId: 'newNote',
          content: (
            <>
              <Text as="u">N</Text>ote
            </>
          ),
          onClick: (e: MouseEvent<HTMLElement>) => {
            setFloatingBoardElement({
              type: BoardElementType.STICKY,
            });
            (e.target as HTMLElement).blur();
          },
          icon: NoteIcon,
          tooltip: {
            text: 'Create a new Note',
            shortcut: <YoKbd>N</YoKbd>,
          },
        },
        {
          testId: 'newListColumn',
          content: (
            <>
              <Text as="u">L</Text>ist
            </>
          ),

          onClick: (e: MouseEvent<HTMLElement>) => {
            setFloatingBoardElement({
              type: BoardElementType.COLUMN,
              meta: { isSorted: true },
            });
            (e.target as HTMLElement).blur();
          },
          icon: ListIcon,
          tooltip: {
            text: 'Create a new List',
            shortcut: <YoKbd>l</YoKbd>,
          },
          dropdownEntries: [
            {
              testId: 'newUnordered',
              content: (
                <>
                  <ColumnIcon fontSize={22} mr={2} />
                  Free Col<Text as="u">u</Text>mn
                </>
              ),
              onClick: (e: MouseEvent<HTMLElement>) => {
                setFloatingBoardElement({
                  type: BoardElementType.COLUMN,
                });
                (e.target as HTMLElement).blur();
              },
              tooltip: {
                text: 'Create a Free Column',
                shortcut: <YoKbd>U</YoKbd>,
              },
            },
          ],
        },

        {
          testId: 'newSwimlane',
          content: (
            <>
              <Text as="u">S</Text>wimlane
            </>
          ),
          onClick: (e: MouseEvent<HTMLElement>) => {
            setFloatingBoardElement({
              type: BoardElementType.SWIMLANE,
            });
            (e.target as HTMLElement).blur();
          },
          icon: SwimlaneIcon,
          tooltip: {
            text: 'Create a new Swimlane',
            shortcut: <YoKbd>S</YoKbd>,
          },
        },
      ] as SpawnElement[],
    [isLoomSupported, isRecordingLoomVideo, setFloatingBoardElement]
  );

  return (
    <>
      <NextSeo title={boardName!} />
      <Box w="100vw" pos="relative">
        <Flex
          position="fixed"
          bg="transparent"
          color="yoBrand.600"
          px={2}
          pb={4}
          zIndex={theme.zIndices.DOM_ELEMENTS}
        >
          <Box
            display="flex"
            px={4}
            position="absolute"
            mt={4}
            left="0.5rem"
            alignItems="center"
            userSelect="none"
          >
            <IconButton
              ref={burgerRef}
              icon={<HamburgerIcon boxSize={5} />}
              aria-label="Toggle Menu"
              onClick={() => {
                // We prefetch the /myboards page here to have faster transitions
                router.prefetch('/myboards');
                router.prefetch(`/myboards/${boardId}`);
                onPopoverOpen();
              }}
              role="button"
              p={0}
              size="sm"
              borderRadius="full"
              color="white"
              boxShadow="topBar"
              _hover={{
                boxShadow: 'circleHover',
                bg: isPopoverOpen ? 'yoOrange.800' : 'yoBrand.800',
              }}
              // boxSizing="content-box"
              bg={isPopoverOpen ? 'yoOrange.500' : 'yoBrand.500'}
            />
            <Button
              display={{ base: 'none', md: 'block' }}
              onClick={onRenameOpen}
              variant="topBar"
              fontSize="xl"
              h="auto"
              lineHeight={theme.lineHeights.heading}
              fontWeight={900}
              cursor="text"
              fontFamily="boardElements"
            >
              {boardName}
            </Button>
          </Box>
        </Flex>
        <Flex
          position="fixed"
          bg="transparent"
          color="yoBrand.600"
          px={2}
          left={{ base: 2, lg: '50%' }}
          top={{ base: '50%', lg: 0 }}
          transform={{ base: 'translateY(-50%)', lg: 'translateX(-50%)' }}
          pb={4}
          zIndex={theme.zIndices.DOM_ELEMENTS}
        >
          <Stack
            opacity={floatingElement ? 0.3 : 1}
            mt={5}
            height={{ base: 'auto', lg: 12 }}
            background="white"
            borderRadius={8}
            boxShadow="topBar"
            direction={{ base: 'column', lg: 'row' }}
            spacing={0}
            justifySelf="center"
            alignItems="center"
            justifyContent="space-around"
          >
            <TooltipWithShortcut
              text="Search for Elements"
              shortcut={
                <>
                  <YoKbd>Ctrl</YoKbd> + <YoKbd>K</YoKbd>
                </>
              }
            >
              <IconButton
                id={SEARCH_BUTTON_ID}
                onClick={() => {
                  plausible(CLIENT_PLAUSIBLE_EVENTS.OPEN_SEARCH, { props: { source: 'Button' } });
                  toggleBoardSearch();
                }}
                h="100%"
                w={{ base: '100%', lg: 14 }}
                py={{ base: 4, lg: 'auto' }}
                px={{ base: 0.5, lg: 'auto' }}
                variant="brand"
                borderLeftRadius={{ base: 0, lg: 8 }}
                borderRightRadius={0}
                borderTopRadius={{ base: 8, lg: 0 }}
                borderTopLeftRadius={{ base: 8, lg: 8 }}
                aria-label="Open Search"
                icon={<SearchIcon boxSize={6} color="white" />}
              />
            </TooltipWithShortcut>
            {SPAWN_ELEMENTS.map((currentElement, i) => {
              const hasDropdownEntries = Boolean(currentElement.dropdownEntries?.length);
              return (
                <Flex alignItems="center" height="100%" key={currentElement.tooltip.text}>
                  <TooltipWithShortcut
                    key={currentElement.tooltip.text}
                    {...currentElement.tooltip}
                  >
                    <Button
                      data-testid={currentElement.testId}
                      variant="topBar"
                      fontSize={18}
                      height="100%"
                      pl={4}
                      pr={{ base: 4, md: hasDropdownEntries ? 2 : 4 }}
                      mr={{ base: 0, md: hasDropdownEntries ? 2 : 0 }}
                      py={{ base: 6, lg: 0 }}
                      onClick={currentElement.onClick}
                      borderRightRadius={i === SPAWN_ELEMENTS.length - 1 ? 6 : 0}
                    >
                      <Box
                        as={currentElement.icon as As<any>}
                        mr={{ base: 0, lg: 3 }}
                        fontSize={22}
                      />
                      <Text as="span" display={{ base: 'none', lg: 'inline' }}>
                        {currentElement.content}
                      </Text>
                    </Button>
                  </TooltipWithShortcut>
                  {hasDropdownEntries && (
                    <Menu placement="bottom" autoSelect={false}>
                      <MenuButton
                        display={{ base: 'none', lg: 'block' }}
                        as={IconButton}
                        aria-label="More Options"
                        icon={<ExpandIcon mt="-2px" />}
                        fontSize={22}
                        variant="topBar"
                        borderRadius={0}
                        height="100%"
                        width={22}
                        minWidth={22}
                        ml={-3}
                        outline="none"
                        _focus={{ boxShadow: 'none' }}
                      />
                      <MenuList minW="150px" p={0} boxShadow="topBar">
                        {currentElement.dropdownEntries?.map((entry: any) => (
                          <MenuItem
                            key={entry.tooltip.text}
                            justifyContent="center"
                            py={3}
                            color="yoBrand.500"
                            fontSize="16px"
                            fontWeight={700}
                            onClick={entry.onClick}
                            isDisabled={entry.isDisabled}
                            title={entry.tooltip.text}
                          >
                            {entry.content}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </Menu>
                  )}
                </Flex>
              );
            })}
          </Stack>
        </Flex>
        <Flex
          position="fixed"
          bg="transparent"
          color="yoBrand.600"
          px={2}
          right="0"
          pb={4}
          zIndex={theme.zIndices.DOM_ELEMENTS}
        >
          <Flex position="absolute" right={6} mt={4} alignItems="center">
            <CurrentAvatars />
            <Button
              onClick={() => onShareOpen()}
              variant="brand"
              size="sm"
              ml={6}
              mr={4}
              display={{ base: 'none', md: 'block' }}
            >
              Share
            </Button>
            <NotificationsButton />
            <Box
              ml={4}
              display="flex"
              rounded="full"
              justifyItems="center"
              alignItems="center"
              transition="all 0.1s ease-out"
              bg={isUserOpen ? 'yoGrayTransparent' : 'inherit'}
              p={0}
              boxShadow="topBar"
              _hover={{
                boxShadow: 'circleHover',
              }}
              cursor="pointer"
              onClick={(e) => {
                e.stopPropagation();
                onUserOpen();
              }}
              ref={dropDownRef}
              userSelect="none"
            >
              <Avatar
                src={user?.avatarUrl}
                name={getFullnameFromUser(user)}
                size="sm"
                borderWidth="2px"
                style={{ borderColor: getCursorColor(user?._id as any).color }}
              />
            </Box>
          </Flex>
        </Flex>
        <PopoverMenu
          isOpen={isUserOpen}
          onClose={onUserClose}
          right={7}
          top={2}
          toggleElementRef={dropDownRef}
        >
          {ACCOUNT_MENU_ENTRIES.map(({ content, ...rest }) => (
            <PopoverItem key={content} {...rest}>
              {content}
            </PopoverItem>
          ))}
        </PopoverMenu>
        <PopoverMenu
          isOpen={isPopoverOpen}
          onClose={onPopoverClose}
          left={4}
          top={2}
          toggleElementRef={burgerRef}
        >
          {DROP_DOWN_ENTRIES.map(({ content, ...rest }, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <PopoverItem key={i} {...rest}>
              {content}
            </PopoverItem>
          ))}
        </PopoverMenu>
        <RenameBoardModal
          isOpen={isRenameOpen}
          onClose={onRenameClose}
          boardName={localBoardName}
          updateBoardName={setLocalBoardName}
        />
        <ShareModal isOpen={isShareOpen} onClose={onShareClose} />
      </Box>
    </>
  );
}

export default memo(Navbar);
