/* eslint-disable no-param-reassign */
import {
  BoardElementType,
  EventTypes,
  getOppositeLinkType,
  BoardElementLink,
  BoardElementLinkType,
  BoardElement,
} from 'shared';
import { action, Action, thunk, Thunk } from 'easy-peasy';
import { sortBoardElementsByZIndex, updateElement } from 'lib/helpers';
import { PublicStoreModel } from 'store/index';
import { BoardElementPayload, UIBoardElement } from 'types/index';
import { v4 as generateUUID } from 'uuid';
import { SortedColumnUpdater } from 'src/sortedColumn/SortedColumnUpdater';
import { ClientSingleElementEvent } from 'src/ServerEvent/ServerEvent';
import BoardEventFactory, { getObjectIntersection } from '../boardEventFactory';
import * as Events from '../../../lib/Events';
import { BoardStoreModel } from '..';

export interface UpdateElementsModel {
  updateElement: Thunk<
    BoardStoreModel,
    BoardElementPayload | Array<BoardElementPayload>,
    null,
    PublicStoreModel
  >;
  updateElementAction: Action<BoardStoreModel, BoardElementPayload | BoardElementPayload[]>;
  updateElementWithoutHistory: Thunk<
    BoardStoreModel,
    { payload: BoardElementPayload | Array<BoardElementPayload>; fetch?: boolean },
    null,
    PublicStoreModel
  >;
  archiveElement: Thunk<BoardStoreModel, string | Array<string>, null, PublicStoreModel>;
  unArchiveElement: Thunk<BoardStoreModel, string | Array<string>, null, PublicStoreModel>;
  deleteLink: Thunk<
    BoardStoreModel,
    { linkToDelete: BoardElementLink<BoardElement>; cardId: string },
    null,
    PublicStoreModel
  >;
  addLink: Thunk<
    BoardStoreModel,
    {
      currentElement: Pick<BoardElement, 'id' | 'links'>;
      targetElement: Pick<BoardElement, 'id' | 'links'>;
      linkType: BoardElementLinkType;
    },
    null,
    PublicStoreModel
  >;
  incrementAttachmentCount: Action<BoardStoreModel, { cardId: string }>;
  toggleWatchCard: Thunk<BoardStoreModel, { cardId: string }, null, PublicStoreModel>;
}

const updateElementsStore: UpdateElementsModel = {
  updateElementAction: action((state, payload) => {
    const newElements = ([] as BoardElementPayload[]).concat(payload);
    const updatedElements = [...state.boardElements];
    newElements.forEach((currentElement) => {
      const index = state.boardElements.findIndex((element) => element.id === currentElement.id);
      if (index > -1) {
        updatedElements[index] = updateElement(currentElement, updatedElements[index]);
      }
    });
    state.boardElements = updatedElements;
  }),
  updateElement: thunk(async (actions, payload, { getStoreState, getStoreActions }) => {
    const { boardElements, currentAction, boardId } = getStoreState().board;
    const elementsToUpdate: BoardElementPayload[] = [];
    const elementsThatCauseReordering: UIBoardElement[] = [];

    const events = ([] as BoardElementPayload[])
      .concat(payload)
      .flatMap((currentPayload: BoardElementPayload) => {
        const index = boardElements.findIndex((element) => element.id === currentPayload.id);
        if (index > -1) {
          // trim title
          if (currentPayload.title) {
            currentPayload.title = currentPayload.title.trim();
          }

          elementsToUpdate.push(currentPayload);

          const boardElementChanges = getObjectIntersection(boardElements[index], currentPayload);

          // find elements that cause reordering of sorted columns
          if (SortedColumnUpdater.doesElementUpdateNeedColumnResorting(boardElementChanges)) {
            elementsThatCauseReordering.push(boardElements[index]);
          }

          // only do undo/redo stuff when there are actually changes

          if (
            JSON.stringify(boardElementChanges.oldValues) !==
            JSON.stringify(boardElementChanges.newValues)
          ) {
            return BoardEventFactory.createEvent(
              EventTypes.UPDATE,
              currentPayload.id,
              boardElements[index],
              currentPayload
            );
          }
        }
        return [];
      })
      // Remove undefined array elements
      .filter(Boolean);

    // It means that there weren't any changes -> thus no need to send to server

    if (events.length === 0) return;

    actions.updateElementAction(elementsToUpdate);

    const sortColumnEvents = SortedColumnUpdater.renderAndUpdateStateByElements({
      elements: elementsThatCauseReordering,
    });

    const bulkEvent = BoardEventFactory.createBulkEvent([...events, ...sortColumnEvents]);
    if (!boardId) {
      return;
    }
    const res = await Events.postEvent(bulkEvent, boardId, (err: string) => {
      getStoreActions().board.unapplyEvent({ payload: bulkEvent, fetch: false });
      getStoreActions().errors.addError(err);
    });

    if (res) {
      if (currentAction) {
        actions.pushEventToPast(currentAction);
      }
      actions.setCurrentAction(bulkEvent);
      // build a new branch from currentState -> clear stored futureActions
      actions.clearFutureActions();
      const withResorting = elementsToUpdate.some(
        (currentElement: BoardElementPayload) => currentElement.zIndex !== undefined
      );
      if (withResorting) {
        const state = getStoreState();
        actions.setBoardAction(sortBoardElementsByZIndex(state.board.boardElements));
      }
    }
  }),
  updateElementWithoutHistory: thunk(
    async (actions, { payload, fetch }, { getStoreState, getStoreActions }) => {
      const { boardElements, boardId } = getStoreState().board;
      actions.updateElementAction(payload);

      const updateEvents: ClientSingleElementEvent[] = [];

      (Array.isArray(payload) ? payload : [payload]).forEach((currentPayload) => {
        const oldElement = boardElements.find((element) => element.id === currentPayload.id);
        if (oldElement) {
          updateEvents.push(
            BoardEventFactory.createEvent(
              EventTypes.UPDATE,
              currentPayload.id,
              oldElement,
              currentPayload
            )
          );
        }
      });

      if (fetch && boardId && updateEvents.length > 0) {
        const eventToPost =
          updateEvents.length === 1
            ? updateEvents[0]
            : BoardEventFactory.createBulkEvent(updateEvents);

        const res = await Events.postEvent(eventToPost, boardId, (err: string) => {
          getStoreActions().board.unapplyEvent({ payload: eventToPost, fetch: false });
          getStoreActions().errors.addError(err);
        });

        if (res) {
          actions.updateIdsWithObjectIds(res);
        }
      }
    }
  ),
  archiveElement: thunk((actions, payload, { getStoreState }) => {
    const currentIds = ([] as string[]).concat(payload) as string[];
    const allElements = getStoreState().board.boardElements;

    const elementIdsWhichAreCards = currentIds.filter((currentId) => {
      const currentIndex = allElements.findIndex(
        (currentElement) => currentElement.id === currentId
      );
      if (currentIndex > -1) {
        return allElements[currentIndex].type === BoardElementType.CARD;
      }
      return false;
    });

    actions.updateElement(
      elementIdsWhichAreCards.map((currentId) => ({
        id: currentId,
        isArchived: true,
      }))
    );

    const elementsToArchive = getStoreState().board.boardElements.filter((el) =>
      elementIdsWhichAreCards.includes(el.id)
    );

    const { archivedElements, boardElements } = getStoreState().board;
    actions.setBoardAction(boardElements.filter((el) => !elementIdsWhichAreCards.includes(el.id)));
    actions.setArchivedElementsAction(archivedElements.concat(elementsToArchive));
  }),
  unArchiveElement: thunk((actions, payload, { getStoreState }) => {
    const currentIds = ([] as string[]).concat(payload);

    const { archivedElements, boardElements } = getStoreState().board;

    const elementsToUnArchive = getStoreState().board.archivedElements.filter((el) =>
      currentIds.includes(el.id)
    );
    actions.setBoardAction(boardElements.concat(elementsToUnArchive));
    actions.setArchivedElementsAction(archivedElements.filter((el) => !currentIds.includes(el.id)));
    actions.updateElement(
      currentIds.map((currentId) => ({
        id: currentId,
        isArchived: false,
      }))
    );
  }),
  addLink: thunk(
    async (_, { currentElement, linkType, targetElement }, { getStoreState, getStoreActions }) => {
      const { boardId } = getStoreState().board;
      if (!boardId) return;
      const linkId = generateUUID();
      const newLink: BoardElementLink = {
        _id: linkId,
        targetElement: { id: targetElement.id },
        type: linkType,
        createdAt: new Date(),
      };

      const targetLink: BoardElementLink = {
        _id: linkId,
        targetElement: { id: currentElement.id },
        type: getOppositeLinkType(linkType),
        createdAt: new Date(),
      };

      const { boardElements } = getStoreState().board;

      await getStoreActions().board.updateElement([
        {
          id: currentElement.id,
          links: [
            ...(boardElements.find((el) => el.id === currentElement.id)?.links ?? []),
            newLink,
          ],
        },
        {
          id: targetElement.id,
          links: [
            ...(boardElements.find((el) => el.id === targetElement.id)?.links ?? []),
            targetLink,
          ],
        },
      ]);
    }
  ),
  deleteLink: thunk(async (actions, { linkToDelete, cardId }, { getStoreState }) => {
    const { boardId, boardElements } = getStoreState().board;
    const linkId = linkToDelete._id;

    const targetCard = boardElements.find((el) => el.id === linkToDelete.targetElement.id);
    const currentCard = boardElements.find((el) => el.id === cardId);

    if (!targetCard || !currentCard) return;

    if (!boardId) return;

    await actions.updateElement([
      {
        id: cardId,
        links: currentCard.links?.filter((link) => link._id !== linkId) ?? [],
      },
      {
        id: linkToDelete.targetElement.id,
        links: targetCard.links?.filter((link) => link._id !== linkId) ?? [],
      },
    ]);
  }),
  incrementAttachmentCount: action((state, { cardId }) => {
    const currentCard = state.boardElements.find((el) => el.id === cardId);

    if (!currentCard) return;
    currentCard.attachmentCount = (currentCard.attachmentCount ?? 0) + 1;
  }),
  toggleWatchCard: thunk((actions, { cardId }, { getStoreState }) => {
    const currentUser = getStoreState().user.user;
    const currentCard = getStoreState().board.boardElements.find((el) => el.id === cardId);
    if (!currentUser || !currentCard) return;

    if (currentCard.watchers?.includes(currentUser._id)) {
      actions.updateElement({
        id: cardId,
        watchers: [...(currentCard.watchers ?? [])].filter(
          (watcherId) => watcherId !== currentUser._id
        ),
      });
    } else {
      actions.updateElement({
        id: cardId,
        watchers: [...(currentCard.watchers ?? []), currentUser._id],
      });
    }
  }),
};

export { updateElementsStore };
