import { extendTheme } from '@chakra-ui/react';
import { CURRENT_USER_MENTION_CLASSNAME } from './editor.helper';

type VariantInput = { theme: Record<string, Record<string, string>>; colorScheme: string };

const activeLabelStyles = {
  transform: 'scale(0.85) translateY(-24px)',
  color: 'black',
  fontWeight: 500,
};

export const yoTheme = {
  colors: {
    placeholder: '#a5afbb',
    back: {
      50: '#efeffe',
      100: '#d0d2e4',
      200: '#b3b4cd',
      300: '#9496b7',
      400: '#7678a2',
      500: '#414361',
      600: '#484a6b',
      700: '#33354d',
      800: '#1e2031',
      900: '#070b17',
    },
    pink: {
      50: '#ffe3fe',
      100: '#ffb2f2',
      200: '#ff80e7',
      300: '#fe4edd',
      400: '#fe21d3',
      500: '#FF84E8',
      600: '#b20591',
      700: '#800068',
      800: '#4e003f',
      900: '#1e0018',
    },
    lightBlue: {
      50: '#e0fafe',
      100: '#bdecf1',
      200: '#98dee6',
      300: '#72d1dc',
      350: '#6B8CBF4D',
      400: '#4ec2d2',
      500: '#92DCE5',
      600: '#27848f',
      700: '#195e67',
      800: '#06393f',
      900: '#001518',
    },
    // taggle colors
    yoGray: {
      border: '#A5A5A5',
      disabled: '#E7E7E7',
      divider: '#C4C4C4',
      100: '#FAFAFA', // column highlighted
      200: '#F1F1F1', // column
      201: 'rgba(241, 241, 241, 0.5)', // column transparent
      300: '#E5E5E5', // background
      400: '#D9D9D9', // column border
      401: 'rgba(217, 217, 217, 0.5)', // column border transparent
      800: '#797979', // text light
      820: '#666666', // table header text
      850: '#5C5C5C', // comment button text
      900: '#363636', // default text
      placeHolder: '#757575',
    },
    yoRed: {
      300: '#dd7d7f',
      400: '#CF5050',
      500: '#FF0000',
    },
    yoBrand: {
      50: '#e7f3ff',
      100: '#cad7e8',
      200: '#abbcd3',
      300: '#718096', // light color for wipChips
      400: '#697C99',
      500: '#3E5372', // yoBrand!!!
      600: '#3f5474',
      700: '#2c3c54',
      750: '#222E41',
      800: '#1F2E45', // button hover
      900: '#040c18',
      hover: '#27354A',
    },
    yoDark: {
      400: '#3c4960',
      500: '#2D3748',
    },
    yoBrandTransparent: 'rgba(62, 83, 114, 0.7)',
    yoBrandTransparent2: 'rgba(62, 83, 114, 0.1)',
    yoGrayTransparent: 'rgba(196, 196, 196, 0.5)',
    // taggle board colors
    yoOrange: {
      300: '#fdba53',
      400: '#FFA800',
      500: '#FBAC2D',
    },
    yoYellow: {
      300: '#fef2ad',
      500: '#FCD907',
    },
    yoGreen: {
      300: '#e0e383',
      500: '#DDE24D',
    },
    yoBlue: {
      300: '#14c3e0',
      500: '#00B5E1',
    },
    yoDarkBlue: {
      500: '#257BFF',
    },
    yoPink: {
      300: '#f481b3',
      500: '#F0569F',
    },
    yoWhite: {
      500: '#FFFFFF',
    },
    taggleOceanBlue: {
      50: '#EBEEF1',
      100: '#D9DDE3',
      200: '#B2BBC7',
      300: '#8C98AC',
      400: '#657690',
      500: '#3F5474',
      600: '#32435D',
      700: '#263246',
      800: '#19222E',
      900: '#0D1117',
    },
    taggleLime: {
      50: '#FCFCED',
      100: '#F8F9DB',
      200: '#F1F3B8',
      300: '#EBEE94',
      400: '#E4E871',
      500: '#DDE24D',
      600: '#C7CC45',
      700: '#B1B53E',
      800: '#85882E',
      900: '#585A1F',
      1000: '#2C2D0F',
    },
    taggleShinyBlue: {
      50: '#FDFEFF',
      100: '#F6FAFF',
      200: '#DCEAFF',
      300: '#B0D0FF',
      400: '#74AAFF',
      500: '#257BFF',
      600: '#184EA3',
      700: '#0D2C5C',
      800: '#061429',
      900: '#01050A',
    },
    taggleGray: {
      50: 'rgba(255, 255, 255, 0.9)',
      100: 'rgba(255, 255, 255, 0.8)',
      200: 'rgba(255, 255, 255, 0.6)',
      300: '#EFEFEF',
      400: '#EAEAEA',
      500: '#E5E5E5',
      600: '#B7B7B7',
      700: '#898989',
      800: '#5C5C5C',
      900: '#2E2E2E',
    },
    designAnnotation: { 500: '#7B61FF', 600: '#6749ff' },
  },
  radii: {
    sm: '3px', // default border-radius for cards, columns, stickys
    xl: '14px',
    modal: '6px',
  },
  lineHeights: {
    base: '1.4', // default line-height for text
    heading: '18.75px',
  },
  fonts: {
    body: 'Roboto, sans-serif',
    heading: 'Roboto, sans-serif',
    boardElements: '"Lexend Deca", sans-serif',
  },
  fontSizes: {
    sm: '14px',
    md: '16px',
    xmd: '28px',
    icon: '32px',
  },
  sizes: {
    '3xl': '4em',
  },
  space: {
    3: '12px', // at html base font size 16px this equals to 12px
    42: '42px',
  },
  shadows: {
    topBar: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    circleHover: '0px 0px 0px 5px rgba(196, 196, 196, 0.5)',
    dropDown: '2px 3px 4px rgba(0, 0, 0, 0.25)',
    uploadIcon: '1px 2px 2px rgba(0, 0, 0, 0.25)',
    layer0: { css: 'none' },
    layer1: {
      css: '0px 1px 1px rgba(0, 0, 0, 0.3)',
      color: 'rgba(0, 0, 0, 0.3)',
      offsetX: 0,
      offsetY: 1,
      blurRadius: 1,
    },
    layer2: {
      css: '0px 2px 3px rgba(0, 0, 0, 0.25)',
      color: 'rgba(0, 0, 0, 0.25)',
      offsetX: 0,
      offsetY: 2,
      blurRadius: 3,
    },
    layer3: {
      css: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      color: 'rgba(0, 0, 0, 0.25)',
      offsetX: 0,
      offsetY: 4,
      blurRadius: 4,
    },
    floating: {
      css: '5px 8px 25px rgba(0, 0, 0, 0.25)',
      color: 'rgba(0, 0, 0, 0.25)',
      offsetX: 5,
      offsetY: 8,
      blurRadius: 25,
    },
    cookieConsent: {
      css: 'rgb(0 0 0 / 25%) 0px 54px 55px, rgb(0 0 0 / 12%) 0px -12px 30px, rgb(0 0 0 / 12%) 0px 4px 6px, rgb(0 0 0 / 17%) 0px 12px 13px, rgb(0 0 0 / 9%) 0px -3px 5px',
    },
  },
  zIndices: {
    DOM_ELEMENTS: 100,
    CONTEXT_MENU: 200,
  },
  components: {
    // Recipe from https://chakra-ui.com/docs/components/recipes/floating-labels
    Form: {
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles,
              },
            },
            'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label': {
              ...activeLabelStyles,
            },
            label: {
              top: 0,
              left: 0,
              zIndex: 2,
              position: 'absolute',
              backgroundColor: 'yoGray.300',
              pointerEvents: 'none',
              mx: 3,
              px: 1,
              my: 2,
              transformOrigin: 'left top',
              color: 'yoGray.placeHolder',
              fontWeight: 400,
            },
          },
        },
      },
    },
    Menu: {
      parts: ['item', 'list'],
      variants: {
        yo: {
          list: {
            bg: 'yoDark.500',
            display: 'flex',
            br: '4px',
            p: 2,
            flexDirection: 'column',
          },
          item: {
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            borderRadius: 3,
            _hover: {
              bg: 'yoBrand.500',
            },
            _focus: {
              bg: 'yoBrand.500',
            },
            _active: {
              bg: 'yoBrand.500',
            },
            mx: 0,
            px: 3,
            py: 2,
            fontWeight: 500,
          },
        },
      },
    },
    Button: {
      variants: {
        topBar: ({ theme }: VariantInput) => ({
          color: theme.colors.yoBrand?.[500],
          bg: 'transparent',
          display: 'flex',
          alignItems: 'center',
          m: 0,
          transition: 'color 0.3s ease-out, background-color 0.3s ease-out',
          rounded: 'none',
          _focus: {},
          _active: { bg: '#D9DDE3' },
          _hover: {
            bg: '#EBEEF1',
            color: theme.colors.yoBrand?.[800],
          },
        }),
        bottomBar: ({ theme, colorScheme }: VariantInput) => ({
          color: theme.colors[colorScheme]?.[500] || colorScheme,
          bg: 'transparent',
          display: 'flex',
          alignItems: 'center',
          m: 0,
          p: 1,
          height: { base: '40px', md: '45px' },
          transition: 'color 0.3s ease-out',
          rounded: 'none',
          _focus: {},
          _active: { bg: '#D9DDE3' },
          _hover: {
            bg: '#EBEEF1',
            color: theme.colors[colorScheme]?.[800] || colorScheme,
          },
        }),
        brand: ({ theme, colorScheme }: VariantInput) => ({
          bg: theme.colors.yoBrand?.[600] || colorScheme,
          color: 'white',
          transition: 'background 0.3s ease-out',
          _focus: {},
          _active: {},
          _hover: {
            bg: theme.colors.yoBrand?.[800] || colorScheme,
          },
        }),
        notifications: ({ theme, colorScheme }: VariantInput) => ({
          bg: theme.colors.taggleShinyBlue?.[500] || colorScheme,
          color: 'white',
          transition: 'background 0.3s ease-out',
          _focus: {},
          _active: {},
          _hover: {
            bg: theme.colors.taggleShinyBlue?.[700] || colorScheme,
          },
        }),
        noNotifications: ({ theme, colorScheme }: VariantInput) => ({
          bg: theme.colors.taggleGray?.[700] || colorScheme,
          color: 'white',
          transition: 'background 0.3s ease-out',
          _focus: {},
          _active: {},
          _hover: {
            bg: theme.colors.taggleGray?.[800] || colorScheme,
          },
        }),
      },
    },
    Drawer: {
      baseStyle: {
        closeButton: {
          mt: 6,
          mr: 4,
          fontSize: 'lg',
        },
        header: {
          pl: 5,
          mt: 4,
        },
      },
      variants: {
        nonBlocking: {
          parts: ['dialog, dialogContainer', 'header'],
          header: {},
          dialog: {
            pointerEvents: 'auto',
            pl: 3,
            maxWidth: '23rem', // custom size as overwriting sizes doesn't work
          },
          dialogContainer: {
            pointerEvents: 'none',
          },
        },
      },
    },
  },
};

const theme = extendTheme({
  ...yoTheme,
  styles: {
    global: {
      '.iframe-wrapper': {
        width: '500px',
        aspectRatio: '16/9',
        marginY: '1rem',
      },
      // All inputs must be atleast 16px in safari
      // so safari doesnt zoom in on the input
      '@supports (-webkit-touch-callout: none)' /* Safari */: {
        'select, textarea, input': {
          fontSize: '16px !important',
        },
      },
      '.emoji-mart-category-list': {
        'ul, li, ol': {
          margin: '0!important',
          padding: '0!important',
        },
        display: 'grid',
        gridTemplateColumns: 'repeat(9, 1fr)',
      },
      '[contenteditable]:focus': {
        outline: '0px solid transparent',
      },
      '.ProseMirror': {
        height: 'inherit',
        minHeight: 'inherit',
      },
      '.cardDetails': {
        a: {
          color: '#0000EE',
          cursor: 'pointer',
          _hover: { textDecoration: 'underline' },
          _visited: { color: '#551A8B' },
        },
        'code, mark': {
          px: 1,
          py: '0.25rem',
          borderRadius: 'sm',
        },
        'p:not(.reaction)': {
          // Empty <p> tags should be treated as <br> tags
          minH: '22px',
          my: '1rem',
        },
        code: { backgroundColor: 'gray.100' },
        mark: { backgroundColor: 'yoOrange.300' },
        '[data-mention]': {
          px: 1,
          py: '0.25rem',
          backgroundColor: 'gray.50',
          fontWeight: 'bold',
          color: yoTheme.colors.yoBrand[500],
          borderRadius: 'sm',
        },
        [`[data-mention].${CURRENT_USER_MENTION_CLASSNAME}`]: {
          backgroundColor: 'orange.100',
        },
        "ul[data-type='taskList']": {
          listStyle: 'none',
          padding: '0 !important',
          accentColor: yoTheme.colors.yoBrand[500],

          p: {
            margin: 0,
          },

          li: {
            marginY: '0.5rem',
            display: 'flex',
            '> label': {
              flex: '0 0 auto',
              marginRight: '0.5rem',
              userSelect: 'none',
              cursor: 'pointer',

              input: {
                cursor: 'pointer',
                marginRight: '0.5rem',
              },
            },

            '> div': {
              flex: '1 1 auto',
            },
          },
        },
        '.second-toolbar': {
          display: 'none',
        },
        'ul, ol': {
          '&:not(.emoji-mart-category-list):not([data-type="taskList"])': {
            margin: '1rem 0 1rem 1.5rem !important',
            paddingLeft: '0.5rem !important',
          },
          '&[data-type="taskList"]': {
            margin: '1rem 0 1rem 1.5rem !important',
          },
        },
        li: {
          '&:not(.emoji-mart-category-list li):not([data-checked])': {
            margin: '0.5rem 0 0.5rem 0.5rem !important',
            paddingLeft: '0.5rem !important',
          },
        },
        'ul:first-child, ul:last-child, ol:first-child, ol:last-child': {
          '&:not(.emoji-mart-category-list)': {
            marginLeft: '1.5rem !important',
            paddingLeft: '0.5rem !important',
          },
        },
      },
    },
  },
});

export default theme;
