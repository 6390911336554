import { useImage } from 'hooks/useImage';
import { KonvaEventObject } from 'konva/types/Node';
import {
  AttachmentsIcon,
  CheckListIcon,
  CommentsIcon,
  LinkIcon,
  NotificationsIcon,
} from 'lib/canvasIcons';
import { CardIntegrationColorPair } from 'lib/colors';
import { handleLeftDblClick } from 'lib/helpers';
import theme from 'lib/theme';
import { ReactNode, useMemo, useState } from 'react';
import { Circle, Group, Path, Rect, Text } from 'react-konva';
import { AppCursor } from 'store/app';
import { CheckLink } from 'shared';
import { BoardMode } from 'store/board/settings';
import { useStoreActions, useStoreState } from 'store/hooks';
import { BoardElementState } from 'types/index';
import { SHARED_STYLES, SHOW_DETAILS_NAME } from './index';
import {
  getCardIconPositions,
  trackShowDetailsIconClick,
  trackShowDetailsTextClick,
} from './CardFooter.helper';
import { ShowDetailsIcon } from './ShowDetailsIcons';

const CARD_LINK_WIDTH = 148;
const CARD_LINK_WIDTH_COLLAPSED = 40;
const CARD_LINK_TEXT = 'Show Details';
const IMAGE_SIZE = 200;
const IMAGE_SCALE = 0.14;

export const STYLES = {
  CARD_LINK_WIDTH: 148,
  CARD_LINK_WIDTH_COLLAPSED: 40,
  CARD_LINK_TEXT: 'Show Details',
  IMAGE_SIZE: 200,
  IMAGE_SCALE: 0.14,
};

interface CardImageProps {
  url: string;
  x: number;
  name: string;
}

type CardIconWithCountProps = {
  icon: ReactNode;
  x: number;
  y: number;
  fill: string;
  opacity: number;
} & ({ count: number; countText?: never } | { count?: never; countText: string });

const CardImage: React.FC<CardImageProps> = ({ url, x, name }) => {
  const [image] = useImage(url, name);
  return (
    <Circle
      height={SHARED_STYLES.assignedAvatarSize}
      fillPatternImage={image}
      fillPatternRepeat="no-repeat"
      fillPatternOffset={{ x: (IMAGE_SIZE * IMAGE_SCALE) / 2, y: (IMAGE_SIZE * IMAGE_SCALE) / 2 }}
      fillPatternScale={{ x: IMAGE_SCALE, y: IMAGE_SCALE }}
      radius={SHARED_STYLES.assignedAvatarSize / 2}
      x={x}
      y={SHARED_STYLES.assignedAvatarSize / 2}
    />
  );
};

const CardIconWithCount: React.FC<CardIconWithCountProps> = ({
  icon,
  count,
  countText,
  x,
  y,
  fill,
  opacity,
}) => (
  <>
    {icon}
    <Text
      listening={false}
      x={x}
      y={y}
      text={countText ?? count?.toString() ?? ''}
      fontFamily="Roboto"
      fontStyle="500"
      fontSize={14}
      opacity={opacity}
      fill={fill}
    />
  </>
);

interface Props {
  hasImage?: boolean;
  commentCount: number;
  cardHeight: number;
  attachmentCount: number;
  linkCount: number;
  isWatching: boolean;
  integrationColorPair: CardIntegrationColorPair;
  assignedAvatars: string[];
  checkLinks?: CheckLink[];
  state: string;
  onDblClick: (e: KonvaEventObject<MouseEvent> | KonvaEventObject<TouchEvent>) => void;
}

export function CardFooter({
  cardHeight,
  commentCount,
  hasImage = false,
  attachmentCount,
  isWatching,
  integrationColorPair,
  assignedAvatars,
  onDblClick,
  linkCount,
  checkLinks = [],
  state,
}: Props) {
  const [showDetailsHovered, setShowDetailsHovered] = useState(false);

  const mode = useStoreState((store) => store.boardSettings.mode);
  const avatars = useStoreState((store) => store.board.avatars);
  const setCursor = useStoreActions((actions) => actions.app.setCursor);

  const {
    showDetailsIconPosition,
    attachmentIcon,
    commentsIcon,
    isWatchingIcon,
    linkIcon,
    taskIcon,
    hasIcons,
  } = useMemo(
    () =>
      getCardIconPositions(
        commentCount,
        attachmentCount,
        isWatching,
        linkCount,
        checkLinks.length ?? 0
      ),
    [commentCount, attachmentCount, isWatching, linkCount, checkLinks.length]
  );

  const filteredAssignedAvatars = assignedAvatars?.slice(0, 3);

  const iconColor = integrationColorPair.icons ?? integrationColorPair.foreground;

  return (
    <>
      <Group x={0} y={cardHeight - SHARED_STYLES.footerHeight}>
        {hasImage && hasIcons && state !== BoardElementState.INEDIT && !showDetailsHovered && (
          <Rect
            width={SHARED_STYLES.width}
            height={SHARED_STYLES.footerHeight}
            fillLinearGradientStartPoint={{
              x: SHARED_STYLES.width / 2,
              y: SHARED_STYLES.footerHeight,
            }}
            fillLinearGradientEndPoint={{ x: SHARED_STYLES.width / 2, y: 0 }}
            fillLinearGradientColorStops={[0, 'rgba(0, 0, 0, 0.55)', 1, 'transparent']}
            cornerRadius={[0, 0, SHARED_STYLES.borderRadius, SHARED_STYLES.borderRadius]}
            listening={false}
          />
        )}
        {showDetailsHovered ? (
          <>
            <Rect
              width={CARD_LINK_WIDTH}
              height={SHARED_STYLES.footerHeight}
              cornerRadius={[0, 3, 0, 3]}
              fill={integrationColorPair.background}
              listening={false}
            />
            <Text
              text={CARD_LINK_TEXT}
              fill={integrationColorPair.foreground}
              fontFamily={theme.fonts.body}
              width={CARD_LINK_WIDTH}
              fontSize={14}
              fontWeight={700}
              lineHeight={SHARED_STYLES.assignedAvatarSize}
              height={SHARED_STYLES.assignedAvatarSize}
              fontStyle="bold"
              verticalAlign="middle"
              opacity={hasImage ? 1 : 0.7}
              x={CARD_LINK_WIDTH_COLLAPSED}
              y={8}
              listening={false}
            />
          </>
        ) : (
          <>
            <Rect
              width={CARD_LINK_WIDTH_COLLAPSED}
              height={SHARED_STYLES.footerHeight}
              fill={integrationColorPair.background}
              cornerRadius={[0, 3, 0, 3]}
              listening={false}
            />
            {attachmentIcon != null && (
              <CardIconWithCount
                count={attachmentCount ?? 0}
                icon={
                  <Path
                    x={attachmentIcon.iconPosition}
                    y={12}
                    {...AttachmentsIcon(integrationColorPair.foreground, hasImage ? 1 : 0.7)}
                  />
                }
                opacity={hasImage ? 1 : 0.7}
                x={attachmentIcon.iconPosition + 20}
                y={15}
                fill={integrationColorPair.foreground}
              />
            )}
            {commentsIcon != null && (
              <CardIconWithCount
                count={commentCount}
                icon={
                  <Path
                    x={commentsIcon.iconPosition}
                    y={10}
                    {...CommentsIcon(iconColor, hasImage ? 1 : 0.7)}
                  />
                }
                opacity={hasImage ? 1 : 0.7}
                x={commentsIcon.countPosition}
                y={15}
                fill={iconColor}
              />
            )}
            {linkIcon != null && (
              <CardIconWithCount
                fill={iconColor}
                count={linkCount}
                x={linkIcon.countPosition}
                y={14}
                opacity={hasImage ? 1 : 0.7}
                icon={
                  <Path
                    x={linkIcon.iconPosition}
                    y={15}
                    {...LinkIcon(iconColor, hasImage ? 1 : 0.7)}
                  />
                }
              />
            )}
            {isWatchingIcon != null && (
              <Path
                listening={false}
                x={isWatchingIcon.iconPosition}
                y={12}
                {...NotificationsIcon(iconColor, hasImage ? 1 : 0.7)}
              />
            )}
            {taskIcon != null && (
              <CardIconWithCount
                countText={`${checkLinks.filter((t) => t.data === true).length}/${
                  checkLinks.length
                }`}
                icon={
                  <CheckListIcon
                    color={iconColor}
                    y={11}
                    opacity={hasImage ? 1 : 0.7}
                    x={taskIcon.iconPosition}
                  />
                }
                opacity={hasImage ? 1 : 0.7}
                x={taskIcon.countPosition - 3}
                y={15}
                fill={iconColor}
              />
            )}
            {attachmentIcon != null && (
              <Path
                listening={false}
                x={attachmentIcon.iconPosition}
                y={12}
                {...AttachmentsIcon(iconColor, hasImage ? 1 : 0.7)}
              />
            )}
          </>
        )}
        <Group
          name={SHOW_DETAILS_NAME}
          onMouseOver={() => {
            if (!showDetailsHovered) {
              setShowDetailsHovered(true);
              setCursor(AppCursor.POINTER);
            }
          }}
          onMouseOut={() => {
            if (showDetailsHovered) {
              setShowDetailsHovered(false);
              setCursor(mode === BoardMode.MOVE ? AppCursor.CURSOR : AppCursor.DEFAULT);
            }
          }}
        >
          <Rect
            name={SHOW_DETAILS_NAME}
            width={CARD_LINK_WIDTH}
            height={SHARED_STYLES.footerHeight}
            onClick={trackShowDetailsTextClick}
          />
          <ShowDetailsIcon
            y={13}
            x={showDetailsIconPosition}
            foregroundColor={integrationColorPair.foreground}
            hasImage={hasImage}
            onClick={trackShowDetailsIconClick}
          />
        </Group>
      </Group>
      <Group
        onDblClick={(evt) => handleLeftDblClick(evt, onDblClick)}
        onDblTap={(evt) => handleLeftDblClick(evt as KonvaEventObject<TouchEvent>, onDblClick)}
        y={cardHeight - SHARED_STYLES.footerHeight + 6}
        listening={false}
      >
        {filteredAssignedAvatars
          ?.map((avatar: string, i: number) => {
            const currentAvatar = avatars.find((stateAvatar) => stateAvatar.id === avatar);
            return (
              <CardImage
                key={avatar}
                url={currentAvatar?.url as any}
                name={currentAvatar?.name as any}
                x={
                  SHARED_STYLES.width +
                  6 -
                  0.2 * SHARED_STYLES.assignedAvatarSize -
                  0.8 * SHARED_STYLES.assignedAvatarSize * (filteredAssignedAvatars?.length - i)
                }
              />
            );
          })
          .reverse()}
      </Group>
    </>
  );
}
