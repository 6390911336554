/* eslint-disable import/prefer-default-export */
import { useToast } from '@chakra-ui/react';
import { useState, useCallback, useEffect, useMemo } from 'react';
import fetchClient from 'lib/Fetch';
import { useStoreActions, useStoreState } from 'store/hooks';
import { AttachmentDTO } from 'shared';
import { useParam } from './useParam';

export function uploadAttachment({
  boardId,
  cardId,
  file,
}: {
  file: File;
  cardId: string;
  boardId: string;
}) {
  const body = new FormData();
  body.append('file', file);

  return fetchClient<AttachmentDTO>(`boards/${boardId}/cards/${cardId}/attachments`, {
    method: 'POST',
    body,
    sendFiles: true,
  });
}

export function useAttachments({
  cardId,
  loadInstantly = true,
  isArchived = false,
}: {
  cardId: string;
  loadInstantly?: boolean;
  isArchived?: boolean;
}) {
  const boardElements = useStoreState((state) => state.board.boardElements);
  const archivedElements = useStoreState((state) => state.board.archivedElements);
  const attachments = useMemo<AttachmentDTO[]>(
    () =>
      (isArchived ? archivedElements : boardElements).find(
        (currentElement) => currentElement.id === cardId
      )?.attachments || [],
    [archivedElements, boardElements, cardId, isArchived]
  );

  const { getCardDetails } = useStoreActions((actions) => actions.board);
  const [isLoading, setIsLoading] = useState(false);
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const toast = useToast();
  const boardId = useParam('boardId');

  const deleteAttachment = useCallback(
    (attachmentId: string) =>
      fetchClient(`boards/${boardId}/attachments/${attachmentId}`, {
        method: 'DELETE',
      })
        .then(async () => {
          toast({
            status: 'success',
            position: 'bottom',
            title: 'The attachment has been deleted successfully',
          });
          await getCardDetails({ id: cardId });
        })
        .catch(() => {
          toast({
            status: 'error',
            position: 'bottom',
            title: 'Could not delete attachment',
          });
        }),
    [boardId, cardId, getCardDetails, toast]
  );

  const addAttachment = useCallback(
    async (file: File) => {
      if (!boardId) return;
      setIsUploadingFile(true);
      await uploadAttachment({ boardId, cardId, file })
        .then(async () => {
          setIsUploadingFile(false);
          await getCardDetails({ id: cardId });
          toast({
            status: 'success',
            title: 'The file has been uploaded successfully',
            position: 'bottom',
          });
        })
        .catch((errorMessage: string) => {
          setIsUploadingFile(false);
          toast({
            status: 'error',
            title: errorMessage || 'The file could not be uploaded',
            position: 'bottom',
          });
        });
    },
    [boardId, cardId, getCardDetails, toast]
  );

  const refetch = useCallback(() => getCardDetails({ id: cardId }), [cardId, getCardDetails]);

  useEffect(() => {
    if (cardId != null && boardId != null && loadInstantly) {
      setIsLoading(true);
      refetch().then(() => {
        setIsLoading(false);
      });
    }
  }, [cardId, boardId, loadInstantly, getCardDetails]);

  return {
    isLoading,
    isUploadingFile,
    attachments,
    deleteAttachment,
    addAttachment,
    refetch,
  };
}
