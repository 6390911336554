/* eslint-disable no-param-reassign */
import { Action, action, Thunk, thunk } from 'easy-peasy';
import produce from 'immer';
import { EventTypes } from 'shared';
import fetchClient from 'lib/Fetch';
import sortBoardElementsByRanking, { sortBoardElementsByZIndex } from 'lib/helpers';
import { BoardElementState, UIBoardElement } from 'types/index';
import { PublicStoreModel } from '..';
import { ClientElementEvent, ServerElementEvent } from '../../ServerEvent/ServerEvent';

function applyEvent(event: ClientElementEvent | ServerElementEvent, elements: UIBoardElement[]) {
  let newElements: UIBoardElement[] = [];
  switch (event.type) {
    case EventTypes.ADD: {
      newElements = produce(elements, (draft) => {
        draft.push({
          ...event.newValues,
          id: event.elementId,
          state: BoardElementState.DEFAULT,
        });
      });
      break;
    }
    case EventTypes.UPDATE: {
      const { shape, ...rest } = event.newValues ?? {};
      const index = elements.findIndex((element) => element.id === event.elementId);
      if (index > -1) {
        newElements = produce(elements, (draft) => {
          draft[index] = {
            ...draft[index],
            shape: {
              ...draft[index].shape,
              ...shape,
            },
            ...rest,
          };
        });
      }
      break;
    }
    case EventTypes.REMOVE: {
      const index = elements.findIndex((element) => element.id === event.elementId);
      if (index > -1) {
        newElements = produce(elements, (draft) => {
          draft.splice(index, 1);
        });
      }
      break;
    }
    default: {
      throw new Error('Unknown Event');
    }
  }

  return newElements;
}

function applyBoardEvents(events: ClientElementEvent[] | ServerElementEvent[]) {
  let newElements: UIBoardElement[] = [];
  events.forEach((currentEvent) => {
    switch (currentEvent.type) {
      case EventTypes.BULK: {
        currentEvent.events.forEach((event) => {
          newElements = applyEvent(event, newElements);
        });
        break;
      }
      default:
        newElements = applyEvent(currentEvent, newElements);
        break;
    }
  });
  return newElements;
}

export interface PublicTimeTravelStoreModel {
  timeTravelBoardElements: UIBoardElement[];
  events: ServerElementEvent[];
  setEvents: Action<TimeTravelStoreModel, ServerElementEvent[]>;
  getEvents: Thunk<TimeTravelStoreModel, void, null, PublicStoreModel>;
  applyEventsToId: Thunk<TimeTravelStoreModel, string, null, PublicStoreModel>;
  resetTimeTravelElements: Action<TimeTravelStoreModel>;
}

export interface TimeTravelStoreModel extends PublicTimeTravelStoreModel {
  setTimeTravelElements: Action<TimeTravelStoreModel, UIBoardElement[]>;
  resetElements: Action<TimeTravelStoreModel>;
}

const timeTravelStore: TimeTravelStoreModel = {
  timeTravelBoardElements: [],
  events: [],
  setTimeTravelElements: action((state, payload) => {
    state.timeTravelBoardElements = payload;
  }),
  getEvents: thunk(async (actions, _, { getStoreState }) => {
    const events = await fetchClient<ServerElementEvent[]>(
      `events/${getStoreState().board.boardId}`
    );
    actions.setEvents(events);
  }),
  setEvents: action((state, payload) => {
    state.events = payload;
  }),
  resetTimeTravelElements: action((state) => {
    state.timeTravelBoardElements = [];
  }),
  applyEventsToId: thunk((actions, eventId, { getStoreState }) => {
    const { events } = getStoreState().board;
    const eventIndex = events.findIndex((event) => event._id === eventId);
    if (eventIndex > -1) {
      const eventRange = events.slice(0, eventIndex + 1);
      actions.resetElements();
      const elements = [...applyBoardEvents(eventRange)];
      actions.setTimeTravelElements(
        sortBoardElementsByZIndex(sortBoardElementsByRanking(elements))
      );
    }
  }),
  resetElements: action((state) => {
    state.timeTravelBoardElements = [];
  }),
};

export default timeTravelStore;
