/* eslint-disable no-param-reassign */
import { Action, action, Thunk, thunk } from 'easy-peasy';
import * as Events from 'lib/Events';
import {
  isBoardElementHighlighted,
  sortBoardElementsByRanking,
  transformElementsForStore,
} from 'lib/helpers';
import fetchClient from 'lib/Fetch';
import { BoardElement, EventTypes, Avatar, CardDetailDTO, IdTuple, BoardElementType } from 'shared';
import { UIUser } from 'store/user';
import { UIBoardElement, BoardElementState, BoardElementPayload } from 'types/index';
import { areAllColumnsAndSwimlanesInBack, moveAllColumnsAndSwimlanesBehind } from 'lib/zIndex';
import {
  resetUrlAfterBoardElementStateChange,
  updateUrlToBoardElementStateChange,
} from 'lib/urlUtils';
import { SortedColumnUpdater } from 'src/sortedColumn/SortedColumnUpdater';
import { PublicStoreModel } from '../index';
import boardHistoryStore, { BoardHistoryStoreModel } from './history';
import { commentsStore, CommentsStoreModel } from './comments';
import BoardEventFactory from './boardEventFactory';
import timeTravelStore, { PublicTimeTravelStoreModel } from './timeTravel';
import elementsCRUDStore, { ElementsCRUDModel } from './elements/crud';
import { ClipboardModel, clipboardStore } from './clipboard';
import { eventsStore, EventsModel } from './events';
import { ComputedElementsModel, computedElementsStore } from './elements/computed';
import { ClientElementEvent } from '../../ServerEvent/ServerEvent';
import { TEMP_ID_PREFIX } from './elements/add';
/**
 * This Interface holds all public available actions and
 * thunks which can be accessed using the typed hooks
 */
export interface PublicBoardStoreModel
  extends BoardHistoryStoreModel,
    PublicTimeTravelStoreModel,
    ElementsCRUDModel,
    EventsModel,
    ComputedElementsModel,
    ClipboardModel,
    CommentsStoreModel {
  boardElements: UIBoardElement[];
  archivedElements: UIBoardElement[];
  name: string;
  avatars: Avatar[];
  users: UIUser[];
  boardId: string;
  setElementState: Thunk<
    BoardStoreModel,
    Pick<UIBoardElement, 'id' | 'state'>,
    null,
    PublicStoreModel
  >;
  setBoardName: Action<BoardStoreModel, string>;
  updateBoardName: Thunk<BoardStoreModel, string, null, PublicStoreModel>;
  duplicateBoard: Thunk<BoardStoreModel, null, null, PublicStoreModel>;
  getBoardData: Thunk<BoardStoreModel, boolean | void, null, PublicStoreModel>;
  getBoardName: Thunk<BoardStoreModel, null, null, PublicStoreModel>;
  getAvatars: Thunk<BoardStoreModel, string, null, PublicStoreModel>;
  getUsers: Thunk<BoardStoreModel, string, null, PublicStoreModel>;
  getCardDetails: Thunk<
    BoardStoreModel,
    { id: string; isArchived?: boolean },
    null,
    PublicStoreModel
  >;
  getArchivedElements: Thunk<BoardStoreModel, string, null, PublicStoreModel>;
  decrementCommentCount: Action<BoardStoreModel, { boardElementId: string }>;
  incrementCommentCount: Action<BoardStoreModel, { boardElementId: string }>;
  resetOrdering: Thunk<BoardStoreModel, null, null, PublicStoreModel>;
  resetElementsState: Thunk<BoardStoreModel, void, null, PublicStoreModel>;
  resetElementsStateAction: Action<BoardStoreModel>;
  setBoardId: Action<BoardStoreModel, string>;
  selectElements: Thunk<BoardStoreModel, string[], null, PublicStoreModel>;
  selectElementsAction: Action<BoardStoreModel, string[]>;
  toggleSelectedElement: Thunk<BoardStoreModel, string, null, PublicStoreModel>;
  setBoardAction: Action<BoardStoreModel, UIBoardElement[]>;
  setArchivedElementsAction: Action<BoardStoreModel, UIBoardElement[]>;
  hasDraggedElement: boolean;
  setHasDraggedElement: Action<BoardStoreModel, boolean>;
  setCardHeight: Thunk<
    BoardStoreModel,
    Pick<UIBoardElement, 'id' | 'state'> & { height: number },
    null,
    PublicStoreModel
  >;
}

/**
 * This interface holds all private available actions and thunks
 */
export interface BoardStoreModel extends PublicBoardStoreModel {
  mergeDetailsWithElement: Action<BoardStoreModel, { detail: CardDetailDTO; isArchived?: boolean }>;
  updateIdsWithObjectIds: Action<BoardStoreModel, IdTuple | Array<IdTuple>>;
  setAvatarAction: Action<BoardStoreModel, Avatar[]>;
  setUsersAction: Action<BoardStoreModel, UIUser[]>;
  setElementStateAction: Action<BoardStoreModel, Pick<UIBoardElement, 'id' | 'state'>>;
  setCardHeightAction: Action<BoardStoreModel, { elementIndex: number; height: number }>;
}

// TODO: try to avoid abstractions if not useful
// TODO: every action should live in a dedicated file and have a dedicated test file close by.
// TODO: every action should perform a clear transition from state A to state B
const boardModel: BoardStoreModel = {
  boardElements: [],
  archivedElements: [],
  avatars: [],
  users: [],
  name: undefined!,
  hasDraggedElement: false,
  boardId: process.env.NEXT_PUBLIC_TEST_BOARD_ID!,
  setBoardId: action((state, payload) => {
    state.boardId = payload;
  }),
  setBoardAction: action((state, payload) => {
    state.boardElements = payload ?? [];
  }),
  setAvatarAction: action((state, payload) => {
    state.avatars = payload;
  }),
  setBoardName: action((state, payload) => {
    state.name = payload;
  }),
  getAvatars: thunk(async (actions, boardId, { getStoreActions }) => {
    // on initial render the boardId is not set in store, you need to pass it via payload
    const avatars = await fetchClient<Avatar[]>(
      `boards/${boardId}/avatars`,
      null,
      getStoreActions().errors.addError,
      boardId
    );
    actions.setAvatarAction(avatars);
  }),
  setUsersAction: action((state, payload) => {
    state.users = payload;
  }),
  getUsers: thunk(async (actions, boardId, { getStoreActions }) => {
    // on initial render the boardId is not set in store, you need to pass it via payload
    const users = await fetchClient<UIUser[]>(
      `boards/${boardId}/users`,
      null,
      getStoreActions().errors.addError,
      boardId
    );
    actions.setUsersAction(users);
  }),
  getBoardData: thunk(async (actions, isPrerender = false, { getStoreState, getStoreActions }) => {
    const { boardId } = getStoreState().board;
    const boardData = await fetchClient<{
      name: string;
      elements: BoardElement[];
      avatars: Avatar[];
    }>(
      `boards/${boardId}${isPrerender ? '?prerender=true' : ''}`,
      null,
      getStoreActions().errors.addError,
      boardId
    );
    if (boardData) {
      const transformedElementsForStore = transformElementsForStore(boardData.elements);
      actions.setBoardAction(transformedElementsForStore);
      if (!areAllColumnsAndSwimlanesInBack(transformedElementsForStore)) {
        // columns and other elements appear to be mixed up -> fix
        const fixedBoardElements = moveAllColumnsAndSwimlanesBehind(transformedElementsForStore);
        await actions.updateElement(fixedBoardElements);
      }
      actions.setAvatarAction(boardData.avatars);
      actions.setBoardName(boardData.name);
    }
  }),
  getArchivedElements: thunk(async (actions, boardId, { getStoreActions }) => {
    const boardData = await fetchClient<UIBoardElement[]>(
      `boards/${boardId}/archived`,
      null,
      getStoreActions().errors.addError,
      boardId
    );
    actions.setArchivedElementsAction(
      boardData.map((element) => ({
        ...element,
        state: BoardElementState.DEFAULT,
      }))
    );
  }),
  setArchivedElementsAction: action((state, payload) => {
    state.archivedElements = payload.filter(Boolean) ?? [];
  }),
  getBoardName: thunk(async (actions, _, { getStoreState, getStoreActions }) => {
    const { boardId } = getStoreState().board;
    const { name } = await fetchClient<{ name: string }>(
      `boards/${boardId}/name`,
      null,
      getStoreActions().errors.addError,
      boardId
    );
    if (name) {
      actions.setBoardName(name);
    }
  }),
  updateBoardName: thunk(async (actions, payload: string, { getState, getStoreActions }) => {
    const { name, boardId } = getState();
    if (payload !== '' && payload !== name) {
      try {
        await fetchClient(
          `boards/${boardId}/name`,
          {
            method: 'PATCH',
            body: { newName: payload },
          },
          getStoreActions().errors.addError
        );

        actions.setBoardName(payload);
        return { didUpdate: true, oldName: name, newName: payload };
      } catch (e) {
        getStoreActions().errors.addError((e as any).message);
      }
    }
    return { didUpdate: false };
  }),
  duplicateBoard: thunk(async (actions, payload, { getState, getStoreActions }) => {
    const { boardId } = getState();
    const { getUser } = getStoreActions().user;
    await fetchClient(`boards/${boardId}`, {
      method: 'POST',
      body: {},
    });
    await getUser();
  }),
  getCardDetails: thunk(
    async (actions, { id: cardId, isArchived }, { getStoreState, getStoreActions }) => {
      const { boardId } = getStoreState().board;
      const cardDetails = await fetchClient<CardDetailDTO>(
        `boards/${boardId}/${cardId}/details`,
        null,
        getStoreActions().errors.addError,
        boardId
      );

      if (cardDetails) {
        actions.mergeDetailsWithElement({ detail: cardDetails, isArchived });
      }
    }
  ),
  decrementCommentCount: action((state, { boardElementId }) => {
    const correlatingIndex = state.boardElements.findIndex(
      (currentElement) => currentElement.id === boardElementId
    );
    if (correlatingIndex === -1) {
      return;
    }
    state.boardElements[correlatingIndex].commentCount! -= 1;
  }),
  incrementCommentCount: action((state, { boardElementId }) => {
    const correlatingIndex = state.boardElements.findIndex(
      (currentElement) => currentElement.id === boardElementId
    );
    if (correlatingIndex === -1) {
      return;
    }
    state.boardElements[correlatingIndex].commentCount! += 1;
  }),
  mergeDetailsWithElement: action((state, { detail, isArchived }) => {
    const { content, metrics, history, comments, attachments } = detail;
    if (isArchived) {
      const index = state.archivedElements.findIndex(
        (currentElement) => currentElement.id === detail.cardId
      );
      state.archivedElements[index] = {
        ...state.archivedElements[index],
        content,
        metrics,
        history,
        hasDetails: true,
        commentCount: comments?.length || 0,
        attachmentCount: attachments?.length || 0,
        ...(!!comments && { comments }),
        ...(!!attachments && { attachments }),
      };
    } else {
      const index = state.boardElements.findIndex(
        (currentElement) => currentElement.id === detail.cardId
      );
      state.boardElements[index] = {
        ...state.boardElements[index],
        content,
        metrics,
        history,
        hasDetails: true,
        commentCount: comments?.length || 0,
        attachmentCount: attachments?.length || 0,
        ...(!!comments && { comments }),
        ...(!!attachments && { attachments }),
      };

      const clipboardIndex = state.clipboard.findIndex(
        (clipboardElement) => clipboardElement.id === detail.cardId
      );
      if (clipboardIndex > -1) {
        state.clipboard[clipboardIndex] = {
          ...state.clipboard[clipboardIndex],
          content,
          metrics,
          history,
          hasDetails: true,
          commentCount: comments?.length || 0,
          attachmentCount: attachments?.length || 0,
          ...(!!comments && { comments }),
          ...(!!attachments && { attachments }),
        };
      }
    }
  }),
  setElementStateAction: action((state, { id, state: newState }) => {
    state.boardElements = state.boardElements.map((element) => ({
      ...element,
      state: element.id === id ? newState : BoardElementState.DEFAULT,
    }));
  }),
  setElementState: thunk((actions, element, { getStoreState, getStoreActions }) => {
    actions.setElementStateAction(element);

    const { boardElements, boardId } = getStoreState().board;
    const elementIndex = boardElements.findIndex((el) => el.id === element.id);
    if (elementIndex > -1) {
      getStoreActions().widgets.spawnWidget(boardElements[elementIndex]);
    }

    updateUrlToBoardElementStateChange(boardId!, element.id, element.state);
  }),
  resetElementsState: thunk((actions, _, { getStoreState }) => {
    actions.resetElementsStateAction();

    const { boardId } = getStoreState().board;

    resetUrlAfterBoardElementStateChange(boardId!);
  }),
  resetElementsStateAction: action((state) => {
    if (state.boardElements.some((element) => element.state !== BoardElementState.DEFAULT)) {
      state.boardElements.forEach((element) => {
        if (element.state !== BoardElementState.DEFAULT) {
          element.state = BoardElementState.DEFAULT;
        }
      });
    }
  }),
  selectElementsAction: action((state, selectedElementIds) => {
    state.boardElements = state.boardElements.map((element) => {
      const newElement = { ...element };
      if (selectedElementIds.includes(element.id)) {
        newElement.state =
          selectedElementIds.length === 1 ? BoardElementState.FOCUSED : BoardElementState.SELECTED;
      } else {
        newElement.state = BoardElementState.DEFAULT;
      }
      return newElement;
    });
  }),
  selectElements: thunk((actions, selectedElementIds, { getStoreState }) => {
    const { boardElements, boardId } = getStoreState().board;

    // if only one element is selected, it will be focused instead, otherwise all items
    // will be selected. => URL needs to be modified/reset accordingly
    if (selectedElementIds.length === 1) {
      const element = boardElements.find(
        (boardElement) => boardElement.id === selectedElementIds[0]
      );
      updateUrlToBoardElementStateChange(boardId!, element!.id, BoardElementState.FOCUSED);
    } else {
      resetUrlAfterBoardElementStateChange(boardId!);
    }

    actions.selectElementsAction(selectedElementIds);
  }),

  toggleSelectedElement: thunk((actions, elementId, { getStoreState }) => {
    const { boardElements, selectedBoardElements } = getStoreState().board;
    const element = boardElements.find((boardElement) => boardElement.id === elementId);
    if (!element || element.isLocked) {
      return;
    }

    const getColumnChildsIds = (columnId: string) =>
      boardElements
        .filter((ele) => ele.type === BoardElementType.CARD && ele.parentColumnId === columnId)
        .map((child) => child.id);

    if (selectedBoardElements.length >= 1) {
      // there are already selected elements, so we need to update the selected elements'
      if (element.state === BoardElementState.SELECTED) {
        // if current element is already selected -> remove it from selectedElements array'
        let newSelectedBoardElements: UIBoardElement[] = [];
        if (element.type === BoardElementType.COLUMN) {
          // find children of column
          const childrenIds = getColumnChildsIds(elementId);
          newSelectedBoardElements = selectedBoardElements.filter(
            (selectedBoardElement) =>
              selectedBoardElement.id !== elementId &&
              !childrenIds.includes(selectedBoardElement.id)
          );
        } else {
          newSelectedBoardElements = selectedBoardElements.filter(
            (selectedBoardElement) => selectedBoardElement.id !== elementId
          );
        }
        actions.selectElementsAction(
          newSelectedBoardElements.map((newSelectedBoardElement) => newSelectedBoardElement.id)
        );
        return;
      }
      // current element is not selected so we need to update the selected elements
      let childrenIds: string[] = [];
      if (element.type === BoardElementType.COLUMN) {
        // check for cards in the columnm
        childrenIds = getColumnChildsIds(elementId);
      }
      actions.selectElementsAction(
        [
          ...selectedBoardElements.map((selectedBoardElement) => selectedBoardElement.id),
          element.id,
        ].concat(childrenIds)
      );
      return;
    }
    // no currently selected elements
    if (isBoardElementHighlighted(element)) {
      // current element is somehow highlighted, so we need to reset the state
      actions.setElementStateAction({ id: element.id, state: BoardElementState.DEFAULT });
      return;
    }
    // all focussed or resizable elements (should always be only one by design)
    // but we exclude locked elements here
    const hasFocussedOrResizableBoardElement = boardElements.filter(
      (boardElement) => !boardElement.isLocked && isBoardElementHighlighted(boardElement)
    );
    if (hasFocussedOrResizableBoardElement.length === 1) {
      // there is another hightlighted, unlocked element -> make both elements the new selected elements'
      const highlightedElement = hasFocussedOrResizableBoardElement[0];
      const childrenIdsSelected =
        element.type === BoardElementType.COLUMN ? getColumnChildsIds(element.id) : [];
      const childrenIdsHighlighted =
        highlightedElement.type === BoardElementType.COLUMN
          ? getColumnChildsIds(highlightedElement.id)
          : [];
      actions.selectElementsAction(
        [elementId, hasFocussedOrResizableBoardElement[0].id]
          .concat(childrenIdsSelected)
          .concat(childrenIdsHighlighted)
      );
      return;
    }
    // there is no focussed element -> make current element the new selected element'
    actions.selectElementsAction([elementId]);
  }),
  resetOrdering: thunk((actions, _, { getStoreState, getStoreActions }) => {
    const { currentAction, boardElements, boardId } = getStoreState().board;
    const updateEvents = sortBoardElementsByRanking(boardElements).map((element, index) => {
      const updatePayLoad: BoardElementPayload = { id: element.id, zIndex: index };
      actions.updateElementAction(updatePayLoad);
      return BoardEventFactory.createEvent(
        EventTypes.UPDATE,
        updatePayLoad.id,
        boardElements[index],
        updatePayLoad
      );
    });
    const bulkEvent = BoardEventFactory.createBulkEvent(updateEvents);

    const res = Events.postEvent(bulkEvent, boardId!, (err: string) => {
      getStoreActions().board.unapplyEvent({ payload: bulkEvent, fetch: false });
      getStoreActions().errors.addError(err);
    }) as any;

    if (res) {
      if (currentAction) {
        actions.pushEventToPast(currentAction);
      }
      // build a new branch from currentState -> clear stored futureActions
      actions.setCurrentAction(bulkEvent);
      actions.clearFutureActions();
    }
  }),
  updateIdsWithObjectIds: action((state, payload) => {
    const newElements = [...state.boardElements];
    let futureActions = [...state.futureActions];
    let pastActions = [...state.pastActions];
    let currentAction = { ...state.currentAction };

    const updates: Array<IdTuple> = [].concat(payload as any);
    updates.forEach((update) => {
      const elementIndex = newElements.findIndex((element) => element.id === update.oldId);
      if (elementIndex > -1) {
        newElements[elementIndex].id = update.newId!;
      }

      // also update futureActions, currentAction and pastActions with new ids because otherwise a series of
      // undoing and redoing would result in a crash because stale references to old boardElements
      // cannot be reapplied anymore
      const replaceOldIdsInStoreEvent = (storeEvent: ClientElementEvent) => {
        if (storeEvent.type === EventTypes.BULK) {
          const newEvent = {
            ...storeEvent,
            ...(!!storeEvent.events && {
              events: storeEvent.events.map((bulkSubEvent) => ({
                ...bulkSubEvent,
                ...(bulkSubEvent.elementId != null && {
                  elementId:
                    bulkSubEvent.elementId === update.oldId ? update.newId : bulkSubEvent.elementId,
                }),
                ...(!!bulkSubEvent.oldValues && {
                  oldValues: {
                    ...bulkSubEvent.oldValues,
                    ...(bulkSubEvent.oldValues?.id != null && {
                      id:
                        bulkSubEvent.oldValues?.id === update.oldId
                          ? update.newId
                          : bulkSubEvent.oldValues?.id,
                    }),
                  },
                }),
              })),
            }),
          };
          return newEvent as ClientElementEvent;
        }

        return {
          ...storeEvent,
          elementId: storeEvent.elementId === update.oldId ? update.newId : storeEvent.elementId,
        } as ClientElementEvent;
      };

      futureActions = futureActions.map(replaceOldIdsInStoreEvent);
      pastActions = pastActions.map(replaceOldIdsInStoreEvent);
      currentAction = replaceOldIdsInStoreEvent(currentAction as any);
    });

    state.futureActions = futureActions;
    state.pastActions = pastActions;
    state.currentAction = currentAction as any;
    state.boardElements = newElements;
  }),
  setHasDraggedElement: action((state, hasDraggedElement) => {
    state.hasDraggedElement = hasDraggedElement;
  }),
  setCardHeightAction: action((state, { elementIndex, height }) => {
    state.boardElements[elementIndex].shape.height = height;
  }),
  setCardHeight: thunk(async (actions, payload, { getStoreState, getStoreActions }) => {
    const { boardElements, boardId } = getStoreState().board;

    const itemIndex = boardElements.findIndex((el) => el.id === payload.id);
    if (itemIndex !== -1) {
      actions.setCardHeightAction({
        elementIndex: itemIndex,
        height: payload.height,
      });

      // update the the containing column
      const updateBulkEvent = BoardEventFactory.createBulkEvent(
        SortedColumnUpdater.renderAndUpdateStateByElements({
          elements: [boardElements[itemIndex]],
        })
      );
      // temp ids should not be included in an update event
      if (boardElements[itemIndex].id.includes(TEMP_ID_PREFIX)) {
        return;
      }
      if (boardId) {
        // and send the reordering events to the server & the other clients
        await Events.postEvent(updateBulkEvent, boardId, (err: string) => {
          getStoreActions().board.unapplyEvent({ payload: updateBulkEvent, fetch: false });
          getStoreActions().errors.addError(err);
        });
      }
    }
  }),
  ...boardHistoryStore,
  ...timeTravelStore,
  ...elementsCRUDStore,
  ...clipboardStore,
  ...eventsStore,
  ...computedElementsStore,
  ...commentsStore,
};

export default boardModel;
