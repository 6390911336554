import { DiscordIcon } from 'lib/icons';
import { Box, Button, Text } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { useStoreActions, useStoreState } from 'store/hooks';
import { HideableSectionState } from 'store/app';

export const DISCORD_INVITE_URL = 'https://discord.gg/eJG8TkWugP';

const DiscordButton = () => {
  const discordButton = useStoreState((store) => store.app.discordButton);
  const hideDiscordButton = useStoreActions((actions) => actions.app.hideDiscordButton);
  return (discordButton === HideableSectionState.VISIBLE && (
    <Box w="218px" mt="12" display={{ base: 'none', md: 'block' }}>
      <Button
        as="a"
        variant="solid"
        fontWeight="bold"
        color="#5865F2"
        background="yoGray.300"
        leftIcon={<DiscordIcon fontSize="2xl" />}
        w="100%"
        href={DISCORD_INVITE_URL}
        target="_blank"
      >
        Join our Discord
      </Button>
      <Text fontSize="sm" mt="4">
        Give feedback, request new features and get help with modelling your kanban process!
      </Text>
      <Button
        mt="3"
        fontSize="sm"
        color="yoBrand.600"
        variant="link"
        leftIcon={<CloseIcon fontSize="10px" mt="-1" ml="1" />}
        onClick={() => hideDiscordButton()}
      >
        Hide this message
      </Button>
    </Box>
  )) as any;
};

export default DiscordButton;
