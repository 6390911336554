import { BoardElementType, assertUnreachable } from 'shared';
import { useStoreState } from 'store/hooks';
import { useMemo } from 'react';
import { UIBoardElement } from 'types/index';
import { getDragModel } from 'src/drag/DragHandlerManager';

function groupElementsByType(elements: UIBoardElement[]) {
  const swimlanesAndColumns: UIBoardElement[] = [];
  const cards: UIBoardElement[] = [];
  const stickyNotes: UIBoardElement[] = [];
  const draggedElements: UIBoardElement[] = [];

  const draggedElemId = getDragModel()?.element.id;

  elements.forEach((element) => {
    if (
      draggedElemId &&
      (element.id === draggedElemId || element.parentColumnId === draggedElemId)
    ) {
      return draggedElements.push(element);
    }
    switch (element.type) {
      case BoardElementType.CARD: {
        return cards.push(element);
      }
      case BoardElementType.COLUMN: {
        return swimlanesAndColumns.push(element);
      }
      case BoardElementType.SWIMLANE: {
        return swimlanesAndColumns.push(element);
      }
      case BoardElementType.STICKY: {
        return stickyNotes.push(element);
      }
      default: {
        return assertUnreachable(element.type);
      }
    }
  });
  return { swimlanesAndColumns, cards, stickyNotes, draggedElements };
}

export function useBoardElements() {
  const defaultBoardElements = useStoreState((state) => state.board.defaultBoardElements);
  const dragModel = getDragModel(); // changes on start and end of dragging
  return useMemo(
    () => groupElementsByType(defaultBoardElements),
    // we need to recompute the elements when the drag model changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [defaultBoardElements, dragModel]
  );
}

export function useSelectedBoardElements() {
  const selectedBoardElements = useStoreState((state) => state.board.selectedBoardElements);
  return useMemo(() => {
    const elements = groupElementsByType(selectedBoardElements);
    return {
      selectedElements: selectedBoardElements,
      hasSelectedElements: selectedBoardElements.length > 0,
      selectedSwimlanesAndColumns: elements.swimlanesAndColumns,
      selectedCards: elements.cards,
      selectedStickyNotes: elements.stickyNotes,
    };
  }, [selectedBoardElements]);
}
