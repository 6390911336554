/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
import { EventTypes, IdTuple, PostEventInfo, BoardElementType } from 'shared';
import produce, { original } from 'immer';
import { NotCopyableBoardElementProperties, UIBoardElement } from 'types/index';
import { exchangeLinkIdsForPaste } from 'lib/CheckLinks.helper';
import nonUpdatingStore from 'store/index';
import logger from 'lib/logger';
import equal from 'fast-deep-equal';
import { ClientElementEvent, ClientSingleElementEvent } from '../ServerEvent/ServerEvent';
import fetchClient from './Fetch';

export function transformEventForApi(
  event: ClientElementEvent
): ClientElementEvent | ClientSingleElementEvent {
  switch (event.type) {
    case EventTypes.ADD:
    case EventTypes.REMOVE:
    case EventTypes.UPDATE: {
      return produce(event, (draft) => {
        // remove properties which don't belong to the backend
        /// @ts-expect-error it's fine to remove them because they are not used in the backend
        delete (draft?.newValues as UIBoardElement)?.state;
        /// @ts-expect-error it's fine to remove them because they are not used in the backend
        delete (draft?.oldValues as UIBoardElement)?.state;

        if (event.type !== EventTypes.ADD) {
          delete (draft?.newValues as UIBoardElement)?.history;
          delete (draft?.oldValues as UIBoardElement)?.history;
        }

        delete (draft?.newValues as UIBoardElement)?.hasDetails;
        delete (draft?.oldValues as UIBoardElement)?.hasDetails;
        if (!draft.oldValues) {
          draft.oldValues = null;
        }
      });
    }
    case EventTypes.BULK: {
      return {
        ...event,
        events: event.events.map(transformEventForApi) as ClientSingleElementEvent[],
      };
    }
    default: {
      throw new Error('Invalid EventType');
    }
  }
}

export function postEvent(
  event: ClientElementEvent,
  boardId: string,
  errorHandler: (message: string) => void,
  info?: PostEventInfo
): Promise<Array<IdTuple>> {
  const transformedEvent = transformEventForApi(event);

  // check if we have an invalid event and filter it
  const isValidEvent = (e: ClientElementEvent) => {
    if (e.type === EventTypes.UPDATE && equal(e.oldValues, e.newValues)) {
      logger.warn(
        `Events.postEvent() received update event with equal oldValues and newValues. There is sth wrong in the code, please fix it.`,
        event
      );
      return false;
    }
    return true;
  };
  if (transformedEvent.type === EventTypes.BULK) {
    transformedEvent.events = transformedEvent.events.filter(isValidEvent);
    if (transformedEvent.events.length === 0) {
      logger.warn(
        `Events.postEvent() received empty bulk event. There is sth wrong in the code, please fix it.`,
        event
      );
      return Promise.resolve([]);
    }
  }
  if (!isValidEvent(transformedEvent)) {
    return Promise.resolve([]);
  }

  // send event to server
  return fetchClient(
    `boards/${boardId}`,
    {
      method: 'PATCH',
      body: {
        event: transformedEvent,
        ...(info != null && { info }),
      },
    },
    errorHandler,
    boardId
  );
}

const PASTE_OFFSET = 30;

export async function pasteElements(timesPasted = 1) {
  const { getCardDetails, resetElementsState, _addElement } = nonUpdatingStore.getActions().board;
  const { closeContextMenu } = nonUpdatingStore.getActions().widgets;
  // get currentClipboard on each call to prevent recreating the function
  // each time the clipboard changes
  const currentClipboard = nonUpdatingStore.getState().board.clipboard;
  if (currentClipboard.length === 0) return;

  // load details for all items in the clipboard to ensure description is copied along if the card
  // hasn't been opened before copying it to the clipboard
  await Promise.all(
    currentClipboard
      .filter((el) => el.type === BoardElementType.CARD)
      .map(async (copiedItem) => getCardDetails({ id: copiedItem.id }))
  );

  // get clipboard again, otherwise the enriched details from getCardDetails are not there
  const currentClipboardWithDetails = nonUpdatingStore.getState().board.clipboard;

  const newElements = produce(currentClipboardWithDetails, (draft) => {
    draft.forEach((copiedElement) => {
      copiedElement.shape.x += +PASTE_OFFSET * timesPasted;
      copiedElement.shape.y += +PASTE_OFFSET * timesPasted;

      if (copiedElement.links) {
        const nonProxyCopiedElement = original(copiedElement);
        if (!nonProxyCopiedElement)
          throw new Error('pasteElements(): Copied Element is not a proxy');
        const { content, links } = exchangeLinkIdsForPaste(nonProxyCopiedElement);

        if (links.length === 0) {
          // if there are no links to copy just delete them
          delete copiedElement.links;
        } else {
          copiedElement.links = links;
          copiedElement.content = content;
        }
      }

      // The stuff from the card details shouldn't be copied
      NotCopyableBoardElementProperties.forEach((propertyKey) => {
        delete copiedElement[propertyKey];
      });
    });
  });

  resetElementsState();
  _addElement({ payload: newElements, selectAfterAdd: true });

  closeContextMenu();
}
