import React, { memo } from 'react';
import { Layer, Line } from 'react-konva';
import { useStoreState } from 'store/hooks';
import theme from 'lib/theme';
import useWindowDimensions from 'hooks/useWindowDimensions';

const GRID_SIZE = 200;

const GRID_LINE_STROKE_WIDTH = 2;
const GRID_LINE_COLOR = theme.colors.yoGray[400]; // same as Column border

/**
 * The Background component draws a additional layer on the canvas.
 * The layer displays a background grid that scales and panes together with the board.
 */
const Background: React.FC = () => {
  const stagePos = useStoreState((state) => state.boardSettings.stageOffset);
  const scale = useStoreState((state) => state.boardSettings.zoomLevel);
  const { height: windowHeight, width: windowWidth } = useWindowDimensions();

  const startX = Math.floor((-stagePos.x - windowWidth / scale) / GRID_SIZE) * GRID_SIZE;
  const endX = Math.floor((-stagePos.x + (windowWidth * 2) / scale) / GRID_SIZE) * GRID_SIZE;
  const startY = Math.floor((-stagePos.y - windowHeight / scale) / GRID_SIZE) * GRID_SIZE;
  const endY = Math.floor((-stagePos.y + (windowHeight * 2) / scale) / GRID_SIZE) * GRID_SIZE;

  const gridLinesPoints = [];
  for (let x = startX; x < endX; x += GRID_SIZE) {
    gridLinesPoints.push([x, startY, x, endY]);
  }
  for (let y = startY; y < endY; y += GRID_SIZE) {
    gridLinesPoints.push([startX, y, endX, y]);
  }

  return (
    <Layer listening={false}>
      {gridLinesPoints.map((gridLinePoints, i) => (
        <Line
          transformsEnabled="position"
          listening={false}
          perfectDrawEnabled={false}
          id={`bg-${i}`}
          points={gridLinePoints}
          key={gridLinePoints.join(',')}
          stroke={GRID_LINE_COLOR}
          strokeWidth={GRID_LINE_STROKE_WIDTH}
          hitStrokeWidth={0}
        />
      ))}
    </Layer>
  );
};

export default memo(Background);
