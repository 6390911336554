import { useStoreState, useStoreActions } from 'store/hooks';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import globalStore, { nonUpdatingStore } from 'store/index';
import { BoardMode } from 'store/board/settings';
import { BoardElementState, UIBoardElement } from 'types/index';
import {
  getNextZoomIncrement,
  getStage,
  resetZoom,
  zoomAndFocusElement,
  zoomStage,
} from 'lib/zoom';
import { usePlausible } from 'next-plausible';
import { CLIENT_PLAUSIBLE_EVENTS } from 'lib/Types';
import { BoardElementType } from 'shared';

import { getVisualLinks } from 'lib/Links.helper';
import { pasteElements } from 'lib/Events';
import { interruptDragging } from 'src/drag/DragHandlerManager';
import { useAssignUser } from './useAssignUser';
import { isCtrlOrMetaKey } from '../lib/helpers';
import { isRegisteredShortcut, ShortCutsKeys, ShortCutsWithCtrlKeys } from '../lib/Shortcuts';

const isEditingText = () =>
  ['textarea', 'input'].includes(document.activeElement?.tagName.toLowerCase() ?? '');

export function useGlobalKeyHandlers() {
  const spaceKey = 'Space';
  const isAnyDetailViewOpen = useStoreState((state) => state.widgets.hasDetailView);
  const hasElementsInEditState = useStoreState((state) => state.board.hasElementsInEditState);
  const currentMode = useStoreState((state) => state.boardSettings.mode);
  const setMode = useStoreActions((state) => state.boardSettings.setMode);

  const isSpaceUpRef = useRef(true);
  const lastModeRef = useRef<BoardMode>(currentMode);

  useEffect(() => {
    const handleSpaceUp = (e: KeyboardEvent) => {
      if (
        e.code === spaceKey &&
        currentMode !== BoardMode.TIMETRAVEL &&
        !hasElementsInEditState &&
        !isAnyDetailViewOpen &&
        lastModeRef.current === BoardMode.MOVE &&
        !globalStore.getState().board.hasDraggedElement
      ) {
        setMode(BoardMode.MOVE);
      }
      isSpaceUpRef.current = true;
    };
    const handleContextMenu = (e: MouseEvent) => {
      /**
       * We want to show the native context menu
       * if the user is in a detail view or if
       * the user is editing a card
       */
      if (!isAnyDetailViewOpen && !hasElementsInEditState) {
        e.preventDefault();
      }
    };
    const handleKeyDown = (e: KeyboardEvent) => {
      if (
        e.code === spaceKey &&
        currentMode !== BoardMode.HAND &&
        currentMode !== BoardMode.TIMETRAVEL &&
        isSpaceUpRef.current &&
        !hasElementsInEditState &&
        !isAnyDetailViewOpen &&
        !globalStore.getState().board.hasDraggedElement
      ) {
        lastModeRef.current = currentMode;
        setMode(BoardMode.HAND);
      }
      isSpaceUpRef.current = false;
    };

    window.addEventListener('contextmenu', handleContextMenu);
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleSpaceUp);
    return () => {
      window.removeEventListener('contextmenu', handleContextMenu);
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleSpaceUp);
    };
  }, [currentMode, hasElementsInEditState, isAnyDetailViewOpen, setMode]);
}

export function useKeyboardShortcuts() {
  const timesPastedRef = useRef(1);
  const isAnyDetailViewOpen = useStoreState((state) => state.widgets.hasDetailView);
  const { removeElements, undo, redo } = useStoreActions((actions) => actions.board);
  const plausible = usePlausible();
  const toggleBoardSearch = useStoreActions((state) => state.app.toggleBoardSearch);
  const { setMode, setZoomLevelAndOffset } = useStoreActions((actions) => actions.boardSettings);
  const canRedo = useStoreState((state) => state.board.canRedo);
  const canUndo = useStoreState((state) => state.board.canUndo);
  const floatingBoardElement = useStoreState((state) => state.app.floatingBoardElement);
  const setFloatingBoardElement = useStoreActions((actions) => actions.app.setFloatingBoardElement);

  const handleAssign = useAssignUser();

  const getActiveElements = useCallback(
    () =>
      nonUpdatingStore
        .getState()
        .board.boardElements.filter(
          (element) =>
            element.state === BoardElementState.SELECTED ||
            element.state === BoardElementState.RESIZABLE ||
            element.state === BoardElementState.FOCUSED
        ),
    []
  );
  const { setClipboard } = useStoreActions((actions) => actions.board);

  /**
   * This map is used to register the handlers for the keyboard shortcuts WITH cmd / ctrl
   * if you want to add a handler here you first need to register the shortcut in the shortcuts list
   */
  const shortcutFunctionMapWithCtrl = useMemo(() => {
    const shortcutMap: Record<ShortCutsWithCtrlKeys, (e: KeyboardEvent) => void> = {
      c: () => {
        if (isAnyDetailViewOpen) return;
        const activeElements = getActiveElements();
        if (activeElements.length > 0) {
          navigator.clipboard.writeText('');
          setClipboard(activeElements.map((element) => element.id));
          timesPastedRef.current = 0;
        }
      },
      v: () => {
        if (isAnyDetailViewOpen) return;
        timesPastedRef.current += 1;
        pasteElements(timesPastedRef.current);
      },
      d: (e) => {
        if (isAnyDetailViewOpen) return;
        e.preventDefault();
        const activeElements = getActiveElements();
        if (activeElements.length > 0) {
          navigator.clipboard.writeText('');
          setClipboard(activeElements.map((element) => element.id));
          timesPastedRef.current += 1;
          pasteElements(timesPastedRef.current);
        }
      },
      x: () => {
        if (isAnyDetailViewOpen) return;
        const activeElements = getActiveElements();
        if (activeElements.length > 0) {
          const workingElementIds = activeElements.map((element) => element.id);
          setClipboard(workingElementIds);
          removeElements(workingElementIds);
        }
      },
      z: () => {
        if (isAnyDetailViewOpen) return;
        if (canUndo) {
          undo();
        }
      },
      y: () => {
        if (isAnyDetailViewOpen) return;
        if (canRedo) {
          redo();
        }
      },
      '+': (e) => {
        if (isAnyDetailViewOpen) return;
        const stage = getStage();
        if (stage) {
          e.preventDefault();
          zoomStage({
            stage,
            setZoomLevelAndOffset,
            zoomIn: true,
            addToScale: getNextZoomIncrement(stage.scaleX()),
          });
        }
      },
      '-': (e) => {
        if (isAnyDetailViewOpen) return;
        const stage = getStage();
        if (stage) {
          e.preventDefault();
          zoomStage({
            stage,
            setZoomLevelAndOffset,
            zoomIn: false,
            addToScale: getNextZoomIncrement(stage.scaleX()),
          });
        }
      },
      '0': (e) => {
        if (isAnyDetailViewOpen) return;
        const stage = getStage();
        e.preventDefault();
        if (stage) {
          resetZoom({ stage, setZoomLevelAndOffset });
        }
      },
      k: (e) => {
        if (isAnyDetailViewOpen) return;
        e.preventDefault();
        plausible(CLIENT_PLAUSIBLE_EVENTS.OPEN_SEARCH, { props: { source: 'Shortcut' } });
        toggleBoardSearch();
      },
    };
    return shortcutMap;
  }, [
    canRedo,
    canUndo,
    getActiveElements,
    isAnyDetailViewOpen,
    plausible,
    redo,
    removeElements,
    setClipboard,
    setZoomLevelAndOffset,
    toggleBoardSearch,
    undo,
  ]);

  /**
   * This map is used to register the handlers for the keyboard shortcuts WITHOUT cmd / ctrl
   * if you want to add a handler here you first need to register the shortcut in the shortcuts list
   */
  const shortcutFunctionMap = useMemo(() => {
    const shortcutMap: Record<ShortCutsKeys, (e: KeyboardEvent) => void> = {
      a: (e) => {
        if (isAnyDetailViewOpen) return;
        if (isEditingText()) return;
        const activeElements = getActiveElements();
        if (activeElements.length !== 1) return;
        e.preventDefault();

        handleAssign(activeElements[0]);
      },
      c: (e) => {
        if (isAnyDetailViewOpen) return;
        e.preventDefault();
        setFloatingBoardElement({
          type: BoardElementType.CARD,
        });
      },
      n: (e) => {
        if (isAnyDetailViewOpen) return;
        e.preventDefault();
        setFloatingBoardElement({
          type: BoardElementType.STICKY,
        });
      },
      l: (e) => {
        if (isAnyDetailViewOpen) return;
        e.preventDefault();
        setFloatingBoardElement({
          type: BoardElementType.COLUMN,
          meta: { isSorted: true },
        });
      },
      u: (e) => {
        if (isAnyDetailViewOpen) return;
        e.preventDefault();
        setFloatingBoardElement({
          type: BoardElementType.COLUMN,
        });
      },
      s: (e) => {
        if (isAnyDetailViewOpen) return;
        e.preventDefault();
        setFloatingBoardElement({
          type: BoardElementType.SWIMLANE,
        });
      },
      '+': (e) => {
        if (isAnyDetailViewOpen) return;
        const stage = getStage();
        if (stage) {
          e.preventDefault();
          zoomStage({
            stage,
            setZoomLevelAndOffset,
            zoomIn: true,
            addToScale: getNextZoomIncrement(stage.scaleX()),
          });
        }
      },
      '-': (e) => {
        if (isAnyDetailViewOpen) return;
        const stage = getStage();
        if (stage) {
          e.preventDefault();
          zoomStage({
            stage,
            setZoomLevelAndOffset,
            zoomIn: false,
            addToScale: getNextZoomIncrement(stage.scaleX()),
          });
        }
      },

      v: () => {
        if (isAnyDetailViewOpen) return;
        if (isEditingText()) return;
        nonUpdatingStore.getActions().boardSettings.toggleMode();
      },
      h: () => {
        if (isAnyDetailViewOpen) return;
        if (isEditingText()) return;
        nonUpdatingStore.getActions().boardSettings.toggleMode();
      },
      Backspace: (e) => {
        if (isAnyDetailViewOpen) return;
        if (!isEditingText()) {
          e.preventDefault();
        }
        const activeElements = getActiveElements();
        removeElements(activeElements.map((element) => element.id));
      },
      Delete: (e) => {
        if (isAnyDetailViewOpen) return;
        if (!isEditingText()) {
          e.preventDefault();
        }
        const activeElements = getActiveElements();
        removeElements(activeElements.map((element) => element.id));
      },
      '?': (e) => {
        if (isAnyDetailViewOpen) return;
        if (isEditingText()) return;
        e.preventDefault();
        globalStore
          .getActions()
          .app.setIsShortcutPopoverOpen(!globalStore.getState().app.isShortcutPopoverOpen);
      },
      Tab: (e) => {
        if (isAnyDetailViewOpen) return;
        const { activeLinkCard } = globalStore.getState().app;
        if (!activeLinkCard) {
          return;
        }

        const tabIndex = globalStore.getState().app.activeLinkTabIndex;
        // Shift + Tab means go to the previous tab
        const newTabIndex = tabIndex + (e.shiftKey ? -1 : 1);

        globalStore.getActions().app.setActiveLinkTabIndex(newTabIndex);

        const tabbableCards = [activeLinkCard.id].concat(
          getVisualLinks(activeLinkCard.links).map((link) => link.targetElement.id)
        );

        // if we only have one card to tab to (the initial card)
        // we don't need to do anything
        if (tabbableCards.length <= 1) return;

        const tabbedCard = globalStore
          .getState()
          .board.boardElements.find(
            (el) => el.id === tabbableCards[newTabIndex % tabbableCards.length]
          );

        if (!tabbedCard) return;

        e.preventDefault();
        zoomAndFocusElement(tabbedCard);
      },
      Escape: () => {
        if (isAnyDetailViewOpen) return;
        if (floatingBoardElement) {
          interruptDragging();
          setFloatingBoardElement(null);
        }
      },
      Shift: () => {
        if (isAnyDetailViewOpen) return;
        if (!isEditingText()) {
          setMode(BoardMode.MOVE);
        }
      },
      r: (e) => {
        if (isAnyDetailViewOpen) return;
        if (isEditingText()) return;
        e.preventDefault();
        let activeElement: UIBoardElement | null = null;

        const elementsWithDetailView = globalStore
          .getState()
          .board.boardElements.filter((el) => el.state === BoardElementState.INDETAIL);
        const activeElements = getActiveElements();
        if (elementsWithDetailView.length === 1) {
          [activeElement] = elementsWithDetailView;
        } else if (activeElements.length === 1) {
          [activeElement] = activeElements;
        }

        if (activeElement?.type !== BoardElementType.CARD) {
          return;
        }

        globalStore.getActions().board.toggleWatchCard({ cardId: activeElement.id });
      },
    };
    return shortcutMap;
  }, [
    floatingBoardElement,
    getActiveElements,
    handleAssign,
    isAnyDetailViewOpen,
    removeElements,
    setFloatingBoardElement,
    setMode,
    setZoomLevelAndOffset,
  ]);

  const handleKeyWithCtrl = useCallback(
    (e: KeyboardEvent) => {
      if (globalStore.getState().app.isShortcutPopoverOpen) return;
      const { key } = e;
      if (isRegisteredShortcut(shortcutFunctionMapWithCtrl, key)) {
        shortcutFunctionMapWithCtrl[key](e);
      }
    },
    [shortcutFunctionMapWithCtrl]
  );

  const handleKey = useCallback(
    (e: KeyboardEvent) => {
      if (globalStore.getState().app.isShortcutPopoverOpen) return;
      const { key } = e;
      if (isRegisteredShortcut(shortcutFunctionMap, key)) {
        shortcutFunctionMap[key](e);
      }
    },
    [shortcutFunctionMap]
  );

  useEffect(() => {
    // We don't want the user to zoom into the DOM elements
    // which are over the canvas because it makes the canvas blurry.
    const handleWheel = (event: WheelEvent) => {
      if (event.metaKey || event.ctrlKey) {
        event.preventDefault();
      }
    };

    // prevent pinch to zoom on mobile
    const handleTouchMove = (event: TouchEvent) => {
      /// @ts-expect-error typings are falsy
      if (event.scale !== 1) {
        event.preventDefault();
      }
    };

    const handleKeyDown = (e: KeyboardEvent) => {
      if (!isEditingText()) {
        if (isCtrlOrMetaKey(e)) {
          handleKeyWithCtrl(e);
        } else {
          handleKey(e);
        }
      }
    };

    window.addEventListener('wheel', handleWheel, { passive: false });
    window.addEventListener('touchmove', handleTouchMove, { passive: false });
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('wheel', handleWheel);
      window.removeEventListener('touchmove', handleTouchMove);
    };
  }, [handleKey, handleKeyWithCtrl]);
}
