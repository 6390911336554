import {
  Box,
  Button,
  ButtonGroup,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  VStack,
  Editable,
  EditablePreview,
  useTheme,
  EditableInput,
  Skeleton,
} from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import { getPreviewImage } from 'hooks/usePreviewImage';
import fetchClient from 'lib/Fetch';
import { DotsIcon, FrameIcon } from 'lib/icons';
import { animateZoomTo, getStage } from 'lib/zoom';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { Position } from 'store/app';
import { useStoreActions, useStoreState } from 'store/hooks';
import { v4 as generateId } from 'uuid';

const NEW_BOOKMARK_TITLE = 'New Bookmark';

type Bookmark = {
  _id: string;
  name: string;
  offset: Position;
  zoomLevel: number;
  boardId: string;
  createdAt: string;
};

type BookmarkItemProps = Bookmark & {
  editMode?: boolean;
  onUpdate: (id: string) => void;
  onTitleEditCancel: (id: string) => void;
  previewUrl: string;
};

const MotionButtonGroup = motion(ButtonGroup);

const BookmarkItem: React.FC<BookmarkItemProps> = ({
  _id,
  offset,
  zoomLevel,
  name,
  editMode,
  onUpdate,
  onTitleEditCancel,
  previewUrl,
}) => {
  const theme = useTheme();
  const { setOffset, setZoomLevel } = useStoreActions((state) => state.boardSettings);
  const [editableName, setName] = useState(name);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.select?.();
  }, []);
  return (
    <>
      <Editable
        fontSize="lg"
        w="100%"
        color="yoGray.500"
        fontWeight={700}
        defaultValue={editableName}
        onSubmit={async (newName) => {
          setName(newName);
          await fetchClient(`user/bookmarks/${_id}`, {
            method: 'PUT',
            body: {
              name: newName,
            },
          });

          onUpdate(_id);
        }}
        onCancel={() => onTitleEditCancel(_id)}
        submitOnBlur={false}
        placeholder="Enter Title..."
        selectAllOnFocus={false}
        startWithEditView={editMode}
        mb={4}
        position="relative"
      >
        {({ isEditing, onCancel, onSubmit }) => (
          <>
            <Skeleton isLoaded={!!previewUrl} width="300px">
              <EditablePreview
                w="100%"
                _hover={{ backgroundColor: theme.colors.yoGray[200] }}
                _focus={{ backgroundColor: theme.colors.yoGray[200] }}
                borderRadius="sm"
                pl={2}
                ml={-2}
                onMouseDown={() => {
                  // setIsEditing(true);
                }}
              />
            </Skeleton>
            <EditableInput
              borderWidth="1px"
              _focus={{ borderColor: 'yoGray.border' }}
              borderColor="yoGray.border"
              mb={3}
              pl={2}
              ml={-2}
              ref={inputRef}
            />
            <AnimatePresence>
              {isEditing ? (
                <MotionButtonGroup
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: 'auto' }}
                  exit={{ transition: { duration: 0 } }}
                >
                  <Button colorScheme="yoBrand" onClick={() => onSubmit()}>
                    Save
                  </Button>
                  <Button
                    colorScheme="yoBrand"
                    variant="ghost"
                    _hover={{ bg: 'none' }}
                    _active={{ bg: 'none' }}
                    onClick={() => onCancel()}
                  >
                    Cancel
                  </Button>
                </MotionButtonGroup>
              ) : null}
            </AnimatePresence>
          </>
        )}
      </Editable>
      <Skeleton isLoaded={!!previewUrl}>
        <Box
          width="300px"
          height="200px"
          rounded="sm"
          bg="yoGray.300"
          justifyContent="flex-end"
          alignItems="center"
          pos="relative"
          cursor="pointer"
          display="flex"
          ml={-1}
          boxShadow="layer3.css"
          style={{
            backgroundImage: `url(${previewUrl})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
          }}
          onClick={() => {
            const stage = getStage();
            if (stage) {
              animateZoomTo({
                setOffset,
                setZoomLevel,
                stage,
                position: offset,
                zoomLevel,
              });
            }
          }}
        />
      </Skeleton>
      {!!previewUrl && (
        <Box justifyContent="flex-end" display="flex" mt={0} width="100%">
          <Menu>
            <MenuButton
              as={IconButton}
              icon={<DotsIcon />}
              aria-label="more"
              variant="ghost"
              fontSize="lg"
              mr={-2}
              mt={2}
            />
            <MenuList>
              <MenuItem
                onClick={async () => {
                  await fetchClient(`user/bookmarks/${_id}`, {
                    method: 'DELETE',
                    body: {},
                  });
                  onUpdate(_id);
                }}
              >
                Delete
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      )}
    </>
  );
};

const SKELETON = Array.from({ length: 4 }, (_, i) => ({ _id: i.toString() } as BookmarkItemProps));

const BookmarksDrawer: React.FC = () => {
  const showBookmarks = useStoreState((state) => state.boardSettings.showBookmarks);
  const stageOffset = useStoreState((state) => state.boardSettings.stageOffset);
  const zoomLevel = useStoreState((state) => state.boardSettings.zoomLevel);
  const router = useRouter();
  const setShowBookmarks = useStoreActions((state) => state.boardSettings.setShowBookmarks);
  const [bookmarks, setBookmarks] = useState<BookmarkItemProps[]>(SKELETON);
  const [newestInEditMode, setNewestInEditMode] = useState<boolean>(false);

  const fetchBookmarks = async (handleEditMode?: boolean) => {
    const result = await fetchClient<Bookmark[]>(
      `user/bookmarks/${router.query.boardId as string}`
    );
    const stage = getStage();
    if (handleEditMode) {
      setNewestInEditMode(true);
    }
    if (!stage) return;

    const newBookmarks = result.map((bookmark) => {
      const div = document.createElement('div');
      const previewUrl = getPreviewImage(stage, div, bookmark.offset, bookmark.zoomLevel);
      div.remove();
      return {
        ...bookmark,
        previewUrl,
      } as BookmarkItemProps;
    });
    setBookmarks(newBookmarks);
  };

  useEffect(() => {
    if (showBookmarks) {
      setBookmarks(SKELETON);

      /**
       * To prevent the drawer from being stuck when opening
       * we need to wait for the opening  animation to be finished
       * before we actually load the bookmarks
       */
      setTimeout(() => {
        fetchBookmarks();
      }, 500);
    }
  }, [showBookmarks]);

  const createNewBookmark = async () => {
    const newBookmark = {
      zoomLevel,
      name: NEW_BOOKMARK_TITLE,
      offset: stageOffset,
    } as BookmarkItemProps;
    const stage = getStage();
    if (!stage) return;
    const div = document.createElement('div');
    const previewUrl = getPreviewImage(stage, div, stageOffset, zoomLevel);
    div.remove();

    const tempId = generateId();

    setBookmarks((cur) => [
      {
        ...newBookmark,
        _id: tempId,
        previewUrl,
      },
      ...cur,
    ]);

    setNewestInEditMode(true);

    // we need to get the last item from the returned bookmarks array (which is the item
    // we just added). Then we have to update the old id that has been generated on the client
    // with the one from the server to assure subsequent request (like directly updating the name)
    // will be executed correctly
    const lastAddedBookmark = (
      await fetchClient<Bookmark[]>(`user/bookmarks`, {
        method: 'POST',
        body: {
          ...newBookmark,
          boardId: router.query.boardId as string,
        },
      })
    ).pop();

    if (lastAddedBookmark) {
      setBookmarks((cur) =>
        cur.map((bookmark) => ({
          ...bookmark,
          ...(bookmark._id === tempId && { _id: lastAddedBookmark._id }),
        }))
      );
    }
  };

  const closeBtnRef = useRef(null);
  const createBtnRef = useRef(null);
  return (
    <Drawer
      isOpen={showBookmarks}
      placement="left"
      closeOnOverlayClick={false}
      size="custom"
      onClose={() => setShowBookmarks(false)}
      finalFocusRef={closeBtnRef}
      initialFocusRef={createBtnRef}
      variant="nonBlocking"
    >
      <DrawerContent shadow="dark-lg">
        <DrawerCloseButton variant="ghost" ref={closeBtnRef} mr={6} />
        <DrawerHeader color="yoBrand.500">My Bookmarks</DrawerHeader>
        <DrawerBody>
          <VStack spacing={4} mb={10} alignItems="flex-start">
            <Button
              ref={createBtnRef}
              mt={2}
              mb={4}
              w="auto"
              colorScheme="yoBrand"
              onClick={createNewBookmark}
            >
              <FrameIcon fontSize="xl" mr={2} /> Bookmark current Position
            </Button>
            {bookmarks?.map((bookmark, index) => (
              <BookmarkItem
                key={bookmark._id}
                editMode={index === 0 && newestInEditMode}
                {...bookmark}
                onUpdate={() => {
                  setNewestInEditMode(false);
                  fetchBookmarks();
                }}
                onTitleEditCancel={() => {
                  setNewestInEditMode(false);
                }}
              />
            ))}
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default BookmarksDrawer;
