import { Key } from 'ts-key-enum';

/**
 * The definition of a shortcut in Taggle
 */
export type Shortcut = {
  /** Used for the shortcut help */
  text: string;

  /** Whether the shortcut needs cmd or control to work (will be inferred) */
  hasCmdOrCtrl?: boolean;

  /** The aliases of this shortcut */
  alias?: string[];

  /** if this command is a alias we should hide it in the UI */
  isHidden?: boolean;
};

/**
 * Typeguard to check whether the key of the event belongs to a shortcut
 * @param shortcutFunctionMap the function lookup
 * @param key the pressed key
 * @returns whether the key belongs to a shortcut
 */
export function isRegisteredShortcut<A extends string>(
  shortcutFunctionMap: Record<A, unknown>,
  key: any
): key is A {
  return typeof key === 'string' && key in shortcutFunctionMap;
}

/**
 * Helper function to generate an object with the keys of the given object
 * but where all values must be of type `Shortcut`
 * @param shortcutMap the object to generate the keys for
 * @returns the typed maps of shortcuts
 */
function createShortcutMap<T>(shortcutMap: { [K in keyof T]: Shortcut }) {
  return Object.freeze(shortcutMap);
}

/**
 * This is a list of all shortcuts that can be used in the app WITHOUT cmd or ctrl
 */
export const shortcuts = createShortcutMap({
  a: {
    text: 'Assign yourself',
  },
  c: {
    text: 'Create a Card',
  },
  n: {
    text: 'Create a Note',
  },
  l: {
    text: 'Create a Sorted Column',
  },
  u: {
    text: 'Create a Unsorted Column',
  },
  s: {
    text: 'Create a Swimlane',
  },
  v: {
    text: 'Toggle mode',
    alias: ['h'],
  },
  h: {
    text: 'Toggle mode',
    isHidden: true,
  },
  [Key.Delete]: {
    text: 'Delete Element(s)',
    alias: ['backspace'],
  },
  [Key.Backspace]: {
    text: 'Delete Element(s)',
    isHidden: true,
  },
  [Key.Shift]: {
    text: 'Enter Work Mode',
  },
  '?': {
    text: 'Open this dialog',
  },
  '+': {
    text: 'Zoom In',
  },
  '-': {
    text: 'Zoom Out',
  },
  [Key.Tab]: {
    text: 'Move to next linked element',
  },
  [Key.Escape]: {
    text: 'Stop creating element',
  },
  r: {
    text: 'Watch / Unwatch the current card',
  },
});

/**
 * This is a list of all shortcuts that can be used in the app WITH cmd or ctrl
 */
export const shortcutsWithCtrl = createShortcutMap({
  c: {
    text: 'Copy Element(s)',
  },
  v: {
    text: 'Paste Element(s)',
  },
  d: {
    text: 'Duplicate Element(s)',
  },
  x: {
    text: 'Cut Element(s)',
  },
  z: {
    text: 'Undo',
  },
  y: {
    text: 'Redo',
  },
  k: {
    text: 'Open Search',
  },
  '+': {
    text: 'Zoom In (higher increment)',
  },
  '-': {
    text: 'Zoom Out (higher increment)',
  },
  '0': {
    text: 'Zoom to 100%',
  },
});

export type ShortCutsWithCtrlKeys = keyof typeof shortcutsWithCtrl;
export type ShortCutsKeys = keyof typeof shortcuts;
