import { useState, KeyboardEvent } from 'react';
import { Input as ChakraInput } from '@chakra-ui/react';
import { useStoreActions, useStoreState } from 'store/hooks';
import { useFocusOnMountRef } from 'hooks/index';
import { BoardElementState, UIBoardElement } from 'types/index';
import { Key } from 'ts-key-enum';
import { Rectangle } from 'shared';

type WidgetStyle = { amount: number; unit: string };

interface InputProps {
  id: string;
  lineHeight?: number;
  refId: string;
  fontSize?: number;
  shape?: Partial<Rectangle>;
  widgetPy?: WidgetStyle;
  widgetPx?: WidgetStyle;
  textAlign?: string;
  verticalAlign?: string;
}

const Input: React.FC<InputProps> = ({
  id,
  shape: { x, y, height, width },
  refId,
  fontSize,
  lineHeight,
  verticalAlign,
  widgetPx,
  widgetPy,
  textAlign = 'right',
  ...props
}: any) => {
  const { title, color } = useStoreState(
    (store) => store.board.boardElements.find((e) => e.id === refId) ?? ({} as UIBoardElement)
  );
  const [val, setVal] = useState(title || '');
  const zoomLevel = useStoreState((state) => state.boardSettings.zoomLevel);
  const removeItem = useStoreActions((actions) => actions.widgets.removeWidget);
  const { updateElement, setElementState } = useStoreActions((actions) => actions.board);
  const inputRef = useFocusOnMountRef();

  const handleExit = (shouldFocus = false) => {
    if (val) {
      if (shouldFocus) {
        setElementState({ id, state: BoardElementState.DEFAULT });
      }
      updateElement({ id: refId, title: val });
    }
    removeItem(id);
  };

  return (
    <ChakraInput
      ref={inputRef}
      position="absolute"
      top={y}
      left={x}
      width={width ? width * zoomLevel : 'auto'}
      height={height ? height * zoomLevel : 'auto'}
      lineHeight={lineHeight ? lineHeight * zoomLevel : 'auto'}
      value={val}
      _focus={{ border: 'none' }}
      onChange={(e: any) => setVal(e.target.value)}
      onBlur={() => handleExit()}
      onKeyDownCapture={(e: KeyboardEvent) => e.stopPropagation()}
      fontSize={fontSize ? fontSize * zoomLevel : 'auto'}
      onKeyDown={(e: KeyboardEvent<HTMLElement>) => {
        if (e.key === Key.Escape) {
          handleExit(true);
        }
      }}
      px={widgetPx ? `${widgetPx.amount * zoomLevel}${widgetPx.unit}` : null}
      py={widgetPy ? `${widgetPy.amount * zoomLevel}${widgetPy.unit}` : null}
      backgroundColor={color || '#fff'}
      transition="none"
      textAlign={textAlign as any}
      verticalAlign={verticalAlign as any}
      {...props}
    />
  );
};
export default Input;
