import { Stack } from '@chakra-ui/react';
import { CardEventDTO, CardEventType } from 'shared';
import React from 'react';
import {
  CardArchivedEvent,
  CardAttachmentChangeEvent,
  CardCreationEvent,
  CardHistoryAssignEvent,
  CardHistoryBlockerEvent,
  CardHistoryMoveEvent,
  CardHistoryWatchEvent,
  CardTitleChangeEvent,
  CardDescriptionChangeEvent,
} from './CardHistoryEntry';

interface CardHistoryProps {
  elements?: CardEventDTO[];
}

function getChildren(element: CardEventDTO) {
  switch (element.type) {
    case CardEventType.MOVE:
      return <CardHistoryMoveEvent {...element} />;
    case CardEventType.CARD_CREATED:
      return <CardCreationEvent {...element} />;
    case CardEventType.CARD_ARCHIVED:
    case CardEventType.CARD_UNARCHIVED:
      return <CardArchivedEvent {...element} />;
    case CardEventType.ATTACHMENT_ADDED:
    case CardEventType.ATTACHMENT_DELETED:
      return <CardAttachmentChangeEvent {...element} />;
    case CardEventType.TITLE_CHANGED:
      return <CardTitleChangeEvent {...element} />;
    case CardEventType.DESCRIPTION_CHANGED:
      return <CardDescriptionChangeEvent {...element} />;
    case CardEventType.BLOCKER_CREATED:
    case CardEventType.BLOCKER_REOPENED:
    case CardEventType.BLOCKER_RESOLVED:
    case CardEventType.BLOCKER_DELETED:
    case CardEventType.BLOCKER_EDITED:
      return <CardHistoryBlockerEvent {...element} />;
    case CardEventType.CARD_ASSIGNED:
    case CardEventType.CARD_UNASSIGNED:
      return <CardHistoryAssignEvent {...element} />;
    case CardEventType.CARD_WATCHED:
    case CardEventType.CARD_UNWATCHED:
      return <CardHistoryWatchEvent {...element} />;
    default:
      return null;
  }
}

const CardHistory: React.FC<CardHistoryProps> = ({ elements }) => (
  <Stack spacing={4}>
    {[...(elements ?? [])]
      .sort((a, b) => new Date(b.createdAt)?.getTime() - new Date(a.createdAt)?.getTime())
      .map((element) => (
        <React.Fragment key={element.id}>{getChildren(element)}</React.Fragment>
      ))}
  </Stack>
);

export default CardHistory;
