import { getStage } from 'lib/zoom';
import { useEffect } from 'react';

import { nonUpdatingStore } from 'store/index';

const useTrackMouseOnStage = () => {
  useEffect(() => {
    const stage = getStage();
    if (!window || !stage) {
      return () => {};
    }
    const listener = (e: MouseEvent) => {
      const currentOffset = stage.position();
      const currentZoomlevel = stage.scaleX();
      const positionToSet = {
        x: (e.clientX - currentOffset.x) / currentZoomlevel,
        y: (e.clientY - currentOffset.y) / currentZoomlevel,
      };
      nonUpdatingStore.getActions().app.setMousePosition(positionToSet);
    };
    window.addEventListener('mousemove', listener);
    return () => window.removeEventListener('mousemove', listener);
  }, []);
};

export default useTrackMouseOnStage;
