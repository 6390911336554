import { BoardElementLink, BoardElementLinkType, CheckLink, isCheckLink } from 'shared';
import produce from 'immer';
import { UIBoardElement } from 'types/index';
import { v4 as uuid } from 'uuid';
import { getTaskLinks } from './Links.helper';

type Checkbox = {
  id: string;
  isChecked: boolean;
};

function getCheckboxesFromHTMLContent(content: string): Checkbox[] {
  const container = document.createElement('div');
  container.innerHTML = content ?? '';
  const checkboxes = Array.from(container.querySelectorAll('li[data-linkid]'));

  return checkboxes
    .map((c) => ({
      id: c.getAttribute('data-linkid'),
      isChecked: c.getAttribute('data-checked') === 'true',
    }))
    .filter((checkbox) => checkbox.id != null) as Checkbox[];
}

export function getCheckboxInfoFromHTML(
  currentLinks: Array<BoardElementLink>,
  newContent: string
): {
  addedCheckboxes: Checkbox[];
  changedCheckboxes: Checkbox[];
  removedCheckboxes: string[];
} {
  const newCheckboxes = getCheckboxesFromHTMLContent(newContent ?? '');
  const addedCheckboxes = newCheckboxes.filter(
    (newCheckbox) => !currentLinks.some((link) => link._id === newCheckbox.id)
  );

  const changedCheckboxes = newCheckboxes.filter((newCheckbox) => {
    const changedCheckbox = currentLinks.find((link) => link._id === newCheckbox.id);
    // Checkbox was just created
    if (!changedCheckbox || !isCheckLink(changedCheckbox)) return false;
    return changedCheckbox.data !== newCheckbox.isChecked;
  });

  const removedCheckboxes = currentLinks
    .filter((link) => isCheckLink(link) && !newCheckboxes.some((n) => n.id === link._id))
    .map((link) => link._id);

  return {
    addedCheckboxes,
    changedCheckboxes,
    removedCheckboxes,
  };
}

export function getChangedLinks({
  currentLinks,
  addedCheckboxes,
  removedCheckboxes,
  changedCheckboxes,
}: {
  currentLinks: Array<BoardElementLink>;
  addedCheckboxes: Array<Checkbox>;
  changedCheckboxes: Array<Checkbox>;
  removedCheckboxes: string[];
}): Array<BoardElementLink> | null {
  const hasChanges =
    addedCheckboxes.length > 0 || removedCheckboxes.length > 0 || changedCheckboxes.length > 0;
  if (!hasChanges) return null;

  const newLinksForCurrentElement = addedCheckboxes.map((checkbox) => {
    const newLink: BoardElementLink = {
      _id: checkbox.id,
      targetElement: { id: checkbox.id },
      type: BoardElementLinkType.CHECK,
      createdAt: new Date(),
      data: checkbox.isChecked,
    };
    return newLink;
  });

  const updatedCurrentLinks: BoardElementLink[] = currentLinks
    .map((link) => {
      const checkboxForLink = changedCheckboxes.find((checkbox) => checkbox.id === link._id);

      if (!checkboxForLink) return link;
      return {
        ...link,
        data: checkboxForLink.isChecked,
      } as BoardElementLink;
    })
    // remove links whose checkbox has been deleted
    .filter((link) => !removedCheckboxes.includes(link._id));

  return [...updatedCurrentLinks, ...newLinksForCurrentElement];
}

/**
 * Helper function to "clean" the HTML content with tasks.
 * @param content the updated HTML content
 * @returns the cleaned HTML
 */
export function removeDataCheckedFromContent(content: string): string {
  if (content == null) return content;
  const container = document.createElement('div');
  container.innerHTML = content;
  container.querySelectorAll('[data-checked]').forEach((c) => c.removeAttribute('data-checked'));

  container.querySelectorAll('input[type="checkbox"]').forEach((c) => {
    c.removeAttribute('checked');
  });
  return container.innerHTML;
}

/**
 * Helper function to update checkboxes for tasks in the HTML
 * depending on the current links
 * @param links The actual links of the current card
 * @param content the raw HTML
 * @returns the updated HTML
 */
export function populateDataCheckedAttribute(links: Array<CheckLink>, content: string): string {
  if (content == null) return content;
  const container = document.createElement('div');
  container.innerHTML = content;
  links.forEach((link) => {
    const checkbox = container.querySelector(`[data-linkid="${link._id}"]`);
    if (checkbox) {
      checkbox.setAttribute('data-checked', String(!!link.data));
      const input = checkbox.querySelector<HTMLInputElement>('input[type="checkbox"]');
      if (input) {
        input.checked = !!link.data;
        input.dataset.checked = String(link.data);
      }
    }
  });
  return container.innerHTML;
}

/**
 * Helper function to get the links and the description for the element
 * in a copy-paste action. It updates the link-ids in the HTML as well as the links itself
 * @param element the element that should be copied
 * @returns the links (only check links) and the description for the new element
 */
export function exchangeLinkIdsForPaste(element: UIBoardElement): {
  content: string;
  links: Array<CheckLink>;
} {
  const container = document.createElement('div');
  container.innerHTML = element.content ?? '';

  // we need to use original here to to convert the proxy object from immer (`element.links`) to a regular js array
  const newLinks = produce(getTaskLinks(element.links), (draft) => {
    container.querySelectorAll('li[data-linkid]').forEach((elementWithLinkId) => {
      if (elementWithLinkId instanceof HTMLElement) {
        const currentLinkId = elementWithLinkId.dataset.linkid;
        const currentLink = draft.find((link) => link._id === currentLinkId);

        if (!currentLinkId || !currentLink) return;
        const newLinkId = uuid();
        // eslint-disable-next-line no-param-reassign
        elementWithLinkId.dataset.linkid = newLinkId;
        currentLink._id = newLinkId;
        currentLink.targetElement.id = newLinkId;
      }
    });
  });

  return {
    links: newLinks,
    content: container.innerHTML,
  };
}
