/* eslint-disable no-param-reassign */
import { action, Action, Thunk, thunk } from 'easy-peasy';
import { PublicStoreModel } from 'store/index';
import logger from 'lib/logger';

export enum SocketIoEvent {
  // Socket Events
  CONNECT = 'connect',
  DISCONNECT = 'disconnect',
  CONNECT_ERROR = 'connect_error',
  // Manager Events
  ERROR = 'error',
  RECONNECT = 'reconnect',
  RECONNECT_ATTEMPT = 'reconnect_attempt',
  RECONNECT_ERROR = 'reconnect_error',
  RECONNECT_FAILED = 'reconnect_failed',
}

export enum SocketConnectivity {
  Connecting = 'Connecting',
  Connected = 'Connected',
  Disconnected = 'Disconnected',
  Reconnecting = 'Reconnecting',
  Reconnected = 'Reconnected',
  Waiting = 'Waiting',
  Error = 'Error',
  Failed = 'Failed',
}

export const socketIoEventToSocketConnectivity: Record<SocketIoEvent, SocketConnectivity> = {
  [SocketIoEvent.CONNECT]: SocketConnectivity.Connected,
  [SocketIoEvent.DISCONNECT]: SocketConnectivity.Disconnected,
  [SocketIoEvent.CONNECT_ERROR]: SocketConnectivity.Error,
  [SocketIoEvent.ERROR]: SocketConnectivity.Error,
  [SocketIoEvent.RECONNECT]: SocketConnectivity.Reconnected,
  [SocketIoEvent.RECONNECT_ATTEMPT]: SocketConnectivity.Reconnecting,
  [SocketIoEvent.RECONNECT_ERROR]: SocketConnectivity.Error,
  [SocketIoEvent.RECONNECT_FAILED]: SocketConnectivity.Failed,
} as const;

export interface SocketConnectivityStoreModel {
  connectivity: SocketConnectivity;
  isUpdatingBoardAfterConnect: boolean;
  handleNewSocketConnectionEvent: Thunk<
    SocketConnectivityStoreModel,
    SocketIoEvent,
    null,
    PublicStoreModel
  >;
  setConnectivity: Action<SocketConnectivityStoreModel, SocketIoEvent>;
  markIsUpdatingBoardAfterConnect: Action<SocketConnectivityStoreModel>;
  unmarkIsUpdatingBoardAfterConnect: Action<SocketConnectivityStoreModel>;
}

/**
 * Tests whether we are facing a reconnect.
 * We have to check this manually since socket io is not always using the Event `reconnect`.
 */
const isReconnect = (
  oldConnectivity: SocketConnectivity,
  newConnectivity: SocketConnectivity
): boolean =>
  newConnectivity === SocketConnectivity.Reconnected ||
  (newConnectivity === SocketConnectivity.Connected &&
    oldConnectivity !== SocketConnectivity.Connecting);

const socketConnectivityStoreModel: SocketConnectivityStoreModel = {
  connectivity: SocketConnectivity.Connecting,
  isUpdatingBoardAfterConnect: false,
  handleNewSocketConnectionEvent: thunk(
    async (actions, newConnectivity, { getStoreActions, getStoreState }) => {
      // update connectivity
      actions.setConnectivity(newConnectivity);

      // update board after reconnect
      const state = getStoreState().socketConnectivity;
      if (
        state.connectivity === SocketConnectivity.Reconnected &&
        !state.isUpdatingBoardAfterConnect
      ) {
        logger.info(
          `handleNewSocketConnectionEvent(${state.connectivity}) update Board after reconnect`
        );

        actions.markIsUpdatingBoardAfterConnect();
        await getStoreActions().board.getBoardData();
        actions.unmarkIsUpdatingBoardAfterConnect();
      }
    }
  ),
  setConnectivity: action((state, socketIoEvent) => {
    const oldConnectivity = state.connectivity;

    // map SocketConnectionEvents to SocketConnectivity whith special handling for reconnected
    const mappedConnectivity = socketIoEventToSocketConnectivity[socketIoEvent];
    if (isReconnect(oldConnectivity, mappedConnectivity)) {
      state.connectivity = SocketConnectivity.Reconnected;
    } else {
      state.connectivity = mappedConnectivity;
    }
    logger.info(`setConnectivity()`, {
      socketIoEvent,
      oldConnectivity,
      'state.connectivity': state.connectivity,
    });
  }),
  markIsUpdatingBoardAfterConnect: action((state) => {
    state.isUpdatingBoardAfterConnect = true;
  }),
  unmarkIsUpdatingBoardAfterConnect: action((state) => {
    state.isUpdatingBoardAfterConnect = false;
  }),
};

export default socketConnectivityStoreModel;
