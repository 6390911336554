import { Flex, Heading } from '@chakra-ui/react';
import { useStoreState } from 'store/hooks';
import isMobileJS from 'ismobilejs';

export function ElementCreationBackdrop() {
  const floatingBoardElement = useStoreState((store) => store.app.floatingBoardElement);

  const isMobile = isMobileJS().any;
  if (!floatingBoardElement || !isMobile) {
    return null;
  }
  return (
    <Flex
      position="absolute"
      bg="rgba(0,0,0,0.3)"
      pointerEvents="none"
      top={0}
      left={0}
      width="100vw"
      height="100vh"
      zIndex={99999}
      justifyContent="center"
      alignItems="center"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Heading textAlign="center" color="yoBrand.500">
        Please Tap anywhere to place your Element
      </Heading>
    </Flex>
  );
}
