/* eslint-disable import/prefer-default-export */
import { User, NotificationDTO, SOCKET_TYPE, SOCKET_EVENTS } from 'shared';
import io from 'lib/socket';
import { useCallback, useEffect, useMemo, useState } from 'react';
import nonUpdatingStore from 'store/index';
import { Position } from 'store/app';
import { throttle } from 'lib/timing';
import logger from 'lib/logger';
import {
  SocketIoEvent,
  socketIoEventToSocketConnectivity,
  SocketConnectivity,
} from 'store/socketConnectivity';
import { useStoreActions, useStoreState } from 'store/hooks';
import { useRouter } from 'next/router';
import { isTakingScreenshot } from 'lib/screenshot';
import { ServerEventHandler } from '../ServerEvent/ServerEventHandler';
import { isServerElementEvent } from '../ServerEvent/ServerEvent';
import useRemoveUser from './useRemoveUser';

export function useSocketActions() {
  const joinBoard = useCallback((boardId: string, isScreenshot?: boolean) => {
    io.emit(
      SOCKET_EVENTS.JOIN,
      boardId,
      !isScreenshot ? SOCKET_TYPE.BOARD : SOCKET_TYPE.SCREENSHOT
    );
  }, []);
  const leaveBoard = useCallback((boardId: string) => {
    if (boardId === nonUpdatingStore.getState().board.boardId) {
      io.emit(SOCKET_EVENTS.LEAVE, boardId);
    }
  }, []);
  const joinUser = useCallback((userId: string) => {
    io.emit(SOCKET_EVENTS.JOIN, userId, SOCKET_TYPE.MYBOARD);
  }, []);
  const leaveUser = useCallback((userId: string) => {
    io.emit(SOCKET_EVENTS.LEAVE, userId, SOCKET_TYPE.MYBOARD);
  }, []);
  return useMemo(
    () => ({ joinBoard, leaveBoard, joinUser, leaveUser }),
    [joinBoard, leaveBoard, joinUser, leaveUser]
  );
}

export function useSockets() {
  const [success, setSuccess] = useState(false);
  const { joinBoard, leaveBoard, joinUser } = useSocketActions();
  const socketConnectivity = useStoreState((state) => state.socketConnectivity.connectivity);
  const removeUserFromBoard = useRemoveUser();

  const { query } = useRouter();
  const boardId = Array.isArray(query?.boardId) ? query.boardId[0] : query?.boardId;

  const userId = useStoreState((state) => state.user.user?._id);

  /** join board when `boardId` changes */
  useEffect(() => {
    if (boardId) {
      joinBoard(boardId, isTakingScreenshot());
    }
    return () => {
      leaveBoard(boardId);
    };
  }, [boardId, joinBoard, leaveBoard, isTakingScreenshot]);

  // join /myboardSocket
  useEffect(() => {
    if (userId) {
      joinUser(userId);
    }
  }, [joinUser, userId]);

  /** join board when user reconnected */
  useEffect(() => {
    if (boardId && socketConnectivity === SocketConnectivity.Reconnected) {
      joinBoard(boardId, isTakingScreenshot());
    }
  }, [socketConnectivity, isTakingScreenshot]);

  useEffect(() => {
    const { setUsers, addUser, removeUser } = nonUpdatingStore.getActions().activeUsers;
    io.on(SOCKET_EVENTS.JOIN_SUCCESS, (users: User[]) => {
      logger.info(`useSockets() io.on(${SOCKET_EVENTS.JOIN_SUCCESS})`, { users });

      // Manually set connected status
      // because JOIN_SUCESS means that we are in fact connected
      nonUpdatingStore.getActions().socketConnectivity.setConnectivity(SocketIoEvent.CONNECT);
      setUsers(users);
      setSuccess(true);
    });
    io.on(SOCKET_EVENTS.USER_JOIN, (newUser: User) => {
      logger.info(`useSockets() io.on(${SOCKET_EVENTS.USER_JOIN})`, { newUser });

      addUser(newUser);
    });
    io.on(SOCKET_EVENTS.USER_LEAVE, (userIdToRemove: string) => {
      logger.info(`useSockets() io.on(${SOCKET_EVENTS.USER_LEAVE})`, { userId: userIdToRemove });

      removeUser(userIdToRemove);
    });
    io.on(SOCKET_EVENTS.FORCE_REMOVE_USER, (payload: string) => {
      removeUserFromBoard(payload);
    });
    io.on(SOCKET_EVENTS.EVENT, (event: any, historyChanged: boolean) => {
      logger.debug(`useSockets() io.on(${SOCKET_EVENTS.EVENT})`, {
        event,
        historyChanged,
      });

      if (!isServerElementEvent(event)) {
        logger.error(`useSockets() io.on(${SOCKET_EVENTS.EVENT}) invalid event`, { event });
        return;
      }

      ServerEventHandler.handle({ event, historyChanged });
    });

    // listen on all kind of socket io events as listed in the `socketConnectionToSocketConnectivity` map
    Object.keys(socketIoEventToSocketConnectivity).forEach((socketEvent) => {
      io.on(socketEvent, () => {
        // We don't want to update
        if (isTakingScreenshot()) {
          logger.info('Creating board screenshot, not using event', socketEvent);
          return;
        }
        nonUpdatingStore
          .getActions()
          .socketConnectivity.handleNewSocketConnectionEvent(socketEvent as SocketIoEvent);
      });
    });
    io.on(SOCKET_EVENTS.NEW_NOTIFICATION, (notification: NotificationDTO) => {
      logger.error('useSockets() io.on(SOCKET_EVENTS.NEW_NOTIFICATION)', { notification });
      nonUpdatingStore.getActions().notifications.addBoardNotification(notification);
    });

    return () => {
      Object.keys(socketIoEventToSocketConnectivity).forEach((event) => io.off(event));
      io.off(SOCKET_EVENTS.NEW_NOTIFICATION);
      io.off(SOCKET_EVENTS.EVENT);
      io.off(SOCKET_EVENTS.USER_LEAVE);
      io.off(SOCKET_EVENTS.USER_JOIN);
      io.off(SOCKET_EVENTS.JOIN_SUCCESS);
      io.off(SOCKET_EVENTS.FORCE_REMOVE_USER);
    };
  }, []);
  return success;
}

const throttledSendPosition = throttle((position: Position & { inDetailView: boolean }) => {
  io.emit(SOCKET_EVENTS.POSITION, position);
}, 50);

export type Cursor = Position & {
  name: string;
  id: string;
};

export type Positions = Record<string, Cursor>;

export function usePointers(): [Positions, (position: Cursor) => void] {
  const [positions, setPositions] = useState<Positions>({});
  const setMyPosition = useCallback((position: Cursor): void => {
    throttledSendPosition(position);
  }, []);
  useEffect(() => {
    io.on(
      SOCKET_EVENTS.POSITION,
      ({ name, x, y, id }: { name: string; x: number; y: number; id: string }) => {
        setPositions((currentPositions) => ({
          ...currentPositions,
          [id]: { x, y, name, id },
        }));
      }
    );
    io.on(SOCKET_EVENTS.USER_LEAVE, (userId: string) => {
      setPositions((currentPositions) => {
        const posWithoutUser = { ...currentPositions };
        delete posWithoutUser[userId];
        return posWithoutUser;
      });
    });
  }, []);

  return [positions, setMyPosition];
}

export type DetailViews = Record<string, boolean>;
export type EditingCard = Record<string, boolean>;

export function useReceiveCursorActions() {
  const [detailViews, setDetailViews] = useState<DetailViews>({});
  const [editingCard, setEditingCard] = useState<EditingCard>({});
  useEffect(() => {
    io.on(
      SOCKET_EVENTS.DETAIL_VIEW_OPEN,
      ({ userId, hasDetailView }: { userId: string; hasDetailView: boolean }) => {
        if (hasDetailView) {
          setDetailViews((current) => ({
            ...current,
            [userId]: true,
          }));
        } else {
          setDetailViews((current) => {
            const newDetailViews = { ...current };
            delete newDetailViews[userId];
            return newDetailViews;
          });
        }
      }
    );

    io.on(
      SOCKET_EVENTS.EDITING_CARD,
      ({ userId, isEditing }: { userId: string; isEditing: boolean }) => {
        if (isEditing) {
          setEditingCard((current) => ({
            ...current,
            [userId]: true,
          }));
        } else {
          setEditingCard((current) => {
            const newEditing = { ...current };
            delete newEditing[userId];
            return newEditing;
          });
        }
      }
    );

    io.on(
      SOCKET_EVENTS.END_EDIT_CARD,
      ({ userId, isEditing }: { userId: string; isEditing: boolean }) => {
        if (isEditing) {
          setEditingCard((current) => ({
            ...current,
            [userId]: false,
          }));
        } else {
          setEditingCard((current) => {
            const newEditing = { ...current };
            delete newEditing[userId];
            return newEditing;
          });
        }
      }
    );
  }, []);
  return { detailViews, editingCard };
}

export function getEmitDetailViewOpen(): {
  setHasDetailViewOpen: (userId: string, hasDetailView: boolean) => void;
} {
  const setHasDetailViewOpen = (userId: string, hasDetailView: boolean) => {
    io.emit(SOCKET_EVENTS.DETAIL_VIEW_OPEN, { userId, hasDetailView });
  };

  return { setHasDetailViewOpen };
}

const LIVE_INDICATOR_TIMEOUT = 2000;

export function useLiveIndicatorEmitting(): {
  liveIndicatorStartEdit: ({ userId, isEditing }: { userId: string; isEditing: boolean }) => void;
  liveIndicatorEndEdit: ({ userId, isEditing }: { userId: string; isEditing: boolean }) => void;
} {
  const [intervalTimer, setIntervalTimer] = useState<NodeJS.Timeout | undefined>(undefined);

  const liveIndicatorStartEdit = ({
    userId,
    isEditing,
  }: {
    userId: string;
    isEditing: boolean;
  }) => {
    io.emit(SOCKET_EVENTS.EDITING_CARD, { userId, isEditing });
    setIntervalTimer(
      setInterval(() => {
        io.emit(SOCKET_EVENTS.EDITING_CARD, { userId, isEditing });
      }, LIVE_INDICATOR_TIMEOUT)
    );
  };

  const liveIndicatorEndEdit = ({ userId, isEditing }: { userId: string; isEditing: boolean }) => {
    if (intervalTimer) {
      clearInterval(intervalTimer);
    }
    io.emit(SOCKET_EVENTS.END_EDIT_CARD, { userId, isEditing });
  };

  return { liveIndicatorStartEdit, liveIndicatorEndEdit };
}

export function useCommentsEmitting() {
  const updateCommentCount = ({ cardId }: { cardId: string }) => {
    io.emit(SOCKET_EVENTS.UPDATE_COMMENTS, { cardId });
  };
  return { updateCommentCount };
}

export function useCommentsReceiving() {
  const { getCardDetails } = useStoreActions((actions) => actions.board);
  useEffect(() => {
    io.on(SOCKET_EVENTS.UPDATE_COMMENTS, ({ cardId }) => {
      getCardDetails({ id: cardId, isArchived: false });
    });
  }, []);
}
