import nonUpdatingStore from 'store/index';
import { DragElement, DragModel } from './DragModel';
import { getStageUnsafe } from '../konva/KonvaHelper';

export const createDragModel = ({
  element,
  canBeResized,
}: {
  element: DragElement;
  canBeResized: boolean;
}): DragModel => {
  const { stageOffset, snapToGrid, zoomLevel } = nonUpdatingStore.getState().boardSettings;
  const dragModel = {
    allBoardElements: [...nonUpdatingStore.getState().board.boardElements],
    element,
    canBeResized,
    moveVector: null,
    stageOffset,
    snapToGrid,
    zoomLevel,
    stage: getStageUnsafe(),
  };
  if (!dragModel.allBoardElements.some((e) => e.id === dragModel?.element.id)) {
    dragModel.allBoardElements.push(dragModel.element);
  }
  return dragModel;
};
