/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import { useStoreState } from 'store/hooks';
import Navbar from 'components/dom/Navbar';
import { memo } from 'react';
import BottomBar from 'components/dom/BottomBar';
import { WidgetTypes } from 'store/widgets';
import Input from 'components/dom/Input';
import TextArea from 'components/dom/TextArea';
import ContextMenu from 'components/dom/ContextMenu';
import { BoardMode } from 'store/board/settings';
import TimeTravelUI from 'components/dom/TimeTravelUI/TimeTravelUI';
import HeaderWidget from 'components/dom/HeaderWidget';
import CardDetailView from 'components/dom/CardDetailView';
import ColumnDetailView from 'components/dom/ColumnDetailView';
import ControlHints from 'components/dom/Hints';
import { Global } from '@emotion/react';
import BookmarksDrawer from 'components/dom/BookmarksDrawer';
import SearchPopover from 'components/dom/SearchPopover';
import { LinkSearchPopover } from 'components/dom/SearchPopover/LinkSearchPopover';
import { ElementCreationBackdrop } from 'components/dom/ElementCreationBackdrop';
import { CloseLinksButton } from 'components/dom/CloseLinksButton';
import { BoardHelp } from 'components/dom/BoardHelp';
import { ShortcutModal } from 'components/dom/ShortcutModal';
import { assertUnreachable } from 'shared';
import { BoardDropZone } from 'components/dom/BoardDropZone';

const BoardWidgets = () => {
  const appMode = useStoreState((state) => state.boardSettings.mode);
  const showHints = useStoreState((state) => state.boardSettings.showHints);
  const widgets = useStoreState((state) => state.widgets.data);
  const floatingBoardElement = useStoreState((state) => state.app.floatingBoardElement);
  const activeLinkCard = useStoreState((state) => state.app.activeLinkCard);

  return (
    <div
      style={{
        position: 'absolute',
        width: 0,
        height: 0,
        top: 0,
        left: 0,
        pointerEvents: floatingBoardElement ? 'none' : 'all',
      }}
    >
      {appMode === BoardMode.TIMETRAVEL ? (
        <TimeTravelUI />
      ) : (
        <>
          {showHints && <ControlHints />}
          <Global styles={{ html: { overflow: 'hidden' } }} />
          <Navbar />
          <BottomBar />
          <BoardHelp />
          <SearchPopover />
          <ShortcutModal />
          <LinkSearchPopover />
          <ElementCreationBackdrop />
          <BookmarksDrawer />
          <BoardDropZone />
          {activeLinkCard?.links && <CloseLinksButton links={activeLinkCard.links} />}
          {widgets.map((widget) => {
            switch (widget.type) {
              case WidgetTypes.INPUT:
                return <Input key={widget.id} {...{ refId: undefined!, ...widget }} />;
              case WidgetTypes.COLUMN:
                return <HeaderWidget key={widget.id} {...{ refId: undefined!, ...widget }} />;
              case WidgetTypes.TEXT:
                return <TextArea key={widget.id} {...{ refId: undefined!, ...widget }} />;
              case WidgetTypes.CONTEXTMENU:
                return <ContextMenu key={widget.id} {...(widget as any)} />;
              case WidgetTypes.CARDDETAILVIEW:
                return <CardDetailView key={widget.id} {...widget} />;
              case WidgetTypes.COLUMNDETAILVIEW: {
                if (!widget.refId) return null;
                return <ColumnDetailView key={widget.id} refId={widget.refId} />;
              }
              default:
                assertUnreachable(widget.type);
            }
          })}
        </>
      )}
    </div>
  );
};
export default memo(BoardWidgets);
