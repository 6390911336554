import { BoardElementType } from 'shared';
import { memo } from 'react';
import { Layer } from 'react-konva';
import isMobileJS from 'ismobilejs';
import { useFloatingElement } from 'hooks/useFloatingElement';
import { useDragFloatingElement } from 'hooks/useDragFloatingElement';
import Card from '../Card';
import Column from '../Column';
import Sticky from '../Sticky';
import Swimlane from '../Swimlane';

const FloatingElement: React.FC = () => {
  const isMobile = isMobileJS().any;

  const { floatingElement } = useFloatingElement();
  if (!floatingElement) {
    throw new Error('FloatingElement(): invalid floatingElement');
  }
  const { dragElementRef } = useDragFloatingElement(floatingElement);

  return (
    <Layer listening>
      {!isMobile
        ? dragElementRef.current &&
          (() => {
            switch (dragElementRef.current.type) {
              case BoardElementType.CARD: {
                return (
                  <Card
                    id={floatingElement.id}
                    state={dragElementRef.current.state}
                    shape={dragElementRef.current.shape}
                    type={dragElementRef.current.type}
                    thumbnailUrl={floatingElement?.thumbnailUrl}
                    title={floatingElement?.title}
                    {...(floatingElement?.meta ?? {})}
                  />
                );
              }
              case BoardElementType.COLUMN: {
                return (
                  <Column
                    id={floatingElement.id}
                    state={dragElementRef.current.state}
                    shape={dragElementRef.current.shape}
                    type={dragElementRef.current.type}
                    {...(floatingElement?.meta ?? {})}
                  />
                );
              }
              case BoardElementType.STICKY: {
                return (
                  <Sticky
                    id={floatingElement.id}
                    state={dragElementRef.current.state}
                    shape={dragElementRef.current.shape}
                    type={dragElementRef.current.type}
                  />
                );
              }
              case BoardElementType.SWIMLANE: {
                return (
                  <Swimlane
                    color="Brand"
                    id={floatingElement.id}
                    state={dragElementRef.current.state}
                    shape={dragElementRef.current.shape}
                    type={dragElementRef.current.type}
                  />
                );
              }
              default: {
                return <></>;
              }
            }
          })()
        : null}
    </Layer>
  );
};

export default memo(FloatingElement);
