/* eslint-disable no-param-reassign */
import { BoardColumsDTO, Metric, ThroughputDTO, CycleTimeDTO } from 'shared';
import fetcher from 'lib/Fetch';
import { Action, action, computed, Computed, Thunk, thunk } from 'easy-peasy';
import { StoreModel } from './index';

export type UIMetricsSettings = Partial<Omit<Metric, 'boardId'>>;

export type StoreThroughput = {
  currentThroughput: ThroughputDTO | null;
  history: ThroughputDTO[];
};

export type StoreCycleTime = {
  currentCycleTime: CycleTimeDTO | null;
  history: CycleTimeDTO[];
};

export interface MetricsStoreModel {
  settings: Metric | null;
  columns: Array<BoardColumsDTO>;
  throughput: StoreThroughput | null;
  cycletime: StoreCycleTime | null;
  getSettings: Thunk<MetricsStoreModel, string, null, StoreModel>;
  setSettingsAction: Action<MetricsStoreModel, Metric>;
  updateSettings: Thunk<MetricsStoreModel, Partial<Metric>, null, StoreModel>;
  getColumns: Thunk<MetricsStoreModel, string, null, StoreModel>;
  setColumnsAction: Action<MetricsStoreModel, Array<BoardColumsDTO>>;
  getThroughput: Thunk<MetricsStoreModel, string>;
  setThroughputAction: Action<MetricsStoreModel, StoreThroughput | null>;
  getCycleTime: Thunk<MetricsStoreModel, string>;
  setCycleTimeAction: Action<MetricsStoreModel, StoreCycleTime | null>;
  hasSettings: Computed<MetricsStoreModel, boolean>;
}

const metricsStoreModel: MetricsStoreModel = {
  settings: null,
  columns: [],
  throughput: null,
  cycletime: null,
  setSettingsAction: action((state, payload) => {
    state.settings = payload;
  }),
  getSettings: thunk(async (actions, boardId, { getStoreActions }) => {
    const newSettings = await fetcher<Metric>(
      `metrics/${boardId}/settings`,
      null,
      getStoreActions().errors.addError
    );
    actions.setSettingsAction(newSettings);
  }),
  getColumns: thunk(async (actions, boardId, { getStoreActions }) => {
    const columnsOnBoard = await fetcher<BoardColumsDTO[]>(
      `metrics/${boardId}/columns`,
      null,
      getStoreActions().errors.addError
    );
    actions.setColumnsAction(columnsOnBoard);
  }),
  setColumnsAction: action((state, payload) => {
    state.columns = payload;
  }),
  updateSettings: thunk(async (actions, changes, { getStoreActions }) => {
    const newSettings = await fetcher<Metric>(
      'metrics/settings',
      {
        method: 'PATCH',
        body: changes,
      },
      getStoreActions().errors.addError
    );
    actions.setSettingsAction(newSettings);
  }),
  getThroughput: thunk(async (actions, boardId) => {
    try {
      const data = await fetcher<{
        current: ThroughputDTO;
        history: ThroughputDTO[];
      }>(`metrics/${boardId}/throughput`);

      actions.setThroughputAction({
        currentThroughput: data.current,
        history: data.history,
      });
      // eslint-disable-next-line no-empty
    } catch (e) {
      actions.setThroughputAction(null);
    }
  }),
  setThroughputAction: action((state, payload) => {
    state.throughput = payload;
  }),
  getCycleTime: thunk(async (actions, boardId) => {
    try {
      const data = await fetcher<{
        current: CycleTimeDTO;
        history: CycleTimeDTO[];
      }>(`metrics/${boardId}/cycletime`);

      actions.setCycleTimeAction({
        currentCycleTime: data.current,
        history: data.history,
      });
    } catch (e) {
      actions.setCycleTimeAction(null);
    }
  }),
  setCycleTimeAction: action((state, payload) => {
    state.cycletime = payload;
  }),
  hasSettings: computed((state) => state.settings != null),
};

export default metricsStoreModel;
