import { Rect } from 'konva/types/shapes/Rect';
import { Node } from 'konva/types/Node';
import { getStage } from 'lib/zoom';
import { Stage } from 'konva/types/Stage';
import { Group } from 'konva/types/Group';
import logger from 'lib/logger';
import { Layer } from 'konva/types/Layer';
import Konva from 'konva';

export function getBoardElementGroup(konvaNode: Rect): Group | null {
  // board elements have a helper function called getCardGroup
  // which returns a reference to the group that the card is in
  if (konvaNode.attrs.getGroup == null || typeof konvaNode.attrs.getGroup !== 'function') {
    // TODO: find out when this occurs
    logger.debug(
      konvaNode,
      'getBoardElementGroup(): konvaNode.attrs.getGroup is null or not a function'
    );
    return null;
  }

  return konvaNode.attrs.getGroup();
}

export const getKonvaElementByStage = <T extends Node>(id: string, stage: Stage): T => {
  const rect = stage.find<T>(`#${id}`)?.[0];
  if (!rect) {
    throw new Error('KonvaHelper.getKonvaElementByStage() element not found');
  }
  return rect;
};

export const getStageUnsafe = () => {
  const stage = getStage();
  if (!stage) {
    throw new Error('KonvaHelper.getStageUnsafe() stage is undefined');
  }
  return stage;
};

export const getKonvaElement = <T extends Node>(id: string): T =>
  getKonvaElementByStage(id, getStageUnsafe());

export const getKonvaLayer = (columnId: string) => {
  const layer = getKonvaElement<Rect>(columnId).parent?.parent;
  if (!layer) {
    throw new Error('KonvaHelper.getKonvaLayer() layer is undefined');
  }
  return layer;
};

export const measureTextHeight = (
  layer: Layer | null,
  text: string,
  options: {
    wrap: string;
    fontSize: number;
    lineHeight: number;
    fontFamily: string;
    width: number;
    padding: number;
  }
): number | undefined => {
  if (!layer) {
    return undefined;
  }
  // calculate the raw height of the title text (without our previously set height)
  // we use a dummy text element
  const dummyTextElement = new Konva.Text({
    ...options,
    text,
    x: 0,
    y: 0,
    fill: 'transparent',
  });
  layer.add(dummyTextElement);
  const height = dummyTextElement.getHeight();
  dummyTextElement.destroy();
  return height;
};
