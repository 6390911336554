/* eslint-disable no-restricted-syntax */
import { EventTypes } from 'shared';
import isPlainObject from 'is-plain-object';
import { BoardElementPayload } from 'types/index';
import set from 'lodash.set';
import packageJson from '../../../package.json';
import { ClientElementEvent, ClientSingleElementEvent } from '../../ServerEvent/ServerEvent';

type GenericObject = Record<string, any>;

export function getObjectIntersection<T extends GenericObject>(oldObject: T, newObject: T) {
  const oldValues = {} as T;
  const newValues = {} as T;

  const getDiff = (object: GenericObject, diff: GenericObject, keyPath: Array<string> = []) => {
    Object.keys(diff).forEach((key) => {
      if (isPlainObject(object[key]) && isPlainObject(diff[key])) {
        getDiff(object[key], diff[key], [...keyPath, key]);
      } else if (object[key] !== diff[key] || key !== 'id') {
        const oldValue = object[key];
        const newValue = diff[key];
        set(oldValues, [...keyPath, key] as any, oldValue);
        set(newValues, [...keyPath, key] as any, newValue);
      }
    });
  };

  getDiff(oldObject, newObject);

  return {
    oldValues,
    newValues,
  };
}

class BoardEventFactory {
  static createEvent(
    type: EventTypes,
    id: string,
    oldValues?: Partial<BoardElementPayload> | null,
    newValues?: Partial<BoardElementPayload> | null
  ): ClientSingleElementEvent {
    let eventPayload: any;
    switch (type) {
      case EventTypes.ADD: {
        eventPayload = {
          type: EventTypes.ADD,
          elementId: id,
          oldValues: null,
          newValues,
        };
        break;
      }
      case EventTypes.UPDATE: {
        eventPayload = {
          type: EventTypes.UPDATE,
          elementId: id,
          ...getObjectIntersection(oldValues ?? {}, newValues ?? {}),
        };
        break;
      }
      case EventTypes.REMOVE: {
        eventPayload = {
          type: EventTypes.REMOVE,
          elementId: id,
          oldValues,
          newValues: null,
        };
        break;
      }
      default:
        throw new Error('Unsupported Event');
    }

    return { ...eventPayload, softwareVersion: packageJson.version };
  }

  static createBulkEvent(events: ClientSingleElementEvent[]): ClientElementEvent {
    return {
      type: EventTypes.BULK,
      events,
      softwareVersion: packageJson.version,
    };
  }
}

export default BoardEventFactory;
