/* eslint-disable operator-linebreak */
import { ColumnIcon, ListIcon } from 'lib/icons';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  InputGroup,
  InputLeftAddon,
  Box,
  Flex,
} from '@chakra-ui/react';
import dynamic from 'next/dynamic';
import { isNumberKey } from 'lib/helpers';
import { BoardElementState } from 'types/index';
import { BoardElementType } from 'shared';
import { useForm } from 'react-hook-form';
import { useStoreActions, useStoreState } from 'store/hooks';
import React, { useState } from 'react';
import { GrEmoji } from 'react-icons/gr';

import YoModal from '../YoModal';
import ColorMatrix from '../ContextMenu/ColorMatrix';

const Picker = dynamic(() => import('../EmojiPicker'), { ssr: false });

interface DetailViewProps {
  refId: string;
}

const WIP_LIMIT_MIN = 1;
const WIP_LIMIT_MAX = 99;
const WIP_LIMIT_STEP = 1;

const COLUMN_WIP_LIMIT_NAME = 'columnWipLimitName';
const COLUMN_TITLE_NAME = 'columnTitleName';
const COLUMN_EMOJI = 'columnEmoji';

const ColumnDetailView: React.FC<DetailViewProps> = ({ refId }) => {
  const close = useStoreActions((actions) => actions.widgets.closeWidgets);
  const updateElement = useStoreActions((actions) => actions.board.updateElement);
  const setElementState = useStoreActions((actions) => actions.board.setElementState);
  const boardElements = useStoreState((store) => store.board.boardElements);
  const currentColumn = boardElements.find((e) => e.id === refId);
  const [emoji, setEmoji] = useState<string | undefined>(currentColumn?.emoji);
  const [isEmojiSelected, setIsEmojiSelected] = useState(false);
  const toggleEmojiSelect = () => setIsEmojiSelected(!isEmojiSelected);

  const { handleSubmit, register, errors } = useForm<{
    [COLUMN_WIP_LIMIT_NAME]: string;
    [COLUMN_TITLE_NAME]: string;
  }>({
    defaultValues: {
      [COLUMN_TITLE_NAME]: currentColumn?.title,
      [COLUMN_WIP_LIMIT_NAME]: currentColumn?.wipLimit
        ? currentColumn?.wipLimit?.toString()
        : undefined,
    },
  });

  const handleClose = () => {
    if (!refId) return;
    setElementState({ id: refId, state: BoardElementState.FOCUSED });
    close();
  };

  const handleConfirm = handleSubmit((data) => {
    const wipLimitToSet =
      data[COLUMN_WIP_LIMIT_NAME] === '' ? undefined : parseInt(data[COLUMN_WIP_LIMIT_NAME], 10);
    if (
      (currentColumn?.title !== data[COLUMN_TITLE_NAME] ||
        currentColumn?.wipLimit !== wipLimitToSet ||
        currentColumn?.emoji !== emoji) &&
      refId
    ) {
      updateElement({
        id: refId,
        emoji,
        title: data[COLUMN_TITLE_NAME],
        wipLimit: wipLimitToSet,
      });
    }
    handleClose();
  });

  const columnOrSwimlane = currentColumn?.type === BoardElementType.COLUMN ? 'Column' : 'Swimlane';

  const onEmojiSelect = (newEmoji: string) => {
    setEmoji(newEmoji);
    toggleEmojiSelect();
  };

  const onRemoveEmojiClick = () => {
    setEmoji(undefined);
  };

  return (
    <YoModal
      isOpen
      confirmText="Save Changes"
      onConfirm={handleConfirm}
      onClose={handleClose}
      onCancel={handleClose}
      size="xl"
      title={`${columnOrSwimlane} Settings`}
    >
      <form onSubmit={handleConfirm}>
        <FormControl display="flex" color="black" mt={6}>
          <FormLabel
            htmlFor={COLUMN_TITLE_NAME}
            flexBasis={40}
            height={10}
            lineHeight={10}
            fontWeight={400}
            m={0}
          >
            {columnOrSwimlane} Name
          </FormLabel>
          <InputGroup width={230}>
            <InputLeftAddon padding={0} width={10} border={0}>
              {isEmojiSelected && (
                <Picker top={20} onClose={toggleEmojiSelect} onSelect={onEmojiSelect} />
              )}
              <Button
                id={COLUMN_EMOJI}
                name={COLUMN_EMOJI}
                ref={register}
                onClick={() => toggleEmojiSelect()}
                width={6}
                padding={0}
              >
                {emoji || <GrEmoji size={24} />}
              </Button>
            </InputLeftAddon>
            <Input
              borderRadius="sm"
              id={COLUMN_TITLE_NAME}
              name={COLUMN_TITLE_NAME}
              type="text"
              ref={register}
            />
          </InputGroup>
        </FormControl>
        <FormControl display="flex" color="black" mt={0}>
          <FormLabel flexBasis={40} m={0} />
          {emoji && (
            <Button
              variant="ghost"
              colorScheme="yoBrand"
              padding={0}
              onClick={onRemoveEmojiClick}
              _hover={{ bg: 'transparent' }}
              _active={{ bg: 'transparent' }}
            >
              Remove Emoji
            </Button>
          )}
        </FormControl>
        <FormControl
          color="black"
          isInvalid={!!errors[COLUMN_WIP_LIMIT_NAME]}
          display="flex"
          mt={6}
        >
          <FormLabel
            htmlFor={COLUMN_WIP_LIMIT_NAME}
            flexBasis={40}
            height={10}
            lineHeight={10}
            fontWeight={400}
            m={0}
          >
            Card Limit
          </FormLabel>
          <div>
            <Input
              type="number"
              width={230}
              borderRadius="sm"
              id={COLUMN_WIP_LIMIT_NAME}
              name={COLUMN_WIP_LIMIT_NAME}
              onKeyPress={(e) => {
                // only allow for number to be entered
                if (!isNumberKey(e.key)) {
                  e.preventDefault();
                }
              }}
              onChange={(e) => {
                // prevent decimals
                // eslint-disable-next-line no-param-reassign
                e.target.value = parseInt(e.target.value, 10).toString();
              }}
              step={WIP_LIMIT_STEP}
              ref={register({ min: WIP_LIMIT_MIN, max: WIP_LIMIT_MAX })}
            />
            <FormErrorMessage>
              {errors[COLUMN_WIP_LIMIT_NAME] && (
                <>
                  {['min', 'max'].includes(errors![COLUMN_WIP_LIMIT_NAME]!.type) &&
                    `The Column Limit needs to be between ${WIP_LIMIT_MIN} and ${WIP_LIMIT_MAX}`}
                  {['required'].includes(errors![COLUMN_WIP_LIMIT_NAME]!.type) &&
                    'The Column Limit can not be empty'}
                </>
              )}
            </FormErrorMessage>
            <Text fontStyle="italic" mt={3}>
              If you don&apos;t need a Card Limit, just leave it empty.
            </Text>
          </div>
          <div />
        </FormControl>

        <FormControl display="flex" color="black" mt={6}>
          {currentColumn?.type === BoardElementType.COLUMN && (
            <>
              <FormLabel
                htmlFor={COLUMN_TITLE_NAME}
                flexBasis={40}
                height={10}
                lineHeight={10}
                fontWeight={400}
                m={0}
              >
                Column Type
              </FormLabel>

              <Box display="flex">
                {!currentColumn?.isSorted ? (
                  <ListIcon marginTop={3} />
                ) : (
                  currentColumn?.isSorted && <ColumnIcon marginTop={3} />
                )}
                <Text marginTop={2} marginLeft={3}>
                  {' '}
                  <b>{currentColumn.isSorted ? 'List' : 'Free Column'}</b>{' '}
                </Text>
              </Box>
            </>
          )}
        </FormControl>
      </form>
      {currentColumn?.type === BoardElementType.SWIMLANE && (
        <Flex color="black" mt={6}>
          <FormLabel
            htmlFor={COLUMN_WIP_LIMIT_NAME}
            flexBasis={40}
            height={10}
            lineHeight={10}
            fontWeight={400}
            m={0}
          >
            Color
          </FormLabel>
          <Box>
            <ColorMatrix refId={refId} swimlaneColors />
          </Box>
        </Flex>
      )}
    </YoModal>
  );
};

export default ColumnDetailView;
