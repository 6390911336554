import pino from 'pino';

/**
 * estimate a user defined dynamic log level in the URL
 */
const getDynamicLogLevel = () => {
  if (typeof window !== 'undefined') {
    const match = window.location.href.match(/loglevel=(trace|debug|info|warn|error|fatal|silent)/);
    return match?.[1];
  }
  return undefined;
};

const logger = pino({
  browser: {
    asObject: false,
  },
  level:
    getDynamicLogLevel() ||
    process.env.TEST_LOG_LEVEL ||
    process.env.NEXT_PUBLIC_LOG_LEVEL ||
    'error',
});

export default logger;
