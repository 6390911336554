import { Box } from '@chakra-ui/react';
import { Avatar } from 'shared';
import AvatarSelection from '../AvatarSelection';

interface Props {
  zIndex: number;
  currentAvatarIndex: number;
  top: string;
  width: number | string;
  searchResults: Avatar[];
  onClick: (userId: string) => void;
  currentUserId: string;
}

const SearchResultList: React.FC<Props> = ({
  top,
  currentAvatarIndex,
  width,
  searchResults,
  onClick,
  zIndex,
  currentUserId,
}) => (
  <Box
    zIndex={zIndex}
    position="absolute"
    bg="white"
    borderWidth={1}
    borderRadius="sm"
    borderTopRadius={0}
    borderTopWidth={0}
    borderColor="yoGray.border"
    top={top}
    width={width}
    boxShadow="md"
    // break out of chakra-stack spacing
    marginTop="0 !important"
  >
    {searchResults.map((avatar, index) => (
      <AvatarSelection
        key={avatar.id}
        isActive={currentAvatarIndex === index}
        onClick={() => {
          onClick(avatar.id);
        }}
        {...avatar}
        name={`${avatar.name} ${avatar.id === currentUserId ? '(You)' : ''}`}
      />
    ))}
  </Box>
);

export default SearchResultList;
