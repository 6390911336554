/* eslint-disable react/require-default-props */
import { Stack, Avatar, AvatarGroup } from '@chakra-ui/react';
import { getCursorColor } from 'lib/colors';
import { getFullnameFromUser } from 'lib/helpers';
import { useStoreState } from 'store/hooks';

const MAX_AVATARS = 5;

function CurrentAvatars() {
  const users = useStoreState((store) => store.activeUsers.users);
  return (
    <Stack
      width={users.length > 0 ? '150px' : 'auto'}
      direction="row"
      justifySelf="center"
      alignItems="center"
      spacing={4}
      cursor="default"
      justifyContent="flex-end"
    >
      <AvatarGroup size="sm" max={MAX_AVATARS}>
        {users.map((currentUser) => {
          const fullName = getFullnameFromUser(currentUser);
          return (
            <Avatar
              key={currentUser._id}
              src={currentUser.avatarUrl}
              name={fullName}
              title={fullName}
              borderColor={getCursorColor(currentUser._id).color}
            />
          );
        })}
      </AvatarGroup>
    </Stack>
  );
}

export default CurrentAvatars;
