/* eslint-disable no-param-reassign */
import { Action, action, Computed, computed, thunk, Thunk } from 'easy-peasy';
import { v4 as generate } from 'uuid';
import { IdTuple, Rectangle, BoardElementType } from 'shared';
import { BoardElementState, UIBoardElement } from 'types/index';
import { getWidgetOptions } from 'lib/helpers';
import { getEmitDetailViewOpen } from 'hooks/Sockets';
import { PublicStoreModel } from '.';

/**
 * A widget is a react component which can be mounted and unmounted
 * over the canvas.
 * Example: widgets are textinputs to change the title of a column
 */

/**
 * This enum holds all possible widget types
 */
export enum WidgetTypes {
  // The input is a singleline text widget (html-input)
  INPUT = 'INPUT',
  // The text is a multiline text widget (html-textarea)
  TEXT = 'TEXT',
  // Column is a multi-input column edit widget (title and wip limit are editable)
  COLUMN = 'COLUMN',
  // The Contextmenu is a menu which opens by some action
  CONTEXTMENU = 'CONTEXTMENU',
  // The DetailView is a modal which contains further information for a boardElement of type card
  CARDDETAILVIEW = 'CARDDETAILVIEW',
  // The DetailView is a modal which contains further information for a boardElement of type column
  COLUMNDETAILVIEW = 'COLUMNDETAILVIEW',
}

export interface WidgetPayload {
  type: WidgetTypes;
  text?: string;
  shape?: Partial<Rectangle>;
  // This makes it possible to add unforeseen HTML properties / react props to a widget
  [key: string]: any;
  refId?: string;
  refIds?: string[];
}

export interface Widget extends WidgetPayload {
  id: string;
}

const { setHasDetailViewOpen } = getEmitDetailViewOpen();
export interface WidgetStoreModel {
  data: Widget[];
  addWidget: Action<WidgetStoreModel, WidgetPayload>;
  spawnWidget: Thunk<WidgetStoreModel, UIBoardElement, null, PublicStoreModel>;
  updateWidgetRef: Action<WidgetStoreModel, IdTuple>;
  removeWidget: Action<WidgetStoreModel, string>;
  closeWidgets: Action<WidgetStoreModel>;
  repositionWidget: Action<WidgetStoreModel, { id: string; x: number; y: number }>;
  closeContextMenu: Action<WidgetStoreModel>;
  hasWidgets: Computed<WidgetStoreModel, boolean>;
  hasDetailView: Computed<WidgetStoreModel, boolean>;
  hasContextMenuOpen: Computed<WidgetStoreModel, boolean>;
  closeDetailView: Thunk<
    WidgetStoreModel,
    { focusAfterClose?: boolean } | void,
    null,
    PublicStoreModel
  >;
}

const widgetsStoreModel: WidgetStoreModel = {
  data: [],
  addWidget: action((state, payload) => {
    if (payload) {
      state.data.push({ ...payload, id: generate() });
    }
  }),
  removeWidget: action((state, id) => {
    const index = state.data.findIndex((widget) => widget.id === id);
    if (index > -1) {
      state.data.splice(index, 1);
    } else {
      // TODO: Add error handling
    }
  }),
  closeWidgets: action((state) => {
    if (state.hasWidgets) {
      state.data = [];
    }
  }),
  repositionWidget: action((state, { id, x, y }) => {
    const index = state.data.findIndex((widget) => widget.refId === id);
    if (index > -1) {
      state.data[index].shape = {
        ...state.data[index].shape,
        x,
        y,
      };
    } else {
      // TODO: Add error handling
    }
  }),
  closeContextMenu: action((state) => {
    state.data = state.data.filter((widget) => widget.type !== WidgetTypes.CONTEXTMENU);
  }),
  hasWidgets: computed((state) => state.data.length > 0),
  hasDetailView: computed((state) =>
    state.data.some((widget) =>
      [WidgetTypes.CARDDETAILVIEW, WidgetTypes.COLUMNDETAILVIEW].includes(widget.type)
    )
  ),
  updateWidgetRef: action((state, payload) => {
    const widgetIndex = state.data.findIndex((widget) => widget.refId === payload.oldId);
    if (widgetIndex > -1) {
      state.data[widgetIndex].refId = payload.newId;
    }
  }),
  spawnWidget: thunk((actions, payload, { getStoreState }) => {
    const { zoomLevel, stageOffset } = getStoreState().boardSettings;
    if ([BoardElementState.INEDIT, BoardElementState.INDETAIL].includes(payload.state)) {
      actions.closeWidgets();
    }
    if (payload.type === BoardElementType.CARD && payload.state === BoardElementState.INDETAIL) {
      const { user } = getStoreState().user;
      if (user?._id) {
        setHasDetailViewOpen(user?._id, true);
      }
    }
    actions.addWidget(getWidgetOptions(payload, { zoomLevel, stageOffset }));
  }),
  hasContextMenuOpen: computed((state) =>
    state.data.some((widget) => widget.type === WidgetTypes.CONTEXTMENU)
  ),
  closeDetailView: thunk((actions, payload, { getStoreActions, getStoreState }) => {
    const openWidget = getStoreState().widgets.data[0];
    if (!openWidget || openWidget.type !== WidgetTypes.CARDDETAILVIEW || !openWidget.refId) return;
    actions.closeWidgets();

    const { user } = getStoreState().user;
    if (user?._id) {
      setHasDetailViewOpen(user?._id, false);
    }

    getStoreActions().board.setElementState({
      id: openWidget.refId,
      state: payload?.focusAfterClose ? BoardElementState.FOCUSED : BoardElementState.DEFAULT,
    });
  }),
};

export default widgetsStoreModel;
