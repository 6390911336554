import { MdClose } from 'react-icons/md';
import { Stack, Button, Flex, IconButton, Text } from '@chakra-ui/react';
import { useStoreState, useStoreActions } from 'store/hooks';
import { HideableSectionState } from 'store/app';
import { useEffect, useState } from 'react';

const COOKIE_BANNER_Z_INDEX = 999;
export const COOKIE_TEXT =
  'We only use technically necessary cookies to keep you logged in. By using this site you accept this.';

const CookieBanner: React.FC = () => {
  const [isConsentVisible, setConsentVisibility] = useState(false);
  const cookieBanner = useStoreState((store) => store.app.cookieBanner);
  const hideCookieBanner = useStoreActions((actions) => actions.app.hideCookieBanner);

  useEffect(() => {
    if (cookieBanner === HideableSectionState.VISIBLE) {
      setTimeout(() => {
        setConsentVisibility(true);
      }, 500);
    } else {
      setConsentVisibility(false);
    }
  }, [cookieBanner]);

  return isConsentVisible ? (
    <Flex
      pos="fixed"
      left="50%"
      bottom={8}
      transform="translateX(-50%)"
      w="50vw"
      minW="300px"
      maxW="700px"
      px={6}
      py={4}
      borderRadius="0.5em"
      boxShadow="cookieConsent.css"
      alignItems="center"
      justifyContent="space-between"
      bg="yoBrand.400"
      color="#ffffff"
      zIndex={COOKIE_BANNER_Z_INDEX}
    >
      <Stack isInline align="center">
        <Text fontWeight="500" fontSize="md" py={2}>
          {COOKIE_TEXT}{' '}
          <Button
            onClick={hideCookieBanner as any}
            fontSize="md"
            fontWeight="500"
            variant="link"
            textDecoration="underline"
            color="yoOrange.500"
            verticalAlign="inherit"
          >
            Understood
          </Button>
        </Text>
      </Stack>
      <IconButton
        onClick={hideCookieBanner as any}
        aria-label="close"
        icon={<MdClose />}
        variant="ghost"
        alignSelf="auto"
        fontSize="3xl"
        ml={2}
        _hover={{ borderColor: 'transparent', bg: 'transparent' }}
      />
    </Flex>
  ) : (
    <div />
  );
};

export default CookieBanner;
