import { Divider, Box } from '@chakra-ui/react';

interface Props {
  onClick?: () => void;
  hasDivider?: boolean;
}

const PopoverItem: React.FC<Props> = ({ children, hasDivider, ...props }) => (
  <>
    <Box
      color="white"
      role="button"
      display="flex"
      alignItems="center"
      borderRadius={3}
      _hover={{ bg: 'yoBrand.500' }}
      mx={2}
      px={3}
      py={2}
      fontWeight={500}
      {...props}
    >
      {children}
    </Box>
    {hasDivider && <Divider w="90%" alignSelf="center" my={2} />}
  </>
);

export default PopoverItem;
