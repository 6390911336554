/* eslint-disable import/prefer-default-export */
import { UIBoardElement } from 'types/index';
import { BoardElementType } from 'shared';
import { divideArrayByRule, isColumnOrSwimlane, sortBoardElementsByZIndex } from './helpers';

export const DEFAULT_INTERVAL = 1000;

export function getZIndexBetween(zIndex1: number, zIndex2: number): number {
  if (zIndex2 === undefined || zIndex2 === null) {
    return zIndex1 + DEFAULT_INTERVAL;
  }
  // no whole number zIndex between zIndex 1 and 2
  if (Math.abs(zIndex2 - zIndex1) === 1) {
    // return floating point number between elements
    return Math.abs((zIndex1 + zIndex2) / 2);
  }
  return Math.ceil(Math.abs((zIndex1 + zIndex2) / 2));
}

export function getNextZIndexForBoardElementType({
  boardElements,
  elementType,
}: {
  boardElements: UIBoardElement[];
  elementType: BoardElementType;
}): number | null {
  if (boardElements.length === 0) {
    return DEFAULT_INTERVAL;
  }
  switch (elementType) {
    case BoardElementType.CARD: {
      // Card should be added on top of every other card and column
      const invertedBoardElements = [...boardElements].reverse();
      const indexOfHighestCard = invertedBoardElements.findIndex(
        (boardElement) => boardElement.type === BoardElementType.CARD
      );

      // The highest card is the highest of all elements
      if (indexOfHighestCard === 0) {
        return invertedBoardElements[indexOfHighestCard].zIndex! + DEFAULT_INTERVAL;
      }

      // There are notes above the highest card
      if (indexOfHighestCard > 0) {
        const zIndexAboveHighestCard = invertedBoardElements[indexOfHighestCard - 1].zIndex;
        return getZIndexBetween(
          invertedBoardElements[indexOfHighestCard].zIndex!,
          zIndexAboveHighestCard!
        );
      }

      // There is no highest card -> get highest Column
      const indexOfHighestColumnOrSwimlane = invertedBoardElements.findIndex((boardElement) =>
        isColumnOrSwimlane(boardElement)
      );

      // The highest column is the highest of all elements
      if (indexOfHighestColumnOrSwimlane === 0) {
        return invertedBoardElements[indexOfHighestColumnOrSwimlane].zIndex! + DEFAULT_INTERVAL;
      }

      // There are notes above the highest column
      if (indexOfHighestColumnOrSwimlane > 0) {
        const zIndexAboveHighestColumnOrSwimlane =
          invertedBoardElements[indexOfHighestColumnOrSwimlane - 1].zIndex;
        return getZIndexBetween(
          invertedBoardElements[indexOfHighestColumnOrSwimlane].zIndex!,
          zIndexAboveHighestColumnOrSwimlane!
        );
      }

      return getZIndexBetween(0, boardElements[0].zIndex!);
    }
    case BoardElementType.SWIMLANE:
    case BoardElementType.COLUMN: {
      // Card should be added on top of every other card and column
      const invertedBoardElements = [...boardElements].reverse();

      const indexOfHighestColumnOrSwimlane = invertedBoardElements.findIndex((boardElement) =>
        isColumnOrSwimlane(boardElement)
      );

      // The highest column is the highest of all elements
      if (indexOfHighestColumnOrSwimlane === 0) {
        return invertedBoardElements[indexOfHighestColumnOrSwimlane].zIndex! + DEFAULT_INTERVAL;
      }

      // There are notes above the highest column
      if (indexOfHighestColumnOrSwimlane > 0) {
        const zIndexAboveHighestColumnOrSwimlane =
          invertedBoardElements[indexOfHighestColumnOrSwimlane - 1].zIndex;
        return getZIndexBetween(
          invertedBoardElements[indexOfHighestColumnOrSwimlane].zIndex!,
          zIndexAboveHighestColumnOrSwimlane!
        );
      }
      return getZIndexBetween(0, boardElements[0].zIndex!);
    }
    case BoardElementType.STICKY: {
      return boardElements[boardElements.length - 1].zIndex! + DEFAULT_INTERVAL;
    }
    default: {
      return null;
    }
  }
}

export function rebalanceElements(boardElements: UIBoardElement[]): UIBoardElement[] {
  return boardElements.map((element, index) => ({
    ...element,
    zIndex: (index + 1) * DEFAULT_INTERVAL,
  }));
}

export function getIndexOfLowestCardOrNote(boardElements: UIBoardElement[]): number {
  return boardElements.findIndex((boardElement) => !isColumnOrSwimlane(boardElement));
}

export function getIndexOfHighestColumnOrSwimlane(boardElements: UIBoardElement[]): number {
  // getting index of highest column/swimlane by finding the first column from the reversed elements
  return (
    boardElements.length -
    1 -
    boardElements
      .slice()
      .reverse()
      .findIndex((boardElement) => isColumnOrSwimlane(boardElement))
  );
}

export function areAllColumnsAndSwimlanesInBack(boardElements: UIBoardElement[]): boolean {
  const indexOfLowestCardOrNote = getIndexOfLowestCardOrNote(boardElements);
  const indexOfHighestColumnOrSwimlane = getIndexOfHighestColumnOrSwimlane(boardElements);
  return indexOfLowestCardOrNote > indexOfHighestColumnOrSwimlane;
}

export function moveAllColumnsAndSwimlanesBehind(
  boardElements: UIBoardElement[]
): UIBoardElement[] {
  const [columns, cardsAndNotes] = divideArrayByRule(boardElements, (element) =>
    isColumnOrSwimlane(element)
  );
  return rebalanceElements([...columns, ...cardsAndNotes]);
}

export function getElementsToUpdateForZIndexChanges({
  boardElements,
  elementsWithZIndexUpdate = [],
}: {
  boardElements: UIBoardElement[];
  elementsWithZIndexUpdate: UIBoardElement[];
}): UIBoardElement[] {
  // if zIndex of any element is not a whole number
  if (elementsWithZIndexUpdate.some((element) => element.zIndex! % 1 !== 0)) {
    const updatedIds = elementsWithZIndexUpdate.map((element) => element.id);
    const updatedBoardElementsUnsorted = boardElements
      .filter((element) => !updatedIds.includes(element.id))
      .concat(elementsWithZIndexUpdate);
    const updatedBoardElements = sortBoardElementsByZIndex(updatedBoardElementsUnsorted);
    const updatedBoardElementsRebalanced = rebalanceElements(updatedBoardElements);
    // every boardElement gets updated after ordering and rebalance
    return updatedBoardElementsRebalanced;
  }
  // every zIndex is a whole number -> just the given updates needed
  return elementsWithZIndexUpdate;
}
