import { BoardElementState } from '../types';

export const handleUtmUrl = (url: string, query: Record<string, string | string[]>) => {
  if (!query) return url;

  const params = new URLSearchParams();

  Object.entries(query).forEach(([paramName, value]) => {
    params.set(paramName, Array.isArray(value) ? value[0] : value);
  });

  return url.includes('?') ? `${url}&${params.toString()}` : `${url}?${params.toString()}`;
};

export const getUrlForElementState = (
  boardId: string,
  elementId: string,
  state: BoardElementState
) => {
  switch (state) {
    case BoardElementState.INDETAIL:
      return `${boardId}?element=${elementId}&detail=true`;
    // for resizable elements, state = RESIZABLE means it actually is focused too
    case BoardElementState.RESIZABLE:
    case BoardElementState.FOCUSED:
      return `${boardId}?element=${elementId}`;
    default:
      return boardId;
  }
};

export const updateUrlToBoardElementStateChange = (
  boardId: string,
  elementId: string,
  state: BoardElementState
) => {
  // Keep browser location on track with currently opened board elements

  // just use replaceState instead of pushState here, because we currently
  // don't want to leverage browser navigation inside the canvas. It messes
  // with next routing anyway, so just replace the current URL so the user
  // can copy a deep link to the element

  // after discussion we explicitly decided to not use the next router here
  // because the application does not care about the current value in the URL
  // when on the canvas, therefore we do not need to have events react to the
  // route change. Otherwise there would for example be an unnecessary loading
  // bar on every click on a boardelement
  window.history.replaceState({}, '', getUrlForElementState(boardId, elementId, state));
};

export const resetUrlAfterBoardElementStateChange = (boardId: string) => {
  // reset url back to default (which is the boardId)
  // the url is page relative, while the slug is not being included,
  // therefore just passing the boardId is sufficient
  window.history.replaceState({}, '', boardId);
};
