import { useStoreActions, useStoreState } from 'store/hooks';

export const useFloatingElement = () => {
  const floatingElement = useStoreState((store) => store.app.floatingBoardElement);
  const setFloatingBoardElement = useStoreActions((actions) => actions.app.setFloatingBoardElement);

  return {
    floatingElement,
    setFloatingElement: setFloatingBoardElement,
  };
};
