import { RefObject, memo } from 'react';
import { Layer, Rect } from 'react-konva';
import { Rect as IRect } from 'konva/types/shapes/Rect';

interface SelectionProps {
  // TODO: what does specs stand for?
  selectionSpecs: {
    show: boolean;
    height: number;
    width: number;
    position: {
      x: number;
      y: number;
    };
  };
  rectRef: RefObject<IRect>;
}

const strokeColor = 'rgba(0,0,255,0.2)';
const selectionColor = 'rgba(0,0,255,0.02)';

const Selection: React.FC<SelectionProps> = ({ selectionSpecs, rectRef }) => (
  <Layer listening={false}>
    <Rect
      ref={rectRef}
      stroke={strokeColor}
      strokeWidth={1}
      fill={selectionColor}
      visible={selectionSpecs.show}
      height={selectionSpecs.height}
      width={selectionSpecs.width}
      x={selectionSpecs.position.x}
      y={selectionSpecs.position.y}
    />
  </Layer>
);

export default memo(Selection);
