/* eslint-disable no-param-reassign */
import { action, Action, Thunk, thunk } from 'easy-peasy';
import fetchClient from 'lib/Fetch';
import { NotificationDTO } from 'shared';
import { PublicStoreModel } from '..';

export interface NotificationStoreModel {
  boardNotifications: NotificationDTO[];
  setBoardNotifications: Action<NotificationStoreModel, NotificationDTO[]>;
  addBoardNotification: Action<NotificationStoreModel, NotificationDTO>;
  markNotificationsAsRead: Thunk<NotificationStoreModel, string[], null, PublicStoreModel>;
  markNotificationsAsReadAction: Action<NotificationStoreModel, string[]>;
  markAllNotificationsForCardAsRead: Thunk<NotificationStoreModel, string, null, PublicStoreModel>;
  getUnreadBoardNotifications: Thunk<
    NotificationStoreModel,
    boolean | void,
    null,
    PublicStoreModel
  >;
}

export const notificationStore: NotificationStoreModel = {
  boardNotifications: [],
  setBoardNotifications: action((state, payload) => {
    state.boardNotifications = payload;
  }),
  addBoardNotification: action((state, payload) => {
    state.boardNotifications = [payload, ...state.boardNotifications];
  }),
  getUnreadBoardNotifications: thunk(async (actions, _, { getStoreState, getStoreActions }) => {
    const { boardId } = getStoreState().board;
    const notificationsForBoard = await fetchClient<NotificationDTO[]>(
      `notifications/${boardId}`,
      null,
      getStoreActions().errors.addError,
      boardId
    );
    actions.setBoardNotifications(notificationsForBoard);
  }),
  markNotificationsAsReadAction: action((state, notificationIds) => {
    state.boardNotifications.forEach((notification) => {
      if (notificationIds.includes(notification._id)) {
        notification.isRead = true;
      }
    });
  }),
  markAllNotificationsForCardAsRead: thunk((actions, cardId, { getStoreState }) => {
    const notificationsForCard = getStoreState().notifications.boardNotifications.filter(
      (notification) => notification.element?._id === cardId
    );
    const notificationIds = notificationsForCard
      .filter((notification) => !notification.isRead)
      .map((notification) => notification._id);

    actions.markNotificationsAsReadAction(notificationIds);
    actions.markNotificationsAsRead(notificationIds);
  }),
  markNotificationsAsRead: thunk(
    async (actions, notificationIds, { getStoreState, getStoreActions }) => {
      const { boardId } = getStoreState().board;
      await fetchClient(
        `notifications/${boardId}/mark-as-read`,
        { method: 'POST', body: { notificationIds } },
        getStoreActions().errors.addError,
        boardId
      );
      actions.markNotificationsAsReadAction(notificationIds);
    }
  ),
};
