import { Flex, Avatar, Box, Text, IconButton } from '@chakra-ui/react';
import { useState } from 'react';
import { MdClose } from 'react-icons/md';
import isMobileJS from 'ismobilejs';

interface AvatarSelectionProps {
  url: string;
  name: string;
  canUnassign?: boolean;
  onClick?: () => void;
  onUnassign?: () => void;
  isActive?: boolean;
  isDisabled?: boolean;
}

const SELECTED_COLOR = 'gray.200';

const AvatarSelection: React.FC<AvatarSelectionProps> = ({
  url,
  name = 'unknown',
  canUnassign,
  onUnassign,
  onClick,
  isActive,
  isDisabled = false,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const isMobile = isMobileJS().any;
  return (
    <Box
      height="44px"
      display="flex"
      alignItems="center"
      bg={isActive ? SELECTED_COLOR : 'yoGray.200'}
      _hover={canUnassign ? {} : { bg: SELECTED_COLOR }}
      p={canUnassign ? 0 : 2}
      cursor={canUnassign ? 'default' : 'pointer'}
      mb={canUnassign ? 1 : 0}
      rounded={canUnassign ? 'md' : null!}
      justifyContent="space-between"
      onClick={() => {
        if (!canUnassign) {
          onClick?.();
        }
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Flex align="center">
        <Avatar size="sm" src={url} name={name} mr={4} title={name} />
        <Text fontSize={16} fontWeight="normal">
          {name}
        </Text>
      </Flex>
      {canUnassign && !isDisabled && (isHovered || isMobile) && (
        <IconButton
          icon={<MdClose />}
          title={`Unassign ${name}`}
          aria-label={`Unassign ${name}`}
          variant="link"
          onClick={onUnassign}
          fontSize={18}
          fontWeight="bold"
          color="black"
          mr={-2}
        />
      )}
    </Box>
  );
};

export default AvatarSelection;
