/* eslint-disable operator-linebreak */
import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { useStoreState, useStoreActions } from 'store/hooks';
import {
  Flex,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  Text,
  Icon,
  useToast,
  Box,
  ModalCloseButton,
} from '@chakra-ui/react';
import { Key } from 'ts-key-enum';
import { BoardElementState, UIBoardElement } from 'types/index';
import { useParam } from 'hooks/useParam';
import { useDropzone } from 'react-dropzone';
import { useAttachments } from 'hooks/useAttachments';
import { MdFileUpload } from 'react-icons/md';
import { CardEventDTO } from 'shared';
import nonUpdatingStore from 'store/index';
import { useLiveIndicatorEmitting } from 'hooks/Sockets';
import RightColumn from './RightColumn';
import LeftColumn from './LeftColumn';
import CloseConfirmation from './CloseConfirmation';
import { getPotentialAssignees } from './Assignees.helper';

const ASSIGNED_TO_ID = 'assignedTo';

interface DetailViewProps {
  refId?: string;
  onClose?: () => void;
  customList?: Array<UIBoardElement>;
  isArchived?: boolean;
}

const DetailView: React.FC<DetailViewProps> = ({ refId, onClose, customList, isArchived }) => {
  const initialRef = useRef(null);
  const [isTitleEditing, setIsTitleEditing] = useState(false);
  const [isDescriptionEditing, setIsDescriptionEditing] = useState(false);
  const [isAnyCommentEditing, setIsAnyCommentEditing] = useState(false);
  const [isNewCommentEditing, setIsNewCommentEditing] = useState(false);
  const { isOpen, onClose: onCloseConfirmation, onOpen: onOpenConfirmation } = useDisclosure();
  const close = useStoreActions((actions) => actions.widgets.closeDetailView);
  const [searchValue, setSearchValue] = useState('');
  const [currentAvatarIndex, setCurrentAvatarIndex] = useState(-1);
  const setElementState = useStoreActions((actions) => actions.board.setElementState);
  const { getAvatars, getCardDetails } = useStoreActions((actions) => actions.board);
  const avatars = useStoreState((store) => store.board.avatars);
  const updateElement = useStoreActions((actions) => actions.board.updateElement);
  const boardElements = useStoreState((store) => store.board.boardElements);
  const boardId = useParam('boardId');
  const toast = useToast();
  const currentUser = useStoreState((store) => store.user.user);
  const [showAssigneesWithoutQuery, setShowAssigneesWithoutQuery] = useState(false);

  const { liveIndicatorStartEdit, liveIndicatorEndEdit } = useLiveIndicatorEmitting();
  const setIsTitleEditingExtended = (newIsTitleEditing: boolean) => {
    if (!currentUser || !refId) {
      setIsTitleEditing(newIsTitleEditing);
      return;
    }
    if (!isTitleEditing && newIsTitleEditing) {
      setIsTitleEditing(true);
      liveIndicatorStartEdit({ userId: currentUser._id, isEditing: true });
    } else if (isTitleEditing && !newIsTitleEditing) {
      setIsTitleEditing(false);
      liveIndicatorEndEdit({ userId: currentUser._id, isEditing: true });
    }
  };

  const attachmentObject = useAttachments({
    cardId: refId!,
    loadInstantly: false,
    isArchived,
  });
  const { refetch, addAttachment } = attachmentObject;

  const { isDragActive, getInputProps, getRootProps } = useDropzone({
    multiple: false,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length !== 1) {
        toast({
          status: 'error',
          title: 'You can only upload one file at once',
          position: 'bottom',
        });
      } else {
        addAttachment(acceptedFiles[0]);
        refetch();
      }
    },
  });

  const currentCard = useMemo(
    () => (customList ?? boardElements).find((e) => e.id === refId),
    [boardElements, customList, refId]
  );

  const avatarSearchResults = useMemo(
    () =>
      getPotentialAssignees({
        availableAvatars: avatars,
        currentUserId: currentUser?._id,
        currentlyAssignedAvatars: currentCard?.assignedAvatars ?? [],
        query: searchValue,
        showAssigneesWithoutQuery,
      }),
    [
      currentUser?._id,
      currentCard?.assignedAvatars,
      avatars,
      showAssigneesWithoutQuery,
      searchValue,
    ]
  );

  const handleAssign = useCallback(
    (id: string) => {
      updateElement({
        id: refId!,
        assignedAvatars: [...(currentCard?.assignedAvatars ?? []), id],
      });
      setSearchValue('');
      setShowAssigneesWithoutQuery(false);
    },
    [currentCard, refId, updateElement, setSearchValue]
  );

  useEffect(() => {
    if (refId !== undefined) {
      getCardDetails({ id: refId, isArchived });
      getAvatars(boardId!);
    }
  }, [boardId, getAvatars, getCardDetails, isArchived, refId]);

  const handleClose = useCallback(() => {
    // only set ElementState if it is not archived
    if (!isArchived) {
      setElementState({ id: refId!, state: BoardElementState.FOCUSED });
    }
    close();
  }, [close, isArchived, refId, setElementState]);

  const handleCloseModal = useCallback(async () => {
    if (onClose !== undefined) {
      onClose();
    } else {
      handleClose();
    }
    await nonUpdatingStore
      .getActions()
      .notifications.markAllNotificationsForCardAsRead(refId ?? '');
  }, [handleClose, onClose]);

  const handleRequestCloseModal = useCallback(() => {
    if (isTitleEditing || isDescriptionEditing || isAnyCommentEditing || isNewCommentEditing) {
      onOpenConfirmation();
    } else {
      handleCloseModal();
    }
  }, [
    isTitleEditing,
    isDescriptionEditing,
    isAnyCommentEditing,
    isNewCommentEditing,
    onOpenConfirmation,
    handleCloseModal,
  ]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === Key.Escape) {
        // Check if currentElement is an input or a textarea
        e.preventDefault();
        e.stopPropagation();
        const currentElement = document.activeElement as HTMLElement;
        if (
          ['input', 'textarea'].includes(currentElement.tagName.toLowerCase()) ||
          currentElement.isContentEditable
        ) {
          if (currentElement.id === ASSIGNED_TO_ID) {
            setSearchValue('');
            setShowAssigneesWithoutQuery(false);
          }
          // If so blur the element
          currentElement.blur();
        } else {
          // Else close the modal
          handleRequestCloseModal();
        }
      }
      if (e.key === Key.Enter && currentAvatarIndex > -1) {
        handleAssign(avatarSearchResults[currentAvatarIndex].id);
        setCurrentAvatarIndex(-1);
      }
      if (
        e.key === Key.ArrowDown &&
        avatarSearchResults.length > 0 &&
        currentAvatarIndex < avatarSearchResults.length - 1
      ) {
        e.preventDefault();
        setCurrentAvatarIndex(currentAvatarIndex + 1);
      }
      if (e.key === Key.ArrowUp && avatarSearchResults.length > 0 && currentAvatarIndex > -1) {
        e.preventDefault();
        setCurrentAvatarIndex(currentAvatarIndex - 1);
      }
    },
    [avatarSearchResults, currentAvatarIndex, handleAssign, handleRequestCloseModal]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    getAvatars(boardId!);
  }, []);

  useEffect(() => {
    // if all editors are closed, set focus back to initalRef element to allow keyboard controls
    if (!isTitleEditing && !isDescriptionEditing && !isNewCommentEditing && !isAnyCommentEditing) {
      // @ts-expect-error: whatever
      initialRef?.current?.focus();
    }
  }, [isTitleEditing, isDescriptionEditing, isNewCommentEditing, isAnyCommentEditing]);

  return (
    <>
      <Box
        left="0"
        top="0"
        height="100vh"
        width="100vw"
        position="fixed"
        zIndex={isDragActive ? 2500 : 0}
        {...(getRootProps() as any)}
      >
        {isDragActive && (
          <Flex
            borderRadius="md"
            left="0"
            top="0"
            height="100vh"
            width="100vw"
            position="fixed"
            bg="rgba(255, 255, 255, 0.7)"
            borderWidth="4px"
            borderColor="yoBrand.500"
            justifyContent="center"
            alignItems="center"
          >
            <Flex color="yoBrand.500">
              <Icon as={MdFileUpload} fontSize={22} />
              <Text fontWeight="bold">Drop files to upload</Text>
            </Flex>
            <input {...getInputProps()} />
          </Flex>
        )}
        <Modal
          onClose={() => {
            handleRequestCloseModal();
          }}
          isOpen
          scrollBehavior="outside"
          size="6xl"
          initialFocusRef={initialRef}
          // we NEED this in order to make loom work
          trapFocus={false}
        >
          <ModalOverlay />
          <ModalContent ref={initialRef}>
            <ModalCloseButton display={{ base: 'flex', md: 'none' }} />
            <ModalBody
              p={0}
              color="yoGray.900"
              // Prevent the default esc close behaviour
              onKeyDown={(e) => {
                if (e.key === Key.Escape) {
                  e.preventDefault();
                }
              }}
              onClick={() => {
                if (searchValue) {
                  setSearchValue('');
                }
              }}
            >
              <Flex w="100%" flexDirection={{ base: 'column', md: 'row' }}>
                <LeftColumn
                  description={currentCard?.content as any}
                  history={currentCard?.history as any}
                  comments={currentCard?.comments as any}
                  refId={refId as any}
                  title={currentCard?.title as any}
                  readOnly={isArchived}
                  setIsTitleEditing={setIsTitleEditingExtended}
                  setIsDescriptionEditing={setIsDescriptionEditing}
                  setIsNewCommentEditing={setIsNewCommentEditing}
                  setIsAnyCommentEditing={setIsAnyCommentEditing}
                  cardIntegrationMeta={currentCard?.cardIntegrationMeta}
                  cardIntegrationType={currentCard?.cardIntegrationType}
                  links={currentCard?.links}
                  thumbnailUrl={currentCard?.thumbnailUrl}
                  attachmentObject={attachmentObject}
                />
                <RightColumn
                  assignedAvatars={currentCard?.assignedAvatars as any}
                  blockers={currentCard?.blockers}
                  creator={avatars.find(
                    (stateAvatar) =>
                      stateAvatar.id === (currentCard?.history?.[0] as CardEventDTO)?.createdBy?._id
                  )}
                  watchers={currentCard?.watchers}
                  avatars={avatars}
                  onChange={setSearchValue}
                  onSearchFocus={() => {
                    setShowAssigneesWithoutQuery(true);
                  }}
                  onSearchBlur={() => {
                    setShowAssigneesWithoutQuery(false);
                  }}
                  refId={refId!}
                  searchValue={searchValue}
                  currentAvatarIndex={currentAvatarIndex}
                  onAssign={handleAssign}
                  searchResults={avatarSearchResults}
                  handleClose={handleRequestCloseModal}
                  readOnly={isArchived}
                  attachmentObject={attachmentObject}
                />
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>
      <CloseConfirmation
        isOpen={isOpen}
        onAccept={handleCloseModal}
        onDecline={onCloseConfirmation}
        setIsTitleEditing={setIsTitleEditingExtended}
      />
    </>
  );
};

export default DetailView;
