import { Button, Text, HStack, Icon } from '@chakra-ui/react';
import { BoardElementLink } from 'shared';
import { getVisualLinksThatHaveBoardElement } from 'lib/Links.helper';
import { useEffect, useMemo } from 'react';
import { MdClose } from 'react-icons/md';
import { useStoreActions, useStoreState } from 'store/hooks';
import { Key } from 'ts-key-enum';

interface Props {
  links: BoardElementLink[];
}

export function CloseLinksButton({ links }: Props) {
  const closeLinks = useStoreActions((actions) => actions.app.hideCardLinks);
  const boardElement = useStoreState((store) => store.board.boardElements);

  const linkAmount = useMemo(
    () => getVisualLinksThatHaveBoardElement(links, boardElement).length,
    [boardElement, links]
  );

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === Key.Escape) {
        closeLinks();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [closeLinks]);

  return (
    <HStack
      bg="designAnnotation.500"
      boxShadow="topBar"
      borderRadius={6}
      position="fixed"
      bottom={4}
      left="50%"
      zIndex={300}
      transform="translateX(-50%)"
      color="white"
      alignItems="center"
      px={4}
      py={2}
    >
      <Text fontWeight={700} fontSize="16">
        Showing {linkAmount} linked Card{linkAmount === 1 ? '' : 's'}
      </Text>
      <Button
        rightIcon={<Icon as={MdClose} fontSize="22px" ml={-1} />}
        onClick={() => closeLinks()}
        variant="outline"
        _hover={{ bg: 'designAnnotation.600' }}
        _active={{ bg: 'designAnnotation.600' }}
        height="35px"
        px={2}
      >
        Hide
      </Button>
    </HStack>
  );
}
