import { AttachmentDTO } from 'shared';
import { useRef } from 'react';
import {
  Text,
  Flex,
  Grid,
  Icon,
  Stack,
  Image,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tag,
  Spinner,
} from '@chakra-ui/react';
import { FileIcon, MenuDots } from 'lib/icons';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { getFullnameFromUser } from 'lib/helpers';
import theme from 'lib/theme';
import { useAttachments } from 'hooks/useAttachments';
import { nonUpdatingStore } from 'store/index';
import { useStoreState } from 'store/hooks';
import { Section } from './Section';

dayjs.extend(relativeTime);

const isImage = (mimeType: string) => mimeType.startsWith('image/');

type AttachmentProps = {
  onRemove: () => void;
  onSetAsImage: () => void;
  isDisabled?: boolean;
  isCurrentCardImage: boolean;
  isUploading?: boolean;
} & AttachmentDTO;

const Attachment = ({
  mimeType,
  onRemove,
  onSetAsImage,
  url,
  name,
  createdAt,
  createdBy,
  isDisabled = false,
  isCurrentCardImage,
}: AttachmentProps) => (
  <a href={url} target="_blank" rel="noreferrer">
    <Grid
      gridTemplateColumns="auto minmax(0, 1fr) auto"
      height="80px"
      bg="taggleGray.300"
      _hover={{ bg: 'taggleGray.500' }}
      borderRadius="modal"
      pr={6}
    >
      <Flex
        width="120px"
        height="100%"
        justifyContent="center"
        alignItems="center"
        bg="rgba(63, 84, 116, 0.2)"
        borderLeftRadius="inherit"
      >
        {isImage(mimeType) ? (
          <Image
            src={url}
            height="80px"
            width="120px"
            borderLeftRadius="inherit"
            objectFit="cover"
          />
        ) : (
          <Icon as={FileIcon} fontSize={28} color="yoBrand.500" />
        )}
      </Flex>
      <Flex pl={4} justifyContent="center" fontSize={16} direction="column">
        <Text
          title={name}
          fontWeight="bold"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {name}
        </Text>
        <Text color="#565656">
          {dayjs(createdAt).fromNow()}, by {getFullnameFromUser(createdBy)}
        </Text>
      </Flex>
      <Flex
        justifyContent="center"
        alignItems="center"
        // this zone should not open the attachment
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        {isCurrentCardImage && (
          <Tag colorScheme="green" mr={2}>
            Card Image
          </Tag>
        )}
        <Menu variant="yo">
          <MenuButton
            as={IconButton}
            icon={<MenuDots />}
            transform="rotate(90deg)"
            bg="inherit"
            _hover={{ bg: 'taggleOceanBlue.100' }}
            isDisabled={isDisabled}
          />
          <MenuList>
            <MenuItem isDisabled={!isImage} onClick={onSetAsImage}>
              {isCurrentCardImage ? 'Unset Card Image' : 'Set as Card Image'}
            </MenuItem>
            <MenuItem onClick={onRemove} _hover={{ bg: 'red.400' }}>
              Delete Attachment
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Grid>
  </a>
);

const AttachmentSpinner = () => (
  <Grid
    gridTemplateColumns="auto minmax(0, 1fr) auto"
    height="80px"
    bg="taggleGray.300"
    _hover={{ bg: 'taggleGray.500' }}
    borderRadius="modal"
    pr={6}
  >
    <Flex
      width="120px"
      height="100%"
      justifyContent="center"
      alignItems="center"
      bg="rgba(63, 84, 116, 0.2)"
      borderLeftRadius="inherit"
    >
      <Spinner color={theme.colors.yoBrand[600]} size="lg" />
    </Flex>
    <Flex pl={4} justifyContent="center" fontSize={16} direction="column">
      <Text
        title="Uploading..."
        fontWeight="bold"
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
      >
        Uploading file...
      </Text>
    </Flex>
  </Grid>
);

type Props = {
  cardId: string;
  isArchived?: boolean;
  attachmentObject: ReturnType<typeof useAttachments>;
};

export function AttachmentSection({ isArchived, attachmentObject, cardId }: Props) {
  const inputRef = useRef<HTMLInputElement>();

  const currentElement = useStoreState((store) =>
    store.board.boardElements.find((el) => el.id === cardId)
  );

  const isCurrentCardImage = (url: string) => currentElement?.thumbnailUrl === url;

  const { attachments, isLoading, isUploadingFile, addAttachment, deleteAttachment } =
    attachmentObject;

  const onDelete = async (attachment: AttachmentDTO) => {
    await deleteAttachment(attachment._id);
    if (isCurrentCardImage(attachment.url)) {
      nonUpdatingStore.getActions().board.updateElement({
        id: cardId,
        thumbnailUrl: null,
      });
    }
  };

  const onSetAsImageClick = (attachment: AttachmentDTO) => {
    nonUpdatingStore.getActions().board.updateElement({
      id: cardId,
      thumbnailUrl: isCurrentCardImage(attachment.url) ? null : attachment.url,
    });
  };

  return (
    <Section
      title="Attachment "
      show={!isLoading && ((attachments && attachments.length > 0) || isUploadingFile)}
      onAddClick={() => inputRef.current?.click()}
      isDisabled={isArchived}
    >
      {' '}
      <Stack spacing={6}>
        {attachments.map((attachment: any) => (
          <Attachment
            key={attachment._id}
            {...attachment}
            onRemove={() => onDelete(attachment)}
            isDisabled={isArchived}
            onSetAsImage={() => onSetAsImageClick(attachment)}
            isCurrentCardImage={isCurrentCardImage(attachment.url)}
          />
        ))}
        {isUploadingFile && <AttachmentSpinner />}
        <input
          multiple={false}
          accept="*"
          type="file"
          hidden
          ref={inputRef as any}
          onChange={(e) => {
            const { files } = e.target as any;
            if (files.length !== 1) return;
            addAttachment(files[0]);
            if (inputRef.current) inputRef.current.value = '';
          }}
        />
      </Stack>
    </Section>
  );
}
