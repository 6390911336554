/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { useRef, memo } from 'react';
import { Rect, Text, Group } from 'react-konva';
import { Text as IText } from 'konva/types/shapes/Text';
import { Group as IGroup } from 'konva/types/Group';
import { BoardElementState, UIBoardElement } from 'types/index';
import StatefulTransformer from 'components/canvas/Transformer';
import { useBoardElementProps, useTransformer } from 'hooks/Elements';
import theme from 'lib/theme';
import { getStickyColorByName } from 'lib/colors';
import { ValidationRules } from 'shared';
import { useGlobalWidgetResize } from 'hooks/Events';
import { useMergeRefs } from '@chakra-ui/react';
import FocusBorder from '../FocusBorder/FocusBorder';

const FLOATING_STICKY_BOX_SHADOW = theme.shadows.floating;
const DEFAULT_STICKY_BOX_SHADOW = theme.shadows.layer2;
const STICKY_FONT_COLOR = theme.colors.yoGray[900];
const STICKY_BORDER_RADIUS = parseInt(theme.radii.sm, 10);

const STICKY_DEFAULT_WIDTH = 100;
const STICKY_DEFAULT_HEIGHT = 100;

export const SHARED_STYLES = {
  fontSize: parseInt(theme.fontSizes.md, 10), // default unit px removed
  padding: parseInt(theme.space[3], 10), // default unit px removed
  lineHeight: parseInt(theme.lineHeights.base, 10),
  textAlign: 'center',
  verticalAlign: 'middle',
  width: STICKY_DEFAULT_WIDTH,
  height: STICKY_DEFAULT_HEIGHT,
};

const Sticky: React.FC<UIBoardElement> = ({
  id,
  color,
  title = '',
  shape: { x = 0, y = 0, height = SHARED_STYLES.height, angle, width = SHARED_STYLES.width },
  state,
}: any) => {
  const textRef = useRef<IText>(null);
  const groupRef = useRef<IGroup>(null);

  const { localWidthAndHeight, rectProps, rectRef, transformerRef } = useTransformer({
    width,
    height,
    state,
    id,
  });

  const {
    position,
    draggable,
    handleClick,
    handleDragEnd,
    handleDragMove,
    handleDragStart,
    handleMouseDown,
    handleDoubleClickOrTap,
    handleTouchStart,
    elementRef,
  } = useBoardElementProps({ state, elementId: id, initialPosition: { x, y } });

  useGlobalWidgetResize(id, textRef);

  const swimlaneShadow =
    state === BoardElementState.FLOATING ? FLOATING_STICKY_BOX_SHADOW : DEFAULT_STICKY_BOX_SHADOW;

  return (
    <Group
      ref={useMergeRefs(groupRef, elementRef)}
      width={width}
      height={height}
      rotation={angle}
      draggable={draggable}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onMouseDown={handleMouseDown}
      onDragMove={handleDragMove}
      onClick={handleClick}
      onTap={handleClick}
      onTouchStart={handleTouchStart}
      {...position}
    >
      {state !== BoardElementState.DEFAULT &&
        state !== BoardElementState.FLOATING &&
        state !== BoardElementState.RESIZABLE && (
          <Rect
            fill="transparent"
            x={-1}
            y={-1}
            cornerRadius={STICKY_BORDER_RADIUS}
            width={width + 2}
            height={height + 2}
            stroke={theme.colors.yoBrand[300]}
            strokeWidth={2}
            listening={false}
          />
        )}
      <FocusBorder
        state={state}
        cornerRadius={3}
        height={localWidthAndHeight.height}
        width={localWidthAndHeight.width}
      />
      <Rect
        id={id}
        isBoardElement
        ref={rectRef}
        key={id}
        fill={getStickyColorByName(color).value}
        cornerRadius={STICKY_BORDER_RADIUS}
        shadowColor={swimlaneShadow.color}
        shadowBlur={swimlaneShadow.blurRadius}
        shadowOffsetX={swimlaneShadow.offsetX}
        shadowOffsetY={swimlaneShadow.offsetY}
        width={width}
        height={height}
        getGroup={() => groupRef.current}
        getId={() => id}
        listening={false}
        {...rectProps}
      />
      <Text
        ref={textRef}
        text={title}
        fill={STICKY_FONT_COLOR}
        fontFamily={theme.fonts.boardElements}
        wrap="word"
        align={SHARED_STYLES.textAlign}
        verticalAlign={SHARED_STYLES.verticalAlign}
        width={localWidthAndHeight.width}
        height={localWidthAndHeight.height}
        fontSize={SHARED_STYLES.fontSize}
        lineHeight={SHARED_STYLES.lineHeight}
        padding={SHARED_STYLES.padding}
        onDblClick={handleDoubleClickOrTap}
        onDblTap={handleDoubleClickOrTap}
      />
      {state === BoardElementState.RESIZABLE && (
        <StatefulTransformer
          state={state}
          transformerRef={transformerRef}
          minValue={ValidationRules.LayoutRules.Sticky.STICKY_MIN_SIZE}
          maxValue={ValidationRules.LayoutRules.Sticky.STICKY_MAX_SIZE}
        />
      )}
    </Group>
  );
};

export default memo(Sticky);
