/* eslint-disable import/prefer-default-export */
import type { DefaultSeoProps } from 'next-seo';

export const defaultSeo: DefaultSeoProps = {
  twitter: {
    handle: 'gettaggle',
    cardType: 'summary_large_image',
  },
  defaultTitle: 'Taggle | The Project Management tool that feels like a real whiteboard',
  titleTemplate: '%s | Taggle',
  openGraph: {
    images: [{ url: 'https://ucarecdn.com/ce8d6938-61d0-4aab-92d8-ec0ea2df01bc/image.png' }],
  },
  description: `Collaborate on Kanban Boards with Ease.
  Taggle takes the best out of the digital as well as the analogue world and combines them to the best digital kanban tool.`,
};
