import React from 'react';
import { Text, Label, Tag } from 'react-konva';
import { Label as ILabel } from 'konva/types/shapes/Label';
import theme from 'lib/theme';

interface WIPChipProps {
  cardsAttached: number;
  wipLimit: number;
  chipBackground: string;
  chipFontColor: string;
  small?: any;
}

const WIPChip = React.forwardRef<ILabel, WIPChipProps>(
  (
    { chipFontColor, cardsAttached = 0, wipLimit = 0, chipBackground = 'white', small },
    forwardedRef
  ) => (
    <Label ref={forwardedRef} x={0} y={0} listening={false}>
      <Tag fill={chipBackground} cornerRadius={parseInt(theme.radii.sm, 10)} />
      <Text
        offsetY={-1}
        fontSize={16}
        // we use the lineHeight to decrease height
        // because padding value applies equally for
        // to, right, bottom and left
        lineHeight={small ? 0 : 0.3}
        fontFamily={theme.fonts.boardElements}
        fontStyle="600"
        fill={chipFontColor}
        padding={small ? 10 : 12}
        text={`${cardsAttached}${wipLimit > 0 ? `/${wipLimit}` : ''}`}
      />
    </Label>
  )
);

export default WIPChip;
