import {
  Avatar,
  FormControl,
  FormLabel,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Switch,
  Heading,
  Stack,
  Text,
  Square,
  Circle,
  Button,
  Box,
  Flex,
  useToast,
} from '@chakra-ui/react';
import { NotificationsIcon } from 'lib/icons';
import { NotificationDTO, CardEventType } from 'shared';
import nonUpdatingStore from 'store/index';
import { useStoreState } from 'store/hooks';
import { useEffect, useState } from 'react';
import { getFullnameFromUser } from 'lib/helpers';
import { zoomOrRedirectToItem } from 'hooks/useCardDeepLink';
import { useRouter } from 'next/router';
import { getColorByName } from 'lib/colors';
import theme from 'lib/theme';
import { useUser } from 'hooks/User';
import type { UIUser } from 'store/user';
import DateTimeTooltip from '../DateTimeTooltip';

function getActionContent(notification: NotificationDTO, currentUser?: UIUser) {
  const isUserNotificationTarget = notification.assignedUser?._id === currentUser?._id;
  const isSelfAssign = notification.assignedUser?._id === notification.createdBy?._id;

  switch (notification.notificationType) {
    case CardEventType.CARD_DELETED: {
      return 'deleted a card';
    }
    case CardEventType.CARD_ARCHIVED: {
      return 'archived a card';
    }
    case CardEventType.MOVE: {
      return 'moved a card';
    }
    case CardEventType.CARD_UNASSIGNED: {
      if (isUserNotificationTarget) {
        return 'unassigned you from a card';
      }
      if (isSelfAssign) {
        return 'unassigned themselves to a card';
      }
      return `unassigned ${getFullnameFromUser(notification.assignedUser)} from a card`;
    }
    case CardEventType.CARD_ASSIGNED: {
      if (isUserNotificationTarget) {
        return 'assigned you to a card';
      }
      if (isSelfAssign) {
        return 'assigned themselves to a card';
      }
      return `assigned ${getFullnameFromUser(notification.assignedUser)} to a card`;
    }
    case CardEventType.CARD_COMMENT: {
      return 'added a comment';
    }
    case CardEventType.BLOCKER_CREATED: {
      return 'created a blocker';
    }
    case CardEventType.BLOCKER_DELETED: {
      return 'deleted a blocker';
    }
    case CardEventType.BLOCKER_EDITED: {
      return 'updated a blocker';
    }
    case CardEventType.BLOCKER_REOPENED: {
      return 'reopened a blocker';
    }
    case CardEventType.BLOCKER_RESOLVED: {
      return 'resolved a blocker';
    }
    case CardEventType.COMMENT_MENTION: {
      return 'mentioned you in a comment';
    }
    case CardEventType.DESCRIPTION_MENTION: {
      return 'mentioned you in a card';
    }
    default: {
      return 'did something';
    }
  }
}

export const useNotificationContent = (notification: NotificationDTO) => {
  const currentUser = useUser();
  const router = useRouter();
  const toast = useToast();
  const creator = getFullnameFromUser(notification.createdBy) || 'Someone';

  const action = getActionContent(notification, currentUser);
  const title = `${creator} ${action}`;
  const color = getColorByName(notification.element?.color ?? 'White').value;
  const movedToColumn = notification.movedToColumn ? ` ➞ ${notification.movedToColumn}` : '';
  const assignee = notification.assignedUser?.firstName
    ? ` ➞ ${notification.assignedUser.firstName}`
    : '';
  const description = `${
    notification.element?.title ?? 'Deleted Element'
  }${movedToColumn}${assignee}`;
  const onClick = async () => {
    if (notification.element?._id) {
      await zoomOrRedirectToItem({
        boardId: notification.boardId,
        elementId: notification.element._id,
        redirectFunc: (url) => router.replace(url),
        setOffset: nonUpdatingStore.getActions().boardSettings.setOffset,
        setZoomLevel: nonUpdatingStore.getActions().boardSettings.setZoomLevel,
        setElementState: nonUpdatingStore.getActions().board.setElementState,
        toast,
      });
    }
    // even if the element was deleted, we still want to mark the notification as read
    await nonUpdatingStore.getActions().notifications.markNotificationsAsRead([notification._id]);
  };
  return { title, color, description, onClick };
};

export const NotificationEntry = (notification: NotificationDTO) => {
  const { title, description, color, onClick } = useNotificationContent(notification);
  return (
    <MenuItem p="5">
      <Flex w="100%" role="button" onClick={onClick}>
        <Avatar
          name={getFullnameFromUser(notification.createdBy)}
          src={notification.createdBy?.avatarUrl}
          size="sm"
          marginRight={4}
        />
        <Stack flexGrow={1}>
          <Heading color="taggleOceanBlue.900" fontSize="sm" size="sm" as="h3">
            {title}
          </Heading>
          <Flex alignItems="center">
            <Square boxShadow="layer1.css" borderRadius="sm" mr="2" bg={color} size={5} />
            <Text color="black" fontSize="sm">
              {description}
            </Text>
          </Flex>
          <Text color="taggleGray.800" fontWeight="bold" fontSize="xs">
            <DateTimeTooltip flexShrink={0} datetime={notification.createdAt || new Date()} />
          </Text>
        </Stack>
        {!notification.isRead && (
          <Circle ml="4" size="4" alignSelf="center" bg="taggleShinyBlue.500" />
        )}
      </Flex>
    </MenuItem>
  );
};

export const NotificationsButton = () => {
  const [showUnreadOnly, setShowUnreadOnly] = useState(true);
  const boardNotifications = useStoreState((store) => store.notifications.boardNotifications);
  const unreadNotifications = boardNotifications.filter((notification) => !notification.isRead);

  const notificationsToDisplay = showUnreadOnly ? unreadNotifications : boardNotifications;
  useEffect(() => {
    nonUpdatingStore.getActions().notifications.getUnreadBoardNotifications();
  }, []);

  return (
    <Menu placement="bottom-start" preventOverflow flip={false} offset={[0, 0]}>
      <MenuButton
        as={Button}
        leftIcon={<NotificationsIcon mr={-1} />}
        size="sm"
        variant={unreadNotifications.length > 0 ? 'notifications' : 'noNotifications'}
        px={2}
      >
        {unreadNotifications.length}
      </MenuButton>
      <MenuList
        py={0}
        w="400px"
        overflow="hidden"
        mt="6"
        mr="6"
        // those need to be important because some chakra library
        // overrides it when they are not focused
        boxShadow={`${theme.shadows.topBar} !important`}
        _focus={{ bg: `${theme.shadows.topBar} !important` }}
      >
        <MenuItem
          as="div"
          bg="taggleGray.300"
          closeOnSelect={false}
          _hover={{ bg: 'taggleGray.300', cursor: 'default' }}
          px="6"
          pt="2"
        >
          <Flex w="100%" h={10} alignItems="center" justify="space-between">
            <Button
              variant="link"
              _hover={{ textDecoration: 'none' }}
              color="taggleOceanBlue"
              fontSize="sm"
              onClick={() => {
                nonUpdatingStore
                  .getActions()
                  .notifications.markNotificationsAsRead(boardNotifications.map((n) => n._id));
              }}
            >
              Mark all as read
            </Button>
            <Box>
              <FormControl display="flex" alignItems="center">
                <FormLabel
                  color="taggleGray.900"
                  htmlFor="showOnlyUnread"
                  mb="0"
                  fontSize="sm"
                  fontWeight="normal"
                  cursor="pointer"
                >
                  only show unread
                </FormLabel>
                <Switch
                  size="sm"
                  colorScheme="taggleOceanBlue"
                  id="showOnlyUnread"
                  checked={showUnreadOnly}
                  onChange={() => setShowUnreadOnly(!showUnreadOnly)}
                  defaultChecked
                />
              </FormControl>
            </Box>
          </Flex>
        </MenuItem>
        <Box overflowY="auto" maxHeight="calc(100vh - 230px)">
          {notificationsToDisplay.length === 0 ? (
            <Flex height="150px" justifyContent="center" alignItems="center">
              <Text fontWeight="500" fontSize={16} color="yoGray.900">
                <Text as="span" display="block" textAlign="center" mb={2}>
                  👍 🌴 🎉
                </Text>
                You you have no {showUnreadOnly ? 'unread' : ''} notifications
              </Text>
            </Flex>
          ) : (
            notificationsToDisplay.map((boardNotification) => (
              <NotificationEntry key={boardNotification._id} {...boardNotification} />
            ))
          )}
        </Box>
      </MenuList>
    </Menu>
  );
};
