import { SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
} from '@chakra-ui/react';
import { isMac } from 'lib/helpers';
import React, { useMemo, useRef, useState } from 'react';
import { useStoreActions, useStoreState } from 'store/hooks';
import { Shortcut, shortcuts, shortcutsWithCtrl } from 'lib/Shortcuts';
import { YoKbd } from '../TooltipWithShortcut';
import YoModal from '../YoModal';

type ShortcutWithKey = Shortcut & { key: string };

const SHORTCUTS = [
  {
    title: 'Board',
    shortcuts: [
      ...Object.entries(shortcuts)
        .filter(([, value]) => !value.isHidden)
        .map<ShortcutWithKey>(([key, value]) => ({
          ...value,
          key,
        })),
      ...Object.entries(shortcutsWithCtrl)
        .filter(([, value]) => !value.isHidden)
        .map<ShortcutWithKey>(([key, value]) => ({
          ...value,
          key,
          hasCmdOrCtrl: true,
        })),
    ],
  },
];

export function ShortcutModal() {
  const searchInputRef = useRef<HTMLInputElement>(null);
  const isOpen = useStoreState((store) => store.app.isShortcutPopoverOpen);
  const onClose = useStoreActions((store) => store.app.setIsShortcutPopoverOpen);
  const [query, setQuery] = useState('');
  const cmdOrCtrl = useMemo(() => (isMac() ? '⌘' : 'ctrl'), []);

  const filteredShortcuts = useMemo(() => {
    if (!query) return SHORTCUTS;

    return SHORTCUTS.map((currentShortcut) => ({
      ...currentShortcut,
      shortcuts: currentShortcut.shortcuts.filter((shortcut) =>
        shortcut.text.toLowerCase().includes(query.toLowerCase())
      ),
    }));
  }, [query]);

  return (
    <YoModal
      title="Shortcuts"
      isOpen={isOpen}
      onClose={() => onClose(false)}
      hasCancel={false}
      hasConfirm={false}
      initialFocusRef={searchInputRef}
    >
      <Stack spacing={6}>
        <InputGroup>
          <Input
            ref={searchInputRef}
            type="search"
            placeholder="Search for shortcuts"
            onChange={(e) => setQuery(e.target.value)}
            value={query}
          />
          <InputLeftElement>
            <Icon as={SearchIcon} />
          </InputLeftElement>
        </InputGroup>
        {filteredShortcuts.map((shortcutGroup) => (
          <Box key={shortcutGroup.title}>
            <Heading size="sm" mb={4}>
              {shortcutGroup.title}
            </Heading>
            <Stack spacing={4}>
              {shortcutGroup.shortcuts.map((shortcut) => (
                <Flex
                  key={`${shortcut.text}-${query}`}
                  data-key={`${shortcut.text}-${query}`}
                  justifyContent="space-between"
                >
                  <Text fontSize="sm">{shortcut.text}</Text>
                  <Box>
                    {shortcut.hasCmdOrCtrl ? (
                      <>
                        <YoKbd>{cmdOrCtrl}</YoKbd> +{' '}
                      </>
                    ) : null}
                    {shortcut.alias?.map((alias) => (
                      <React.Fragment key={alias}>
                        <YoKbd>{alias.toLowerCase()}</YoKbd> /{' '}
                      </React.Fragment>
                    ))}
                    <YoKbd>{shortcut.key.toLowerCase()}</YoKbd>
                  </Box>
                </Flex>
              ))}
            </Stack>
          </Box>
        ))}
      </Stack>
    </YoModal>
  );
}
