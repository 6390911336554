/* eslint-disable import/prefer-default-export */
import { useStoreState, useStoreActions } from 'store/hooks';
import { useEffect } from 'react';
import { useToast } from '@chakra-ui/react';

export function useErrorToasts() {
  const toast = useToast();
  const errors = useStoreState((state) => state.errors.errors);
  const removeError = useStoreActions((actions) => actions.errors.removeError);
  useEffect(() => {
    if (errors.length > 0) {
      toast({
        duration: 3000,
        status: 'error',
        position: 'bottom',
        variant: 'solid',
        title: 'Error!',
        description: errors[0].message,
      });
      removeError(errors[0].id);
    }
  }, [errors.length]);
}
