/* eslint-disable import/prefer-default-export */
import { UIBoardElement } from 'types/index';
import { useStoreState, useStoreActions } from 'store/hooks';
import { isColumnOrSwimlane, isElementInElement } from 'lib/helpers';
import { getElementsToUpdateForZIndexChanges, getZIndexBetween } from 'lib/zIndex';

const DEFAULT_USE_ORDERING_RETURN = {
  sendBackward: null,
  sendForward: null,
  sendToBack: null,
  sendToTop: null,
  isOnTop: null,
  isInBack: null,
};

function useOrdering(currentBoardElement: UIBoardElement | undefined) {
  const boardElements = useStoreState((state) => state.board.boardElements);
  const { updateElement } = useStoreActions((actions) => actions.board);

  // this also catches the case that there are no boardElements at all
  if (!currentBoardElement) {
    return DEFAULT_USE_ORDERING_RETURN;
  }

  // if currentElement is a column -> only work inside of the columns
  // if currentElement is not a column -> only work with cards and stickies
  const elementsToWorkOn = isColumnOrSwimlane(currentBoardElement)
    ? boardElements.filter((boardElement) => isColumnOrSwimlane(boardElement))
    : boardElements.filter((boardElement) => !isColumnOrSwimlane(boardElement));

  // if there is only 1 element -> no ordering options for the element
  if (elementsToWorkOn.length === 1) {
    return DEFAULT_USE_ORDERING_RETURN;
  }

  // for Cards or Notes this is the overall top element
  // for Columns this is the highest Column
  const elementOnTop = !isColumnOrSwimlane(currentBoardElement)
    ? boardElements[boardElements.length - 1]
    : elementsToWorkOn[elementsToWorkOn.length - 1];
  // for Cards or Notes this is the lowest Card or Note
  // for Columns this is the lowest overall element
  const elementInBack = !isColumnOrSwimlane(currentBoardElement)
    ? elementsToWorkOn[0]
    : boardElements[0];
  const isOnTop = currentBoardElement.id === elementOnTop.id;
  const isInBack = currentBoardElement.id === elementInBack.id;

  const sendBackward = () => {
    // if already in back -> do nothing
    if (isInBack) return;
    // only work with elements behind currentElement
    const elementsBehind = elementsToWorkOn.filter(
      (boardElement) => (boardElement.zIndex ?? 0) < (currentBoardElement.zIndex ?? 0)
    );
    // TODO: tolerance should maybe be in pixels not in percentage ->
    // create config for tolerance type later
    const elementsDirectlyBehind = elementsBehind.filter((elementBehind) =>
      isElementInElement(currentBoardElement.shape ?? {}, elementBehind.shape ?? {}, {
        tolerance: 0.9999,
      })
    );
    if (elementsDirectlyBehind.length > 0) {
      // the element with overlap that is the closest behind the current element
      const elementBehind = elementsDirectlyBehind[elementsDirectlyBehind.length - 1];
      const indexOfElementBehind = boardElements.findIndex(
        (element) => element.id === elementBehind.id
      );
      const elementBehindBehind = boardElements[indexOfElementBehind - 1];
      const updatedZIndex = getZIndexBetween(
        elementBehind.zIndex ?? 0,
        elementBehindBehind?.zIndex ?? 0
      );
      // move currentElement to the updatedZIndex
      const updatedElements = getElementsToUpdateForZIndexChanges({
        boardElements,
        elementsWithZIndexUpdate: [
          {
            ...currentBoardElement,
            zIndex: updatedZIndex,
          },
        ],
      });
      updateElement(updatedElements);
    }
  };
  const sendForward = () => {
    // if already on top -> do nothing
    if (isOnTop) return;
    // only work with elements in front of currentElement
    const elementsInFront = elementsToWorkOn.filter(
      (boardElement) => (boardElement.zIndex ?? 0) > (currentBoardElement.zIndex ?? 0)
    );
    // TODO: tolerance should maybe be in pixels not in percentage ->
    // create config for tolerance type later
    const elementsDirectlyInFront = elementsInFront.filter((elementInFront) =>
      isElementInElement(currentBoardElement.shape ?? {}, elementInFront.shape ?? {}, {
        tolerance: 0.9999,
      })
    );
    if (elementsDirectlyInFront.length > 0) {
      // the element with overlap that is the closest in front of the current element
      const elementInFront = elementsDirectlyInFront[0];
      const elementInFrontIndex = boardElements.findIndex(
        (element) => element.id === elementInFront.id
      );
      const elementInFrontInFront =
        elementInFrontIndex < boardElements.length - 1
          ? boardElements[elementInFrontIndex + 1]
          : null;
      const updatedZIndex = getZIndexBetween(
        elementInFront.zIndex ?? 0,
        elementInFrontInFront ? elementInFrontInFront.zIndex! : undefined!
      );
      // move currentBoardElement to elementInFront and rebalance if needed
      const updatedElements = getElementsToUpdateForZIndexChanges({
        boardElements,
        elementsWithZIndexUpdate: [
          {
            ...currentBoardElement,
            zIndex: updatedZIndex,
          },
        ],
      });
      updateElement(updatedElements);
    }
  };

  const sendToTop = () => {
    // if already on top -> do nothing
    if (isOnTop) return;
    const elementOnTopIndex = boardElements.findIndex((element) => element.id === elementOnTop.id);
    const elementOnTopOnTop =
      elementOnTopIndex < boardElements.length - 1 ? boardElements[elementOnTopIndex + 1] : null;
    const updatedZIndex = getZIndexBetween(
      elementOnTop.zIndex ?? 0,
      elementOnTopOnTop ? elementOnTopOnTop.zIndex! : null!
    );
    // move currentBoardElement to top
    const updatedElements = getElementsToUpdateForZIndexChanges({
      boardElements,
      elementsWithZIndexUpdate: [
        {
          ...currentBoardElement,
          zIndex: updatedZIndex,
        },
      ],
    });
    updateElement(updatedElements);
  };

  const sendToBack = () => {
    // if already in back -> do nothing
    if (isInBack) return;
    const elementInBackIndex = boardElements.findIndex(
      (element) => element.id === elementInBack.id
    );
    const elementInBackInBack =
      elementInBackIndex > 0 ? boardElements[elementInBackIndex - 1] : null;
    const updatedZIndex = getZIndexBetween(
      elementInBack.zIndex ?? 0,
      elementInBackInBack ? elementInBackInBack.zIndex! : 0
    );
    // move currentBoardElement to back
    const updatedElements = getElementsToUpdateForZIndexChanges({
      boardElements,
      elementsWithZIndexUpdate: [
        {
          ...currentBoardElement,
          zIndex: updatedZIndex,
        },
      ],
    });
    updateElement(updatedElements);
  };

  return { sendBackward, sendForward, sendToBack, sendToTop, isOnTop, isInBack };
}

export { useOrdering };
