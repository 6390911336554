import { BoardElementDTO, BoardElementType, CardDetailDTO, Rectangle } from 'shared';

/**
 * This enum describes all possibles state for a BoardElement
 * to have while being on the board
 */
export enum BoardElementState {
  // Element is in the edit mode and has a widget like a textarea attached
  INEDIT = 'INEDIT',
  // Element is selected
  SELECTED = 'SELECTED',
  //  Element is resizable
  RESIZABLE = 'RESIZABLE',
  // Element is currently focused. Only One element can be focused at a time
  FOCUSED = 'FOCUSED',
  //  Element has no widgets and just draggable
  DEFAULT = 'DEFAULT',
  // Element has an open detail view,
  INDETAIL = 'INDETAIL',
  // Element is not yet placed and floating above the board
  FLOATING = 'FLOATING',
}

type BaseBoardElement = {
  state: BoardElementState;
} & Omit<BoardElementDTO, '_id'> &
  Partial<Pick<CardDetailDTO, 'content' | 'metrics' | 'attachments' | 'comments' | 'history'>>;

export type ClientCreatedBoardElement = Pick<BaseBoardElement, 'id' | 'shape' | 'type' | 'links'> &
  Partial<BaseBoardElement>;

/**
 * Type used to hold information about a CHANGE on a
 * board element. This is used for events that are sent
 */
export type BoardElementPayload = {
  id: string;
  // the shape can also contain partial changes
  shape?: Partial<Rectangle>;
} & Partial<Omit<BaseBoardElement, 'shape'>>;

export type UIBoardElement = {
  hasDetails?: boolean;
} & BaseBoardElement & { _id?: string };

export const NotCopyableBoardElementProperties: ReadonlyArray<keyof UIBoardElement> = [
  'commentCount',
  'hasDetails',
  'assignedAvatars',
  'blockers',
  'comments',
  'watchers',
];

export type UIBoardCard = UIBoardElement & { type: BoardElementType.CARD };
export type UIBoardColumn = UIBoardElement & { type: BoardElementType.COLUMN };
export type UIBoardSwimlane = UIBoardElement & { type: BoardElementType.SWIMLANE };
export type UIBoardSticky = UIBoardElement & { type: BoardElementType.STICKY };
