/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { memo, useRef } from 'react';
import { Rect, Group } from 'react-konva';
import { BoardElementState, UIBoardElement } from 'types/index';
import StatefulTransformer from 'components/canvas/Transformer';
import { useBoardElementProps, useCountElementsAttached, useTransformer } from 'hooks/Elements';
import theme from 'lib/theme';
import { BoardElementType, ValidationRules } from 'shared';
import Konva from 'konva';
import { useGlobalWidgetResize } from 'hooks/Events';
import { useMergeRefs } from '@chakra-ui/react';
import Header from './Header';
import FocusBorder from '../FocusBorder/FocusBorder';

export const COLUMN_WIDTH = 300;
export const COLUMN_HEIGHT = 800;

const FLOATING_COLUMN_BOX_SHADOW = theme.shadows.floating;

const COLUMN_BACKGROUND_COLOR = theme.colors.yoGray[200];
const COLUMN_HIGHLIGHT_BACKGROUND_COLOR = theme.colors.yoGray[100];
const COLUMN_BORDER_COLOR = theme.colors.yoGray[400];
const COLUMN_BORDER_RADIUS = parseInt(theme.radii.sm, 10);

const COLUMN_TITLE_COLOR = theme.colors.yoBrand[500];
const WIP_LIMIT_EXCEEDED_CHIP_BACKGROUND = theme.colors.yoRed[300];
const WIP_LIMIT_CHIP_BACKGROUND = theme.colors.yoGray[300];

const WIP_LIMIT_EXCEEDED_COLOR = theme.colors.yoRed[400];

export const SHARED_STYLES = {
  minWidthAndHeight: ValidationRules.LayoutRules.Column.COLUMN_MIN_SIZE,
  borderWidth: 1,
  borderColor: COLUMN_BORDER_COLOR,
  backgroundColor: COLUMN_BACKGROUND_COLOR,
  highlightBackgroundColor: COLUMN_HIGHLIGHT_BACKGROUND_COLOR,
  width: COLUMN_WIDTH,
  height: COLUMN_HEIGHT,
  text: {
    fontSize: parseInt(theme.fontSizes.md, 10), // remove unit px from default fontSize
    height: 50,
    padding: 20,
  },
};

const Column: React.FC<UIBoardElement> = ({
  id,
  emoji,
  title = '',
  shape: { x = 0, y = 0, height = COLUMN_HEIGHT, width = COLUMN_WIDTH },
  state,
  wipLimit = 0,
  isLocked,
  isSorted,
}: any) => {
  const { elementsAttachedCount } = useCountElementsAttached(
    { x, y, height, width },
    {
      excludeBoardElementsTypes: [
        BoardElementType.COLUMN,
        BoardElementType.STICKY,
        BoardElementType.SWIMLANE,
      ],
    }
  );
  const { rectRef, rectProps, transformerRef, localWidthAndHeight } = useTransformer({
    width,
    height,
    state,
    id,
    isLocked,
  });

  const wipLimitExceeded = wipLimit > 0 && wipLimit < elementsAttachedCount;

  const {
    position,
    draggable,
    handleClick,
    handleDragEnd,
    handleDragMove,
    handleDragStart,
    handleMouseDown,
    handleDoubleClickOrTap,
    handleTouchStart,
    elementRef,
  } = useBoardElementProps({
    state,
    elementId: id,
    initialPosition: { x, y },
    isLocked,
    canChangeColor: false,
    canBeResized: !isSorted,
  });

  const groupRef = useRef<Konva.Group>(null);

  const headerBoardColor = wipLimitExceeded ? WIP_LIMIT_EXCEEDED_COLOR : COLUMN_BACKGROUND_COLOR;

  useGlobalWidgetResize(id, groupRef);

  const floatingColumnShadowProps = {
    shadowColor: FLOATING_COLUMN_BOX_SHADOW.color,
    shadowBlur: FLOATING_COLUMN_BOX_SHADOW.blurRadius,
    shadowOffsetX: FLOATING_COLUMN_BOX_SHADOW.offsetX,
    shadowOffsetY: FLOATING_COLUMN_BOX_SHADOW.offsetY,
  };

  return (
    <Group
      ref={useMergeRefs(groupRef, elementRef)}
      name="column"
      // we need this wipLimitExceeded prop for useHighlightTargetColumn hook
      wipLimitExceeded={wipLimitExceeded}
      draggable={draggable && !isLocked}
      onDragEnd={handleDragEnd}
      onDragMove={handleDragMove}
      onDragStart={handleDragStart}
      onDblClick={handleDoubleClickOrTap}
      onDblTap={handleDoubleClickOrTap}
      onMouseDown={handleMouseDown}
      onClick={handleClick}
      onTap={handleClick}
      onTouchStart={handleTouchStart}
      {...position}
    >
      <Rect
        name={isLocked ? 'locked' : undefined}
        id={id}
        getGroup={() => groupRef.current}
        getId={() => id}
        isBoardElement
        ref={rectRef}
        key={id}
        fill={headerBoardColor}
        height={height}
        width={width}
        stroke={COLUMN_BORDER_COLOR}
        strokeWidth={wipLimitExceeded ? 0 : SHARED_STYLES.borderWidth}
        cornerRadius={COLUMN_BORDER_RADIUS}
        {...(state === BoardElementState.FLOATING ? floatingColumnShadowProps : {})}
        {...rectProps}
      />
      <Header
        x={12}
        y={12}
        title={title}
        emoji={emoji}
        wipLimit={wipLimit}
        elementsAttachedCount={elementsAttachedCount}
        wipLimitExceeded={wipLimitExceeded}
        state={state}
        width={localWidthAndHeight.width}
        chipBackground={
          wipLimitExceeded ? WIP_LIMIT_EXCEEDED_CHIP_BACKGROUND : WIP_LIMIT_CHIP_BACKGROUND
        }
        titleColor={wipLimitExceeded ? 'white' : COLUMN_TITLE_COLOR}
        isLocked={isLocked}
        isSorted={isSorted}
        isColumn
      />
      {wipLimitExceeded && (
        <Rect
          x={3}
          y={51}
          fill={COLUMN_BACKGROUND_COLOR}
          cornerRadius={COLUMN_BORDER_RADIUS}
          width={localWidthAndHeight.width - 6}
          height={localWidthAndHeight.height - 54}
        />
      )}
      <FocusBorder
        state={state}
        width={localWidthAndHeight.width}
        height={localWidthAndHeight.height}
        cornerRadius={COLUMN_BORDER_RADIUS}
      />
      {state === BoardElementState.RESIZABLE && (
        <StatefulTransformer
          state={state}
          transformerRef={transformerRef}
          minValue={SHARED_STYLES.minWidthAndHeight}
        />
      )}
    </Group>
  );
};

export default memo(Column);
