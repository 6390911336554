import { UIBoardColumn } from 'types/index';
import nonUpdatingStore from 'store/index';
import { BoardElementType } from 'shared';
import { DragModel, DragPatch } from './DragModel';
import { DragCardState } from './DragCardState';
import { getStageUnsafe } from '../konva/KonvaHelper';
import { getColumnWithHighestY } from '../sortedColumn/SortedColumnHelper';

export const createDragCardState = (dragState: DragModel): DragCardState => {
  const allBoardElements = nonUpdatingStore.getState().board.boardElements;
  const allColumns = allBoardElements.filter(
    (e) => e.type === BoardElementType.COLUMN
  ) as UIBoardColumn[];

  const allSortedColumns = allColumns.filter((col) => col.isSorted);

  return {
    stage: getStageUnsafe(),
    allBoardElements,
    allElementPatches: new Map<string, DragPatch>(),
    allColumns,
    allSortedColumns,
    curColumn: getColumnWithHighestY(allSortedColumns, dragState.element.shape),
  };
};
