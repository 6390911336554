/* eslint-disable no-nested-ternary */
/* eslint-disable operator-linebreak */
/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { useCallback, useState, useEffect, useRef, SetStateAction } from 'react';
import { FloatingElement, Position } from 'store/app';
import { WidgetTypes } from 'store/widgets';
import { ContextMenuType } from 'components/dom/ContextMenu';
import { KonvaEventObject } from 'konva/types/Node';
import { SHARED_STYLES as cardSharedStyles } from 'components/canvas/Card';
import { SHARED_STYLES as columnSharedStyles } from 'components/canvas/Column';
import { BoardElementState } from 'types/index';
import { Key } from 'ts-key-enum';
import {
  getNextSnappingPosition,
  isCtrlOrMetaKey,
  isElementInElement,
  isMouseEvent,
  isTouchEvent,
} from 'lib/helpers';
import { BoardElementType, Rectangle } from 'shared';
import { useStoreActions, useStoreState } from 'store/hooks';
import { getDefaultCardColor, getDefaultStickyColor, getDefaultSwimlaneColor } from 'lib/colors';
import { Group } from 'konva/types/Group';
import { Transformer as ITransformer } from 'konva/types/shapes/Transformer';
import { Rect as IRect } from 'konva/types/shapes/Rect';
import { Layer } from 'konva/types/Layer';
import { BoardMode } from 'store/board/settings';
import nonUpdatingStore from 'store/index';
import { MOUSE_BUTTON } from 'lib/Types';
import { doesEventBelongToLockButton } from 'components/canvas/Column/LockButton';
import { doesEventBelongToEditButton } from 'components/canvas/Column/EditButton';
import { useSpawnContextMenu } from './Widgets';
import { moveDragElement, startDragging, stopDragging } from '../drag/DragHandlerManager';

const ATTACHED_TO_COLUMN_TOLERANCE = 0.5;
export const SNAP_TO_GRID_VALUE = 25;

export const DEFAULT_COLUMN_TITLE = 'New Column';
export const DEFAULT_SWIMLANE_TITLE = 'New Swimlane';

function useIsBoardElementInDetailView() {
  const widgets = useStoreState((state) => state.widgets.data);
  return (elementId: string) =>
    widgets.findIndex(
      (currentWidget) =>
        currentWidget.refId === elementId && currentWidget.type === WidgetTypes.CARDDETAILVIEW
    ) !== -1;
}

function useSpawnElements() {
  const addElement = useStoreActions((actions) => actions.board.addElement);
  const snapToGrid = useStoreState((store) => store.boardSettings.snapToGrid);

  const spawnCard = useCallback(
    (shape: Rectangle, defaultValues?: FloatingElement) => {
      const { name } = getDefaultCardColor();
      addElement({
        type: BoardElementType.CARD,
        shape,
        color: name,
        title: defaultValues?.title ?? '',
        isArchived: false,
        parentColumnId: defaultValues?.parentColumnId,
        ...(defaultValues?.links && { links: defaultValues.links }),
        ...(defaultValues?.meta ?? {}),
        ...(defaultValues?.file && { file: defaultValues.file }),
      });
    },
    [addElement]
  );

  const spawnColumn = useCallback(
    (position: Position, defaultValues?: FloatingElement) => {
      addElement({
        type: BoardElementType.COLUMN,
        title: DEFAULT_COLUMN_TITLE,
        shape: {
          ...(snapToGrid
            ? getNextSnappingPosition({ position, snappingValue: SNAP_TO_GRID_VALUE })
            : position),
        },
        color: 'white',
        wipLimit: 0,
        isLocked: false,
        isSorted: defaultValues?.meta?.isSorted,
      });
    },
    [addElement, snapToGrid]
  );
  const spawnSwimlane = useCallback(
    (position: Position) => {
      const { name } = getDefaultSwimlaneColor();
      addElement({
        type: BoardElementType.SWIMLANE,
        title: DEFAULT_SWIMLANE_TITLE,
        shape: {
          ...(snapToGrid
            ? getNextSnappingPosition({ position, snappingValue: SNAP_TO_GRID_VALUE })
            : position),
        },
        color: name,
        wipLimit: 0,
        isLocked: false,
      });
    },
    [addElement, snapToGrid]
  );
  const spawnSticky = useCallback(
    (position: Position) => {
      const { name } = getDefaultStickyColor();
      addElement({
        type: BoardElementType.STICKY,
        shape: {
          ...(snapToGrid
            ? getNextSnappingPosition({ position, snappingValue: SNAP_TO_GRID_VALUE })
            : position),
        },
        color: name,
        title: '',
      });
    },
    [addElement, snapToGrid]
  );
  return { spawnCard, spawnColumn, spawnSticky, spawnSwimlane };
}

function useBoardElementProps({
  state,
  elementId,
  initialPosition,
  canChangeColor = true,
  canBeResized = true,
  isLocked = undefined,
  canAddBlockers,
}: {
  state: BoardElementState;
  elementId: string;
  initialPosition: Position;
  canChangeColor?: boolean;
  canBeResized?: boolean;
  isLocked?: boolean; // true/false: current-state, undefined: not lockable at all
  canAddBlockers?: boolean;
}) {
  const hasDetailViewOpen = useIsBoardElementInDetailView();
  const [isDraggable, setIsDraggable] = useState(true);
  const isPinching = useStoreState((globalState) => globalState.app.isPinching);
  const hasWidgets = useStoreState((globalState) => globalState.widgets.data.length > 0);
  const hasContextMenuOpen = useStoreState((globalState) => globalState.widgets.hasContextMenuOpen);
  const resetWidgets = useStoreActions((actions) => actions.widgets.closeWidgets);
  const { updateElement, setElementState, resetElementsState, toggleSelectedElement } =
    useStoreActions((actions) => actions.board);
  const spawnContextMenu = useSpawnContextMenu();
  const floatingElement = useStoreState((globalStore) => globalStore.app.floatingBoardElement);
  const selectedElements = useStoreState((globalStore) => globalStore.board.selectedBoardElements);
  const elementRef = useRef<Group>(null);

  //--------------------------------------------------------------------------------------
  // drag &drop

  const handleDragStart = useCallback(() => {
    const element = nonUpdatingStore.getState().board.boardElements.find((e) => e.id === elementId);
    if (!element) {
      throw new Error('Element.handleDragStart() no boardElement found');
    }
    startDragging({
      element,
      canBeResized,
      updateElement,
      setElementState,
    });
  }, [canBeResized, elementId, setElementState, updateElement]);

  const handleDragMove = useCallback((e: KonvaEventObject<MouseEvent>) => {
    moveDragElement({
      position: e.target.getPosition(),
      absolutePosition: e.target.getAbsolutePosition(),
    });
  }, []);

  const handleDragEnd = useCallback(() => {
    stopDragging();
  }, []);

  // drag & drop
  //--------------------------------------------------------------------------------------

  const enterFocusedState = useCallback(() => {
    if (nonUpdatingStore.getState().boardSettings.mode === BoardMode.MOVE && !floatingElement) {
      setElementState({ id: elementId, state: BoardElementState.FOCUSED });
    }
  }, [elementId]);

  const handleContextMenu = useCallback(
    (e: KonvaEventObject<MouseEvent>) => {
      if (floatingElement) return;
      enterFocusedState();
      spawnContextMenu({
        id: elementId,
        pos: { x: e.evt.clientX, y: e.evt.clientY },
        type: canChangeColor
          ? ContextMenuType.COLORABLE
          : canAddBlockers
          ? ContextMenuType.DEFAULT_WITH_BLOCKERS
          : undefined,
      });
    },
    [elementId, canChangeColor]
  );

  const enterResizableState = useCallback(() => {
    if (nonUpdatingStore.getState().boardSettings.mode === BoardMode.MOVE && canBeResized) {
      setElementState({ id: elementId, state: BoardElementState.RESIZABLE });
    }
  }, [elementId, canBeResized]);

  const handleClickOrTap = useCallback(
    (e: KonvaEventObject<MouseEvent> | KonvaEventObject<TouchEvent>) => {
      if (nonUpdatingStore.getState().app.floatingBoardElement) {
        // if we have a floating element clicks on other elements should do nothing
        return;
      }

      if (hasWidgets) {
        resetWidgets();
      }

      if (
        doesEventBelongToLockButton(e) &&
        (isTouchEvent(e) || e.evt.button === MOUSE_BUTTON.LEFT)
      ) {
        updateElement({ id: elementId, isLocked: !isLocked });

        return; // we are done for now!
      }

      if (
        doesEventBelongToEditButton(e) &&
        (isTouchEvent(e) || e.evt.button === MOUSE_BUTTON.LEFT)
      ) {
        setElementState({ id: elementId, state: BoardElementState.INDETAIL });

        return; // we are done for now!
      }

      if (isMouseEvent(e) && e.evt.shiftKey && e.evt.button === MOUSE_BUTTON.LEFT) {
        // Shift and left mouse button means multiselect, therefore let the
        // canvas handle the selection stuff
        return;
      }

      if (isMouseEvent(e) && e.evt.button === MOUSE_BUTTON.LEFT && isCtrlOrMetaKey(e)) {
        toggleSelectedElement(elementId);
      }

      if (!isCtrlOrMetaKey(e)) {
        // enter the focus state if conditions are met (inside the function)
        enterFocusedState();
        // only enter ResizableState if it is a left-click
        // right-click triggers context menu and this does
        // not work correctly with ResizableState
        if (
          canBeResized &&
          (isTouchEvent(e) || e.evt.button === MOUSE_BUTTON.LEFT) &&
          !floatingElement
        ) {
          enterResizableState();
        }
      }

      if (isMouseEvent(e) && e.evt.button === MOUSE_BUTTON.RIGHT) {
        handleContextMenu(e);
      }
    },
    [
      hasWidgets,
      enterFocusedState,
      canBeResized,
      floatingElement,
      resetWidgets,
      updateElement,
      elementId,
      isLocked,
      setElementState,
      handleContextMenu,
      enterResizableState,
      selectedElements,
    ]
  );

  const handleMouseDown = useCallback(
    (e: KonvaEventObject<MouseEvent>) =>
      setIsDraggable(
        isLocked !== true &&
          !e.evt.shiftKey &&
          e.evt.button === MOUSE_BUTTON.LEFT &&
          state !== BoardElementState.INEDIT &&
          nonUpdatingStore.getState().boardSettings.mode !== BoardMode.HAND &&
          nonUpdatingStore.getState().boardSettings.areElementsDraggable &&
          !floatingElement
      ),
    [isLocked, state, floatingElement]
  );

  const handleTouchStart = useCallback(() => {
    setIsDraggable(
      isLocked !== true &&
        state !== BoardElementState.INEDIT &&
        nonUpdatingStore.getState().boardSettings.mode !== BoardMode.HAND &&
        nonUpdatingStore.getState().boardSettings.areElementsDraggable &&
        !floatingElement
    );
  }, [isLocked, state, floatingElement]);

  const handleDoubleClickOrTap = useCallback(
    (e: KonvaEventObject<MouseEvent> | KonvaEventObject<TouchEvent>) => {
      e.evt.preventDefault();
      if (isTouchEvent(e) || e.evt.button === MOUSE_BUTTON.LEFT) {
        resetElementsState();
        setElementState({ id: elementId, state: BoardElementState.INEDIT });
      }
    },
    [elementId]
  );

  useEffect(() => {
    const handleEnter = (e: KeyboardEvent) => {
      if (
        e.key === Key.Enter &&
        (state === BoardElementState.RESIZABLE || state === BoardElementState.FOCUSED) &&
        !hasDetailViewOpen(elementId) &&
        !hasContextMenuOpen &&
        !nonUpdatingStore.getState().app.isLinkPopoverOpen
      ) {
        setElementState({ id: elementId, state: BoardElementState.INEDIT });
      }
    };
    window.addEventListener('keydown', handleEnter);
    return () => {
      window.removeEventListener('keydown', handleEnter);
    };
  }, [elementId, hasContextMenuOpen, hasDetailViewOpen, setElementState, state]);

  return {
    draggable: isDraggable && !isPinching,
    position: initialPosition,
    handleClick: handleClickOrTap,
    enterFocusedState,
    enterResizableState,
    handleContextMenu,
    handleDragEnd,
    handleDragMove,
    handleDragStart,
    handleMouseDown,
    handleDoubleClickOrTap,
    handleTouchStart,
    elementRef,
  };
}

function useCountElementsAttached(
  parentClientRect: Partial<Rectangle>,
  config: { excludeBoardElementsTypes?: BoardElementType[]; excludeSameSize?: boolean } = {
    excludeBoardElementsTypes: [],
    excludeSameSize: true,
  }
) {
  const boardElements = useStoreState((globalState) => globalState.board.boardElements);
  const elementsAttachedCount: number = boardElements.filter((boardElement) => {
    if (config.excludeSameSize) {
      if (!boardElement.shape) {
        throw new Error(`Elements() boardElement.shape is falsy`);
      }
      if (
        parentClientRect.x === boardElement.shape.x &&
        parentClientRect.y === boardElement.shape.y &&
        parentClientRect.width === boardElement.shape.width &&
        parentClientRect.height === boardElement.shape.height
      ) {
        return false;
      }
    }

    if (!config.excludeBoardElementsTypes) {
      throw new Error(`Elements() config.excludeBoardElementsTypes is falsy`);
    }
    if (config.excludeBoardElementsTypes.includes(boardElement.type as BoardElementType)) {
      return false;
    }
    return isElementInElement(parentClientRect, boardElement.shape, {
      tolerance: ATTACHED_TO_COLUMN_TOLERANCE,
    });
  }).length;

  return { hasElementsAttached: elementsAttachedCount > 0, elementsAttachedCount };
}

function useHighlightTargetColumn() {
  const [columns, setColumns] = useState<any>(null);
  const getColumns = (e: KonvaEventObject<MouseEvent>) => {
    const layer: Layer = e.target.getLayer();
    if (layer) {
      const col = layer.find('.column') as unknown as SetStateAction<null>;
      setColumns(col);
    }
  };
  const highlightTargetColumn = (e: KonvaEventObject<MouseEvent>) => {
    const pos = e.target.getPosition();
    const cardClientRect = {
      ...pos,
      width: cardSharedStyles.width,
      height: cardSharedStyles.height,
    };
    if (columns == null) {
      throw new Error(`useHighlightTargetColumn() columns is nully`);
    }
    columns.each((column: Group) => {
      const columnClientRect = {
        x: column.attrs.x,
        y: column.attrs.y,
        width: column.children[0].attrs.width,
        height: column.children[0].attrs.height,
      };
      const hasIntersection = isElementInElement(columnClientRect, cardClientRect, {
        tolerance: ATTACHED_TO_COLUMN_TOLERANCE,
      });
      // if wipLimit is exceeded we highlight the inner rect (index 2), else the first (index 0)
      const columnRectToHighlight = column.children[column.attrs.wipLimitExceeded ? 2 : 0];
      columnRectToHighlight.attrs.fill = hasIntersection
        ? columnSharedStyles.highlightBackgroundColor
        : columnSharedStyles.backgroundColor;
    });
  };
  const resetColumns = () => {
    if (columns && columns.length > 0) {
      columns.each((column: Group) => {
        // if wipLimit is exceeded we highlight the inner rect (index 2), else the first (index 0)
        const columnRectToReset = column.children[column.attrs.wipLimitExceeded ? 2 : 0];
        columnRectToReset.attrs.fill = columnSharedStyles.backgroundColor;
      });
    }
    setColumns([] as any);
  };

  return { getColumns, highlightTargetColumn, resetColumns };
}

const useTransformer = ({
  width,
  height,
  state,
  id,
  isLocked = undefined,
}: {
  width: number;
  height: number;
  state: BoardElementState;
  id: string;
  isLocked?: boolean;
}) => {
  const [localWidthAndHeight, setLocalWidthAndHeight] = useState({ width, height });
  const rectRef = useRef<IRect>(null);
  const transformerRef = useRef<ITransformer>(null);
  const { updateElement } = useStoreActions((actions) => actions.board);

  // sync localWidthAndHeight if the width and height in the props change
  useEffect(() => {
    if (width !== localWidthAndHeight.width || height !== localWidthAndHeight.height) {
      setLocalWidthAndHeight({ width, height });
    }
  }, [width, height]);

  useEffect(() => {
    switch (state) {
      case BoardElementState.SELECTED:
      case BoardElementState.RESIZABLE: {
        if (isLocked === true) {
          // just pass this, it's a feature.. not a bug! =)
        }

        const transformer = transformerRef.current;
        if (transformer) {
          transformer.nodes([rectRef.current as any]);
          (transformer.getLayer() as any).batchDraw();
        }

        break;
      }

      default:
        // TODO: Add error handling
        break;
    }
  }, [id, state, width, isLocked]);

  const rectProps = {
    onTransform: () => {
      const transformer = transformerRef.current;
      if (transformer == null) {
        throw new Error(`useTransformer() transformer is nully`);
      }

      const rect = rectRef.current;
      if (rect == null) {
        throw new Error(`useTransformer() rect is nully`);
      }
      rect.width(rect.width() * rect.scaleX());
      rect.height(rect.height() * rect.scaleY());
      rect.scaleX(1);
      rect.scaleY(1);
      rect.x(0);
      rect.y(0);

      const stage = transformer.getStage();
      if (stage == null) {
        throw new Error(`useTransformer() transformer is nully`);
      }
      const stageScale = stage.scaleX();
      const transformerWidth = Math.floor(transformer.width() / stageScale);
      const transformerHeight = Math.floor(transformer.height() / stageScale);
      setLocalWidthAndHeight({ width: transformerWidth, height: transformerHeight });
    },
    onTransformEnd: () => {
      const rect = rectRef.current;
      if (rect == null) {
        throw new Error(`useTransformer() rect is nully`);
      }
      updateElement({
        id,
        shape: {
          width: Math.floor(localWidthAndHeight.width),
          height: Math.floor(localWidthAndHeight.height),
        },
      });
      rect.scaleX(1);
      rect.scaleY(1);
    },
  };

  return { rectRef, rectProps, transformerRef, localWidthAndHeight };
};

export {
  useSpawnElements,
  useBoardElementProps,
  useCountElementsAttached,
  useIsBoardElementInDetailView,
  useHighlightTargetColumn,
  useTransformer,
};
