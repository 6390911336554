/* eslint-disable no-param-reassign */
import { BoardElement, BoardElementType, assertUnreachable } from 'shared';
import { Action, action, computed, Computed, persist } from 'easy-peasy';
import { trackPlausibleEvent } from 'lib/plausible';
import { CLIENT_PLAUSIBLE_EVENTS } from 'lib/Types';
import { BoardElementState, ClientCreatedBoardElement, UIBoardElement } from 'types/index';
import { getDefaultElementHeight, getDefaultElementWidth } from 'lib/DefaultElementSizes';
import { v4 as generateUUID } from 'uuid';
import { PublicStoreModel } from '.';

export type Position = { x: number; y: number };

export enum AppCursor {
  DEFAULT = 'GRAB',
  GRABBING = 'GRABBING',
  POINTER = 'POINTER',
  CURSOR = 'DEFAULT',
}

export enum HideableSectionState {
  HIDDEN = 'HIDDEN',
  VISIBLE = 'VISIBLE',
}

export type FloatingElement = {
  state: BoardElementState.FLOATING;
  type: BoardElementType;
  thumbnailUrl?: BoardElement['thumbnailUrl'];
  file?: File;
  title?: BoardElement['title'];
  meta?: Pick<BoardElement, 'cardIntegrationMeta' | 'cardIntegrationType' | 'isSorted'>;
} & Pick<ClientCreatedBoardElement, 'id' | 'shape' | 'parentColumnId' | 'links'>;

export type PopoverType = 'search' | 'links' | 'shortcuts';

export interface AppStoreModel {
  cookieBanner: HideableSectionState;
  discordButton: HideableSectionState;
  floatingBoardElement: FloatingElement | null;
  mousePosition: Position | undefined;
  isTouchDevice: boolean;
  isPinching: boolean;
  setIsPiching: Action<AppStoreModel, boolean>;
  hideCookieBanner: Action<AppStoreModel>;
  hideDiscordButton: Action<AppStoreModel>;
  setFloatingBoardElement: Action<
    AppStoreModel,
    (Partial<FloatingElement> & { type: BoardElementType }) | null
  >;
  setIsTouchDevice: Action<AppStoreModel, boolean>;
  setMousePosition: Action<AppStoreModel, Position>;
  cursor: AppCursor;
  setCursor: Action<AppStoreModel, AppCursor>;
  isRecordingLoom: boolean;
  isLoomSupported: boolean;
  setIsRecordingLoom: Action<AppStoreModel, boolean>;
  setIsLoomSupported: Action<AppStoreModel, boolean>;
  // Global Popover
  currentPopover: PopoverType | null;
  boardSearchOpen: Computed<AppStoreModel, boolean>;
  setBoardSearchOpen: Action<AppStoreModel, boolean>;
  toggleBoardSearch: Action<AppStoreModel>;
  isLinkPopoverOpen: Computed<AppStoreModel, boolean>;
  setIsLinkPopoverOpen: Action<AppStoreModel, boolean>;
  setActiveLinkCardId: Action<AppStoreModel, string>;
  hideCardLinks: Action<AppStoreModel>;
  activeLinkCardId: string | null;
  activeLinkCard: Computed<AppStoreModel, UIBoardElement | undefined, PublicStoreModel>;
  activeLinkTabIndex: number;
  setActiveLinkTabIndex: Action<AppStoreModel, number>;
  isShortcutPopoverOpen: Computed<AppStoreModel, boolean>;
  setIsShortcutPopoverOpen: Action<AppStoreModel, boolean>;
}

const appStoreModel: AppStoreModel = persist(
  {
    cookieBanner: HideableSectionState.VISIBLE,
    discordButton: HideableSectionState.VISIBLE,
    floatingBoardElement: null,
    isRecordingLoom: false,
    isLoomSupported: false,
    isTouchDevice: false,
    isPinching: false,
    currentPopover: null,
    setIsRecordingLoom: action((state, isRecordingLoom) => {
      state.isRecordingLoom = isRecordingLoom;
    }),
    setIsPiching: action((state, isPinching) => {
      state.isPinching = isPinching;
    }),
    setIsLoomSupported: action((state, isLoomSupported) => {
      state.isLoomSupported = isLoomSupported;
    }),
    setIsTouchDevice: action((state, isTouchDevice) => {
      state.isTouchDevice = isTouchDevice;
    }),
    mousePosition: undefined,
    hideCookieBanner: action((state) => {
      state.cookieBanner = HideableSectionState.HIDDEN;
    }),
    hideDiscordButton: action((state) => {
      state.discordButton = HideableSectionState.HIDDEN;
    }),
    setFloatingBoardElement: action((state, payload) => {
      if (!payload) {
        state.floatingBoardElement = null;
        return;
      }
      switch (payload.type) {
        case BoardElementType.CARD:
        case BoardElementType.COLUMN:
        case BoardElementType.SWIMLANE:
        case BoardElementType.STICKY:
          state.floatingBoardElement = {
            state: BoardElementState.FLOATING,
            id: generateUUID(),
            shape: {
              height: getDefaultElementHeight(payload.type),
              width: getDefaultElementWidth(payload.type),
              x: 0,
              y: 0,
            },
            ...payload,
          };
          break;
        default:
          assertUnreachable(payload.type);
      }
    }),
    setMousePosition: action((state, payload) => {
      state.mousePosition = payload;
    }),
    cursor: AppCursor.DEFAULT,
    setCursor: action((state, payload) => {
      state.cursor = payload;
    }),
    boardSearchOpen: computed((state) => state.currentPopover === 'search'),
    setBoardSearchOpen: action((state, payload) => {
      state.currentPopover = payload ? 'search' : null;
    }),
    toggleBoardSearch: action((state) => {
      state.currentPopover = state.currentPopover === 'search' ? null : 'search';
    }),
    isLinkPopoverOpen: computed((state) => state.currentPopover === 'links'),
    setIsLinkPopoverOpen: action((state, payload) => {
      state.currentPopover = payload ? 'links' : null;
    }),
    activeLinkCardId: null,
    activeLinkTabIndex: 0,
    setActiveLinkCardId: action((state, payload) => {
      state.activeLinkTabIndex = 0;

      state.activeLinkCardId = payload;
    }),
    hideCardLinks: action((state) => {
      state.activeLinkCardId = null;
    }),
    activeLinkCard: computed(
      [(state) => state.activeLinkCardId, (_, storeState) => storeState.board.boardElements],
      (cardId, boardElement) => boardElement.find((el) => el.id === cardId)
    ),
    setActiveLinkTabIndex: action((state, payload) => {
      trackPlausibleEvent(CLIENT_PLAUSIBLE_EVENTS.TABBED_THROUGH_LINKS, {
        direction: payload > state.activeLinkTabIndex ? 'forward' : 'backward',
      });
      state.activeLinkTabIndex = payload;
    }),
    isShortcutPopoverOpen: computed((state) => state.currentPopover === 'shortcuts'),
    setIsShortcutPopoverOpen: action((state, payload) => {
      state.currentPopover = payload ? 'shortcuts' : null;
    }),
  },
  {
    mergeStrategy: 'mergeDeep',
    storage: 'localStorage',
    whitelist: ['cookieBanner', 'discordButton'],
  }
);

export default appStoreModel;
