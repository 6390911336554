import { memo, useEffect } from 'react';
import { useStoreState } from 'store/hooks';
import { Layer, Group, Path, Text, Label, Tag } from 'react-konva';
import { Cursor, usePointers, useReceiveCursorActions } from 'hooks/Sockets';
import { getCursorColor } from 'lib/colors';
import { KonvaEventObject } from 'konva/types/Node';
import { CursorIcon } from 'lib/canvasIcons';
import useStage from 'hooks/useStage';
import theme from 'lib/theme';
import { nonUpdatingStore } from 'store/index';

const Cursors: React.FC = () => {
  const user = useStoreState((state) => state.user.user);
  const stage = useStage();
  const [positions, setMyPosition] = usePointers();
  const { detailViews, editingCard } = useReceiveCursorActions();
  const zoomLevel = useStoreState((state) => state.boardSettings.zoomLevel);

  useEffect(() => {
    if (!stage) {
      return () => {};
    }
    const listener = (e: KonvaEventObject<MouseEvent>) => {
      const currentOffset = stage.position();
      if (user?._id) {
        if (nonUpdatingStore.getState().board.hasElementsInEditState) return;
        setMyPosition({
          x: (e.evt.clientX - currentOffset.x) / stage.scaleX(),
          y: (e.evt.clientY - currentOffset.y) / stage.scaleX(),
          name: user?.firstName || user?.lastName,
          id: user._id,
        });
      }
    };
    stage.on('mousemove', listener);
    return () => stage.removeEventListener('mousemove');
  }, [stage, user, zoomLevel, detailViews, editingCard, setMyPosition]);

  const getCursorActionText = (cursor: Cursor) => {
    const userName = cursor.name;
    if (editingCard?.[cursor.id]) return `${userName}\n✍️ editing title`;
    if (detailViews?.[cursor.id]) return `${userName}\n🔍 viewing details`;
    return userName;
  };

  return (
    <Layer listening={false}>
      <Group>
        {Object.entries(positions).map(([key, cursor]: [string, Cursor]) => {
          const cursorColor = getCursorColor(key);
          return (
            user?._id !== key && (
              <Group key={key} x={cursor.x} y={cursor.y}>
                <Path {...CursorIcon(cursorColor.color, cursorColor.stroke, 0.9 / zoomLevel)} />
                <Label x={25 / zoomLevel} y={20 / zoomLevel}>
                  <Tag
                    fill={cursorColor.color}
                    shadowColor="rgba(0, 0, 0, 0.25)"
                    shadowBlur={3}
                    cornerRadius={4 / zoomLevel}
                  />
                  <Text
                    padding={4 / zoomLevel}
                    fontSize={14 / zoomLevel}
                    text={getCursorActionText(cursor)}
                    fill={cursorColor.stroke}
                    fontFamily={theme.fonts.boardElements}
                    lineHeight={1.15}
                  />
                </Label>
              </Group>
            )
          );
        })}
      </Group>
    </Layer>
  );
};

export default memo(Cursors);
