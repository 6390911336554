import { UIBoardColumn, UIBoardElement } from 'types/index';
import { Rect } from 'konva/types/shapes/Rect';
import { DragPatch } from '../drag/DragModel';
import { getBoardElementGroup, getKonvaElement, getStageUnsafe } from '../konva/KonvaHelper';
import { SortedColumnPatchCalculator } from './SortedColumnPatchCalculator';
import { ElementShapePatch } from './ElementShapePatch';
import { SortedColumnGhost } from './SortedColumnGhost';

export abstract class SortedColumnRenderer {
  /**
   * Orders a sorted Column.
   *
   * Afterwards it will render it
   * on the konva level but won't
   * update the store
   */
  static render({
    col,
    allBoardElements,
  }: {
    col: UIBoardColumn;
    allBoardElements: UIBoardElement[];
  }): ElementShapePatch[] {
    const patches = SortedColumnPatchCalculator.calculate({ col, allBoardElements });
    const allElementPatches = new Map<string, DragPatch>();

    patches.forEach((patch) => {
      const rect = getKonvaElement<Rect>(patch.id);

      // adjust the konva shape
      if (col.id === patch.id) {
        // height of column
        rect.height(patch.shape.height);
      } else {
        const cardGroup = getBoardElementGroup(rect);
        // position of card
        cardGroup?.position(patch.shape);
      }

      // The height of the card can change (blocker added / deleted)
      // in this case we need to update the height of the ghost element
      // so it has the same height as the currently dragged card
      if (rect.height() !== patch.shape.height) {
        rect.height(patch.shape.height);
      }

      // save patch so that we can update all changed elements on `dragEnd`
      allElementPatches.set(patch.id, patch);
    });

    // directly draw on konva for perf reasons
    getStageUnsafe().batchDraw();

    return Array.from(allElementPatches.values());
  }

  /**
   * Orders a sorted Column.
   *
   * Afterwards it will render it using a ghost as a preview for the given Element.
   */
  static renderWithGhost({
    col,
    element,
    allBoardElements,
    isCurrentSortedColumn = false,
  }: {
    col: UIBoardColumn;
    element: UIBoardElement;
    allBoardElements: UIBoardElement[];
    isCurrentSortedColumn?: boolean;
  }): ElementShapePatch[] {
    const patches = SortedColumnPatchCalculator.calculate({
      col,
      allBoardElements,
      element,
      isCurrentSortedColumn,
    });
    const allElementPatches = new Map<string, ElementShapePatch>();

    let ghostRectUsed = false;
    patches.forEach((patch) => {
      let rect: Rect;
      if (patch.id === element.id) {
        rect = SortedColumnGhost.show({
          shape: patch.shape,
          columnId: col.id,
          elementId: element.id,
        });
        ghostRectUsed = true;
      } else {
        // rect = real element
        rect = getKonvaElement<Rect>(patch.id);
      }

      // adjust the konva shape
      if (col.id === patch.id) {
        // height of column
        rect.height(patch.shape.height);
      } else {
        const cardGroup = getBoardElementGroup(rect);
        // position of card
        cardGroup?.position(patch.shape);
      }

      // The height of the card can change (blocker added / deleted)
      // in this case we need to update the height of the ghost element
      // so it has the same height as the currently dragged card
      if (rect.height() !== patch.shape.height) {
        rect.height(patch.shape.height);
      }

      // save patch so that we can update all changed elements on `dragEnd`
      allElementPatches.set(patch.id, patch);
    });

    // hide ghost if we don't need it now (we keep the ref for perf reasons)
    if (!ghostRectUsed) {
      SortedColumnGhost.hide();
    }

    // directly draw on konva for perf reasons
    getStageUnsafe().batchDraw();

    return Array.from(allElementPatches.values());
  }
}
