import { BaseBoardElementLink, BoardElementLinkType, isCheckLink, CheckLink } from 'shared';
import { UIBoardElement } from 'types/index';
import theme from './theme';

export const LINK_DESCRIPTIONS: Record<
  Exclude<BoardElementLinkType, BoardElementLinkType.CHECK>,
  string
> = {
  [BoardElementLinkType.BLOCKS]: 'Blocks',
  [BoardElementLinkType.BLOCKED_BY]: 'Blocked by',
  [BoardElementLinkType.PARENT]: 'Parent',
  [BoardElementLinkType.CHILD]: 'Child',
  [BoardElementLinkType.RELATED]: 'Related to',
} as const;

export function getLinkDescription(type: BoardElementLinkType): string | undefined {
  if (type in LINK_DESCRIPTIONS) {
    return (LINK_DESCRIPTIONS as any)[type] as string;
  }
  return undefined;
}

export function getVisualLinks(links?: Array<BaseBoardElementLink>): Array<BaseBoardElementLink> {
  return links?.filter((link) => link.type !== BoardElementLinkType.CHECK) ?? [];
}

/**
 * There are links that link to elements that are archived. For perf reasons
 * we lazy load the archive data when entering the archive. Therefore there are links
 * that point to "nothing". This function filters those out.
 * @param links all links
 * @returns all links that point to actual elements
 */
export function getVisualLinksThatHaveBoardElement(
  links: Array<BaseBoardElementLink> | undefined,
  boardElements: UIBoardElement[]
): Array<BaseBoardElementLink> {
  return getVisualLinks(links).filter((link) =>
    boardElements.some((el) => el.id === link.targetElement.id)
  );
}

export function getTaskLinks(links?: Array<BaseBoardElementLink>): Array<CheckLink> {
  return links?.filter(isCheckLink) ?? [];
}

export function getLinkColor(linkType: BoardElementLinkType) {
  switch (linkType) {
    case BoardElementLinkType.BLOCKED_BY:
    case BoardElementLinkType.BLOCKS: {
      return theme.colors.yoRed[500];
    }
    case BoardElementLinkType.CHILD:
    case BoardElementLinkType.PARENT: {
      return theme.colors.taggleLime[700];
    }
    default: {
      return theme.colors.designAnnotation[500];
    }
  }
}
