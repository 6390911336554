import { UIBoardColumn, UIBoardElement } from 'types/index';
import { Rect } from 'konva/types/shapes/Rect';
import { BoardElementType, Rectangle, assertUnreachable } from 'shared';
import { isElementInElement } from 'lib/helpers';
import { getBoardElementGroup, getKonvaElement } from './KonvaHelper';
import { ATTACHED_TO_COLUMN_TOLERANCE } from '../drag/DragConfig';

export const isRectInColumn = (rect: Rectangle, column: UIBoardColumn, isSortedColumn?: boolean) =>
  isElementInElement(column.shape, rect, {
    // sorted columns only allow cards to be attached if they are 100% in the column
    tolerance: isSortedColumn ? 0 : ATTACHED_TO_COLUMN_TOLERANCE,
  });

export const moveElementToTop = (element: UIBoardElement) => {
  switch (element.type) {
    case BoardElementType.CARD: {
      const card = getKonvaElement<Rect>(element.id);
      getBoardElementGroup(card)?.moveToTop();
      break;
    }
    case BoardElementType.COLUMN:
      // todo stage.find<Rect>(`#${dragModel.element.id}`)?.[0].parent?.moveToTop();
      break;
    case BoardElementType.STICKY: {
      const sticky = getKonvaElement<Rect>(element.id);
      getBoardElementGroup(sticky)?.moveToTop();
      break;
    }
    case BoardElementType.SWIMLANE:
      // todo stage.find<Rect>(`#${dragModel.element.id}`)?.[0].parent?.moveToTop();
      break;
    default:
      assertUnreachable(element.type);
  }
};
