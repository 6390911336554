/* eslint-disable import/prefer-default-export */
import { useStoreState } from 'store/hooks';
import { UIUser } from 'store/user';

/**
 * Helper hook to get the current user
 */
export function useUser() {
  const currentUser = useStoreState((store) => store.user.user);
  return currentUser as UIUser | undefined;
}
