import { useState } from 'react';
import { AspectRatio, Skeleton, Flex } from '@chakra-ui/react';

const BaseMediaElement: React.FC = ({ children }) => (
  <Flex direction="column" w="100%">
    <AspectRatio ratio={16 / 9} w="100%" mb={8}>
      {children}
    </AspectRatio>
  </Flex>
);

interface CardImageProps {
  src: string;
}

export function CardImage({ src }: CardImageProps) {
  return (
    <BaseMediaElement>
      <img src={src} alt="" />
    </BaseMediaElement>
  );
}

type LoomVideoProps = {
  videoUrl: string;
};

export function LoomVideo({ videoUrl }: LoomVideoProps) {
  const [isLoading, setIsLoading] = useState(true);
  return (
    <Skeleton isLoaded={!isLoading} mb={isLoading ? 8 : 0}>
      <BaseMediaElement>
        <iframe src={videoUrl} title="Loom Video" onLoad={() => setIsLoading(false)} />
      </BaseMediaElement>
    </Skeleton>
  );
}
