/* eslint-disable import/prefer-default-export */
export const MOUSE_BUTTON = {
  LEFT: 0,
  MIDDLE: 1,
  RIGHT: 2,
};

// https://plausible.io/taggle.so/settings/goals
export enum CLIENT_PLAUSIBLE_EVENTS {
  OPEN_SEARCH = 'Opened Search',
  OPEN_METRICS = 'Opened Metrics',
  OPEN_ARCHIVE = 'Opened Archive',
  COPY_SHARE_LINK = 'Copied Share Link',
  INVITE_USER = 'Invited User',
  BOOKMARKS_OPENED = 'Opened Bookmarks',
  OPENED_LINK_SEARCH = 'Opened Link Search',
  DISPLAY_LINKS = 'Displayed Links',
  TABBED_THROUGH_LINKS = 'Tabbed Through Links',
  OPEN_CARD_DETAILS = 'Opened Card Details',
}
