import { ButtonGroup, Button, ButtonGroupProps } from '@chakra-ui/react';

interface Props {
  onSave: () => void;
  onCancel: () => void;
  id?: string;
  disableSave?: boolean;
}

const EditButtons: React.FC<Props & ButtonGroupProps> = ({
  onCancel,
  onSave,
  id,
  disableSave,
  ...props
}) => (
  <ButtonGroup id={id} {...props}>
    <Button colorScheme="yoBrand" onClick={onSave} disabled={disableSave}>
      Save
    </Button>
    <Button
      colorScheme="yoBrand"
      variant="ghost"
      _hover={{ bg: 'none' }}
      _active={{ bg: 'none' }}
      onClick={onCancel}
    >
      Cancel
    </Button>
  </ButtonGroup>
);

EditButtons.defaultProps = {
  disableSave: false,
} as Partial<Props>;

export default EditButtons;
