/* eslint-disable import/prefer-default-export */
import { ChangeEvent, useRef, useState } from 'react';
import fetchClient from 'lib/Fetch';
import { BoardElement } from 'shared';
import { useDebouncedCallback } from 'use-debounce';
import { useParam } from './useParam';

export enum SearchStatus {
  IDLE,
  SEARCHING,
  ERROR,
  SUCCESS,
}

export const useSearchCards = ({
  searchArchive = false,
  omitResultsWithId = [],
}: {
  searchArchive?: boolean;
  omitResultsWithId?: string[];
}) => {
  const [searchStatus, setSearchStatus] = useState(SearchStatus.IDLE);
  const [canSearch, setCanSearch] = useState(false);
  const [searchResults, setSearchResults] = useState<BoardElement[] | null>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);

  const boardId = useParam('boardId');

  const onSearchValueChange = async (searchValue: string) => {
    const search = async () => {
      setSearchStatus(SearchStatus.SEARCHING);
      const queryParams = new URLSearchParams({
        value: searchValue.trim(),
        searchArchive: searchArchive ? 'true' : 'false',
      });
      try {
        const data = await fetchClient(`boards/${boardId}/search?${queryParams}`);

        setSearchResults(
          (data as BoardElement[]).filter((element) => !omitResultsWithId.includes(element.id))
        );
        setSearchStatus(SearchStatus.SUCCESS);
      } catch (e) {
        setSearchResults(null);
        setSearchStatus(SearchStatus.ERROR);
      }
    };

    if (searchValue.trim() !== '') {
      search();
    } else {
      setSearchStatus(SearchStatus.IDLE);
      setSearchResults(null);
    }
  };

  const debouncedSearchHandler = useDebouncedCallback(onSearchValueChange, 500);

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setCanSearch(newValue.trim().length > 0);
    debouncedSearchHandler(newValue);
  };

  const resetSearch = () => {
    if (searchInputRef.current) {
      searchInputRef.current.value = '';
      setSearchResults(null);
      setSearchStatus(SearchStatus.IDLE);
    }
  };

  return {
    canSearch,
    searchInputRef,
    onSearch,
    resetSearch,
    searchResults,
    searchStatus,
  };
};
