/* eslint-disable @typescript-eslint/no-use-before-define */
import { Rect } from 'konva/types/shapes/Rect';
import { Rectangle } from 'shared';
import Konva from 'konva';
import { getKonvaLayer } from '../konva/KonvaHelper';

/**
 * A global singleton Ghost to preview the position of an Element inside a sorted Column.
 */
export abstract class SortedColumnGhost {
  static rect: Rect | undefined;

  static show({
    shape,
    columnId,
    elementId,
  }: {
    shape: Rectangle;
    columnId: string;
    elementId: string;
  }): Rect {
    // rect = ghost
    if (!SortedColumnGhost.rect) {
      const group = new Konva.Group();
      SortedColumnGhost.rect = new Konva.Rect({
        cornerRadius: 3,
        fill: '#B2BBC7',
        shadowBlur: 1,
        shadowColor: 'rgba(0, 0, 0, 0.3)',
        shadowOffsetX: 0,
        shadowOffsetY: 1,
        transformsEnabled: 'position',
        height: shape.height,
        width: shape.width,
        getGroup: () => group,
      });

      // add the ghost rect to a group and then to layer to have the same hierarchy as all other rects
      group.add(SortedColumnGhost.rect);
      getKonvaLayer(columnId).add(group);
    }

    const group = SortedColumnGhost.rect.attrs.getGroup();
    group.moveToTop();
    // The floating element is on another layer than all other board elements.
    // So, if the currently floating element is a card that should be shown a ghost placeholder for,
    // then the ghost should be on top of all elements, otherwise it should be the second highest.
    if (getKonvaLayer(columnId) === getKonvaLayer(elementId)) {
      group.moveDown();
    }

    SortedColumnGhost.rect.visible(true);

    return SortedColumnGhost.rect;
  }

  static hide() {
    SortedColumnGhost.rect?.visible(false);
  }
}
