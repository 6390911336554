import { UIBoardColumn, UIBoardElement } from 'types/index';
import { BoardElementType, Rectangle, ValidationRules } from 'shared';
import { DragPatch } from '../drag/DragModel';
import { SNAP_TO_GRID_VALUE } from '../drag/DragConfig';
import { ElementShapePatch } from './ElementShapePatch';
import { isRectInExtendedColumn } from './SortedColumnHelper';

// TODO: Check naming (margin, padding, gap, ...)
const COL_MARGIN_TOP = SNAP_TO_GRID_VALUE * 2;
const COL_MARGIN_Y = SNAP_TO_GRID_VALUE / 2;
export const COL_MIN_HEIGHT =
  ValidationRules.LayoutRules.Card.CARD_MIN_HEIGHT + COL_MARGIN_Y + COL_MARGIN_TOP;
export abstract class SortedColumnPatchCalculator {
  /**
   * Calculates the shapes of all affected Elements in order to have a sorted Column.
   *
   * Consumers can apply the returned patches for rendering the Column or updating its State.
   */
  static calculate({
    col,
    allBoardElements,
    element,
    isCurrentSortedColumn,
  }: {
    col: UIBoardColumn;
    allBoardElements: UIBoardElement[];
    element?: UIBoardElement;
    isCurrentSortedColumn?: boolean;
  }): ElementShapePatch[] {
    const dragPatches: DragPatch[] = [];

    const allCardsInThatColumn = allBoardElements.filter(
      (e) =>
        e.type === BoardElementType.CARD &&
        isRectInExtendedColumn(e.shape, col) &&
        (e.parentColumnId === col.id || e.id === element?.id) &&
        // return false if e is dragged and this is not the current column
        (e.id !== element?.id || isCurrentSortedColumn) &&
        !e.isArchived
    );
    allCardsInThatColumn.sort((a, b) => a.shape.y - b.shape.y);

    let currentY = col.shape.y + COL_MARGIN_TOP;

    allCardsInThatColumn.forEach((cardInCol) => {
      const shape: Rectangle = {
        ...cardInCol.shape,
        x: col.shape.x + SNAP_TO_GRID_VALUE,
        y: currentY,
        height: cardInCol.shape.height ?? cardInCol.shape.height,
      };
      currentY = shape.y + (cardInCol.shape.height ?? cardInCol.shape.height) + COL_MARGIN_Y;

      dragPatches.push({
        id: cardInCol.id,
        shape,
      });
    });

    const colNewHeight = currentY - col.shape.y;
    // adjust col height
    dragPatches.push({
      id: col.id,
      shape: {
        ...col.shape,
        height: colNewHeight < COL_MIN_HEIGHT ? COL_MIN_HEIGHT : colNewHeight,
      },
    });

    return dragPatches;
  }
}
