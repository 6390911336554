import { Text, Tooltip, TooltipProps, Grid, Flex, Box, Kbd, KbdProps } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

interface TooltipWithShortcutProps {
  text: ReactNode;
  shortcut?: ReactNode;
  offset?: TooltipProps['offset'];
}

const TooltipWithShortcut: React.FC<TooltipWithShortcutProps> = ({
  text,
  shortcut,
  children,
  offset,
}) => {
  const label = (
    <Grid padding="2" gridTemplateColumns="minmax(0, 1fr) auto" alignItems="center">
      <Flex marginRight="5px" justifyContent="center" direction="column">
        <Text>{text}</Text>
      </Flex>
      {shortcut && (
        <Box paddingLeft="2" marginTop="-7px">
          {shortcut}
        </Box>
      )}
    </Grid>
  );

  return (
    <Tooltip
      openDelay={800}
      label={label}
      fontSize="14px"
      fontWeight={500}
      height="100%"
      gutter={10}
      background="yoBrand.750"
      offset={offset}
      closeOnMouseDown
    >
      {children}
    </Tooltip>
  );
};

export const YoKbd: React.FC<Pick<KbdProps, 'fontSize'>> = ({ children, fontSize }) => (
  <Kbd color="yoBrand.750" fontSize={fontSize}>
    {children}
  </Kbd>
);
export default TooltipWithShortcut;
