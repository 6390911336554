/* eslint-disable no-param-reassign */
import { BoardElementType, EventTypes } from 'shared';
import { action, Action, thunk, Thunk } from 'easy-peasy';
import { PublicStoreModel } from 'store/index';
import { BoardStoreModel } from '..';
import BoardEventFactory from '../boardEventFactory';
import * as Events from '../../../lib/Events';
import { SortedColumnUpdater } from '../../../sortedColumn/SortedColumnUpdater';

export interface DeleteElementsModel {
  removeElements: Thunk<BoardStoreModel, string | string[], null, PublicStoreModel>;
  removeElementWithoutHistory: Thunk<
    BoardStoreModel,
    { payload: string; fetch?: boolean },
    null,
    PublicStoreModel
  >;
  removeElementAction: Action<BoardStoreModel, string | string[]>;
  removeArchivedElement: Thunk<BoardStoreModel, string, null, PublicStoreModel>;
  removeArchivedElementAction: Action<BoardStoreModel, string>;
}

const deleteElementsStore: DeleteElementsModel = {
  removeElementAction: action((state, payload) => {
    const elementsToRemove = ([] as string[]).concat(payload);
    state.boardElements = state.boardElements.filter(
      (element) => !elementsToRemove.includes(element.id)
    );
  }),
  removeElements: thunk(async (actions, payload, { getStoreState, getStoreActions }) => {
    const { currentAction, boardElements, boardId } = getStoreState().board;
    const ids = Array.isArray(payload) ? payload : [payload];

    const getElementsToRemove = () =>
      getStoreState().board.boardElements.filter((element) => ids.includes(element.id));

    const elementsToRemove = getElementsToRemove();

    if (elementsToRemove.length > 0) {
      // Check if the cards have details attached. This is necessary to persist the history
      const detailPromises = elementsToRemove.map((currentElement) => {
        if (currentElement.type === BoardElementType.CARD && !currentElement.hasDetails) {
          return actions.getCardDetails({ id: currentElement.id });
        }
        return null;
      });

      await Promise.all(detailPromises);

      // we need to get the elements again, because the details might have changed
      const removeEvents = getElementsToRemove().map((elementToRemove) =>
        BoardEventFactory.createEvent(EventTypes.REMOVE, elementToRemove.id, elementToRemove)
      );

      // We need to update all links pointing to the cards
      // that are being removed
      const updateEvents = boardElements
        .filter((currentElement) =>
          currentElement.links?.some((link) => ids.includes(link.targetElement.id))
        )
        .map((elementThatPointsToDeletedElement) =>
          BoardEventFactory.createEvent(
            EventTypes.UPDATE,
            elementThatPointsToDeletedElement.id,
            {
              links: elementThatPointsToDeletedElement.links ?? [],
            },
            {
              links: elementThatPointsToDeletedElement.links?.filter(
                (link) => !ids.includes(link.targetElement.id)
              ),
            }
          )
        );

      actions.removeElementAction(elementsToRemove.map((element) => element.id));

      const sortColumnEvents = SortedColumnUpdater.renderAndUpdateStateByElements({
        elements: elementsToRemove,
      });

      // apply the event locally to update the elements
      // but don't send it over to the server
      // as it will be bundled with the deletion event
      actions.applyEvent({
        payload: BoardEventFactory.createBulkEvent(updateEvents),
        fetch: false,
      });

      const bulkEvent = BoardEventFactory.createBulkEvent([
        ...removeEvents,
        ...updateEvents,
        ...sortColumnEvents,
      ]);
      if (!boardId) return;
      const res = await Events.postEvent(bulkEvent, boardId, (err: string) => {
        getStoreActions().board.unapplyEvent({ payload: bulkEvent, fetch: false });
        getStoreActions().errors.addError(err);
      });

      if (res) {
        if (currentAction) {
          actions.pushEventToPast(currentAction);
        }
        // build a new branch from currentState -> clear stored futureActions
        actions.setCurrentAction(bulkEvent);
        actions.clearFutureActions();
      }
    }
  }),
  removeElementWithoutHistory: thunk(
    async (actions, { payload: id, fetch }, { getStoreState, getStoreActions }) => {
      const {
        board: { boardElements, boardId },
        widgets,
      } = getStoreState();
      const { closeWidgets } = getStoreActions().widgets;
      actions.removeElementAction(id);
      // if the element that gets deleted has a widget(detailview, editmode, contextmenu) open
      // we need to close the widgets to prevent broken states and save errors
      const hasElementOpenWidgets = widgets.data
        .flatMap((widget) => widget.refId || widget.refIds)
        .includes(id);
      if (hasElementOpenWidgets) {
        closeWidgets();
      }
      const elementToRemove = boardElements.find((element) => element.id === id);
      if (elementToRemove) {
        const removeEvent = BoardEventFactory.createEvent(EventTypes.REMOVE, id, elementToRemove);
        if (fetch && boardId) {
          const res = await Events.postEvent(removeEvent, boardId, (err: string) => {
            getStoreActions().board.unapplyEvent({ payload: removeEvent, fetch: false });
            getStoreActions().errors.addError(err);
          });

          if (res) {
            actions.updateIdsWithObjectIds(res);
          }
        }
      }
    }
  ),
  removeArchivedElement: thunk(async (actions, payload, { getStoreState, getStoreActions }) => {
    const { archivedElements, boardId } = getStoreState().board;

    const elementToRemove = archivedElements.find((element) => element.id === payload);
    if (!elementToRemove) return;

    const removeEvent = BoardEventFactory.createEvent(
      EventTypes.REMOVE,
      elementToRemove.id,
      elementToRemove
    );
    actions.removeArchivedElementAction(elementToRemove.id);

    if (elementToRemove.type === BoardElementType.CARD && !elementToRemove.hasDetails) {
      actions.getCardDetails({ id: elementToRemove.id });
    }
    if (boardId) {
      await Events.postEvent(removeEvent, boardId, (err: string) => {
        getStoreActions().errors.addError(err);
      });
    }
  }),
  removeArchivedElementAction: action((state, payload) => {
    state.archivedElements = state.archivedElements.filter((element) => element.id !== payload);
  }),
};

export { deleteElementsStore };
