/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { StoreProvider } from 'easy-peasy';
import * as Sentry from '@sentry/node';
import { ChakraProvider } from '@chakra-ui/react';
import PlausibleProvider from 'next-plausible';
import NProgress from 'nprogress';
import Router, { useRouter } from 'next/router';
import { useErrorToasts } from 'hooks/Errors';
import store from 'store/index';
import theme from 'lib/theme';
import CookieBanner from 'components/dom/CookieBanner';
import { restorePersistedSettingsFromLocalstorage } from 'lib/helpers';
import { DefaultSeo } from 'next-seo';
import { useLiveChat } from 'hooks/useLiveChat';
import { defaultSeo } from '../src/data/seo';

// Import fonts
import '@fontsource/lexend-deca/400.css';
import '@fontsource/lexend-deca/600.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/400-italic.css';
import '@fontsource/roboto/500-italic.css';
import '@fontsource/roboto/700.css';
import '@fontsource/rubik/900.css';

function initSentry() {
  Sentry.init({
    dsn: 'https://c022de7eb3d54a5fb5007eca4e88499f@o967808.ingest.sentry.io/5919170',
    enabled: process.env.NODE_ENV !== 'development',
    environment: process.env.NODE_ENV,
  });
}

Router.events.on('routeChangeStart', () => {
  NProgress.start();
});

Router.events.on('routeChangeComplete', () => {
  NProgress.done();
});

const InnerApp = ({ Component, pageProps, err }: { Component: any; pageProps: any; err: any }) => {
  useErrorToasts();
  // if the page component has a Layout attached, wrap it with this layout
  const Layout = Component.Layout ? Component.Layout : React.Fragment;
  return (
    <Layout>
      <Component {...pageProps} err={err} />
    </Layout>
  );
};

initSentry();

function useSettingsRestore() {
  const router = useRouter();
  useEffect(() => {
    const boardId = router.query?.boardId as string;
    if (boardId) {
      const { setPlainOffset, setZoomLevel } = store.getActions().boardSettings;
      restorePersistedSettingsFromLocalstorage(boardId, setZoomLevel, setPlainOffset);
    }
  }, [router.query?.boardId]);
}

function MyApp(props: { Component: any; pageProps: any; err: any }) {
  useLiveChat();
  useSettingsRestore();
  return (
    <>
      <DefaultSeo {...defaultSeo} />
      <PlausibleProvider domain="taggle.so">
        <StoreProvider store={store}>
          <ChakraProvider theme={theme}>
            <CookieBanner />
            <InnerApp {...props} />
          </ChakraProvider>
        </StoreProvider>
      </PlausibleProvider>
    </>
  );
}
export default MyApp;
