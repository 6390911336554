/* eslint-disable indent */
import dayjs from 'dayjs';
import { UIBoardElement, BoardElementPayload, BoardElementState } from 'types/index';
import BoardEventFactory from 'store/board/boardEventFactory';
import { Position } from 'store/app';
import { KonvaEventObject } from 'konva/types/Node';
import {
  BoardElementType,
  EventTypes,
  Rectangle,
  User,
  BoardElement,
  IdTuple,
  ValidationRules,
} from 'shared';
import { WidgetPayload, WidgetTypes } from 'store/widgets';
import * as React from 'react';

import { MOUSE_BUTTON } from './Types';
import { SHARED_STYLES as CardStyles } from '../components/canvas/Card/index';
import { SHARED_STYLES as StickyStyles } from '../components/canvas/Sticky/index';
import { ClientElementEvent, ClientSingleElementEvent } from '../ServerEvent/ServerEvent';
import logger from './logger';

export const PREVIEW_IMAGE_PREFIX = `yoImg-hash`;

/**
 * This objects holds the zIndex / Ranking values for each boardElement
 */
export const boardElementRanking: Record<BoardElementType, number> = {
  [BoardElementType.COLUMN]: 1,
  [BoardElementType.SWIMLANE]: 1,
  [BoardElementType.CARD]: 2,
  [BoardElementType.STICKY]: 3,
};
/**
 * Function to sort the BoardElements according to their zIndex on the board
 * @param elements The elements which should appear on the Board
 */
export function sortBoardElementsByRanking(elements: UIBoardElement[]) {
  return [...elements].sort(
    (currentElement, nextElement) =>
      boardElementRanking[currentElement.type!] - boardElementRanking[nextElement.type!]
  );
}

export function sortBoardElementsByZIndex(elements: UIBoardElement[]) {
  return [...elements].sort(
    (currentElement, nextElement) => currentElement.zIndex! - nextElement.zIndex!
  );
}

/**
 * Group object array by property
 * Example, groupBy(array, ( x: Props ) => x.id );
 * @param array
 * @param property
 */
export const groupBy = <T, K extends string | number = string>(
  array: Array<T>,
  property: (x: T) => K
): Record<K, Array<T>> =>
  array.reduce((memo, x: T) => {
    const temp = memo;
    if (!temp[property(x)]) {
      temp[property(x)] = [];
    }
    temp[property(x)].push(x);
    return temp;
  }, {} as Record<K, Array<T>>);

export function getRevertedEvent(event: ClientSingleElementEvent): ClientSingleElementEvent {
  switch (event.type) {
    case EventTypes.ADD: {
      return BoardEventFactory.createEvent(
        EventTypes.REMOVE,
        event.elementId,
        event.newValues,
        event.oldValues
      );
    }
    case EventTypes.UPDATE: {
      return BoardEventFactory.createEvent(
        EventTypes.UPDATE,
        event.elementId,
        event.newValues,
        event.oldValues
      );
    }
    case EventTypes.REMOVE: {
      return BoardEventFactory.createEvent(
        EventTypes.ADD,
        event.elementId,
        event.newValues,
        event.oldValues
      );
    }
    default: {
      throw new Error('The given event is falsy');
    }
  }
}

export function isElementInElement(
  containingElementRect: Partial<Rectangle>,
  containedElementRect: Partial<Rectangle> | undefined,
  config: { tolerance: number } = { tolerance: 0 }
): boolean {
  /* eslint-disable */
  if (
    containedElementRect?.x! + containedElementRect?.width! * config.tolerance <
    containingElementRect?.x!
  ) {
    return false;
  }
  if (
    containedElementRect?.y! + containedElementRect?.height! * config.tolerance <
    containingElementRect?.y!
  ) {
    return false;
  }
  if (
    containedElementRect?.x! + containedElementRect?.width! * (1 - config.tolerance) >
    containingElementRect?.x! + containingElementRect?.width!
  ) {
    return false;
  }
  if (
    containedElementRect?.y! + containedElementRect?.height! * (1 - config.tolerance) >
    containingElementRect?.y! + containingElementRect?.height!
  ) {
    return false;
  }
  return true;
  /* eslint-enable */
}

export function formatNumber(value: any, min: number, max: number): number | boolean {
  const numberValue = parseInt(value, 10);
  return numberValue || numberValue === 0 ? Math.min(max, Math.max(min, numberValue)) : false;
}

export function formatDate(date: Date | undefined | null, withTime?: boolean) {
  if (date) {
    return dayjs(date).format(`YYYY-MM-DD${withTime ? ' - HH:mm' : ''}`);
  }
  return null;
}

/**
 * Helper typeguard function to check whether an event is a touch event
 */
export function isMouseEvent(
  event: KonvaEventObject<MouseEvent> | KonvaEventObject<TouchEvent>
): event is KonvaEventObject<MouseEvent> {
  return event.evt instanceof MouseEvent;
}

/**
 * Helper typeguard function to check whether an event is a touch event
 */
export function isTouchEvent(
  event: KonvaEventObject<MouseEvent> | KonvaEventObject<TouchEvent>
): event is KonvaEventObject<TouchEvent> {
  return (event.evt as TouchEvent).touches !== undefined;
}

/**
 * currently, the konva-react `onDblClick` will be called even
 * if the second click is the right-mouse-button!
 * so we need this wrapper here..
 */
export function handleLeftDblClick(
  kEvt: KonvaEventObject<MouseEvent> | KonvaEventObject<TouchEvent>,
  cb: (kEvt: KonvaEventObject<MouseEvent> | KonvaEventObject<TouchEvent>) => void
) {
  // double tap on mobile -> zooms in. We don't want that
  if (isTouchEvent(kEvt)) {
    kEvt.evt.preventDefault();
  }

  if (isMouseEvent(kEvt) && kEvt.evt.button !== MOUSE_BUTTON.LEFT) {
    return;
  }

  cb(kEvt);
}

export function updateElement(
  newElementData: BoardElementPayload,
  currentElement: UIBoardElement
): UIBoardElement {
  const { shape, metrics, ...rest } = newElementData;
  return {
    ...currentElement,
    shape: {
      ...currentElement.shape,
      ...shape,
    },
    metrics: {
      ...currentElement.metrics,
      ...metrics,
    },
    ...rest,
  };
}

export function getFullnameFromUser(user?: User) {
  if (user && (user.firstName || user.lastName)) {
    return `${user.firstName} ${user.lastName}`;
  }
  return '';
}

function parseLocalStorageObject<T = Record<string, any>>(key: string) {
  const item = localStorage.getItem(key);
  if (item) {
    return JSON.parse(item) as T;
  }
  return undefined;
}

export function restorePersistedSettingsFromLocalstorage(
  boardId: string,
  setZoomLevel: (level: number) => void,
  setOffset: (pos: Position) => void
) {
  const parsedData = parseLocalStorageObject<{ zoomLevel: number; stageOffset: Position }>(
    `yo-${boardId}`
  );
  if (parsedData) {
    const { stageOffset, zoomLevel } = parsedData;
    setOffset(stageOffset);
    setZoomLevel(zoomLevel);
  }
}

/**
 * Helper function to transform the elements sent from the server to be valid UIElements
 * @param elements The BoardElements returned from the server
 */
export function transformElementsForStore(elements: BoardElement[]) {
  return sortBoardElementsByZIndex(
    elements.map((element) => ({
      ...element,
      state: BoardElementState.DEFAULT,
    }))
  );
}

/**
 * Helper function to update the current elements in the store using the
 * new ids returned from the server
 * @param idTuples The IdTuples from the server
 * @param eventToUpdate The BulkEvent including all added elements
 */
export function updateIdsInEvent(
  idTuples: Array<IdTuple>,
  eventToUpdate: ClientElementEvent
): ClientElementEvent {
  const updatedEvent = { ...eventToUpdate };
  if (updatedEvent.type === EventTypes.BULK) {
    idTuples.forEach((idTuple) => {
      const index = updatedEvent.events.findIndex(
        (currentEvent) => currentEvent.elementId === idTuple?.oldId
      );
      if (index > -1) {
        const newId = idTuple?.newId as string;

        logger.debug(
          `[ElementId Exchange]: Updating event id from ${updatedEvent.events[index].elementId} to ${newId}`
        );
        updatedEvent.events[index].newValues.id = newId;
        updatedEvent.events[index].elementId = newId;
      }
    });
  }
  return updatedEvent;
}

/**
 * Helper function to get the absolute position of an element including the offset and the zoomlevel
 * @param options The arguments
 */
export function getAbsolutePosition({
  position,
  stageOffset,
  zoomLevel,
}: {
  position: Position;
  stageOffset: Position;
  zoomLevel: number;
}): Position {
  return {
    x: (position.x + stageOffset.x) * zoomLevel,
    y: (position.y + stageOffset.y) * zoomLevel,
  };
}

/**
 * Getter function which return the Arguments for the addWidget thunk
 * including the right values depending on the state / type of the element
 * @param element The Element the Widget refers to
 * @param options additional options
 */
export function getWidgetOptions(
  element: Pick<UIBoardElement, 'state' | 'shape' | 'type' | 'id'>,
  { zoomLevel, stageOffset }: { zoomLevel: number; stageOffset: Position }
): WidgetPayload {
  switch (element.state) {
    case BoardElementState.INEDIT: {
      switch (element.type) {
        case BoardElementType.STICKY: {
          return {
            refId: element.id,
            shape: {
              ...element.shape,
              ...getAbsolutePosition({
                // eslint-disable-next-line
                position: { x: element?.shape?.x!, y: element?.shape?.y! },
                stageOffset,
                zoomLevel,
              }),
            },
            type: WidgetTypes.TEXT,
            ...StickyStyles,
          };
        }
        case BoardElementType.CARD: {
          return {
            refId: element.id,
            shape: {
              ...element.shape,
              height: ValidationRules.LayoutRules.Card.CARD_MIN_HEIGHT - CardStyles.footerHeight,
              ...getAbsolutePosition({
                // eslint-disable-next-line
                position: { x: element?.shape?.x!, y: element?.shape?.y! },
                stageOffset,
                zoomLevel,
              }),
            },
            type: WidgetTypes.TEXT,
            ...CardStyles,
          };
        }
        case BoardElementType.COLUMN: {
          return {
            type: WidgetTypes.COLUMN,
            shape: {
              ...element.shape,
              ...getAbsolutePosition({
                // eslint-disable-next-line
                position: { x: element?.shape?.x!, y: element?.shape?.y! },
                stageOffset,
                zoomLevel,
              }),
              height: 50,
            },
            refId: element.id,
            fontSize: '16',
            widgetPx: { amount: 20, unit: 'px' },
            widgetPy: { amount: 32, unit: 'px' },
            backgroundColor: 'transparent',
          };
        }
        case BoardElementType.SWIMLANE: {
          return {
            type: WidgetTypes.COLUMN,
            shape: {
              ...element.shape,
              ...getAbsolutePosition({
                // eslint-disable-next-line
                position: { x: element?.shape?.x!, y: element?.shape?.y! },
                stageOffset,
                zoomLevel,
              }),
              height: 32,
            },
            isSwimlane: true,
            refId: element.id,
            widgetHeight: 32,
            widgetWidth: 280,
            fontSize: '16',
            backgroundColor: 'transparent',
          };
        }
        default: {
          return null!;
        }
      }
    }
    case BoardElementState.INDETAIL: {
      switch (element.type) {
        case BoardElementType.CARD: {
          return {
            type: WidgetTypes.CARDDETAILVIEW,
            refId: element.id,
          };
        }
        case BoardElementType.COLUMN: {
          return {
            type: WidgetTypes.COLUMNDETAILVIEW,
            refId: element.id,
          };
        }
        case BoardElementType.SWIMLANE: {
          return {
            type: WidgetTypes.COLUMNDETAILVIEW,
            refId: element.id,
          };
        }
        default: {
          return null!;
        }
      }
    }
    default: {
      return null!;
    }
  }
}

/**
 * Function to get the snapping position for elements
 * @param options
 */
export function getNextSnappingPosition({
  position,
  snappingValue,
}: {
  position: Position;
  snappingValue: number;
}) {
  return {
    x: Math.round(position.x / snappingValue) * snappingValue,
    y: Math.round(position.y / snappingValue) * snappingValue,
  };
}

/**
 * Splits up the given array by using this isValid rule
 * @param array the Array to divide
 * @param isValid the validation function checks per element
 * @returns [validElements, invalidElements]
 */
export function divideArrayByRule<T>(array: T[], isValid: (el: T) => boolean) {
  return array.reduce<[T[], T[]]>(
    ([pass, fail], elem) => (isValid(elem) ? [[...pass, elem], fail] : [pass, [...fail, elem]]),
    [[], []]
  );
}

export default sortBoardElementsByRanking;

export function isNumberKey(key: string) {
  return ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(key);
}

export function isColumnOrSwimlane(boardElement: UIBoardElement) {
  return (
    boardElement.type === BoardElementType.SWIMLANE || boardElement.type === BoardElementType.COLUMN
  );
}

export function isHexString(hex: string) {
  const hexRegex = /^#([\da-f]{3}){1,2}$/i;
  return hexRegex.test(hex);
}

export function daysToWeekString(days = 0) {
  const weeks = Math.floor(days / 7);
  if (weeks === 0) {
    return `${days} day${days === 1 ? '' : 's'}`;
  }
  return `${weeks} week${weeks === 1 ? '' : 's'}`;
}

export function filterCardsById(cards: UIBoardElement[], ids: string[]) {
  if (ids.length === 0) return [];
  return cards.filter((card) => ids.includes(card.id));
}
export function printLocaleDateAndTime(d: Date | string, locale = 'en'): string {
  let date: Date | undefined;
  if (typeof d === 'string') {
    date = new Date(d);
  }

  if (d instanceof Date) {
    date = d;
  }

  if (!date) return '';

  return `${date.toLocaleDateString(locale)}, ${date.toLocaleTimeString(locale)}`;
}

export const DefaultPasswordPolicyError =
  'The password must have a minimum length of 8 characters.';

export const getOnClickWithoutSelect = (() => {
  let clickTime = 0;
  const pos = { x: 0, y: 0 };

  return (onClick: () => void) => ({
    onMouseDown: ({ nativeEvent: e }: React.MouseEvent<HTMLDivElement>) => {
      clickTime = Date.now();
      pos.x = e.x;
      pos.y = e.y;
    },
    onMouseUp: ({ nativeEvent: e }: React.MouseEvent<HTMLDivElement>) => {
      if (Date.now() - clickTime < 200 && pos.x === e.x && pos.y === e.y) {
        const { target } = e;
        const actualTarget = target as HTMLElement;

        // if the "click" was on a link or a checkbox (that has the data-checked attribute)
        // we don't want to allow the click, because we either want to register
        // the checkbox or link click rather then executing the click
        if (
          actualTarget &&
          (actualTarget.tagName?.toLowerCase?.() === 'a' ||
            (actualTarget.dataset.checked != null && actualTarget.dataset.type == null))
        ) {
          return;
        }
        onClick();
      }
    },
  });
})();

export const getPersistedPreviewImageForBoard = (boardId: string) => ({
  previewImage: localStorage.getItem(`yoImg-${boardId}`),
  previewImageHash: localStorage.getItem(`${PREVIEW_IMAGE_PREFIX}${boardId}`),
});

export const setPreviewImageForBoard = (
  boardId: string,
  boardPreviewImage: string,
  boardCheckSum: string
) => {
  localStorage.setItem(`${PREVIEW_IMAGE_PREFIX}${boardId}`, boardCheckSum);
  localStorage.setItem(`yoImg-${boardId}`, boardPreviewImage);
};

export const isCtrlOrMetaKey = (
  e:
    | KonvaEventObject<MouseEvent>
    | KonvaEventObject<TouchEvent>
    | KonvaEventObject<DragEvent>
    | React.KeyboardEvent
    | KeyboardEvent
) => ('evt' in e ? e.evt.metaKey || e.evt.ctrlKey : e.metaKey || e.ctrlKey);

export const isBoardElementHighlighted = (boardElement: UIBoardElement) =>
  boardElement.state === BoardElementState.FOCUSED ||
  boardElement.state === BoardElementState.RESIZABLE;

export function isMac() {
  return window.navigator.platform === 'MacIntel';
}

export function getDefaultElementHeight(type: BoardElementType): number {
  switch (type) {
    case BoardElementType.COLUMN:
      return ValidationRules.LayoutRules.Column.COLUMN_MIN_SIZE;
    case BoardElementType.SWIMLANE:
      return ValidationRules.LayoutRules.Swimlane.SWIMLANE_MIN_SIZE;
    case BoardElementType.CARD:
      return ValidationRules.LayoutRules.Card.CARD_MIN_HEIGHT;
    case BoardElementType.STICKY:
      return ValidationRules.LayoutRules.Sticky.STICKY_MIN_SIZE;
    default: {
      throw new Error(`Unknown element type: ${type}`);
    }
  }
}

export function getDefaultElementWidth(type: BoardElementType): number {
  switch (type) {
    case BoardElementType.COLUMN:
      return ValidationRules.LayoutRules.Column.COLUMN_MIN_SIZE;
    case BoardElementType.SWIMLANE:
      return ValidationRules.LayoutRules.Swimlane.SWIMLANE_MIN_SIZE;
    case BoardElementType.CARD:
      return ValidationRules.LayoutRules.Card.CARD_MIN_WIDTH;
    case BoardElementType.STICKY:
      return ValidationRules.LayoutRules.Sticky.STICKY_MIN_SIZE;
    default: {
      throw new Error(`Unknown element type: ${type}`);
    }
  }
}

/**
 * Helper function to preload images
 * by loading them so they get cached by the browser
 * @param url url of the image
 * @returns a promise that resolves when the image is loaded
 */
export function preloadImage(url: string) {
  return new Promise<void>((resolve, reject) => {
    const image = new Image();
    image.onload = () => resolve();
    image.onerror = () => reject();
    image.src = url;
  });
}
