import { Text, Tooltip, Grid, Flex, Box } from '@chakra-ui/react';
import { formatNames } from 'lib/formatting.helper';
import React, { useEffect } from 'react';
import { useStoreState, useStoreActions } from 'store/hooks';

interface ReactionTooltipProps {
  userIds: string[];
  emoji: string;
}

const ReactionTooltip: React.FC<ReactionTooltipProps> = ({ userIds, emoji, children }) => {
  const boardId = useStoreState((store) => store.board.boardId);
  const users = useStoreState((store) => store.board.users);
  const { getUsers } = useStoreActions((store) => store.board);

  useEffect(() => {
    getUsers(boardId!);
  }, [getUsers, boardId]);

  const userNames = userIds
    .slice(0, 3)
    .map((userId) => users.find((user) => user._id.toString() === userId)?.firstName ?? 'Unknown');

  const showExtraRow = userIds.length > 3;
  const extraUserCount = userIds.length - 3;

  // when we go for multi-language in the future, and Intl.ListFormat is included in the new TS standard
  // we should use that instead
  const formattedUsernames =
    showExtraRow || userNames.length < 2 ? userNames.join(', ') : formatNames(userNames);

  const emojiLabel = (
    <Grid gridTemplateColumns="auto minmax(0, 1fr) auto">
      <Flex marginRight="5px" justifyContent="center" direction="column">
        <Text>{emoji}</Text>
      </Flex>
      <Box marginTop="5px" marginBottom="5px" marginRight="5px">
        <Text>{formattedUsernames}</Text>
        {showExtraRow && (
          <Text>
            and {extraUserCount} other user{extraUserCount > 1 ? 's' : ''}
          </Text>
        )}
      </Box>
    </Grid>
  );

  return (
    <Tooltip
      label={emojiLabel}
      fontSize="14px"
      fontWeight={500}
      gutter={10}
      placement="top"
      background="yoBrand.750"
    >
      {children}
    </Tooltip>
  );
};

export default ReactionTooltip;
