import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { Blocker } from 'shared';
import { PenIcon, CheckIcon, DotsIcon } from 'lib/icons';
import { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';

const InputBox = styled(Box)`
  br {
    display: none;
  }
`;

type BlockerCardProps = {
  onDelete: (blockerId: string) => void;
  onResolve: (blockerId: string) => void;
  onEdit: (blockerId: string, description: string) => void;
  isDisabled: boolean;
};

type BlockerCardInactiveProps = {
  onDelete: (blockerId: string) => void;
  onReopen: (blockerId: string) => void;
  canReopen: boolean;
  isDisabled: boolean;
};

export const BlockerCardInactive: React.FC<Blocker & BlockerCardInactiveProps> = ({
  onDelete,
  onReopen,
  canReopen,
  isDisabled,
  ...blocker
}) => (
  <Box mb={3} w="100%">
    <Box bg="yoGray.300" backgroundPosition="47px" color="black" borderRadius="sm" p={3}>
      <Text fontWeight="bold" mb={3}>
        {blocker.description}
      </Text>
      <Text fontSize="sm">{`Blocked on ${new Date(blocker.createdAt!).toLocaleDateString()}`}</Text>
      <HStack justify="space-between">
        <Text fontSize="sm">
          {`Resolved on ${new Date(blocker.resolvedAt!).toLocaleDateString()}`}
        </Text>
        <Menu>
          <MenuButton as={IconButton} icon={<DotsIcon />} variant="ghost" aria-label="more" />
          <MenuList>
            <MenuItem
              title="delete blocker"
              isDisabled={isDisabled}
              onClick={() => onDelete(blocker._id)}
            >
              Delete
            </MenuItem>
            <MenuItem
              isDisabled={isDisabled || !canReopen}
              onClick={() => onReopen(blocker._id)}
              title={canReopen ? 'reopen blocker' : 'resolve the active blocker to reopen'}
            >
              Reopen
            </MenuItem>
          </MenuList>
        </Menu>
      </HStack>
    </Box>
  </Box>
);

function setCursor(element: any) {
  const range = document.createRange();
  range.selectNodeContents(element);
  range.collapse(false);
  const sel = window.getSelection()!;
  sel.removeAllRanges();
  sel.addRange(range);
}

export const BlockerCardActive: React.FC<Blocker & BlockerCardProps> = ({
  onDelete,
  onResolve,
  onEdit,
  isDisabled,
  ...blocker
}) => {
  const [editing, setEditing] = useState(false);
  const [descriptionHTML, setDescriptionHTML] = useState(blocker.description);
  const textareaRef = useRef<any>();

  useEffect(() => {
    if (editing && textareaRef.current) {
      textareaRef.current.focus();
      setCursor(textareaRef.current);
    }
  }, [editing, textareaRef.current]);

  useEffect(() => {
    if (textareaRef.current) {
      setCursor(textareaRef.current);
    }
  }, [textareaRef.current, descriptionHTML]);

  return (
    <Box mb={3} w="100%">
      <Box
        bg={`repeating-linear-gradient(
          117deg,
          #DE6464,
          #DE6464 37px,
          #CF5050 37px,
          #CF5050 74px
      )`}
        color="white"
        borderRadius="sm"
        p={3}
      >
        {editing ? (
          <InputBox
            ref={textareaRef}
            role="textbox"
            contentEditable={!isDisabled}
            border="none"
            bg="transparent"
            outline="none"
            variant="unstyled"
            resize="vertical"
            mb={3}
            fontWeight="bold"
            onInput={(event: any) => {
              event.preventDefault();
              setDescriptionHTML(event.target.innerHTML);
            }}
            onBlur={() => {
              // setEditing(false);
            }}
            dangerouslySetInnerHTML={{ __html: descriptionHTML }}
          />
        ) : (
          <Text fontWeight="bold" mb={3}>
            {blocker.description}
          </Text>
        )}
        <Text fontSize="sm">
          {`Blocked since ${new Date(blocker.createdAt!).toLocaleDateString()}`}
        </Text>
      </Box>
      <ButtonGroup color="yoBrand.600" spacing={0}>
        {editing ? (
          <Button
            variant="ghost"
            name="save"
            key="save"
            isDisabled={isDisabled}
            p={2}
            ml={-2}
            onMouseDown={() => {
              if (textareaRef.current) {
                setEditing(false);
                setDescriptionHTML(textareaRef.current.innerText);
                onEdit(blocker._id, textareaRef.current.innerText.trim());
              }
            }}
          >
            <PenIcon mr={2} />
            Save
          </Button>
        ) : (
          <Button
            variant="ghost"
            name="edit"
            key="edit"
            isDisabled={isDisabled}
            p={2}
            ml={-2}
            onClick={() => {
              setEditing(true);
              setDescriptionHTML(blocker.description);
            }}
          >
            <PenIcon mr={2} />
            Edit
          </Button>
        )}
        <Button
          key="resolve"
          variant="ghost"
          disabled={editing || isDisabled}
          onClick={() => onResolve(blocker._id)}
        >
          <CheckIcon mr={2} />
          Resolve
        </Button>
        <Menu>
          <MenuButton
            as={IconButton}
            disabled={editing || isDisabled}
            icon={<DotsIcon />}
            aria-label="more"
            variant="ghost"
          />
          <MenuList>
            <MenuItem isDisabled={isDisabled} onClick={() => onDelete(blocker._id)}>
              Delete
            </MenuItem>
          </MenuList>
        </Menu>
      </ButtonGroup>
    </Box>
  );
};

export default BlockerCardActive;
