import { Grid, Box, Flex } from '@chakra-ui/react';
import { useStoreActions, useStoreState } from 'store/hooks';
import { COLORS, SWIMLANE_COLORS } from 'lib/colors';
import { useEffect, useState } from 'react';
import { CONTEXT_MENU_WIDTH } from '.';

interface ColorMatrixProps {
  pos?: DOMRect;
  refId: string;
  swimlaneColors?: boolean;
  isDisabled?: boolean;
}

const COLOR_MATRIX_WIDTH = 150;

export function useComputeColorMatrixLeftProps(parentBox: DOMRect | undefined) {
  const [openLeft, setOpenLeft] = useState<boolean>();
  useEffect(() => {
    const viewportWidth = window.innerWidth;
    const defaultColorMatrixRight = (parentBox?.right ?? 0) + COLOR_MATRIX_WIDTH;
    setOpenLeft(defaultColorMatrixRight > viewportWidth);
  }, [parentBox]);
  const colorMatrixLeft = openLeft ? -COLOR_MATRIX_WIDTH : CONTEXT_MENU_WIDTH;
  // to prevent flickering hide colormatrix until openLeft is no longer undefined
  const colorMatrixVisibility = openLeft === undefined ? 'hidden' : 'visible';
  return { left: colorMatrixLeft, visibility: colorMatrixVisibility as any };
}

const ColorMatrix: React.FC<ColorMatrixProps> = ({
  pos,
  refId,
  swimlaneColors,
  isDisabled = false,
}) => {
  const currentSelectedColor = useStoreState(
    (store) => store.board.boardElements.find((el) => el.id === refId)?.color
  );
  const setColor = useStoreActions((actions) => actions.board.updateElement);
  const leftProps = useComputeColorMatrixLeftProps(pos);
  const colors = swimlaneColors ? SWIMLANE_COLORS : COLORS;

  return pos ? (
    <Grid
      position="absolute"
      width={COLOR_MATRIX_WIDTH}
      height={100}
      bg="gray.700"
      borderRadius={4}
      color="white"
      gridTemplateColumns="repeat(3, 1fr)"
      gridTemplateRows="repeat(2, 1fr)"
      gridGap={0.5}
      {...leftProps}
    >
      {colors.map(({ value, name }) => (
        <Box
          key={value}
          as={Flex}
          height="100%"
          width="100%"
          justifyContent="center"
          alignItems="center"
          _hover={{ bg: 'gray.800' }}
          title={`Set Color to ${name}`}
          cursor={isDisabled ? 'not-allowed' : 'pointer'}
          onClick={() => {
            if (isDisabled) return;
            setColor({ id: refId, color: name });
          }}
        >
          <Box
            background={value}
            filter={isDisabled ? 'grayscale(0.8)' : undefined}
            borderRadius="50%"
            h="50%"
            w="50%"
          />
        </Box>
      ))}
    </Grid>
  ) : (
    <Grid
      position="relative"
      width="100%"
      height={35}
      borderRadius={4}
      color="white"
      gridTemplateColumns="repeat(6, 1fr)"
      gridTemplateRows="repeat(1, 1fr)"
      gridGap={0.5}
    >
      {colors.map(({ value, name }) => (
        <Box
          key={value}
          as={Flex}
          height="100%"
          width="100%"
          justifyContent="center"
          alignItems="center"
          position="relative"
        >
          <Box
            background={value}
            borderRadius="50%"
            h={35}
            w={35}
            border={currentSelectedColor === name ? '2px solid #257BFF' : '1px solid #cdcdcd'}
            boxShadow={currentSelectedColor === name ? '1px 1px 4px rgba(0, 0, 0, 0.25)' : 'none'}
            _hover={isDisabled ? {} : { boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.25)' }}
            title={`Set Color to ${name}`}
            cursor={isDisabled ? 'not-allowed' : 'pointer'}
            onClick={() => {
              if (isDisabled) return;
              setColor({ id: refId, color: name });
            }}
          />
        </Box>
      ))}
    </Grid>
  );
};

export default ColorMatrix;
