/* eslint-disable max-len */
/* eslint-disable operator-linebreak */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState, useRef, useEffect, useCallback } from 'react';
import { Stage } from 'react-konva';
import { Stage as IStage } from 'konva/types/Stage';
import { Rect as IRect } from 'konva/types/shapes/Rect';
import { StoreProvider } from 'easy-peasy';
import BoardElements from 'components/canvas/BoardElements';
import Cursors from 'components/canvas/Cursors';
import { AppCursor } from 'store/app';
import { BoardMode } from 'store/board/settings';
import { useCanvasListeners } from 'hooks/index';
import { useKeyboardShortcuts } from 'hooks/Keyboard';
import Selection from 'components/canvas/Selection';
import BoardWidgets from 'components/canvas/BoardWidgets';
import { Vector2d } from 'konva/types/types';
import { MOUSE_BUTTON } from 'lib/Types';
import theme from 'lib/theme';
import { isCtrlOrMetaKey, isElementInElement, setPreviewImageForBoard } from 'lib/helpers';
import { useStoreState, useStoreActions } from 'store/hooks';
import { useRouter } from 'next/router';
import { KonvaEventObject } from 'konva/types/Node';
import { useCardDeepLink } from 'hooks/useCardDeepLink';
import useWindowDimensions from 'hooks/useWindowDimensions';
import fetchClient from 'lib/Fetch';
import useTrackMouseOnStage from 'hooks/useTrackMouseOnStage';
import storeSingleton, { nonUpdatingStore } from 'store/index';
import { useCommentsReceiving } from 'hooks/Sockets';
import { isTakingScreenshot } from 'lib/screenshot';
import Background from '../Background';
import FloatingElement from '../FloatingElement';

export const STAGE_NAME = 'main';

function Canvas() {
  useKeyboardShortcuts();
  useCommentsReceiving();
  const stageRef = useCanvasListeners();
  const router = useRouter();
  const [isSelecting, setIsSelecting] = useState(false);
  const mouseDownPosRef = useRef<Vector2d | null>(null);
  const [selectionSpecs, setSelectionSpecs] = useState({
    show: false,
    position: {
      x: 0,
      y: 0,
    },
    width: 0,
    height: 0,
  });
  const rectRef = useRef<IRect>(null);
  const resetWidgets = useStoreActions((state) => state.widgets.closeWidgets);
  const setOffset = useStoreActions((actions) => actions.boardSettings.setOffset);
  const resetElementsState = useStoreActions((actions) => actions.board.resetElementsState);
  const selectElements = useStoreActions((actions) => actions.board.selectElements);
  const cursor = useStoreState((state) => state.app.cursor);
  const floatingBoardElement = useStoreState((state) => state.app.floatingBoardElement);
  const setMode = useStoreActions((actions) => actions.boardSettings.setMode);
  const setCursor = useStoreActions((actions) => actions.app.setCursor);
  const boardId = router.query.boardId as string;

  // we don't actually need the dimensions, but we want to rerender the canvas as soon as the browser gets resized
  useWindowDimensions();
  useCardDeepLink();

  useTrackMouseOnStage();

  const isStageOrBackground = useCallback(
    (e: KonvaEventObject<any>) => e.target === e.currentTarget || e.target.id().includes('bg'),
    []
  );

  useEffect(() => {
    if (stageRef.current) {
      const stage = stageRef.current.getStage() as IStage;
      const { stageOffset, zoomLevel } = storeSingleton.getState().boardSettings;
      stage.position({ x: stageOffset.x * zoomLevel, y: stageOffset.y * zoomLevel });
      stage.scale({ x: zoomLevel, y: zoomLevel });
      stage.batchDraw();
    }
  }, []);

  const getServerChecksumAndUpdateStorage = useCallback(
    async (boardImage: string) => {
      const { serverCheckSum } = await fetchClient<{ serverCheckSum: string }>(
        `boards/${boardId}/checksum`,
        {
          method: 'GET',
        }
      );
      setPreviewImageForBoard(boardId, boardImage, serverCheckSum);
    },
    [boardId]
  );

  useEffect(() => {
    // Rendering might not be completely done at the time of calling this
    // Currently the easiest solution is to delay the generation of the preview
    // by some time.
    // Reason for this is, that blockers aren't directly generated on the first render
    // but need some rerenders to justify it's layout on the card so the height is calculated
    // correctly
    // also this should only be done when we're opening the board in "screenshot mode" aka. in an iframe
    setTimeout(() => {
      const { mode } = storeSingleton.getState().boardSettings;
      if (mode !== BoardMode.TIMETRAVEL && stageRef.current && isTakingScreenshot()) {
        const BOARD_PREVIEW_QUALITY_REDUCTION_RATE = 0.2;
        const boardImage = stageRef.current
          .getStage()
          .toDataURL({ pixelRatio: BOARD_PREVIEW_QUALITY_REDUCTION_RATE });
        getServerChecksumAndUpdateStorage(boardImage);
      }
    }, 1000);
  }, [getServerChecksumAndUpdateStorage, stageRef]);

  const hideSelectionRectangle = useCallback(() => {
    setSelectionSpecs((cur) => ({
      ...cur,
      show: false,
    }));
  }, []);

  const selectContainingElements = useCallback(() => {
    // get all boardElements (rect -> card, column, note, swimlane and image -> special cards)
    const shapes = stageRef.current
      ?.getStage()
      .find('Rect')
      .toArray()
      .concat(stageRef.current?.getStage().find('Image').toArray());
    const selectionClientRect = rectRef.current!.getClientRect({});

    const selected = shapes?.filter((shape) => {
      if (shape.attrs.isBoardElement !== true) return false;
      // we need to check if it's `false`! everything else means visible!
      if (shape.attrs.visible === false || shape.attrs.name === 'locked') return false;
      const shapeClientRect = shape.getClientRect({ skipShadow: true });

      return isElementInElement(selectionClientRect, shapeClientRect);
    });

    if (selected) {
      /** boardElements are selected by their id
       * and they also have a special function called getId attached to them
       * which allows us to get the id of the element without knowing the structure and type
       * of the element
       * */

      selectElements(selected.map((s) => s.attrs.getId()));
    }
  }, [selectElements, stageRef]);

  const handleSelectionEnd = useCallback(() => {
    if (!isSelecting) return;

    setIsSelecting(false);

    hideSelectionRectangle();
    selectContainingElements();
  }, [hideSelectionRectangle, isSelecting, selectContainingElements]);

  const onCanvasClickOrTap = (e: KonvaEventObject<MouseEvent> | KonvaEventObject<TouchEvent>) => {
    const isEditingText = ['textarea', 'input'].includes(
      (document.activeElement as HTMLElement).tagName.toLowerCase()
    );

    // Only if the user isn't selecting and the target is the stage
    if (!e.evt.shiftKey && !isCtrlOrMetaKey(e) && !isSelecting && isStageOrBackground(e)) {
      if (!isEditingText) {
        resetWidgets();
      }
      resetElementsState();
    }
  };

  const { height: windowHeight, width: windowWidth } = useWindowDimensions();

  return (
    <>
      <Stage
        id={STAGE_NAME}
        ref={stageRef}
        width={windowWidth}
        height={windowHeight}
        draggable={!isSelecting}
        onContentMouseout={handleSelectionEnd}
        onMouseDown={(e) => {
          const { mode } = storeSingleton.getState().boardSettings;

          // User can multiselect everywhere, as long as he's pressing shift
          if (e.evt.shiftKey) {
            if (mode !== BoardMode.MOVE) {
              setMode(BoardMode.MOVE);
            }
            const { stageOffset, zoomLevel } = storeSingleton.getState().boardSettings;
            const pointerPosition = stageRef.current!.getStage().getPointerPosition();

            if (!pointerPosition) return;

            mouseDownPosRef.current = pointerPosition;
            setSelectionSpecs((cur) => ({
              ...cur,
              show: false,
              position: {
                x: pointerPosition.x / zoomLevel - stageOffset.x,
                y: pointerPosition.y / zoomLevel - stageOffset.y,
              },
              width: 1,
              height: 1,
            }));
          }
        }}
        onMouseMove={(e) => {
          if (!e.evt.shiftKey || !mouseDownPosRef.current || e.evt.button !== MOUSE_BUTTON.LEFT)
            return;

          const pointerPosition = stageRef.current!.getStage().getPointerPosition();
          const selectionPosition = rectRef.current!.getAbsolutePosition();
          if (!isSelecting) setIsSelecting(true);

          const { zoomLevel } = storeSingleton.getState().boardSettings;

          setSelectionSpecs((cur) => ({
            ...cur,
            show: true,
            width: (pointerPosition!.x - selectionPosition.x) / zoomLevel,
            height: (pointerPosition!.y - selectionPosition.y) / zoomLevel,
          }));
        }}
        onMouseUp={(e) => {
          const currentMode = storeSingleton.getState().boardSettings.mode;
          if (!isCtrlOrMetaKey(e)) {
            handleSelectionEnd();
          }

          mouseDownPosRef.current = null;
          setCursor(currentMode === BoardMode.MOVE ? AppCursor.CURSOR : AppCursor.DEFAULT);
        }}
        onClick={onCanvasClickOrTap}
        onTap={onCanvasClickOrTap}
        onDragStart={useCallback(
          (e) => {
            if (
              isStageOrBackground(e) &&
              e.evt.button === MOUSE_BUTTON.LEFT &&
              cursor !== AppCursor.GRABBING
            ) {
              // If the user is neither selecting, multiselecting nor dragging an element
              // that means he is dragging on the canvas to move, that means we must
              // behave like he clicked on the canvas and reset widgets state (like InEdit)
              if (
                !e.evt.shiftKey &&
                !isSelecting &&
                !isCtrlOrMetaKey(e) &&
                isStageOrBackground(e)
              ) {
                resetWidgets();
              }

              setCursor(AppCursor.GRABBING);
            }
          },
          [cursor, isSelecting, isStageOrBackground, resetWidgets, setCursor]
        )}
        onDragEnd={useCallback(
          (e) => {
            if (e.target === e.currentTarget) {
              setOffset({ x: e.currentTarget.attrs.x, y: e.currentTarget.attrs.y });
            }
          },
          [setOffset]
        )}
        style={{ cursor, backgroundColor: theme.colors.yoGray[300], overflow: 'hidden' }}
      >
        {/** We need to add a new StoreProvider here to "bridge" it into Konva */}
        <StoreProvider store={nonUpdatingStore}>
          <Background />
          <BoardElements />
          <Selection selectionSpecs={selectionSpecs} rectRef={rectRef} />
          {floatingBoardElement && <FloatingElement />}
          <Cursors />
        </StoreProvider>
      </Stage>
      <BoardWidgets />
    </>
  );
}

export default Canvas;
