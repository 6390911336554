import { Rect, Group, Image } from 'react-konva';
import { AppCursor } from 'store/app';
import { BoardMode } from 'store/board/settings';
import { useStoreActions, useStoreState } from 'store/hooks';
import { useImage } from 'hooks/useImage';
import { KonvaEventObject } from 'konva/types/Node';

const EDIT_BUTTON_NAME = 'editButton';

export function doesEventBelongToEditButton(
  e: KonvaEventObject<MouseEvent> | KonvaEventObject<TouchEvent>
): boolean {
  return e.target.attrs.name === EDIT_BUTTON_NAME;
}

interface EditButtonProps {
  x: number;
  y: number;
  hexColor: string;
}
// This function is used to colorize the icon via a parameter because the Konva Image doesn't pass the color to the svg
export function getColoredEditIconURL(color?: string) {
  const iconSvg = `
    <svg width="16" height="16" stroke="${
      color ?? 'blue'
    }" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 13.3333H14" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M11 2.33334C11.2652 2.06813 11.6249 1.91913 12 1.91913C12.1857 1.91913 12.3696 1.95571 12.5412 2.02678C12.7128 2.09785 12.8687 2.20202 13 2.33334C13.1313 2.46466 13.2355 2.62057 13.3066 2.79215C13.3776 2.96373 13.4142 3.14762 13.4142 3.33334C13.4142 3.51906 13.3776 3.70296 13.3066 3.87454C13.2355 4.04612 13.1313 4.20202 13 4.33334L4.66667 12.6667L2 13.3333L2.66667 10.6667L11 2.33334Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  `;
  return `data:image/svg+xml;base64,${btoa(iconSvg)}`;
}

const EditButton: React.FC<EditButtonProps> = ({ x, y, hexColor }) => {
  const setCursor = useStoreActions((actions) => actions.app.setCursor);
  const [editColumnImage] = useImage(getColoredEditIconURL(hexColor), 'Edit');
  const mode = useStoreState((store) => store.boardSettings.mode);

  return (
    <Group
      name={EDIT_BUTTON_NAME}
      onMouseEnter={() => setCursor(AppCursor.POINTER)}
      onMouseLeave={() => setCursor(mode === BoardMode.MOVE ? AppCursor.CURSOR : AppCursor.DEFAULT)}
      width={40}
      height={40}
      x={x}
      y={y}
    >
      {/* This rectangle increases the hitbox for the button the size of 40x40 */}
      <Rect name={EDIT_BUTTON_NAME} width={40} height={40} />
      <Image name={EDIT_BUTTON_NAME} image={editColumnImage} width={16} height={16} y={12} x={12} />
    </Group>
  );
};

export default EditButton;
