import React, { useMemo, useRef } from 'react';
import {
  Avatar,
  Box,
  Flex,
  FormControl,
  Icon,
  IconButton,
  Input,
  InputGroup,
  Stack,
  Text,
  useOutsideClick,
} from '@chakra-ui/react';
import { v4 as generateUuid } from 'uuid';
import { Avatar as AvatarType, Blocker } from 'shared';
import { useStoreActions, useStoreState } from 'store/hooks';
import { MdClose, MdFileUpload, MdLink } from 'react-icons/md';
import { trackPlausibleEvent } from 'lib/plausible';
import { CLIENT_PLAUSIBLE_EVENTS } from 'lib/Types';
import { useAttachments } from 'hooks/useAttachments';
import AvatarSelection from '../AvatarSelection';
import SearchResultList from './SearchResultList';
import CreateBlockerForm from './CreateBlockerForm';
import { BlockerCardActive, BlockerCardInactive } from './BlockerCards';
import ColorMatrix from '../ContextMenu/ColorMatrix';
import { ActionButton } from './ActionButton';
import { WatchButton } from './WatchButton';

export const ASSIGNED_TO_ID = 'assignedTo';
const SEARCH_RESULT_ZINDEX = 200;

interface Props {
  assignedAvatars: string[];
  avatars: AvatarType[];
  blockers?: Blocker[];
  creator?: AvatarType;
  watchers?: string[];
  searchValue: string;
  onChange: (newValue: string) => void;
  onSearchFocus: () => void;
  onSearchBlur: () => void;
  refId: string;
  currentAvatarIndex: number;
  onAssign: (id: string) => void;
  searchResults: AvatarType[];
  handleClose: () => void;
  readOnly?: boolean;
  attachmentObject: ReturnType<typeof useAttachments>;
}

const RightColumn: React.FC<Props> = ({
  assignedAvatars = [],
  avatars,
  blockers = [],
  creator,
  watchers = [],
  searchValue,
  onChange,
  onSearchFocus,
  onSearchBlur,
  refId,
  currentAvatarIndex,
  onAssign,
  searchResults,
  handleClose,
  readOnly = false,
  attachmentObject,
}) => {
  const assignedInputRef = useRef<HTMLInputElement>(null);
  const assigneeRef = useRef<HTMLDivElement>(null);
  const updateElement = useStoreActions((actions) => actions.board.updateElement);
  const hasSearchBox = searchResults.length > 0;

  useOutsideClick({
    ref: assigneeRef,
    handler: onSearchBlur,
  });

  const user = useStoreState((store) => store.user.user);
  const openLinkPopover = useStoreActions((actions) => actions.app.setIsLinkPopoverOpen);
  const fileUploadRef = useRef<HTMLInputElement>(null);
  const activeBlockers = useMemo(
    () => blockers?.filter((blocker) => !blocker.resolvedAt),
    [blockers]
  );

  const { addAttachment } = attachmentObject;
  const currentElement = useStoreState((store) =>
    store.board.boardElements.find((element) => element.id === refId)
  );

  const inactiveBlockers = useMemo(
    () => blockers?.filter((blocker) => !!blocker.resolvedAt),
    [blockers]
  );
  const handleUnassign = (id: string) => {
    updateElement({
      id: refId,
      assignedAvatars: [...(assignedAvatars ?? [])].filter((avatar) => avatar !== id),
    });
  };

  const handleAssign = (id: string) => {
    onAssign(id);
    assignedInputRef.current!.focus();
  };

  const handleCreateBlockerSubmit = async (description: string) => {
    updateElement({
      id: refId,
      blockers: [
        ...blockers,
        {
          _id: generateUuid(),
          description,
          createdAt: new Date(),
          cardId: refId,
        },
      ],
    });
  };

  const handleBlockerDelete = async (id: string) => {
    updateElement({
      id: refId,
      blockers: blockers.filter((currentBlocker) => currentBlocker._id !== id),
    });
  };

  const handleBlockerUpdate = (id: string, value: string) => {
    if (value !== blockers.find((currentBlocker) => currentBlocker._id === id)!.description) {
      updateElement({
        id: refId,
        blockers: blockers.map((currentBlocker) => {
          if (id !== currentBlocker._id) {
            return currentBlocker;
          }
          return {
            ...currentBlocker,
            description: value,
          };
        }),
      });
    }
  };

  const handleBlockerResolve = (id: any) => {
    const newBlockers = blockers.map((currentBlocker) => {
      if (id !== currentBlocker._id) {
        return currentBlocker;
      }
      return {
        ...currentBlocker,
        resolvedAt: new Date(),
      };
    });
    updateElement({
      id: refId,
      blockers: newBlockers,
    });
  };

  const handleBlockerReopen = (id: any) => {
    updateElement({
      id: refId,
      blockers: blockers.map((currentBlocker) => {
        if (id !== currentBlocker._id) {
          return currentBlocker;
        }
        return {
          ...currentBlocker,
          resolvedAt: null,
          createdAt: new Date(),
        };
      }) as any,
    });
  };

  const getSearchBoxTop = () => {
    const creatorHeight = creator ? 87 : 78;
    const inputHeight = 46;
    const assignedUserElementHeight = 48;
    const chakraStackSpacing = 2;
    if (assignedAvatars.length === 0) {
      return creatorHeight + inputHeight;
    }

    if (assignedAvatars.length === 1) {
      return creatorHeight + inputHeight + assignedAvatars.length * assignedUserElementHeight;
    }
    // the chakra stack adds margin to the top and bottom of the stack
    return (
      creatorHeight +
      inputHeight +
      assignedAvatars.length * (assignedUserElementHeight + chakraStackSpacing)
    );
  };

  return (
    <Flex
      direction="column"
      bg="yoGray.200"
      flex={1}
      p={10}
      borderRightRadius="modal"
      data-testid="right-column"
    >
      <Flex alignItems="center" justifyContent="space-between" mb={4}>
        <Text size="sm" fontWeight={700}>
          Created by
        </Text>
        <IconButton
          display={{ base: 'none', md: 'flex' }}
          onClick={handleClose}
          aria-label="Close Detail View"
          icon={<MdClose />}
          fontSize="3xl"
          size="sm"
          bg="transparent"
          _focus={{ border: 'none' }}
          _hover={{ bg: 'transparent' }}
        />
      </Flex>
      <Stack ref={assigneeRef} direction="column" spacing={8} position="relative">
        <Flex align="center">
          {creator ? (
            <>
              <Avatar size="sm" src={creator.url} name={creator.name} mr={4} title={creator.name} />
              <Text fontSize={16} fontWeight="normal">
                {creator.name}
              </Text>
            </>
          ) : (
            <Text fontSize={16} fontWeight="normal">
              unknown
            </Text>
          )}
        </Flex>
        <Box>
          <Text size="sm" fontWeight={700}>
            Assigned to
          </Text>
          <FormControl>
            <Stack mb={2} spacing={2}>
              {assignedAvatars?.map((currentId) => {
                const currentAvatar = avatars.find((avatar) => avatar.id === currentId);
                return (
                  // @ts-expect-error: whatever
                  <AvatarSelection
                    key={currentId}
                    canUnassign
                    onUnassign={() => handleUnassign(currentId)}
                    isDisabled={readOnly}
                    {...currentAvatar}
                  />
                );
              })}
            </Stack>
            <Input
              type="text"
              id={ASSIGNED_TO_ID}
              data-testid={ASSIGNED_TO_ID}
              value={searchValue}
              borderColor="yoGray.border"
              color="black"
              borderRadius="sm"
              borderBottomRadius={hasSearchBox ? 0 : 'sm'}
              placeholder="Assign to..."
              _placeholder={{ color: 'placeholder' }}
              onChange={(e) => onChange(e.target.value)}
              onClick={onSearchFocus}
              ref={assignedInputRef}
              width="100%"
              _focus={{ outline: 'none' }}
              _hover={{ borderColor: 'yoGray.border' }}
              isDisabled={readOnly}
            />
          </FormControl>
          {hasSearchBox && (
            <SearchResultList
              top={`${getSearchBoxTop()}px`}
              width="100%"
              currentAvatarIndex={currentAvatarIndex}
              onClick={handleAssign}
              searchResults={searchResults}
              zIndex={SEARCH_RESULT_ZINDEX}
              currentUserId={user?._id ?? ''}
            />
          )}
        </Box>
        {/* Color */}
        {!currentElement?.cardIntegrationType && (
          <Box>
            <Text size="sm" fontWeight={700} mb={4}>
              Color
            </Text>
            <ColorMatrix swimlaneColors={false} refId={refId} isDisabled={readOnly} />
          </Box>
        )}
        {/* Actions */}
        <Box>
          <Text size="sm" fontWeight={700} mb={4}>
            Actions
          </Text>
          <Stack>
            <WatchButton watchers={watchers} cardId={refId} isDisabled={readOnly} />
            <ActionButton
              leftIcon={<Icon as={MdLink} fontSize="22px" />}
              onClick={() => {
                trackPlausibleEvent(CLIENT_PLAUSIBLE_EVENTS.OPENED_LINK_SEARCH, {
                  location: 'DetailView - RightColumn',
                });
                openLinkPopover(true);
              }}
              title="Add a new link to a card"
              isDisabled={readOnly}
            >
              Link a Card
            </ActionButton>
            <InputGroup onClick={() => fileUploadRef.current?.click()} width="auto" mr="auto">
              <input
                multiple={false}
                accept="*"
                type="file"
                hidden
                ref={fileUploadRef}
                onChange={(e) => {
                  const { files } = e.target;
                  if (!files || files.length !== 1) return;
                  addAttachment(files[0]);
                  if (fileUploadRef.current) fileUploadRef.current.value = '';
                }}
                disabled={readOnly}
              />
              <ActionButton
                leftIcon={<Icon as={MdFileUpload} fontSize={22} />}
                title="Add new Attachment"
                isDisabled={readOnly}
              >
                Add Attachment
              </ActionButton>
            </InputGroup>
          </Stack>
        </Box>
        {/* <LoomButton /> */}
        {/* Blocker Part */}
        <Box>
          <Text size="sm" fontWeight={700} mb={4}>
            Blocker
          </Text>
          {activeBlockers?.length > 0 ? (
            activeBlockers?.map((blocker) => (
              <BlockerCardActive
                key={blocker._id}
                {...blocker}
                isDisabled={readOnly}
                onDelete={handleBlockerDelete}
                onEdit={handleBlockerUpdate}
                onResolve={handleBlockerResolve}
              />
            ))
          ) : (
            <CreateBlockerForm onSubmit={handleCreateBlockerSubmit} isDisabled={readOnly} />
          )}
          {inactiveBlockers?.map((blocker) => (
            <BlockerCardInactive
              key={blocker._id}
              {...blocker}
              isDisabled={readOnly}
              onDelete={handleBlockerDelete}
              canReopen={activeBlockers.length === 0}
              onReopen={handleBlockerReopen}
            />
          ))}
        </Box>
      </Stack>
    </Flex>
  );
};

export default RightColumn;
