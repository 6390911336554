import { createStore } from 'easy-peasy';
import socketConnectivityStoreModel, {
  SocketConnectivityStoreModel,
} from 'store/socketConnectivity';
import boardModel, { PublicBoardStoreModel, BoardStoreModel } from './board';
import widgetModel, { WidgetStoreModel } from './widgets';
import appStoreModel, { AppStoreModel } from './app';
import errorStoreModel, { ErrorStoreModel } from './errors';
import settingsStore, { SettingsStoreModel } from './board/settings';
import userStoreModel, { UserStoreModel } from './user';
import activeUserStoreModel, { ActiveUserStoreModel } from './activeUsers';
import metricsStoreModel, { MetricsStoreModel } from './metrics';
import serviceAccountStoreModel, { ServiceAccountStoreModel } from './serviceAccounts';
import { NotificationStoreModel, notificationStore } from './board/notifications';

export interface StoreModel {
  board: BoardStoreModel;
  widgets: WidgetStoreModel;
  app: AppStoreModel;
  socketConnectivity: SocketConnectivityStoreModel;
  errors: ErrorStoreModel;
  boardSettings: SettingsStoreModel;
  user: UserStoreModel;
  activeUsers: ActiveUserStoreModel;
  metrics: MetricsStoreModel;
  serviceAccounts: ServiceAccountStoreModel;
  notifications: NotificationStoreModel;
}

export interface PublicStoreModel extends Omit<StoreModel, 'board'> {
  board: PublicBoardStoreModel;
}

export const storeModel: StoreModel = {
  board: boardModel,
  widgets: widgetModel,
  app: appStoreModel,
  socketConnectivity: socketConnectivityStoreModel,
  errors: errorStoreModel,
  boardSettings: settingsStore,
  user: userStoreModel,
  activeUsers: activeUserStoreModel,
  metrics: metricsStoreModel,
  serviceAccounts: serviceAccountStoreModel,
  notifications: notificationStore,
};

const store = createStore(storeModel);

const initialStore = store.getState().boardSettings;

const currentValues = {
  zoomLevel: initialStore.zoomLevel,
  stageOffset: initialStore.stageOffset,
};

/**
 * We need to listen to store updates
 * and if there are some relevant updates for the store
 * save them to the localstorage
 */
store.subscribe(() => {
  const { boardSettings, board } = store.getState();
  if (
    currentValues.stageOffset.x !== boardSettings.stageOffset.x ||
    currentValues.stageOffset.y !== boardSettings.stageOffset.y ||
    currentValues.zoomLevel !== boardSettings.zoomLevel
  ) {
    currentValues.stageOffset = boardSettings.stageOffset;
    currentValues.zoomLevel = boardSettings.zoomLevel;
    const { boardId } = board;
    if (boardId) {
      localStorage.setItem(`yo-${boardId}`, JSON.stringify(currentValues));
    }
  }
});

export const nonUpdatingStore = store;

export default store;
