import { BoardElementState } from 'types/index';
import { Position } from 'store/app';
import { BoardElementType } from 'shared';
import nonUpdatingStore from 'store/index';
import { getStagePositionForElementDrag } from 'lib/zoom';
import { DragContext, DragElement, DragModel } from './DragModel';
import { DragCardHandler } from './DragCardHandler';
import { DragColumnHandler } from './DragColumnHandler';
import { DragDefaultHandler } from './DragDefaultHandler';
import { createDragModel } from './DragModelCreator';

/**
 * The `DragHandlerManager` orchestrates scenario-speficic `DragHandler`.
 *
 * It is implemented as primitive JS module.
 * We don't want `dragMove` being perf-impacted by react hooks.
 */

let dragContext: DragContext | undefined;
let dragModel: DragModel | undefined;
let dragCardHandler: DragCardHandler | undefined;
let dragColumnHandler: DragColumnHandler | undefined;
let dragDefaultHandler: DragDefaultHandler | undefined;

export function getDragModel() {
  return dragModel;
}

function moveStage() {
  // stop recursion whenever
  // - there is no more dragModel (end drag) or
  // - there is no more moveVector (end dragging at border)
  if (dragModel?.moveVector == null) {
    return;
  }

  const newStagePos = {
    x: dragModel.stage.x() + dragModel.moveVector.x,
    y: dragModel.stage.y() + dragModel.moveVector.y,
  };

  dragModel.stage.position(newStagePos).batchDraw();

  // TODO: increase performance by not redrawing all elements
  nonUpdatingStore.getActions().boardSettings.setNormalizedOffset(newStagePos);
  dragModel.stageOffset = newStagePos;

  // go into recursion (continue paning into the direction of the moveVector)
  setTimeout(moveStage, 50);
}

export function clearDragging() {
  if (!dragModel) {
    return;
  }

  // update global state
  nonUpdatingStore.getActions().boardSettings.setNormalizedOffset(dragModel.stageOffset);
  nonUpdatingStore.getActions().board.setHasDraggedElement(false);

  dragModel = undefined;
}

export function startDragging({
  element,
  canBeResized,
  updateElement,
  setElementState,
}: {
  element: DragElement;
  canBeResized: boolean;
  updateElement: DragContext['updateElement'];
  setElementState: DragContext['setElementState'];
}) {
  // create `DragContext`
  if (!dragContext) {
    dragContext = {
      updateElement,
      setElementState,
    };
  }

  // create `DragHandler`
  if (!dragDefaultHandler) {
    dragDefaultHandler = new DragDefaultHandler(dragContext);
  }
  if (!dragCardHandler) {
    dragCardHandler = new DragCardHandler(dragContext);
  }
  if (!dragColumnHandler) {
    dragColumnHandler = new DragColumnHandler(dragContext);
  }

  if (dragModel) {
    throw new Error(
      `DragHandlerManager.startDragging() a previous dragModel has not been cleaned up`
    );
  }

  // globally mark that we are dragging
  nonUpdatingStore.getActions().board.setHasDraggedElement(true);

  // create `DragState`
  dragModel = createDragModel({ element, canBeResized });

  // hand over to dedicated `DragHandler`
  if (element.type === BoardElementType.CARD) {
    dragCardHandler.start(dragModel);
  } else if (element.type === BoardElementType.COLUMN) {
    dragColumnHandler.start(dragModel);
  } else {
    dragDefaultHandler.start(dragModel);
  }

  setElementState({
    id: element.id,
    state: canBeResized ? BoardElementState.RESIZABLE : BoardElementState.FOCUSED,
  });
}

export function moveDragElement({
  position,
  absolutePosition,
}: {
  position: Position;
  absolutePosition: Position;
}) {
  if (!dragModel) {
    throw new Error(`DragHandlerManager.moveDragElement() no dragModel`);
  }

  // patch new position into our state
  dragModel.element.shape = { ...dragModel.element.shape, ...position };

  // hand over to dedicated `DragHandler`
  if (dragModel.element.type === BoardElementType.CARD) {
    dragCardHandler?.move({ position });
  } else if (dragModel.element.type === BoardElementType.COLUMN) {
    dragColumnHandler?.move({ position });
  } else {
    dragDefaultHandler?.move({ position });
  }

  // todo fix paning for flyout
  if (position.x === absolutePosition.x && position.y === absolutePosition.y) {
    return;
  }

  // pane stage when dragging at border
  if (![BoardElementType.SWIMLANE, BoardElementType.COLUMN].includes(dragModel.element.type)) {
    const result = getStagePositionForElementDrag({
      currentElement: dragModel.element,
      currentElementPosition: absolutePosition,
      offset: dragModel.stageOffset,
      zoomLevel: dragModel.zoomLevel,
      screenHeight: window.innerHeight,
      screenWidth: window.innerWidth,
    });

    if (result) {
      // start moving the stage
      dragModel.moveVector = result.moveVector;
      moveStage();
    } else {
      // stop moving the stage
      dragModel.moveVector = null;
    }
  }
}

export function stopDragging() {
  if (!dragModel) {
    throw new Error(`DragHandlerManager.stopDragging() no dragModel`);
  }

  // hand over to dedicated `DragHandler`
  if (dragModel.element.type === BoardElementType.CARD) {
    dragCardHandler?.stop();
  } else if (dragModel.element.type === BoardElementType.COLUMN) {
    dragColumnHandler?.stop();
  } else {
    dragDefaultHandler?.stop();
  }

  clearDragging();
}

export function interruptDragging() {
  if (!dragModel) {
    throw new Error(`DragHandlerManager.interruptDragging() no dragModel`);
  }

  // hand over to dedicated `DragHandler`
  if (dragModel.element.type === BoardElementType.CARD) {
    dragCardHandler?.interrupt();
  }

  clearDragging();
}
