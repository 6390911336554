/* eslint-disable operator-linebreak */
import { Text, Flex, Stack, Heading, Button, Avatar, Box } from '@chakra-ui/react';
import { UIUser } from 'store/user';
import { Avatar as IAvatar } from 'shared';
import { CloseIcon } from '@chakra-ui/icons';
import { useUser } from 'hooks/User';
import AddTeamMemberBox from './AddTeamMemberBox';
import { useTeamMembers, useVirtualTeamMembers } from './hooks';
import BoardShare from './BoardShare';

interface VirtualTeamMemberProps extends IAvatar {
  index: number;
  handleDelete: (id: string) => void;
}

const VirtualTeamMember: React.FC<VirtualTeamMemberProps> = ({
  index,
  url,
  name,
  id,
  handleDelete,
}) => (
  <Flex
    justifyContent="space-between"
    alignItems="center"
    borderTop={index === 0 ? 'none' : '1px solid #C4C4C4'}
    paddingTop={index === 0 ? '0' : 'calc(0.75rem - 1px)'}
    paddingBottom={3}
  >
    <Flex alignItems="center">
      <Avatar src={url} name={name} mr={6} />
      <Text>{name} (Virtual Team Member)</Text>
    </Flex>
    <Button
      variant="ghost"
      color="yoBrand.500"
      fontSize="md"
      fontWeight="bold"
      stroke="2px"
      title={`Remove ${name}`}
      onClick={() => handleDelete(id)}
      _hover={{ bg: 'transparent' }}
      _active={{ bg: 'transparent', border: 'none' }}
      _focus={{ bg: 'transparent', border: 'none' }}
    >
      Remove
      <CloseIcon boxSize="16px" pt={1} mt={-1} ml={4} />
    </Button>
  </Flex>
);

export interface ITeamMember extends UIUser {
  isOwner?: boolean;
  name: string;
}

interface TeamMemberProps extends ITeamMember {
  index: number;
  handleDelete: (id: string) => void;
  isCurrentUser?: boolean;
}

const TeamMember: React.FC<TeamMemberProps> = ({
  index,
  avatarUrl,
  name,
  email,
  _id,
  handleDelete,
  isOwner,
  isCurrentUser,
}) => {
  const canBeRemoved = !(isCurrentUser || isOwner);
  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      borderTop={index === 0 ? 'none' : '1px solid #C4C4C4'}
      paddingTop={index === 0 ? '0' : 'calc(0.75rem - 1px)'}
      paddingBottom={3}
    >
      <Flex alignItems="center">
        <Avatar src={avatarUrl} name={name || email} mr={6} />
        <Text>
          {name ? `${name} ` : ''}({email})
        </Text>
        {isOwner && (
          <Text ml={1} display="inline" fontStyle="italic">
            {' '}
            - Board Owner
          </Text>
        )}
      </Flex>
      {canBeRemoved && (
        <Button
          variant="ghost"
          color="yoBrand.500"
          fontSize="md"
          fontWeight="bold"
          title={`Remove ${name}`}
          onClick={() => handleDelete(_id)}
          _hover={{ bg: 'transparent' }}
          _active={{ bg: 'transparent', border: 'none' }}
          _focus={{ bg: 'transparent', border: 'none' }}
        >
          Remove
          <CloseIcon boxSize="16px" pt={1} mt={-1} ml={4} />
        </Button>
      )}
    </Flex>
  );
};

export const TeamMembers: React.FC<{ showTitle?: boolean; boardId?: string }> = ({
  showTitle = true,
  boardId,
}) => {
  const { teamMembers, removeTeamMember } = useTeamMembers(boardId);
  const { virtualTeamMembers, removeVirtualTeamMember } = useVirtualTeamMembers(boardId);
  const user = useUser();
  const allTeamMembers = [...teamMembers, ...virtualTeamMembers].sort(
    ({ name: nameA }, { name: nameB }) => nameA!.localeCompare(nameB!)
  );

  return (
    <Box maxW="800px">
      {showTitle && (
        <Heading color="yoBrand.600" fontSize="xl" fontWeight="bold" mb={2}>
          Sharing
        </Heading>
      )}
      <Text color="yoGray.900">
        You can share the current board with other Taggle users. This will give these users access
        to your board. They can use the board the same way you can except deleting it. You will only
        be able to invite users via email that already signed up for Taggle.
      </Text>
      <Flex mt={4} direction="row" alignItems="center">
        <AddTeamMemberBox boardId={boardId} />
        <Text ml={5} mr={1} color="yoGray.900">
          or
        </Text>
        <BoardShare boardId={boardId} />
      </Flex>
      <Flex mt={6} mb={6} direction="column" color="yoGray.900">
        <Stack spacing={0}>
          {allTeamMembers?.map((teamMember, i) =>
            (teamMember as ITeamMember).email ? (
              <TeamMember
                key={(teamMember as ITeamMember)._id}
                handleDelete={removeTeamMember}
                index={i}
                isCurrentUser={user?._id === (teamMember as ITeamMember)._id}
                {...(teamMember as ITeamMember)}
              />
            ) : (
              <VirtualTeamMember
                key={(teamMember as IAvatar).id}
                handleDelete={removeVirtualTeamMember}
                index={i}
                {...(teamMember as IAvatar)}
              />
            )
          )}
        </Stack>
      </Flex>
    </Box>
  );
};

export default TeamMembers;
