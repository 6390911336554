import { RefObject, useEffect, useRef } from 'react';

export const CURRENT_USER_MENTION_CLASSNAME = 'current-user-mention';

/**
 * This function is used to enhance the html content before it is displayed to the user.
 * If there are mentions in it, it add a class to the mention for the currently logged in user
 * if there are checkboxes in it they will be enhanced with data-tags
 * @param html the raw HTML content
 * @param userId the id of the currently logged in user
 * @returns the enhanced HTML content
 */
export function enhanceHTMLForUI(html: string, userId: string | undefined): string {
  const htmlElement = document.createElement('div');
  htmlElement.innerHTML = html;
  htmlElement.querySelectorAll(`[data-userid='${userId}']`).forEach((el) => {
    el.classList.add(CURRENT_USER_MENTION_CLASSNAME);
  });

  // Update the taskItem nodes so that they are wrapped in a label
  // This makes the clickarea of the checkbox wider and thus more enjoyable to click
  htmlElement.querySelectorAll<HTMLLIElement>("[data-type='taskItem']").forEach((el) => {
    const label = document.createElement('label');
    const checkbox = document.createElement('input');
    checkbox.type = 'checkbox';
    checkbox.dataset.checked = el.dataset.checked;
    checkbox.dataset.linkid = el.dataset.linkid;

    label.append(checkbox);
    label.append(el.innerText);
    label.dataset.checked = el.dataset.checked;
    label.dataset.linkid = el.dataset.linkid;

    // eslint-disable-next-line no-param-reassign
    el.innerHTML = '';
    el.appendChild(label);
  });

  return htmlElement.innerHTML;
}

/**
 * Helper hook that will update the checked attribute of the checkboxes
 * after successfully mounting on the client (doesn't work with SSR).
 * It also allows to add a handler for a checkbox change
 * @param onCheckboxChange handler for a checkbox change
 * @param isEnabled flag to disable the handler (e.g. if we are editing using the WYSIWYG editor)
 * @returns the ref for the read-only editor content
 */
export function useHydrateCheckboxes(
  onCheckboxChange: (linkId: string) => void,
  isEnabled = false
): RefObject<HTMLDivElement> {
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // get all checkboxes and update their checked attribute
    contentRef.current
      ?.querySelectorAll<HTMLInputElement>('input[data-checked="true"]')
      .forEach((el) => {
        if ((el.dataset.checked === 'true') !== el.checked) {
          // eslint-disable-next-line no-param-reassign
          el.checked = true;
        }
      });
  });

  // Disable checkboxes
  useEffect(() => {
    if (!isEnabled) {
      contentRef.current
        ?.querySelectorAll<HTMLInputElement>('input[type="checkbox"]')
        .forEach((el) => {
          // eslint-disable-next-line no-param-reassign
          el.disabled = true;
        });
    }
  });

  useEffect(() => {
    const contentDiv = contentRef.current;
    const handleCheckboxOrLabelClick = (e: MouseEvent) => {
      if (!isEnabled) return;
      const target = e.target as HTMLElement;

      if (
        target.dataset.checked != null &&
        target.dataset.type == null &&
        target.dataset.linkid != null
      ) {
        onCheckboxChange(target.dataset.linkid);
      }
    };

    contentDiv?.addEventListener('click', handleCheckboxOrLabelClick);
    return () => {
      contentDiv?.removeEventListener('click', handleCheckboxOrLabelClick);
    };
  }, [isEnabled, onCheckboxChange]);

  return contentRef;
}
