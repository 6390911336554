import React, { useMemo, useCallback, useEffect, useRef, useState } from 'react';
import {
  Flex,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  InputLeftAddon,
  Box,
  useOutsideClick,
  Text,
  Spinner,
  Grid,
  InputProps,
  Tag,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import { BoardElement } from 'shared';
import { SearchStatus, useSearchCards } from 'hooks/useSearchCards';
import { getCardColorByName } from 'lib/colors';
import { SearchIcon } from 'lib/icons';
import { Key } from 'ts-key-enum';
import { useDebouncedCallback } from 'use-debounce';
import { SEARCH_BUTTON_ID } from '../Navbar';

const ScrollBox = styled(Flex)`
  flex-direction: column;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

const SEARCH_RESULT_SIZE = 60;
const MAX_ELEMENTS = 6;

type SearchResultProps = Pick<BoardElement, 'color'> & {
  isActive: boolean;
  onClick: () => void;
  title?: string | React.ReactNode;
  isNew?: boolean;
};
const SearchResult = React.forwardRef<HTMLDivElement, SearchResultProps>(
  ({ color, title, isActive, onClick, isNew }, ref) => (
    <Grid
      data-list-item
      ref={ref}
      gridTemplateColumns="auto 1fr"
      columnGap={2}
      height={`${SEARCH_RESULT_SIZE}px`}
      role="button"
      cursor="pointer"
      bgColor={isActive ? 'yoBrand.500' : 'yoDark.500'}
      px={4}
      py={4}
      alignItems="center"
      onClick={onClick}
      _hover={{ bgColor: 'yoBrand.500' }}
    >
      <Box width="40px">
        {isNew ? (
          <Tag colorScheme="green">+</Tag>
        ) : (
          <Box bg={getCardColorByName(color).value} width="24px" height="13px" borderRadius="2px" />
        )}
      </Box>

      <Text
        fontSize="16px"
        maxH="20px"
        fontWeight={500}
        lineHeight={1}
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        overflow="hidden"
      >
        {title}
      </Text>
    </Grid>
  )
);

type BasePopoverProps = {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (element: BoardElement) => void;
  leftAddon?: React.ReactElement;
  searchIcon?: React.ReactElement;
  omitResultsWithId?: string[];
} & (
  | { onAdd: (title: string) => void; newElementText: string }
  | { onAdd?: never; newElementText?: never }
);

const BasePopover = ({
  isOpen,
  onClose: _onClose,
  onSelect,
  leftAddon,
  searchIcon,
  omitResultsWithId,
  onAdd,
  newElementText,
}: BasePopoverProps) => {
  const [searchValue, setSearchValue] = useState('');
  const [activeIndex, setActiveIndex] = useState(-1);

  /**
   * the internalIndex keeps track of the index in current
   * visible list. It is used to calculate whether we need
   * to scroll down or up when navigation through the results via
   * the keyboard
   * */
  const [internalIndex, setInternalIndex] = useState(-1);

  const resultListRef = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);

  const { searchResults, searchInputRef, onSearch, searchStatus } = useSearchCards({
    searchArchive: false,
    omitResultsWithId,
  });

  const onClose = useCallback(() => {
    setSearchValue('');
    _onClose();
  }, [_onClose]);

  const searchResultCount = (searchResults?.length ?? 0) + (onAdd ? 1 : 0);

  const handleSelect = useCallback(
    (index: number) => {
      if (index === -1 && onAdd) {
        onAdd(searchValue);
        onClose();
      } else {
        if (searchResults == null) return;
        const currentElement = searchResults[index];
        onClose();
        setActiveIndex(index);
        onSelect(currentElement);
      }
    },
    [onAdd, onClose, onSelect, searchResults, searchValue]
  );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (isOpen) {
        if (event.key === Key.Escape) {
          event.preventDefault();
          onClose();
          return;
        }

        if (event.key === Key.ArrowDown) {
          event.preventDefault();

          if (activeIndex < searchResultCount - 1) {
            popoverRef.current!.focus();
            setActiveIndex((cur) => cur + 1);

            if (internalIndex < MAX_ELEMENTS - 1) {
              setInternalIndex((cur) => cur + 1);
            }

            /**
             * If the internalIndex is currently at the last element,
             * we need to move the scroll bar on element height lower
             */
            if (internalIndex === MAX_ELEMENTS - 1) {
              resultListRef.current!.scrollTop += SEARCH_RESULT_SIZE;
            }
          }
        }

        if (event.key === Key.ArrowUp) {
          event.preventDefault();

          if (activeIndex > 0) {
            popoverRef.current!.focus();
            setActiveIndex((cur) => cur - 1);

            if (internalIndex > 0) {
              setInternalIndex((cur) => cur - 1);
            }

            /**
             * If the internalIndex is currently at the first element,
             * we need to move the scroll bar on element height higher
             */
            if (internalIndex === 0) {
              resultListRef.current!.scrollTop -= SEARCH_RESULT_SIZE;
            }
          }
        }

        if (event.key === Key.Enter && activeIndex >= 0) {
          event.preventDefault();
          event.stopPropagation();
          handleSelect(activeIndex);
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, activeIndex, handleSelect, internalIndex, searchResults, searchResultCount, onClose]);

  useOutsideClick({
    handler: (e) => {
      const target = e.target as HTMLElement;

      if (target.dataset.listItem === 'true' || target.parentElement?.dataset.listItem === 'true') {
        return;
      }
      if (target.id === SEARCH_BUTTON_ID || target.parentElement?.id === SEARCH_BUTTON_ID) {
        return;
      }
      onClose();
    },
    enabled: isOpen,
    ref: popoverRef,
  });

  const debouncedScrollHandler = useDebouncedCallback(() => {
    // if we scroll with the mouse the internal index should be reset
    if (resultListRef.current) {
      setActiveIndex(Math.round(resultListRef.current.scrollTop / SEARCH_RESULT_SIZE));
      setInternalIndex(0);
    }
  }, 250);

  useEffect(() => {
    if (isOpen === true) {
      const input = searchInputRef.current;
      if (input) {
        input.focus();
      }
    }
    /**
     * if the search is closed after being opened
     * it should reset the scroll and also
     * the selected and internal indices
     * */
    if (isOpen === false) {
      if (resultListRef.current) {
        resultListRef.current.scrollTop = 0;
      }

      setActiveIndex(-1);
      setInternalIndex(-1);
    }
  }, [activeIndex, isOpen, searchInputRef]);

  const InputStyles = useMemo(
    () =>
      ({
        outline: 'none',
        border: 'none',
        borderBottomRadius:
          searchStatus === SearchStatus.IDLE || searchResultCount === 0 ? '6px' : 0,
        borderBottom: '1px solid #424b5a',
      } as InputProps),
    [searchResultCount, searchStatus]
  );

  if (!isOpen) return null;
  return (
    <Flex
      ref={popoverRef}
      minW={{ base: '80vw', lg: '600px' }}
      maxHeight={`calc(${SEARCH_RESULT_SIZE * MAX_ELEMENTS}px + 60px)`}
      position="absolute"
      top="20vh"
      left="50vw"
      transform="translateX(-50%)"
      zIndex={9999}
      bg="yoDark.500"
      color="white"
      borderRadius="6px"
      boxShadow="2px 4px 50px 0px #00000099"
      direction="column"
    >
      <InputGroup height="60px">
        {leftAddon && (
          <InputLeftAddon
            height="60px"
            bg="yoDark.500"
            color="white"
            pr={-1}
            pl={0}
            {...InputStyles}
          >
            {leftAddon}
          </InputLeftAddon>
        )}
        <Input
          py={4}
          ml={leftAddon ? -1 : 'auto'}
          ref={searchInputRef}
          height="60px"
          placeholder="Search for Elements...."
          onChange={(e) => {
            // reset index to -1 when changing search input
            setActiveIndex(-1);
            onSearch(e);
            setSearchValue(e.target.value);
          }}
          fontSize={18}
          {...InputStyles}
          _placeholder={{ color: 'placeholder' }}
          _hover={InputStyles}
          _focus={InputStyles}
        />
        <InputRightElement height="60px" pr={4}>
          <Icon as={(searchIcon as any) ?? SearchIcon} fontSize="24px" />
        </InputRightElement>
      </InputGroup>
      {searchStatus !== SearchStatus.IDLE && (
        <ScrollBox ref={resultListRef} onWheel={debouncedScrollHandler}>
          {onAdd ? (
            <SearchResult
              onClick={() => {
                // indicate that the new element is selected
                // we use -1 as index to indicate that
                // the new element is selected and not a search result
                handleSelect(-1);
              }}
              isActive={activeIndex === 0}
              title={
                <Text as="span">
                  {newElementText} with Title <b>{searchValue || '...'}</b>
                </Text>
              }
              isNew
            />
          ) : null}
          {(() => {
            switch (searchStatus) {
              case SearchStatus.SEARCHING: {
                return (
                  <Flex minH="100px" w="100%" h="100%" justifyContent="center" alignItems="center">
                    <Spinner />
                  </Flex>
                );
              }
              case SearchStatus.SUCCESS: {
                return searchResults?.map((result: any, i: number) => (
                  <SearchResult
                    key={result.id}
                    onClick={() => {
                      handleSelect(i);
                    }}
                    isActive={i === activeIndex}
                    {...result}
                  />
                ));
              }
              default: {
                return null;
              }
            }
          })()}
        </ScrollBox>
      )}
    </Flex>
  );
};

export default BasePopover;
