/* eslint-disable class-methods-use-this */
import { UIBoardColumn } from 'types/index';
import { Rect } from 'konva/types/shapes/Rect';
import nonUpdatingStore from 'store/index';
import { getNextSnappingPosition } from 'lib/helpers';
import { DragContext, DragHandler, DragModel, DragMoveArgs } from './DragModel';
import { isFloatingBoardElement } from './DragModelHelper';
import { DragCardState } from './DragCardState';
import { createDragCardState } from './DragCardStateCreator';
import { getBoardElementGroup, getKonvaElement } from '../konva/KonvaHelper';
import { SortedColumnRenderer } from '../sortedColumn/SortedColumnRenderer';
import { SortedColumnGhost } from '../sortedColumn/SortedColumnGhost';
import { getExtendedColumnWithHighestY } from '../sortedColumn/SortedColumnHelper';
import { SNAP_TO_GRID_VALUE } from './DragConfig';

export class DragCardHandler implements DragHandler {
  private wasRecentlyInterrupted = false;

  private dragState: DragModel | undefined;

  private dragCardState: DragCardState | undefined;

  private enteredColumn: UIBoardColumn | undefined;

  constructor(private readonly context: DragContext) {}

  start(dragState: DragModel): void {
    this.dragState = dragState;
    this.dragCardState = createDragCardState(this.dragState);

    this.enteredColumn = undefined;
  }

  move({ position }: DragMoveArgs) {
    // prevents an error if the user presses esc while stile moving the floating card
    if (this.wasRecentlyInterrupted) return;

    this.wasRecentlyInterrupted = false;

    if (!this.dragState) {
      throw new Error(`${DragCardHandler.name}.move() no dragState`);
    }
    if (!this.dragCardState) {
      throw new Error(`${DragCardHandler.name}.move() no dragCardState`);
    }

    // patch new position into our local state (so that we can order all elements correctly)
    this.dragState.element.shape = { ...this.dragState.element.shape, ...position };

    // dragging a card with autoSort
    const oldColumn = this.dragCardState.curColumn;

    // the target column is the column that the card was moved to (regardless of the autoSort setting)
    const targetColumn = getExtendedColumnWithHighestY(this.dragCardState.allColumns, {
      ...this.dragState.element.shape,
      ...position,
    });

    // this is the column that the card will be sorted into (if autoSort is enabled)
    const newSortedColumn = getExtendedColumnWithHighestY(this.dragCardState.allSortedColumns, {
      ...this.dragState.element.shape,
      ...position,
    });

    // coming from an old column
    if (oldColumn) {
      if (newSortedColumn) {
        // switch to new column
        if (newSortedColumn.id !== oldColumn.id) {
          this.orderAndSizeColumn(oldColumn, this.dragState, this.dragCardState, false);
          this.orderAndSizeColumn(newSortedColumn, this.dragState, this.dragCardState, true);
        }
        // remain in the old column
        else {
          this.orderAndSizeColumn(oldColumn, this.dragState, this.dragCardState, true);
        }
      }
      // leave old column into outside of any column
      else {
        this.orderAndSizeColumn(oldColumn, this.dragState, this.dragCardState, false);
        this.addOutsideOfColumnPatch(this.dragState, this.dragCardState);
      }
    }
    // enter new column
    else if (newSortedColumn) {
      this.orderAndSizeColumn(newSortedColumn, this.dragState, this.dragCardState, true);
    }
    // remain outside of any column
    else {
      this.addOutsideOfColumnPatch(this.dragState, this.dragCardState);
    }

    // we want to keep track of the target column aka the column the card was moved into
    // regardless of the autoSort setting
    this.enteredColumn = targetColumn;

    // update curColumn by either newColumn, oldColumn or undefined
    this.dragCardState.curColumn = newSortedColumn || oldColumn;
  }

  private addOutsideOfColumnPatch(dragState: DragModel, dragCardState: DragCardState): void {
    dragCardState.allElementPatches.set(dragState.element.id, {
      id: dragState.element.id,
      shape: { ...dragState.element.shape },
    });
    SortedColumnGhost.hide();
  }

  private orderAndSizeColumn(
    col: UIBoardColumn,
    dragModel: DragModel,
    dragCardState: DragCardState,
    isCurrentSortedColumn: boolean
  ) {
    const patches = SortedColumnRenderer.renderWithGhost({
      col,
      element: dragModel.element,
      allBoardElements: dragModel.allBoardElements,
      isCurrentSortedColumn,
    });
    patches.forEach((p) => dragCardState.allElementPatches.set(p.id, p));
  }

  stop() {
    if (!this.dragState) {
      throw new Error(`${DragCardHandler.name}.stop() no dragState`);
    }
    if (!this.dragCardState) {
      throw new Error(`${DragCardHandler.name}.stop() no dragCardState`);
    }

    // manually render the real element on the position of the ghost
    const dragElementPatch = this.dragCardState.allElementPatches.get(this.dragState.element.id);
    if (dragElementPatch?.shape) {
      const konvaElement = getKonvaElement<Rect>(dragElementPatch.id);

      getBoardElementGroup(konvaElement)?.position(dragElementPatch.shape).draw();

      // manually update the state of the floating drag element
      // since it won't be done by the store (because it's not yet part of the all board element state)
      if (isFloatingBoardElement(dragElementPatch.id, this.dragState)) {
        this.dragState.element.parentColumnId = this.enteredColumn?.id;
        this.dragState.element.shape = {
          ...this.dragState.element.shape,
          ...dragElementPatch.shape,
        };
      }
    }

    // if the column of the card has changed, update the column
    if (this.enteredColumn?.id !== this.dragState.element.parentColumnId) {
      this.dragCardState.allElementPatches.set(this.dragState.element.id, {
        shape: dragElementPatch?.shape ?? this.dragState.element.shape,
        id: this.dragState.element.id,
        parentColumnId: this.enteredColumn?.id,
      });
    }

    if (!this.enteredColumn?.isSorted && this.dragState.snapToGrid) {
      const snapPosition = getNextSnappingPosition({
        position: dragElementPatch?.shape ?? this.dragState.element.shape,
        snappingValue: SNAP_TO_GRID_VALUE,
      });
      this.dragState.element.shape = {
        ...this.dragState.element.shape,
        ...snapPosition,
      };
      this.dragCardState.allElementPatches.set(this.dragState.element.id, {
        ...this.dragCardState.allElementPatches.get(this.dragState.element.id),
        shape: {
          ...(dragElementPatch?.shape ?? this.dragState.element.shape),
          ...snapPosition,
        },
        id: this.dragState.element.id,
      });
    }

    // updateAllChangedElements
    this.context.updateElement(Array.from(this.dragCardState.allElementPatches.values()));

    SortedColumnGhost.hide();
    this.dragCardState = undefined;
    this.enteredColumn = undefined;
  }

  // this is used if the card is in floating mode and the user interrupts the creation of
  // the card by hitting esc
  interrupt() {
    if (!this.dragState || !this.dragCardState) {
      return;
    }

    const { curColumn } = this.dragCardState;
    if (curColumn) {
      SortedColumnRenderer.renderWithGhost({
        col: curColumn,
        element: this.dragState.element,
        // does not include the current card
        allBoardElements: nonUpdatingStore.getState().board.boardElements,
      });
    }
    this.dragCardState = undefined;
    this.enteredColumn = undefined;
    this.wasRecentlyInterrupted = true;
  }
}
