import React, { useRef } from 'react';
import { Box, Button } from '@chakra-ui/react';
import ColorMatrix from './ColorMatrix';

interface MenuItemProps {
  id: string;
  refId: string;
  icon?: React.FC;
  onClick?: () => void;
  expandable?: boolean;
  hoverColor?: string;
  open: string | null;
  disabled?: boolean;
  setOpen: (payload: string) => void;
  swimlaneColors?: boolean;
  title?: string;
}

const MenuItem: React.FC<MenuItemProps> = ({
  id,
  icon,
  onClick,
  expandable = false,
  children,
  hoverColor,
  open,
  setOpen,
  refId,
  disabled = false,
  swimlaneColors = false,
  title,
}) => {
  const itemRef = useRef<HTMLButtonElement>(null);
  return (
    <>
      <Button
        variant="ghost"
        role="button"
        isDisabled={disabled}
        bg={disabled ? 'gray.900' : 'transparent'}
        ref={itemRef}
        color={disabled ? 'gray.500' : 'white'}
        _hover={disabled ? {} : { bg: hoverColor || 'teal.500' }}
        px={2}
        py={2}
        borderRadius={4}
        cursor="pointer"
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        fontSize={18}
        fontWeight={500}
        onClick={onClick}
        onMouseEnter={() => setOpen(id)}
        title={title}
      >
        {icon && <Box as={icon} h={5} w={5} mr={1} />}
        {children}
      </Button>
      {expandable && open === id && (
        <ColorMatrix
          swimlaneColors={swimlaneColors}
          pos={itemRef.current?.getBoundingClientRect()}
          refId={refId}
        />
      )}
    </>
  );
};

export default MenuItem;
