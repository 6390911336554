import { Stage } from 'konva/types/Stage';
import { getStage } from 'lib/zoom';
import { useEffect, useState } from 'react';

function useStage() {
  const [stage, setStage] = useState<Stage | null>(null);
  // stage is null before mount or at ssr
  useEffect(() => {
    setStage(getStage() ?? null);
  }, []);
  return stage;
}

export default useStage;
