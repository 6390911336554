/* eslint-disable indent */
/* eslint-disable no-confusing-arrow */
import { Transformer as KonvaTransformer } from 'react-konva';
import { Box, Transformer as ITransformer } from 'konva/types/shapes/Transformer';
import { RefObject } from 'react';
import { BoardElementState } from 'types/index';
import theme from 'lib/theme';
import { SNAP_TO_GRID_VALUE } from 'hooks/Elements';
import store from 'store/index';

export function getCorrectBoundBox({
  oldBoundBox,
  newBoundBox,
  minHeight,
  minWidth,
}: {
  oldBoundBox: Box;
  newBoundBox: Box;
  minHeight: number;
  minWidth: number;
}): Box {
  const boxToReturn = { ...newBoundBox };
  // For some reason the x & y values change sometimes while resizing
  // this doesn't make any sense, therefore we need to prevent it!
  if (boxToReturn.x !== oldBoundBox.x) {
    if (boxToReturn.x < oldBoundBox.x) {
      // If x is also smaller than old x, set width to minWidth
      boxToReturn.width = minWidth;
    }
    // Set x to old x
    boxToReturn.x = oldBoundBox.x;
  }
  if (boxToReturn.y !== oldBoundBox.y) {
    if (boxToReturn.y < oldBoundBox.y) {
      // If y is also smaller than old y, set width to minHeight
      boxToReturn.height = minHeight;
    }
    // Set y to old y
    boxToReturn.y = oldBoundBox.y;
  }
  return boxToReturn;
}

interface TransformerProps {
  transformerRef: RefObject<ITransformer>;
  minValue?: number;
  maxValue?: number;
  minWidth?: number;
  maxWidth?: number;
  minHeight?: number;
  maxHeight?: number;
  resizable?: boolean;
}

const transformerAnchors = ['bottom-right'];
const Transformer: React.FC<TransformerProps> = ({
  transformerRef,
  minValue,
  maxValue,
  minHeight,
  maxHeight,
  minWidth,
  maxWidth,
  resizable = false,
}) => {
  const boxBoundFunc = (oldBoundBox: Box, newBoundBox: Box) => {
    const { snapToGrid, zoomLevel } = store.getState().boardSettings;
    const localMinHeight = (minHeight ?? minValue)! * zoomLevel;
    const localMinWidth = (minWidth ?? minValue)! * zoomLevel;
    const localMaxHeight = (maxHeight ?? maxValue)! * zoomLevel;
    const localMaxWidth = (maxWidth ?? maxValue)! * zoomLevel;
    const box = {
      ...newBoundBox,
      // This function creates a fix for a konva bug when resizing
      ...getCorrectBoundBox({
        oldBoundBox,
        newBoundBox,
        minHeight: localMinHeight,
        minWidth: localMinWidth,
      }),
    };
    // If newBox would be smaller than minWidth -> set to minWidth
    if (box.width < localMinWidth) {
      box.width = localMinWidth;
    }
    // If newBox would be smaller than minHeight -> set to minHeight
    if (box.height < localMinHeight) {
      box.height = localMinHeight;
    }
    // If newBox would be higher than maxWidth -> set to maxWidth
    if (box.width > localMaxWidth) {
      box.width = localMaxWidth;
    }
    // If newBox would be higher than maxHeight -> set to maxHeight
    if (box.height > localMaxHeight) {
      box.height = localMaxHeight;
    }
    // limit the shape to multiples of SNAP_TO_GRID_VALUE
    if (snapToGrid) {
      box.width =
        Math.round(box.width / (SNAP_TO_GRID_VALUE * zoomLevel)) * (SNAP_TO_GRID_VALUE * zoomLevel);
      if (box.width < localMinWidth) {
        box.width += SNAP_TO_GRID_VALUE * zoomLevel;
      }
      if (box.width > localMaxWidth) {
        box.width -= SNAP_TO_GRID_VALUE * zoomLevel;
      }
      box.height =
        Math.round(box.height / (SNAP_TO_GRID_VALUE * zoomLevel)) *
        (SNAP_TO_GRID_VALUE * zoomLevel);
      if (box.height < localMinHeight) {
        box.height += SNAP_TO_GRID_VALUE * zoomLevel;
      }
      if (box.height > localMaxHeight) {
        box.height -= SNAP_TO_GRID_VALUE * zoomLevel;
      }
    }
    return box;
  };
  return (
    <KonvaTransformer
      ref={transformerRef}
      padding={0}
      rotateEnabled={false}
      keepRatio={false}
      borderStrokeWidth={2}
      borderStroke={theme.colors.yoBrand[300]}
      enabledAnchors={resizable ? transformerAnchors : []}
      boundBoxFunc={boxBoundFunc}
    />
  );
};

interface StatefulTransformerProps extends TransformerProps {
  state: BoardElementState;
}

const StatefulTransformer: React.FC<StatefulTransformerProps> = ({ state, ...props }) =>
  state !== BoardElementState.DEFAULT ? (
    <Transformer {...props} resizable={state === BoardElementState.RESIZABLE} />
  ) : null;
export default StatefulTransformer;
