/* eslint-disable no-param-reassign */
import { Action, action } from 'easy-peasy';
import { User } from 'shared';

export interface ActiveUserStoreModel {
  users: User[];
  setUsers: Action<ActiveUserStoreModel, User[]>;
  addUser: Action<ActiveUserStoreModel, User>;
  removeUser: Action<ActiveUserStoreModel, string>;
}

const activeUserStoreModel: ActiveUserStoreModel = {
  users: [],
  setUsers: action((state, users) => {
    state.users = users;
  }),
  addUser: action((state, user) => {
    if (!state.users.some((currentUser) => currentUser._id === user._id)) {
      state.users.push(user);
    }
  }),
  removeUser: action((state, userId) => {
    const userIndex = state.users.findIndex((currentUser) => currentUser._id === userId);
    if (userIndex > -1) {
      state.users.splice(userIndex, 1);
    }
  }),
};

export default activeUserStoreModel;
