/* eslint-disable import/prefer-default-export */
import { useEffect, useRef } from 'react';
import { useStoreActions } from 'store/hooks';
import { animateZoomTo, getStage } from 'lib/zoom';
import { useParam } from 'hooks/useParam';
import { ValidationRules, BoardElement } from 'shared';
import { useRouter } from 'next/router';
import { resetUrlAfterBoardElementStateChange } from 'lib/urlUtils';
import { useToast } from '@chakra-ui/react';
import fetchClient from 'lib/Fetch';
import { Position } from 'store/app';
import { ThunkCreator, ActionCreator } from 'easy-peasy';
import { BoardElementState, UIBoardElement } from '../types';

export function zoomToElement({
  element,
  setOffset,
  setZoomLevel,
}: {
  element: Pick<BoardElement, 'shape'>;
  setOffset: ThunkCreator<Position, any>;
  setZoomLevel: ActionCreator<number>;
}) {
  const zoomPosition = {
    x:
      -element.shape.x +
      (window.innerWidth / 2 - ValidationRules.LayoutRules.Card.CARD_MIN_WIDTH / 2),
    y:
      -element.shape.y +
      (window.innerHeight / 2 - ValidationRules.LayoutRules.Card.CARD_MIN_HEIGHT / 2),
  };

  animateZoomTo({
    setOffset,
    setZoomLevel,
    stage: getStage()!,
    position: zoomPosition,
    zoomLevel: 1,
  });
}

export const zoomOrRedirectToItem = async ({
  boardId,
  elementId,
  isInDetailMode,
  redirectFunc,
  setElementState,
  setOffset,
  setZoomLevel,
  toast,
}: {
  boardId: string;
  elementId: string;
  isInDetailMode?: string;
  redirectFunc: (newUrl: string) => void;
  setElementState: ThunkCreator<Pick<UIBoardElement, 'id' | 'state'>, any>;
  setOffset: ThunkCreator<Position, any>;
  setZoomLevel: ActionCreator<number>;
  toast: (args: any) => void;
}) => {
  const element = await fetchClient<BoardElement>(`boards/${boardId}/cards/${elementId}`);

  if (!element) {
    resetUrlAfterBoardElementStateChange(boardId);
    toast({
      position: 'bottom',
      status: 'error',
      title: 'The linked item does not exist anymore.',
    });
    return;
  }

  // when it is an archived element, open the archive and pass the elementId to be opened
  // if it also is no archived element, it means the item is deleted, then we have to clear the element id from the url and show a notification toast
  if (element.isArchived) {
    redirectFunc(`/board/${boardId}/archive?element=${elementId}`);
  } else {
    zoomToElement({ element, setOffset, setZoomLevel });

    if (isInDetailMode) {
      setElementState({ id: elementId, state: BoardElementState.INDETAIL });
    } else {
      setElementState({ id: elementId, state: BoardElementState.FOCUSED });
    }
  }
};

export const useCardDeepLink = () => {
  const { setOffset, setZoomLevel } = useStoreActions((state) => state.boardSettings);
  const { setElementState } = useStoreActions((actions) => actions.board);
  const isRedirectTriggered = useRef(false);
  const router = useRouter();
  const toast = useToast();

  const boardId = useParam('boardId');
  const elementId = useParam('element');
  const isInDetailMode = useParam('detail');

  useEffect(() => {
    // Execute hook only once, because we don't want it to react to changes in the URL
    // while the application is being used. This case is only to directly zoom to / open
    // elements when opened via specific URL
    if (!isRedirectTriggered.current && boardId && elementId) {
      // eslint-disable-next-line no-param-reassign
      isRedirectTriggered.current = true;
      zoomOrRedirectToItem({
        boardId,
        elementId,
        isInDetailMode,
        setElementState,
        setOffset,
        setZoomLevel,
        toast,
        redirectFunc: (newUrl) => router.push(newUrl),
      });
    }
  }, [boardId, elementId, isInDetailMode, router, setElementState, setOffset, setZoomLevel, toast]);
};
