import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Stack,
} from '@chakra-ui/react';

import { CardEventDTO, Comment, BoardElement, BoardElementLink, CardIntegrationType } from 'shared';
import React, { memo, useEffect, useRef } from 'react';
import { useStoreActions } from 'store/hooks';
import dynamic from 'next/dynamic';
import theme from 'lib/theme';

import { useAttachments } from 'hooks/useAttachments';
import CardHistory from '../CardHistory';
import TitleEdit from './TitleEdit';
import { LoomVideo, CardImage } from './MediaElement';
import { BoardElementLinkSection } from './Links';
import CommentBox from '../CommentBox';
import { AttachmentSection } from './Attachments';
import { CheckProgress } from './CheckProgress';
import { getTaskLinks, getVisualLinks } from '../../../lib/Links.helper';
import {
  getChangedLinks,
  getCheckboxInfoFromHTML,
  populateDataCheckedAttribute,
  removeDataCheckedFromContent,
} from '../../../lib/CheckLinks.helper';

const DetailViewEditor = dynamic(() => import('./Editor'), {
  ssr: false,
  loading: () => <Box height="100px" />,
});

type Props = {
  history: CardEventDTO[];
  comments: Comment[];
  refId: string;
  title: string;
  description: string;
  readOnly?: boolean;
  setIsTitleEditing: (isEditing: boolean) => void;
  setIsDescriptionEditing: (isEditing: boolean) => void;
  setIsAnyCommentEditing: (isEditing: boolean) => void;
  setIsNewCommentEditing: (isEditing: boolean) => void;
  links?: BoardElementLink<BoardElement>[];
  attachmentObject: ReturnType<typeof useAttachments>;
} & Pick<BoardElement, 'cardIntegrationType' | 'cardIntegrationMeta' | 'thumbnailUrl'>;

const LeftColumn: React.FC<Props> = ({
  description: initialValue,
  history,
  comments,
  refId,
  title,
  readOnly = false,
  setIsTitleEditing,
  setIsDescriptionEditing,
  setIsAnyCommentEditing,
  setIsNewCommentEditing,
  cardIntegrationType,
  cardIntegrationMeta,
  links = [],
  thumbnailUrl,
  attachmentObject,
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>();
  const updateElement = useStoreActions((actions) => actions.board.updateElement);

  const adjustDescriptionHeight = () => {
    if (textAreaRef.current) {
      const textarea = textAreaRef.current;
      textarea.style.overflow = 'hidden';
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const handleSave = (newValue: string) => {
    const updatedLinks = getChangedLinks({
      currentLinks: links,
      ...getCheckboxInfoFromHTML(links, newValue),
    });

    updateElement({
      id: refId,
      content: removeDataCheckedFromContent(newValue),
      ...(updatedLinks !== null && { links: updatedLinks }),
    });
  };

  useEffect(() => {
    adjustDescriptionHeight();
  }, [textAreaRef.current]);

  const taskLinks = getTaskLinks(links);

  return (
    <Flex
      w={{ base: '100%', md: '70%' }}
      p={{ base: 8, md: 10 }}
      direction="column"
      borderLeftRadius="modal"
      data-testid="left-column"
    >
      <TitleEdit
        title={title}
        onSave={(newTitle) => updateElement({ id: refId, title: newTitle })}
        isDisabled={readOnly}
        setIsEditing={setIsTitleEditing}
      />

      {thumbnailUrl && <CardImage src={thumbnailUrl} />}

      {/* The thumbnail should always be displayed instead of the loom video */}
      {cardIntegrationType === CardIntegrationType.LOOM && !thumbnailUrl && (
        <LoomVideo videoUrl={cardIntegrationMeta!.embedUrl} />
      )}
      <FormControl borderRadius="sm" w="100%">
        <FormLabel htmlFor="description" fontWeight="700" mb={4}>
          Description
        </FormLabel>
        <DetailViewEditor
          cardId={refId}
          links={links}
          initialValue={populateDataCheckedAttribute(taskLinks, initialValue)}
          onSave={handleSave}
          setIsEditing={setIsDescriptionEditing}
          isReadOnly={readOnly}
        />
        <CheckProgress checkedLinks={taskLinks} />
      </FormControl>
      <Stack>
        <AttachmentSection
          cardId={refId}
          isArchived={readOnly}
          attachmentObject={attachmentObject}
        />
        <BoardElementLinkSection
          cardId={refId}
          links={getVisualLinks(links)}
          isDisabled={readOnly}
        />
      </Stack>

      <Box>
        <Tabs variant="unstyled">
          <TabList>
            <Tab
              data-focus-visible-added
              _focus={{
                boxShadow: `inset 0 -4px ${theme.colors.yoBrand[500]}  !important`,
              }}
              _selected={{
                boxShadow: `inset 0 -4px ${theme.colors.yoBrand[500]}  !important`,
              }}
              fontWeight="700"
              pb={5}
            >
              Comments
            </Tab>
            <Tab
              data-focus-visible-added
              _focus={{
                boxShadow: `inset 0 -4px ${theme.colors.yoBrand[500]} !important`,
              }}
              _selected={{
                boxShadow: `inset 0 -4px ${theme.colors.yoBrand[500]}  !important`,
              }}
              fontWeight="700"
              pb={5}
            >
              History
            </Tab>
          </TabList>

          <TabPanels padding="0">
            <TabPanel paddingLeft="0">
              <CommentBox
                comments={comments}
                cardId={refId}
                setIsAnyCommentEditing={setIsAnyCommentEditing}
                setIsNewCommentEditing={setIsNewCommentEditing}
                isDisabled={readOnly}
              />
            </TabPanel>

            <TabPanel paddingLeft="0">
              <CardHistory elements={history} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Flex>
  );
};

export default memo(LeftColumn);
