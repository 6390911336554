/* eslint-disable no-param-reassign */
import { AddElementsModel, addElementsStore } from './add';
import { UpdateElementsModel, updateElementsStore } from './update';
import { DeleteElementsModel, deleteElementsStore } from './delete';

export interface ElementsCRUDModel
  extends AddElementsModel,
    UpdateElementsModel,
    DeleteElementsModel {}

const crudModel: ElementsCRUDModel = {
  ...deleteElementsStore,
  ...addElementsStore,
  ...updateElementsStore,
};

export default crudModel;
