import { oembed } from '@loomhq/loom-embed';
import { isSupported, LoomVideo, setup } from '@loomhq/loom-sdk';
import { CardIntegrationType } from 'shared';
import { useStoreActions, useStoreState } from 'store/hooks';
import { useState, useRef, useEffect } from 'react';
import logger from 'lib/logger';

enum SDKState {
  Closed = 'closed',
  PreRecording = 'pre-recording',
  ActiveRecording = 'active-recording',
  PostRecording = 'post-recording',
}

export function useLoom(callback: (loom: LoomVideo) => void, insertButtonText?: string) {
  const isRecordingLoom = useStoreState((state) => state.app.isRecordingLoom);
  const isLoomSupported = useStoreState((state) => state.app.isLoomSupported);
  const { setIsRecordingLoom, setIsLoomSupported } = useStoreActions((actions) => actions.app);
  const ref = useRef<HTMLElement>(null);

  useEffect(() => {
    async function setupLoom() {
      const { supported, error } = await isSupported();

      if (!supported) {
        logger.warn(`Error setting up Loom: ${error}`);
        return;
      }
      setIsLoomSupported(true);

      const button = ref.current as HTMLButtonElement;

      // button should not work if we are already recording a loom video
      if (!button || isRecordingLoom) {
        return;
      }

      const { configureButton } = await setup({
        // fallback to sandbox key
        apiKey: process.env.LOOM_API_KEY ?? '70282542-466f-44a9-92cb-79541f152b41',
        config: { insertButtonText },
      });

      const sdkButton = configureButton({
        element: button,
      });

      sdkButton.on('insert-click', async (video) => {
        setIsRecordingLoom(false);
        callback(video);
      });

      // keep track whether a loom recording is already in progess
      sdkButton.on('lifecycle-update', (state) => {
        setIsRecordingLoom(state === SDKState.ActiveRecording || state === SDKState.PreRecording);
      });
    }

    setupLoom();
  }, [callback, insertButtonText, setIsRecordingLoom, isRecordingLoom, setIsLoomSupported]);

  return [isLoomSupported, ref] as const;
}

export function useLoomThumbnail(
  cardIntegration: CardIntegrationType | undefined,
  embedUrl?: string
) {
  const [thumbnailUrl, setThumbnailUrl] = useState<string | null>(null);
  const hasLoadedImageRef = useRef(false);

  useEffect(() => {
    if (!cardIntegration || !embedUrl) return () => {};
    async function loadLoomData() {
      try {
        // eslint-disable-next-line camelcase
        const { thumbnail_url: retrievedThumbnailURL } = await oembed(embedUrl as string);
        if (retrievedThumbnailURL) {
          setThumbnailUrl(retrievedThumbnailURL);
          // store the thumbnail url once to prevent multiple lookups (floating element -> board element)
          localStorage.setItem(`loom-thumbnail-${embedUrl}`, retrievedThumbnailURL);
        }

        hasLoadedImageRef.current = true;
      } catch (e) {
        logger.warn(e);
      }
    }
    const storedThumbnailUrl = localStorage.getItem(`loom-thumbnail-${embedUrl}`);

    if (storedThumbnailUrl) {
      setThumbnailUrl(localStorage.getItem(`loom-thumbnail-${embedUrl}`));
    } else if (embedUrl && cardIntegration === CardIntegrationType.LOOM) {
      loadLoomData();
    }

    return () => {
      hasLoadedImageRef.current = false;
    };
  }, [cardIntegration, embedUrl]);
  return thumbnailUrl;
}
