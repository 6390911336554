import React, { useRef, useState, useEffect } from 'react';
import { Label as ILabel } from 'konva/types/shapes/Label';
import theme from 'lib/theme';
import { BoardElementState } from 'types/index';
import { Group, Text } from 'react-konva';
import WIPChip from './WIPChip';
import { SHARED_STYLES } from './index';
import EditButton from './EditButton';
import LockButton from './LockButton';

const COLUMN_FONT_FAMILY = theme.fonts.boardElements;

interface HeaderProps {
  title: string;
  emoji: string;
  x: number;
  y: number;
  wipLimit: number;
  elementsAttachedCount: number;
  wipLimitExceeded: boolean;
  state: BoardElementState;
  width: number;
  titleColor: string;
  chipBackground: string;
  chipSmall?: boolean;
  isLocked: boolean;
  isSorted?: boolean;
  isColumn?: boolean;
}

const Header: React.FC<HeaderProps> = ({
  title,
  emoji,
  x,
  y,
  wipLimit,
  elementsAttachedCount,
  state,
  width,
  titleColor,
  chipBackground,
  chipSmall,
  isLocked,
  isColumn,
}) => {
  const wipChipLabelRef = useRef<ILabel>(null);

  const [wipChipWidth, setWipChipWidth] = useState<number | undefined>();
  useEffect(() => {
    const currentWipChipWidth = wipChipLabelRef.current!.getWidth();
    if (wipChipWidth !== currentWipChipWidth) {
      setWipChipWidth(currentWipChipWidth);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementsAttachedCount]);

  const displayTitle = emoji ? `${emoji}  ${title}` : title;

  return (
    <Group x={x} y={y}>
      <WIPChip
        ref={wipChipLabelRef}
        wipLimit={wipLimit}
        cardsAttached={elementsAttachedCount}
        chipFontColor={titleColor}
        chipBackground={chipBackground}
        small={chipSmall}
      />
      {wipChipWidth && (
        <Text
          offsetY={-2}
          text={displayTitle}
          x={wipChipWidth}
          fill={titleColor}
          opacity={state === BoardElementState.INEDIT ? 0 : undefined}
          fontSize={SHARED_STYLES.text.fontSize}
          fontFamily={COLUMN_FONT_FAMILY}
          width={width - (isColumn ? 90 : 60) - wipChipWidth}
          ellipsis
          fontStyle="600"
          wrap="none"
          padding={chipSmall ? 10 : 12}
          lineHeight={chipSmall ? 0 : 0.3}
          verticalAlign="middle"
          listening={false}
        />
      )}

      <EditButton
        x={chipSmall ? width - 81 : width - 88}
        y={chipSmall ? -9 : -6}
        hexColor={titleColor}
      />
      <LockButton
        x={chipSmall ? width - 51 : width - 58}
        y={chipSmall ? -9 : -6}
        hexColor={titleColor}
        isLocked={isLocked}
      />
    </Group>
  );
};

export default Header;
