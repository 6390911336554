import { memo } from 'react';
import { Box, Flex, Text, Stack, Button } from '@chakra-ui/react';
import { useStoreActions } from 'store/hooks';
import { MouseClickIcon, MouseWheelIcon } from 'lib/icons';

const ControlHints = () => {
  const close = useStoreActions((actions) => actions.boardSettings.closeHints);
  return (
    <Flex
      position="fixed"
      bottom="36px"
      transform="translateX(-50%)"
      left="50%"
      pointerEvents="none"
      display={{ base: 'none', md: 'flex' }}
    >
      <Stack fontWeight="500" fontSize="sm" spacing={8} direction="row" alignItems="center">
        <Stack direction="row" alignItems="center" spacing={1}>
          <Text>Move View:</Text>
          <Box bg="yoBrandTransparent" color="white" borderRadius={3} py={1} px={4} h={30}>
            <Text>Space</Text>
          </Box>
          <Text>+</Text>
          <Box bg="yoBrandTransparent" borderRadius={3} p={1} h={30}>
            <MouseClickIcon fontSize="2xl" />
          </Box>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Text>Zoom in/out:</Text>
          <Box bg="yoBrandTransparent" color="white" borderRadius={3} py={1} px={4} h={30}>
            <Text>CTRL / ⌘</Text>
          </Box>
          <Text>+</Text>
          <Box bg="yoBrandTransparent" borderRadius={3} p={1} h={30}>
            <MouseWheelIcon fontSize="2xl" />
          </Box>
        </Stack>
        <Button
          pointerEvents="all"
          aria-label="Hide Hints"
          variant="link"
          colorScheme="yoBrand"
          fontSize="inherit"
          fontWeight="normal"
          textDecoration="underline"
          onClick={() => close()}
          cursor="pointer"
          bg="none"
          _focus={{ bg: 'none' }}
          _hover={{ bg: 'none' }}
          _active={{ bg: 'none' }}
        >
          Hide Hints
        </Button>
      </Stack>
    </Flex>
  );
};

export default memo(ControlHints);
