import { BoardElementPayload, UIBoardColumn, UIBoardElement } from 'types/index';
import { EventTypes } from 'shared';
import BoardEventFactory from 'store/board/boardEventFactory';
import nonUpdatingStore from 'store/index';
// import { getActiveBlockers } from 'lib/Elements.helper';
import { SortedColumnRenderer } from './SortedColumnRenderer';
import { getSortedColumnOfElement } from './SortedColumnHelper';

export abstract class SortedColumnUpdater {
  static doesElementUpdateNeedColumnResorting({
    oldValues,
    newValues,
  }: {
    oldValues: BoardElementPayload;
    newValues: BoardElementPayload;
  }) {
    return newValues?.isArchived === true && oldValues?.isArchived === false;
  }

  /**
   * Orders a sorted Column.
   *
   * Afterwards it will render it and update it's state in the Board Store.
   */
  static renderAndUpdateStateByElements({ elements }: { elements: UIBoardElement[] }) {
    const affectedColumns = elements
      .map((element) => getSortedColumnOfElement(element))
      .filter(Boolean) as UIBoardColumn[];

    const sortColumnEvents = affectedColumns
      .map((col) => this.renderAndUpdateState({ col }))
      .flat();

    return sortColumnEvents;
  }

  /**
   * Orders a sorted Column.
   *
   * Afterwards it will render it and update it's state in the Board Store.
   */
  static renderAndUpdateState({ col }: { col: UIBoardColumn }) {
    const oldElements = nonUpdatingStore.getState().board.boardElements.slice();

    const affectedElements = SortedColumnRenderer.render({
      col,
      allBoardElements: nonUpdatingStore.getState().board.boardElements,
    });

    // we don't want to post the update to other users
    // as well as update the history here because it should be batched
    // with the actual action that caused the resorting
    nonUpdatingStore.getActions().board.updateElementWithoutHistory({
      payload: affectedElements,
      fetch: false,
    });

    return affectedElements.map((affectedElement) =>
      BoardEventFactory.createEvent(
        EventTypes.UPDATE,
        affectedElement.id,
        oldElements.find((el) => el.id === affectedElement.id),
        affectedElement
      )
    );
  }
}
