import { Flex, Icon } from '@chakra-ui/react';
import { SiLoom } from 'react-icons/si';
import { BoardElementType, CardIntegrationType } from 'shared';
import { useLoom } from 'hooks/useLoom';
import nonUpdatingStore from 'store/index';
import { useCallback } from 'react';
import { LoomVideo } from '@loomhq/loom-sdk';

export function LoomButton() {
  const loomCallback = useCallback((video: LoomVideo) => {
    nonUpdatingStore.getActions().app.setFloatingBoardElement({
      type: BoardElementType.CARD,
      meta: {
        cardIntegrationType: CardIntegrationType.LOOM,
        cardIntegrationMeta: {
          id: video.id,
          thumbnailUrl: video.thumbnailUrl,
          videoUrl: video.sharedUrl,
          embedUrl: video.embedUrl,
        },
      },
    });
  }, []);

  const [, ref] = useLoom(loomCallback, 'Add Loom Card');
  return (
    <Flex ref={ref as any} alignItems="center">
      <Icon as={SiLoom} mr={2} fontSize="20px" />
      Loom video
    </Flex>
  );
}
