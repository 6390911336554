import { Avatar } from 'shared';

/**
 * Helper function to get a list of potential assignees
 * it will prepend the current user if hes not already in the list
 * @returns the list of potential assignees
 */
export function getPotentialAssignees({
  currentUserId,
  currentlyAssignedAvatars,
  query,
  showAssigneesWithoutQuery,
  availableAvatars,
}: {
  currentUserId?: string;
  currentlyAssignedAvatars: string[];
  query: string;
  showAssigneesWithoutQuery: boolean;
  availableAvatars: Avatar[];
}) {
  const isCurrentUserAssigned =
    currentUserId != null && currentlyAssignedAvatars?.includes(currentUserId);
  const currentUserAvatar = availableAvatars.find((avatar) => avatar.id === currentUserId);
  const avatarsWithoutCurrentUser = availableAvatars.filter(
    (avatar) => avatar.id !== currentUserId && !currentlyAssignedAvatars?.includes(avatar.id)
  );
  const showResultsWithoutQuery = showAssigneesWithoutQuery && !query;

  let results: Avatar[] = [];
  let showResults = false;

  // if we focused the input and don't have a query yet
  // simply display a list of 5 potential assignees
  if (showResultsWithoutQuery) {
    showResults = true;
    // if the user is not assigned yet, we only want to get the first 4 results as the current user is prepended
    results = avatarsWithoutCurrentUser.splice(0, isCurrentUserAssigned ? 5 : 4);
    // if there is a search value, filter the list of assignees
  } else if (query) {
    showResults = true;

    results = availableAvatars.filter(
      (avatar) =>
        avatar.name.toLowerCase().includes(query.trim().toLowerCase()) &&
        !currentlyAssignedAvatars?.includes(avatar.id)
    );
  }

  if (!showResults || !currentUserAvatar) {
    return [];
  }

  // if the currentUser is not assigned, we want to PREprend him to the list
  if (
    results.some((r) => r.id === currentUserId) ||
    (showResultsWithoutQuery && !isCurrentUserAssigned)
  ) {
    return [currentUserAvatar, ...results.filter((r) => r.id !== currentUserId)];
  }
  return results;
}
