import { useCallback } from 'react';
import { UIBoardElement } from 'types/index';
import { Rect } from 'react-konva';
import { SELECTED_AREA_ID } from 'components/canvas/BoardElements';
import { useStoreActions, useStoreState } from 'store/hooks';
import { useSpawnContextMenu } from 'hooks/Widgets';
import { KonvaEventObject } from 'konva/types/Node';
import { BoardElementType, Rectangle } from 'shared';
import { isCtrlOrMetaKey } from 'lib/helpers';
import { getDefaultElementHeight, getDefaultElementWidth } from 'lib/DefaultElementSizes';

export const getMultiselectRect = (selectedElements: UIBoardElement[]) => {
  const padding = 2;

  if (selectedElements.length > 1) {
    let right: number | undefined;
    let bottom: number | undefined;

    // This case should not happen -- at all.
    if (!selectedElements[0].type) {
      throw new Error('Invalid element type');
    }
    // populate the selection with the first element
    const selectionRect: Omit<Rectangle, 'angle'> = {
      x: selectedElements[0].shape?.x ?? 0,
      width: selectedElements[0].shape?.width ?? getDefaultElementWidth(selectedElements[0].type),
      y: selectedElements[0].shape?.y ?? 0,
      height:
        selectedElements[0].shape?.height ?? getDefaultElementHeight(selectedElements[0].type),
    };

    selectedElements.forEach((selectedBoardElement) => {
      if (!selectedBoardElement.type) {
        throw new Error('Invalid selection');
      }

      const height =
        (selectedBoardElement.shape?.y ?? 0) +
        (selectedBoardElement.type === BoardElementType.CARD && selectedBoardElement.shape.height
          ? selectedBoardElement.shape.height
          : selectedBoardElement.shape?.height ??
            getDefaultElementHeight(selectedBoardElement.type));

      const width =
        (selectedBoardElement.shape?.x ?? 0) +
        (selectedBoardElement.shape?.width ?? getDefaultElementWidth(selectedBoardElement.type));

      if ((selectedBoardElement.shape?.x ?? 0) < selectionRect.x) {
        selectionRect.x = selectedBoardElement.shape?.x ?? 0;
      }
      if (right === undefined || width > right) {
        right = width;
      }
      if ((selectedBoardElement.shape?.y ?? 0) < selectionRect.y) {
        selectionRect.y = (selectedBoardElement.shape?.y ?? 0) - padding;
      }
      if (bottom === undefined || height > bottom) {
        bottom = height;
      }
    });

    selectionRect.width = Math.abs((right ?? 0) - (selectionRect.x ?? 0));
    selectionRect.height = Math.abs((bottom ?? 0) - (selectionRect.y ?? 0));

    // logger.info('Selection rect', selectionRect);

    return selectionRect;
  }
  return {
    x: 0,
    width: 0,
    y: 0,
    height: 0,
  };
};

interface MultiSelectProps {
  selectedElements: UIBoardElement[];
}

const STROKE_COLOR = 'rgba(0,0,255,0.4)';

const MultiSelect: React.FC<MultiSelectProps> = ({ selectedElements }) => {
  const scale = useStoreState((state) => state.boardSettings.zoomLevel);
  const toggleSelectedElement = useStoreActions((actions) => actions.board.toggleSelectedElement);

  const selectSpecs = getMultiselectRect(selectedElements);
  const spawnContextMenu = useSpawnContextMenu();

  const handleContextMenu = useCallback(
    (e: KonvaEventObject<MouseEvent>) => {
      const ids = selectedElements.map((selectedElement) => selectedElement.id);
      spawnContextMenu({
        id: undefined!,
        ids,
        pos: { x: e.evt.clientX, y: e.evt.clientY },
      });
    },
    [selectedElements]
  );

  return selectedElements.length ? (
    <Rect
      id={SELECTED_AREA_ID}
      onContextMenu={handleContextMenu}
      stroke={STROKE_COLOR}
      strokeWidth={2 / scale}
      x={selectSpecs.x}
      y={selectSpecs.y}
      width={selectSpecs.width}
      height={selectSpecs.height}
      transformsEnabled="position"
      onClick={(e) => {
        if (!isCtrlOrMetaKey(e)) return;
        const intersectionKonvaNodes = e.target
          .getStage()!
          .getAllIntersections({ x: e.evt.clientX, y: e.evt.clientY });

        const highestBoardElement = intersectionKonvaNodes
          .reverse()
          .find((intersectingKonvaNode) => intersectingKonvaNode.attrs?.isBoardElement === true);
        if (highestBoardElement?.attrs?.id) {
          toggleSelectedElement(highestBoardElement.attrs.id);
        }
      }}
    />
  ) : null;
};

export default MultiSelect;
