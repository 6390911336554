/* eslint-disable no-param-reassign */
import { Action, action, Thunk, thunk, Computed, computed } from 'easy-peasy';
import { BoardStoreModel } from '.';
import { PublicStoreModel } from '..';
import { ClientElementEvent } from '../../ServerEvent/ServerEvent';

export interface BoardHistoryStoreModel {
  pastActions: Array<ClientElementEvent>;
  currentAction: ClientElementEvent | null;
  futureActions: Array<ClientElementEvent>;
  undoAction: Action<BoardStoreModel>;
  redoAction: Action<BoardStoreModel>;
  pushEventToPast: Action<BoardStoreModel, ClientElementEvent>;
  pushEventToFuture: Action<BoardStoreModel, ClientElementEvent>;
  popFutureAction: Action<BoardStoreModel>;
  popPastAction: Action<BoardStoreModel>;
  clearFutureActions: Action<BoardStoreModel>;
  setCurrentAction: Action<BoardStoreModel, ClientElementEvent | null>;
  canUndo: Computed<BoardStoreModel, boolean>;
  canRedo: Computed<BoardStoreModel, boolean>;
  undo: Thunk<BoardStoreModel, void, null, PublicStoreModel>;
  redo: Thunk<BoardStoreModel, void, null, PublicStoreModel>;
}

const boardHistory: BoardHistoryStoreModel = {
  currentAction: null,
  pastActions: [],
  futureActions: [],
  undoAction: action((state) => {
    if (!state.currentAction) return;
    state.futureActions.push(state.currentAction);
    if (state.pastActions.length > 0) {
      // typescript is stupid
      state.currentAction = state.pastActions.pop()!;
    } else {
      state.currentAction = null;
    }
  }),
  undo: thunk(async (actions, _, { getStoreState }) => {
    // TODO: Handle bulk actions like moving a selection -> undo all at once
    const oldCurrentAction = getStoreState().board.currentAction;
    if (!oldCurrentAction) return;
    // need to await the unapply because updating references might otherwise interfere
    // with the next undo/redo action when executing them fast
    await actions.unapplyEvent({ payload: oldCurrentAction });
    const { currentAction, pastActions } = getStoreState().board;
    if (!currentAction) return;
    actions.pushEventToFuture(currentAction);
    if (pastActions.length > 0) {
      actions.setCurrentAction(pastActions[pastActions.length - 1]);
      actions.popPastAction();
    } else {
      actions.setCurrentAction(null);
    }
  }),
  redoAction: action((state) => {
    if (state.currentAction !== null) {
      state.pastActions.push(state.currentAction);
    }
    if (state.futureActions.length > 0) {
      state.currentAction = state.futureActions.pop()!;
    }
  }),
  redo: thunk(async (actions, _, { getStoreState }) => {
    // TODO: Handle bulk actions like moving a selection -> redo all at once
    actions.redoAction();
    const { currentAction } = getStoreState().board;
    if (!currentAction) return;
    // need to await the apply because updating references might otherwise interfere
    // with the next undo/redo action when executing them fast
    await actions.applyEvent({ payload: currentAction });
  }),
  canUndo: computed((state) => !!state.currentAction),
  canRedo: computed((state) => state.futureActions.length > 0),
  setCurrentAction: action((state, payload) => {
    state.currentAction = payload;
  }),
  pushEventToFuture: action((state, payload) => {
    state.futureActions.push(payload);
  }),
  pushEventToPast: action((state, payload) => {
    state.pastActions.push(payload);
  }),
  popFutureAction: action((state) => {
    state.futureActions.pop();
  }),
  popPastAction: action((state) => {
    state.pastActions.pop();
  }),
  clearFutureActions: action((state) => {
    state.futureActions = [];
  }),
};

export default boardHistory;
