import { AddIcon } from '@chakra-ui/icons';
import { Button, ButtonGroup, Textarea } from '@chakra-ui/react';
import { useState } from 'react';

type CreateBlockerFormProps = {
  onSubmit: (description: string) => void;
  isDisabled?: boolean;
};

const CreateBlockerForm: React.FC<CreateBlockerFormProps> = ({ onSubmit, isDisabled = false }) => {
  const [showBlockerCreateForm, setShowBlockerCreateForm] = useState(false);
  const [description, setDescription] = useState('');

  return showBlockerCreateForm ? (
    <>
      <Textarea
        value={description}
        placeholder="Why is this Card blocked?"
        onChange={(event) => {
          setDescription(event.target.value);
        }}
        mb={3}
        autoFocus
      />
      <ButtonGroup mb={3}>
        <Button
          colorScheme="yoBrand"
          onClick={() => {
            onSubmit(description);
          }}
        >
          Add Blocker
        </Button>
        <Button
          colorScheme="yoBrand"
          variant="ghost"
          _hover={{ bg: 'none' }}
          _active={{ bg: 'none' }}
          onClick={() => setShowBlockerCreateForm(false)}
        >
          Cancel
        </Button>
      </ButtonGroup>
    </>
  ) : (
    <Button
      leftIcon={<AddIcon fontSize="16px" />}
      mr="auto"
      variant="link"
      color="yoBrand.500"
      bg="inherit"
      display="flex"
      alignItems="center"
      _hover={{ bg: 'inherit' }}
      mb={3}
      onClick={() => {
        if (isDisabled) return;
        setShowBlockerCreateForm(true);
      }}
      isDisabled={isDisabled}
    >
      Add Blocker
    </Button>
  );
};

export default CreateBlockerForm;
