import { BoardElementType, Rectangle, assertUnreachable } from 'shared';
import { SHARED_STYLES as CARD_STYLES } from 'components/canvas/Card';
import { SHARED_STYLES as STICKY_STYLES } from 'components/canvas/Sticky';
import { SHARED_STYLES as COLUMN_STYLES } from 'components/canvas/Column';
import { SHARED_STYLES as SWIMLANE_STYLES } from 'components/canvas/Swimlane';
import { COL_MIN_HEIGHT } from 'src/sortedColumn/SortedColumnPatchCalculator';

export function getDefaultElementWidthAndHeight(
  type: BoardElementType,
  isSorted?: boolean
): Pick<Rectangle, 'height' | 'width'> {
  switch (type) {
    case BoardElementType.CARD: {
      return {
        height: CARD_STYLES.height,
        width: CARD_STYLES.width,
      };
    }
    case BoardElementType.COLUMN: {
      return {
        width: COLUMN_STYLES.width,
        height: isSorted ? COL_MIN_HEIGHT : COLUMN_STYLES.height,
      };
    }
    case BoardElementType.SWIMLANE: {
      return {
        width: SWIMLANE_STYLES.width,
        height: SWIMLANE_STYLES.height,
      };
    }
    case BoardElementType.STICKY: {
      return {
        width: STICKY_STYLES.width,
        height: STICKY_STYLES.height,
      };
    }
    default: {
      return assertUnreachable(type);
    }
  }
}
