import { Avatar, Text, Flex, Box, Button, useToast, ButtonProps } from '@chakra-ui/react';
import { Reaction, User } from 'shared';
import React, { useState } from 'react';
import { getFullnameFromUser } from 'lib/helpers';
import { useStoreActions, useStoreState } from 'store/hooks';
import dynamic from 'next/dynamic';
import { enhanceHTMLForUI } from 'lib/editor.helper';
import ReactionList from '../../ReactionList';
import DateTimeTooltip from '../../DateTimeTooltip';

const BaseEditor = dynamic(() => import('../../BaseEditor'), {
  ssr: false,
  loading: () => <textarea />,
});

interface CommentProps {
  id: string;
  comment: string;
  commentReactions?: Reaction[];
  createdBy?: User;
  createdAt: Date;
  cardId: string;
  handleAddReaction: (commentId: string, emoji: string, userId: string) => void;
  handleRemoveReaction: (commentId: string, emoji: string, userId: string) => void;
  removeComment: (id: string) => void;
  setCommentIsEditing: (id: string, isEditing: boolean) => void;
  isDisabled?: boolean;
}

const CommentDetail: React.FC<CommentProps> = ({
  id,
  comment,
  commentReactions,
  createdBy,
  createdAt,
  cardId,
  handleAddReaction,
  handleRemoveReaction,
  removeComment,
  setCommentIsEditing,
  isDisabled = false,
}) => {
  const user = useStoreState((store) => store.user.user);
  const userName = getFullnameFromUser(createdBy);
  const { updateComment, deleteComment } = useStoreActions((actions) => actions.board);
  const [activeComment, setActiveComment] = useState(comment);
  const [isEditing, setIsEditing] = useState(false);
  const canEditAndDelete = user!._id === createdBy!._id && !isDisabled;
  const toast = useToast();

  const setIsEditingInternal = (newIsEditing: boolean) => {
    setIsEditing(newIsEditing);
    setCommentIsEditing(id, newIsEditing);
  };

  const handleSave = async (newValue: string) => {
    const oldValue = activeComment;
    setActiveComment(newValue);
    try {
      await updateComment({ comment: { _id: id, comment: newValue } });
      toast({
        title: `Comment succesfully updated.`,
        position: 'bottom',
        status: 'success',
        duration: 2000,
      });
    } catch (e) {
      toast({
        title: `Comment could not be updated. Please try again.`,
        position: 'bottom',
        status: 'error',
        duration: 2000,
      });
      setActiveComment(oldValue);
    }
  };

  const handleDelete = async () => {
    try {
      await deleteComment({ id, cardId });
      toast({
        title: `Comment succesfully removed.`,
        position: 'bottom',
        status: 'success',
        duration: 2000,
      });
      removeComment(id);
    } catch (e) {
      toast({
        title: `Comment could not be removed. Please try again.`,
        position: 'bottom',
        status: 'error',
        duration: 2000,
      });
    }
  };

  const commentButtonStyle: ButtonProps = {
    mr: '3',
    variant: 'link',
    fontWeight: 400,
    color: 'yoGray.850',
    bg: 'inherit',
    alignItems: 'center',
    _hover: { color: 'black', textDecoration: 'underline' },
    mb: 3,
    minWidth: '0',
  };

  return (
    <Box key={id}>
      <Flex alignItems="center">
        <Avatar src={createdBy?.avatarUrl} name={userName || 'y o'} size="sm" mr={2} />
        <Text as="span" fontWeight="bold" noOfLines={1} flexShrink={0} paddingRight={5}>
          {userName || 'Someone'}
        </Text>
        <DateTimeTooltip flexShrink={0} datetime={createdAt} />
      </Flex>
      {isEditing && (
        <Box marginLeft={10} marginTop={2}>
          <BaseEditor
            initialValue={activeComment}
            onSave={handleSave}
            setIsEditing={setIsEditingInternal}
            isEditing={isEditing}
            minEditorHeight="0px"
            disableSavingEmpty
            setHasContentChanged={(newVal) => setCommentIsEditing(id, newVal)}
            isCommentEditor
          />
        </Box>
      )}
      {!isEditing && (
        <Box className="cardDetails">
          <Box
            color="black"
            marginLeft={10}
            className="read-view"
            marginTop={2}
            dangerouslySetInnerHTML={{
              __html: activeComment?.length !== 0 ? enhanceHTMLForUI(activeComment, user?._id) : '',
            }}
          />
          <Box marginLeft={10} marginTop={2}>
            {canEditAndDelete && (
              <>
                <Button
                  key="editCommentButton"
                  {...commentButtonStyle}
                  onClick={() => {
                    setIsEditing(true);
                  }}
                >
                  Edit
                </Button>
                <Button
                  key="deleteCommentButton"
                  {...commentButtonStyle}
                  onClick={() => {
                    handleDelete();
                  }}
                >
                  Delete
                </Button>
              </>
            )}
            <ReactionList
              handleAddReaction={(emoji, userId) => handleAddReaction(id, emoji, userId)}
              handleRemoveReaction={(emoji, userId) => handleRemoveReaction(id, emoji, userId)}
              commentId={id}
              reactions={commentReactions}
              isDisabled={isDisabled}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CommentDetail;
