/* eslint-disable no-param-reassign */
import { action, Action, Computed, computed } from 'easy-peasy';
import { UIBoardElement } from 'types/index';
import { BoardStoreModel } from '.';

export interface ClipboardModel {
  clipboard: UIBoardElement[];
  setClipboard: Action<BoardStoreModel, string[]>;
  resetClipboard: Action<BoardStoreModel>;
  hasPastableElements: Computed<BoardStoreModel, boolean>;
}

const clipboardStore: ClipboardModel = {
  clipboard: [],
  setClipboard: action((state, payload) => {
    state.clipboard = state.boardElements.filter((element) => payload.includes(element.id));
  }),
  resetClipboard: action((state) => {
    state.clipboard = [];
  }),
  hasPastableElements: computed((state) => state.clipboard.length > 0),
};

export { clipboardStore };
