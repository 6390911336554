import { getOppositeLinkType, BoardElementLinkType } from 'shared';
import { getLinkDescription } from 'lib/Links.helper';
import theme from 'lib/theme';
import { useEffect, useRef, useState } from 'react';
import { Group, Rect, Text } from 'react-konva';
import Konva from 'konva';

interface Props {
  type: BoardElementLinkType;
}

const DEFAULT_TEXT_WIDTH = 80;

export function BoardElementLinkLabel({ type }: Props) {
  const [textWidth, setTextWidth] = useState(DEFAULT_TEXT_WIDTH);

  const textRef = useRef<Konva.Text>(null);
  useEffect(() => {
    if (textRef.current) {
      setTextWidth(textRef.current.getWidth() + 10);
    }
  }, []);

  return (
    <Group y={-30} x={0} listening={false} height={30} width={textWidth}>
      <Rect height={30} width={textWidth} fill={theme.colors.designAnnotation[500]} />
      <Text
        ref={textRef}
        height={30}
        text={`${getLinkDescription(getOppositeLinkType(type))}: `}
        fill="white"
        verticalAlign="middle"
        align="center"
        fontWeight={500}
        fontSize={16}
        x={4}
        fontFamily={theme.fonts.boardElements}
      />
    </Group>
  );
}
