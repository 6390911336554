/* eslint-disable import/prefer-default-export */
import { BoardElementType } from 'shared';
import { useCallback } from 'react';
import { useStore, useStoreActions } from 'store/hooks';
import { UIBoardElement } from 'types/index';

export function useAssignUser() {
  const updateElement = useStoreActions((actions) => actions.board.updateElement);
  const store = useStore();
  return useCallback(
    (element: UIBoardElement) => {
      const currentUserId = store.getState().user.user?._id;
      if (element.type !== BoardElementType.CARD) return;
      if (element.assignedAvatars?.some((id) => id === currentUserId)) return;
      if (!currentUserId) return;
      updateElement({
        id: element.id,
        assignedAvatars: [...(element.assignedAvatars || []), currentUserId],
      });
    },
    [store]
  );
}
