/* eslint-disable no-param-reassign */
import { Action, action, Thunk, thunk, persist, Computed, computed } from 'easy-peasy';
import { AppCursor, Position } from 'store/app';
import isMobile from 'ismobilejs';
import { PublicStoreModel } from '..';

export enum BoardMode {
  MOVE = 'MOVE',
  HAND = 'HAND',
  TIMETRAVEL = 'TIMETRAVEL',
}

export type ZoomAndOffset = {
  zoomLevel: number;
  offset: Position;
};

export interface SettingsStoreModel {
  mode: BoardMode;
  stageOffset: Position;
  normalizedOffset: Position;
  showHints: boolean;
  zoomLevel: number;
  snapToGrid: boolean;
  showBookmarks: boolean;
  toggleSnapToGrid: Action<SettingsStoreModel>;
  setZoomLevel: Action<SettingsStoreModel, number>;
  setOffset: Thunk<SettingsStoreModel, Position>;
  setOffsetAction: Action<SettingsStoreModel, Position>;
  setNormalizedOffset: Action<SettingsStoreModel, Position>;
  setPlainOffset: Action<SettingsStoreModel, Position>;
  setShowBookmarks: Action<SettingsStoreModel, boolean>;
  setMode: Thunk<SettingsStoreModel, BoardMode, null, PublicStoreModel>;
  toggleMode: Thunk<SettingsStoreModel, void>;
  setModeAction: Action<SettingsStoreModel, BoardMode>;
  closeHints: Action<SettingsStoreModel>;
  incrementZoom: Action<SettingsStoreModel>;
  decrementZoom: Action<SettingsStoreModel>;
  areElementsDraggable: Computed<SettingsStoreModel, boolean>;
  zoomLevelPercent: Computed<SettingsStoreModel, string>;
  setZoomLevelAndOffset: Action<SettingsStoreModel, ZoomAndOffset>;
}

const settingsStoreModel: SettingsStoreModel = persist(
  {
    // default mode should be "Hand" on mobile/touch devices
    mode: isMobile().any ? BoardMode.HAND : BoardMode.MOVE,
    stageOffset: { x: 0, y: 0 },
    normalizedOffset: { x: 0, y: 0 },
    zoomLevel: 1,
    showHints: true,
    snapToGrid: true,
    showBookmarks: false,
    closeHints: action((state) => {
      state.showHints = false;
    }),
    setZoomLevel: action((state, payload) => {
      state.zoomLevel = payload;
    }),
    toggleSnapToGrid: action((state) => {
      state.snapToGrid = !state.snapToGrid;
    }),
    incrementZoom: action((state) => {
      const currentZoom = state.zoomLevel;
      if (currentZoom < 1.5) {
        state.zoomLevel += 0.25;
      } else {
        state.zoomLevel += 0.5;
      }
    }),
    decrementZoom: action((state) => {
      const currentZoom = state.zoomLevel;
      if (currentZoom < 1.5) {
        state.zoomLevel -= 0.25;
      } else {
        state.zoomLevel -= 0.5;
      }
    }),
    setOffset: thunk((actions, payload) => {
      actions.setOffsetAction(payload);
      actions.setNormalizedOffset(payload);
    }),
    setOffsetAction: action((state, payload) => {
      state.stageOffset = { x: payload.x / state.zoomLevel, y: payload.y / state.zoomLevel };
    }),
    setNormalizedOffset: action((state, payload) => {
      state.normalizedOffset = { x: payload.x, y: payload.y };
    }),
    setPlainOffset: action((state, payload) => {
      state.stageOffset = payload;
    }),
    setModeAction: action((state, mode) => {
      state.mode = mode;
    }),
    setShowBookmarks: action((state, payload) => {
      state.showBookmarks = payload;
    }),
    setMode: thunk((actions, mode, { getStoreActions }) => {
      switch (mode) {
        case BoardMode.TIMETRAVEL: {
          getStoreActions().board.resetTimeTravelElements();
          break;
        }
        case BoardMode.HAND: {
          getStoreActions().app.setCursor(AppCursor.DEFAULT);
          break;
        }
        case BoardMode.MOVE: {
          getStoreActions().app.setCursor(AppCursor.CURSOR);
          break;
        }
        default: {
          break;
        }
      }

      actions.setModeAction(mode);
    }),
    areElementsDraggable: computed(
      (state) => state.mode !== BoardMode.HAND && state.mode !== BoardMode.TIMETRAVEL
    ),
    zoomLevelPercent: computed((state) => `${(state.zoomLevel * 100).toFixed(0)}%`),
    setZoomLevelAndOffset: action((state, { offset, zoomLevel }) => {
      state.zoomLevel = zoomLevel;
      state.stageOffset = { x: offset.x / zoomLevel, y: offset.y / zoomLevel };
    }),
    toggleMode: thunk((actions, _, { getState }) => {
      const currentMode = getState().mode;
      actions.setMode(currentMode === BoardMode.MOVE ? BoardMode.HAND : BoardMode.MOVE);
    }),
  },
  {
    mergeStrategy: 'mergeDeep',
    storage: 'localStorage',
    whitelist: ['showHints'],
  }
);

export default settingsStoreModel;
