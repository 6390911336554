/* eslint-disable no-param-reassign */
import { BoardElementLink, getOppositeLinkType, EventTypes, IdTuple } from 'shared';
import { action, Action, thunk, Thunk } from 'easy-peasy';
import sortBoardElementsByRanking, {
  divideArrayByRule,
  preloadImage,
  sortBoardElementsByZIndex,
  updateIdsInEvent,
} from 'lib/helpers';
import { PublicStoreModel } from 'store/index';
import { v4 as generateUuid } from 'uuid';
import { UIBoardElement, BoardElementState, ClientCreatedBoardElement } from 'types/index';
import { getElementsToUpdateForZIndexChanges, getNextZIndexForBoardElementType } from 'lib/zIndex';
import { getDefaultElementWidthAndHeight } from 'lib/Elements.helper';
import { uploadAttachment } from 'hooks/useAttachments';
import produce from 'immer';
import { BoardStoreModel } from '..';
import BoardEventFactory from '../boardEventFactory';
import * as Events from '../../../lib/Events';

export const TEMP_ID_PREFIX = 'TEMP';
type BoardElementWithoutIdAndWidthAndHeight = Omit<ClientCreatedBoardElement, 'id' | 'shape'> & {
  // the width and height set by the component itself
  shape: Omit<ClientCreatedBoardElement['shape'], 'width' | 'height'>;
  // you can pass a file with a created element to add it as an attachment
  file?: File;
};

type AddElementPayload =
  | BoardElementWithoutIdAndWidthAndHeight
  | Array<BoardElementWithoutIdAndWidthAndHeight>;

export interface AddElementsModel {
  addElement: Thunk<BoardStoreModel, AddElementPayload, null, PublicStoreModel>;
  _addElement: Thunk<
    BoardStoreModel,
    {
      payload: AddElementPayload;
      selectAfterAdd?: boolean;
    },
    null,
    PublicStoreModel
  >;
  addElementAction: Action<BoardStoreModel, UIBoardElement | UIBoardElement[]>;
  addElementWithoutHistory: Thunk<
    BoardStoreModel,
    { payload: ClientCreatedBoardElement; fetch?: boolean },
    null,
    PublicStoreModel
  >;
}

const addElementsStore: AddElementsModel = {
  addElementAction: action((state, payload) => {
    state.boardElements = sortBoardElementsByZIndex(
      sortBoardElementsByRanking(state.boardElements.concat(payload))
    );
  }),
  addElement: thunk(async (actions, payload) =>
    actions._addElement({ payload, selectAfterAdd: false })
  ),
  _addElement: thunk(
    async (actions, { payload, selectAfterAdd }, { getStoreState, getStoreActions }) => {
      const hasMultiple = Array.isArray(payload);
      const { currentAction, boardId, boardElements } = getStoreState().board;
      const { updateWidgetRef } = getStoreActions().widgets;

      const isNewElement = !hasMultiple && !('id' in payload);
      let hasUnvalidatedLink = false;

      const idUpdates: IdTuple[] = [];
      // generate zIndex for elements
      const newElements = ([] as Array<BoardElementWithoutIdAndWidthAndHeight & { id?: string }>)
        .concat(payload)
        .map((newElement) => {
          // temporary ids can be identified by their prefix
          const newId = TEMP_ID_PREFIX + generateUuid();

          // If the element already has an ID it means it
          if (newElement.id) {
            idUpdates.push({ oldId: newElement.id, newId });
          }
          return {
            ...newElement,
            id: newId,
            zIndex: getNextZIndexForBoardElementType({
              boardElements,
              elementType: newElement.type,
            }),
            shape: {
              ...getDefaultElementWidthAndHeight(newElement.type, newElement.isSorted),
              ...newElement.shape,
            },
            state:  BoardElementState.DEFAULT,
          };
        }) as UIBoardElement[];

      const addedAndUpdatedElements = getElementsToUpdateForZIndexChanges({
        boardElements,
        elementsWithZIndexUpdate: newElements,
      });

      const hasAdditionalUpdates = newElements.length < addedAndUpdatedElements.length;
      const [addedElements, updatedElements] = !hasAdditionalUpdates
        ? [addedAndUpdatedElements, []]
        : divideArrayByRule(addedAndUpdatedElements, (e: UIBoardElement) =>
            newElements.map((el) => el.id).includes(e.id)
          );

      if (isNewElement && !Array.isArray(payload) && payload.links?.length === 1) {
        // 1. find target
        // 2. if has target update it
        // 3. remember to update id after new id from server
        const newestCardLink = payload.links[0];
        const target = boardElements.find((e) => e.id === newestCardLink.targetElement.id);
        if (!target) return;
        const newLink: BoardElementLink = {
          _id: newestCardLink._id,
          targetElement: { id: (payload as any).id },
          type: getOppositeLinkType(newestCardLink.type),
          createdAt: new Date(),
        };
        updatedElements.push({
          ...target,
          links: [...(target?.links ?? []), newLink],
        });
        hasUnvalidatedLink = true;
      }

      if (updatedElements.length > 0) {
        actions.updateElementAction(updatedElements);
      }

      // add elements to store
      actions.addElementAction(addedElements);

      // If there was only one element added, spawn a widget for that element
      if (!hasMultiple) {
        getStoreActions().widgets.spawnWidget(newElements[0]);
        // if there were multiple elements and the elements have been pasted
        // we want to select the newly added elements
      } else if (selectAfterAdd) {
        getStoreActions().board.selectElements(newElements.map((e) => e.id));
      }

      // every old boardElement gets updated because of zIndex rebalancing
      const updateEvents = updatedElements.map((updatedElement, index) => {
        // updatedElements and boardElements contain the same elements in the same order
        const unupdatedElement = boardElements[index];
        return BoardEventFactory.createEvent(
          EventTypes.UPDATE,
          updatedElement.id,
          unupdatedElement,
          updatedElement
        );
      });

      const bulkEvent = BoardEventFactory.createBulkEvent(
        addedElements
          .map((newElement) =>
            BoardEventFactory.createEvent(EventTypes.ADD, newElement.id, undefined, newElement)
          )
          .concat(updateEvents)
      );
      if (!boardId) return;

      const res = await Events.postEvent(
        bulkEvent,
        boardId,
        (err: string) => {
          getStoreActions().board.unapplyEvent({ payload: bulkEvent, fetch: false });
          getStoreActions().errors.addError(err);
        },
        { idUpdates }
      );

      if (res) {
        let updatedBulkEvent = updateIdsInEvent(res, bulkEvent);
        actions.updateIdsWithObjectIds(res);
        if (!hasMultiple) {
          const { newId } = res[0];
          if (newId) {
            getStoreActions().board.setElementState({ id: newId, state: BoardElementState.INEDIT });
          }
          const { file } = payload as any;
          if (file && newId) {
            try {
              const attachment = await uploadAttachment({ boardId, cardId: newId, file });

              actions.incrementAttachmentCount({ cardId: newId });

              if (attachment.mimeType.startsWith('image/')) {
                // preload image to prevent flickering
                await preloadImage(attachment.url);
                actions.updateElement({ id: newId, thumbnailUrl: attachment.url });
              }
            } catch (e) {
              getStoreActions().errors.addError(
                `Could not upload file${typeof e === 'string' ? `: ${e}` : ''}`
              );
            }
          }
          updateWidgetRef(res[0]);

          if (hasUnvalidatedLink && !Array.isArray(payload)) {
            const link = payload.links?.[0];

            const linkTarget = boardElements.find((e) => e.id === link?.targetElement.id);
            if (!link || !linkTarget || !res[0].newId) return;

            const updatedLinks = [
              ...(linkTarget?.links ?? []),
              {
                ...link,
                targetElement: { id: res[0].newId },
              },
            ];
            actions.updateElementWithoutHistory({
              payload: {
                id: linkTarget.id,
                links: updatedLinks,
              },
              fetch: true,
            });
            // Update the event as well
            // satisfy ts
            if (updatedBulkEvent.type !== EventTypes.BULK) return;

            updatedBulkEvent = produce(updatedBulkEvent, (draft) => {
              // get last update event (aka the link update event)
              const linkUpdateEvent = draft.events
                .reverse()
                .find((e) => e.type === EventTypes.UPDATE);
              if (linkUpdateEvent) {
                linkUpdateEvent.newValues.links = updatedLinks;
              }
            });
          }
        }
        if (currentAction) {
          actions.pushEventToPast(currentAction);
        }
        actions.setCurrentAction(updatedBulkEvent);
        // build a new branch from currentState -> clear stored futureActions
        actions.clearFutureActions();
      }
    }
  ),
  addElementWithoutHistory: thunk(
    async (actions, { payload, fetch }, { getStoreState, getStoreActions }) => {
      actions.addElementAction({
        ...payload,
        state: BoardElementState.DEFAULT,
      });
      const addEvent = BoardEventFactory.createEvent(
        EventTypes.ADD,
        payload.id,
        undefined,
        payload
      );
      const { boardId } = getStoreState().board;
      if (fetch && boardId) {
        const res = await Events.postEvent(addEvent, boardId, (err: string) => {
          getStoreActions().board.unapplyEvent({ payload: addEvent, fetch: false });
          getStoreActions().errors.addError(err);
        });

        if (res) {
          actions.updateIdsWithObjectIds(res);
        }
      }
    }
  ),
};

export { addElementsStore };
