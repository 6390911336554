import { Group as IGroup } from 'konva/types/Group';
import { ShapeConfig } from 'konva/types/Shape';
import { forwardRef } from 'react';
import { Group, Image, Rect } from 'react-konva';

type CardBackgroundImageProps = {
  image: any;
  width: number;
  height: number;
  cornerRadius: number | number[];

  getGroup: () => IGroup | null;
} & ShapeConfig;

export const CardBackgroundImage = forwardRef<any, CardBackgroundImageProps>((props, ref) => {
  const {
    id,
    cornerRadius: borderRadius,
    width,
    height,
    shadowBlur,
    shadowColor,
    shadowOffsetX,
    shadowOffsetY,
    getGroup,
  } = props;
  const cornerRadius = Array.isArray(borderRadius) ? borderRadius[1] : borderRadius;
  return (
    // this function is used to get the parent group of the image
    // if the card has an image the internal structure is different (more nested)
    // the getGroup function allows us to get the parent group of the image or the card
    // without any .parent.parent ... traversing
    <Group id={id} getGroup={getGroup} width={width} height={height}>
      {/* images can't have shadows on the canvas, thus we need to snug 
      a rect with a shadow behind it */}
      <Rect
        width={width}
        height={height}
        fill="white"
        shadowBlur={shadowBlur}
        shadowColor={shadowColor}
        shadowOffsetX={shadowOffsetX}
        shadowOffsetY={shadowOffsetY}
        listening={false}
        cornerRadius={cornerRadius}
      />
      <Group
        clipFunc={(ctx: any) => {
          // this function makes the corners rounded
          // it's complicated but the only way to make it work in Konva with images
          ctx.beginPath();
          const topLeft = Math.min(cornerRadius, width / 2, height / 2);
          const topRight = Math.min(cornerRadius, width / 2, height / 2);
          const bottomLeft = Math.min(cornerRadius, width / 2, height / 2);
          const bottomRight = Math.min(cornerRadius, width / 2, height / 2);

          ctx.moveTo(topLeft, 0);
          ctx.lineTo(width - topRight, 0);
          ctx.arc(width - topRight, topRight, topRight, (Math.PI * 3) / 2, 0, false);
          ctx.lineTo(width, height - bottomRight);
          ctx.arc(width - bottomRight, height - bottomRight, bottomRight, 0, Math.PI / 2, false);
          ctx.lineTo(bottomLeft, height);
          ctx.arc(bottomLeft, height - bottomLeft, bottomLeft, Math.PI / 2, Math.PI, false);
          ctx.lineTo(0, topLeft);
          ctx.arc(topLeft, topLeft, topLeft, Math.PI, (Math.PI * 3) / 2, false);
          ctx.closePath();
        }}
      >
        <Image ref={ref} transformsEnabled="position" {...props} />
      </Group>
    </Group>
  );
});
