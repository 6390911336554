import nonUpdatingStore from 'store/index';
import { EventTypes } from 'shared';
import { ServerElementEvent } from './ServerEvent';
import logger from '../lib/logger';

const eventIds: string[] = [];
export abstract class ServerEventHandler {
  /**
   * Handles the next incoming `ServerEvent`
   * @param event The `ServerEvent` to handle
   * @param historyChanged todo what is this?
   *
   * todo buffer events in case we are updating the board after a socket connect event
   * todo check if we apply a eventId twice
   */
  static handle({
    event,
    historyChanged,
  }: {
    event: ServerElementEvent;
    historyChanged: boolean;
  }): void {
    this.applyEvent({ event, historyChanged });
  }

  private static applyEvent({
    event,
    historyChanged,
  }: {
    event: ServerElementEvent;
    historyChanged: boolean;
  }): void {
    const { applyEvent } = nonUpdatingStore.getActions().board;
    const { user } = nonUpdatingStore.getState().user;
    if (!user) return;

    // Keep track of all incoming events to prevent duplicates
    if (eventIds.includes(event._id)) {
      logger.debug(`Duplicate event id ${event._id}`);
      // this event has already been applied. Skip it!
      return;
    }
    eventIds.push(event._id);

    // if history has changed refetch card details
    if (historyChanged && nonUpdatingStore.getState().widgets.hasDetailView) {
      const { refId } = nonUpdatingStore.getState().widgets.data[0];
      if (refId) {
        nonUpdatingStore.getActions().board.getCardDetails({ id: refId });
      }
    }

    if (
      event.createdBy !== user._id ||
      event.type === EventTypes.UPDATE ||
      (event.type === EventTypes.BULK &&
        event.events.every((evt) => evt.type === EventTypes.UPDATE))
    ) {
      /**
       * If we archive a card locally there is no need to re-apply this event
       * therefore we need to prevent it by checking if the creator was the user
       * and if there were any .isArchived changes
       */

      if (
        event.type === EventTypes.BULK &&
        event?.events !== undefined &&
        event.createdBy === user._id &&
        !event.events.some((ev) => ev?.newValues?.isArchived !== ev?.oldValues?.isArchived)
      ) {
        return;
      }
      applyEvent({ payload: event, fetch: false });
    }
  }
}
