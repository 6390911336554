import { FlexboxProps, Text, Tooltip, TextProps } from '@chakra-ui/react';
import React from 'react';
import dayjs from 'dayjs';
import { printLocaleDateAndTime } from 'lib/helpers';

interface DateTimeTooltipProps extends FlexboxProps {
  datetime: Date;
  locale?: string;
  textAlign?: TextProps['textAlign'];
}

const DateTimeTooltip: React.FC<DateTimeTooltipProps> = ({
  datetime,
  flexShrink,
  flexGrow,
  locale,
  textAlign,
}) => {
  // Sometimes dayjs shows "in a few seconds" when there are minor differences in local and
  // server time. This is confusing and shall realistically never happen,
  // therefore fix the date to "now - 1 second" so "a few seconds ago" will be shown instead
  const fixedDate =
    new Date(datetime)?.getTime() < Date.now() ? datetime : new Date(Date.now() - 1000);

  return (
    <Tooltip
      label={printLocaleDateAndTime(datetime, locale)}
      fontSize="14px"
      fontWeight="500"
      gutter={2}
      placement="top"
      background="yoBrand.750"
    >
      <Text
        as="span"
        noOfLines={1}
        flexShrink={flexShrink}
        flexGrow={flexGrow}
        textAlign={textAlign}
        color="yoGray.850"
      >
        {dayjs(fixedDate).fromNow()}
      </Text>
    </Tooltip>
  );
};

export default DateTimeTooltip;
