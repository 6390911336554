/* eslint-disable import/prefer-default-export */

export function throttle(callback: (...args: any[]) => void, limit: number) {
  let waiting = false; // Initially, we're not waiting
  return function throttled(...args: any[]) {
    // We return a throttled function
    if (!waiting) {
      // If we're not waiting
      callback(...args); // Execute users function
      waiting = true; // Prevent future invocations
      setTimeout(() => {
        // After a period of time
        waiting = false; // And allow future invocations
      }, limit);
    }
  };
}

export function debounce(callback: (...args: any[]) => void, limit: number) {
  let timer: NodeJS.Timeout | undefined;

  const restartTimeout = (...args: any[]) => {
    if (timer) clearTimeout(timer);
    // reset timer
    timer = setTimeout(() => {
      // callback is executed if timer ends before another reset
      callback(...args);
    }, limit);
  };

  const cancel = () => {
    if (timer) clearTimeout(timer);
  };

  return { restartTimeout, cancel };
}
