import TeamMembers from '../BoardSettings/TeamMembers';
import YoModal from '../YoModal';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  boardId?: string;
}

function ShareModal({ isOpen, onClose, boardId }: Props) {
  return (
    <YoModal
      title="Sharing"
      isOpen={isOpen}
      onConfirm={onClose}
      onClose={onClose}
      hasCancel={false}
      confirmText="Close"
      size="4xl"
      disableCtrlEnter
    >
      <TeamMembers showTitle={false} boardId={boardId} />
    </YoModal>
  );
}

export default ShareModal;
