import React, { RefObject, useEffect, useRef } from 'react';
import { Flex, Box } from '@chakra-ui/react';

import { motion, AnimatePresence } from 'framer-motion';
import useWindowDimensions from 'hooks/useWindowDimensions';
import theme from 'lib/theme';

const AnimatedFlex = motion(Flex);

export const NAV_ANIMATION = {
  initial: { y: -15 },
  animate: { y: 0, opacity: 1 },
  exit: {
    y: -15,
    opacity: 0,
    transition: {
      type: 'tween',
      duration: 0.3,
    },
  },
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
  left?: number;
  right?: number;
  top?: number;
  toggleElementRef?: RefObject<any>;
}

const PopoverMenu: React.FC<Props> = ({
  children,
  isOpen,
  onClose,
  left,
  right,
  top,
  toggleElementRef,
}) => {
  const ref = useRef<HTMLElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const { height } = useWindowDimensions();

  const openDropdownAbove =
    (containerRef?.current?.getBoundingClientRect()?.top ?? 0) +
      React.Children.count(children) * 40 >
    height;
  const dropdownAboveOffset = React.Children.count(children) * 40 + 60;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Only close the element if the target is the Popover
      // or the toggle element
      if (
        isOpen &&
        (!ref.current?.contains(event.target as Node) ||
          toggleElementRef?.current?.contains(event.target))
      ) {
        onClose();
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen, onClose, toggleElementRef]);
  return (
    <Box ref={containerRef} position="relative">
      <AnimatePresence>
        {isOpen && (
          <AnimatedFlex
            ref={ref}
            position="absolute"
            width={200}
            bg="yoDark.500"
            borderRadius={4}
            color="white"
            py={2}
            left={left}
            right={right}
            mt={top}
            top={openDropdownAbove ? 12 - dropdownAboveOffset : 12}
            direction="column"
            zIndex={{ base: theme.zIndices.DOM_ELEMENTS + 1, md: 0 }}
            {...NAV_ANIMATION}
          >
            {React.Children.map(children, (currentChild) => {
              if (React.isValidElement(currentChild)) {
                return React.cloneElement(currentChild, {
                  onClick: () => {
                    // Enhance the child onClick function
                    const handler = currentChild.props?.onClick || (() => {});
                    handler();
                    onClose();
                  },
                  // TODO: revise this type
                } as any);
              }
              return currentChild;
            })}
          </AnimatedFlex>
        )}
      </AnimatePresence>
    </Box>
  );
};

export default PopoverMenu;
