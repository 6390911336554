import { BoardElement, EventTypes } from 'shared';
import { Thunk, thunk } from 'easy-peasy';
import {
  sortBoardElementsByZIndex,
  getRevertedEvent,
  transformElementsForStore,
} from 'lib/helpers';
import { BoardStoreModel } from '.';
import { PublicStoreModel } from '..';
import BoardEventFactory from './boardEventFactory';
import * as Events from '../../lib/Events';
import {
  ClientElementEvent,
  ClientSingleElementEvent,
  ServerElementEvent,
} from '../../ServerEvent/ServerEvent';

export interface EventsModel {
  applyEvent: Thunk<
    BoardStoreModel,
    { payload: ClientElementEvent | ServerElementEvent; fetch?: boolean },
    null,
    PublicStoreModel
  >;
  unapplyEvent: Thunk<
    BoardStoreModel,
    { payload: ClientElementEvent | ServerElementEvent; fetch?: boolean },
    null,
    PublicStoreModel
  >;
  applyArchiveEvent: Thunk<BoardStoreModel, ClientSingleElementEvent, null, PublicStoreModel>;
  unApplyArchiveEvent: Thunk<BoardStoreModel, ClientSingleElementEvent, null, PublicStoreModel>;
}

export const eventsStore: EventsModel = {
  applyEvent: thunk(
    async (actions, { payload, fetch = true }, { getStoreState, getStoreActions }) => {
      switch (payload.type) {
        case EventTypes.UPDATE: {
          // isArchived changed
          if (payload.newValues?.isArchived !== payload.oldValues?.isArchived) {
            actions.applyArchiveEvent(payload);
          }

          actions.updateElementWithoutHistory({
            payload: {
              ...payload.newValues,
              id: payload.elementId,
            },
            fetch,
          });
          break;
        }
        case EventTypes.ADD: {
          actions.addElementWithoutHistory({
            payload: {
              ...payload.newValues,
              id: payload.elementId,
            },
            fetch,
          });
          break;
        }
        case EventTypes.REMOVE: {
          actions.removeElementWithoutHistory({ payload: payload.elementId, fetch });
          break;
        }
        case EventTypes.BULK: {
          const eventsToPost: ClientSingleElementEvent[] = [];
          let hasZIndexChanges = false;
          payload.events.forEach((event) => {
            eventsToPost.push(event);
            actions.applyEvent({ payload: event, fetch: false });

            // when there are zIndex changes in one ore more events
            // we want to reorder ofter applying all events
            if (!hasZIndexChanges && event?.oldValues?.zIndex !== event?.newValues?.zIndex) {
              hasZIndexChanges = true;
            }
          });
          if (hasZIndexChanges) {
            const state = getStoreState();
            actions.setBoardAction(sortBoardElementsByZIndex(state.board.boardElements.slice()));
          }
          const { boardId } = getStoreState().board;
          if (fetch && boardId) {
            const res = await Events.postEvent(
              BoardEventFactory.createBulkEvent(eventsToPost),
              boardId,
              getStoreActions().errors.addError
            );

            if (res) {
              actions.updateIdsWithObjectIds(res);
            }
          }
          break;
        }
        default:
          throw new Error('Unknown Event');
      }
    }
  ),
  unapplyEvent: thunk(
    async (actions, { payload, fetch = true }, { getStoreState, getStoreActions }) => {
      switch (payload.type) {
        case EventTypes.UPDATE: {
          // isArchived changed
          if (payload.newValues.isArchived !== payload.oldValues?.isArchived) {
            actions.unApplyArchiveEvent(payload);
          }
          actions.updateElementWithoutHistory({
            payload: {
              ...payload.oldValues,
              id: payload.elementId,
            },
            fetch,
          });
          break;
        }
        case EventTypes.ADD: {
          actions.removeElementWithoutHistory({ payload: payload.elementId, fetch });
          break;
        }
        case EventTypes.REMOVE: {
          if (!payload.oldValues) break;
          actions.addElementWithoutHistory({
            payload: {
              ...payload.oldValues,
              id: payload.elementId,
            },
            fetch,
          });
          break;
        }
        case EventTypes.BULK: {
          const eventsToPost: ClientSingleElementEvent[] = [];
          payload.events.forEach((event) => {
            eventsToPost.push(getRevertedEvent(event));
            actions.unapplyEvent({ payload: event, fetch: false });
          });
          if (fetch) {
            const { boardId } = getStoreState().board;
            if (!boardId) return;
            const res = await Events.postEvent(
              BoardEventFactory.createBulkEvent(eventsToPost),
              boardId,
              getStoreActions().errors.addError
            );

            if (res) {
              actions.updateIdsWithObjectIds(res);
            }
          }
          break;
        }
        default:
          throw new Error('Unknown Event');
      }
    }
  ),
  applyArchiveEvent: thunk((actions, payload, { getStoreState }) => {
    const { archivedElements, boardElements } = getStoreState().board;
    const { setArchivedElementsAction, setBoardAction } = actions;
    if (payload.oldValues?.isArchived === true) {
      const newArchivedElements = archivedElements.filter((el) => el?.id !== payload.elementId);
      const actualElement = archivedElements.find((el) => el?.id === payload.elementId);
      setArchivedElementsAction(newArchivedElements);
      if (!actualElement) return;
      setBoardAction(
        transformElementsForStore(boardElements.concat(actualElement) as BoardElement[])
      );
    } else {
      // element was on the board --> needs to be archived
      const newBoardElements = boardElements.filter((el) => el?.id !== payload.elementId);
      const actualElement = boardElements.find((el) => el?.id === payload.elementId);
      setBoardAction(transformElementsForStore(newBoardElements as BoardElement[]));
      if (!actualElement) return;
      setArchivedElementsAction(archivedElements.concat(actualElement));
    }
  }),
  unApplyArchiveEvent: thunk((actions, payload, { getStoreState }) => {
    const { archivedElements, boardElements } = getStoreState().board;
    const { setArchivedElementsAction, setBoardAction } = actions;
    if (payload.newValues.isArchived === true) {
      const newArchivedElements = archivedElements.filter((el) => el?.id !== payload.elementId);
      const actualElement = archivedElements.find((el) => el?.id === payload.elementId);
      setArchivedElementsAction(newArchivedElements);
      if (!actualElement) return;
      setBoardAction(
        transformElementsForStore(boardElements.concat(actualElement) as BoardElement[])
      );
    } else {
      // element was on the board --> needs to be archived
      const newBoardElements = boardElements.filter((el) => el?.id !== payload.elementId);
      const actualElement = boardElements.find((el) => el?.id === payload.elementId);
      setBoardAction(transformElementsForStore(newBoardElements as BoardElement[]));
      if (!actualElement) return;
      setArchivedElementsAction(archivedElements.concat(actualElement));
    }
  }),
};
