import { Rect, Group, Image } from 'react-konva';
import { AppCursor } from 'store/app';
import { BoardMode } from 'store/board/settings';
import { useStoreActions, useStoreState } from 'store/hooks';
import { useImage } from 'hooks/useImage';
import { KonvaEventObject } from 'konva/types/Node';

const LOCK_BUTTON_NAME = 'lockButton';

export function doesEventBelongToLockButton(
  e: KonvaEventObject<MouseEvent> | KonvaEventObject<TouchEvent>
): boolean {
  return e.target.attrs.name === LOCK_BUTTON_NAME;
}

interface LockButtonProps {
  x: number;
  y: number;
  hexColor: string;
  isLocked: boolean;
}

export function getColoredLockIconURL(color?: string, isLocked?: boolean) {
  const lockOpenSvg = `
    <svg stroke="${color}" width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.6667 7.33334H6.33333C5.59695 7.33334 5 7.9303 5 8.66668V13.3333C5 14.0697 5.59695 14.6667 6.33333 14.6667H15.6667C16.403 14.6667 17 14.0697 17 13.3333V8.66668C17 7.9303 16.403 7.33334 15.6667 7.33334Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M1.66675 5.5V4.66668C1.66675 3.78262 2.01794 2.93478 2.64306 2.30965C3.26818 1.68453 4.11603 1.33334 5.00008 1.33334C5.88414 1.33334 6.73198 1.68453 7.3571 2.30965C7.98223 2.93478 8.33341 3.78262 8.33341 4.66668V7.33334"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  `;
  const lockClosedSvg = `
    <svg stroke="${color}" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.6667 7.33334H3.33333C2.59695 7.33334 2 7.9303 2 8.66668V13.3333C2 14.0697 2.59695 14.6667 3.33333 14.6667H12.6667C13.403 14.6667 14 14.0697 14 13.3333V8.66668C14 7.9303 13.403 7.33334 12.6667 7.33334Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M4.66675 7.33334V4.66668C4.66675 3.78262 5.01794 2.93478 5.64306 2.30965C6.26818 1.68453 7.11603 1.33334 8.00008 1.33334C8.88414 1.33334 9.73198 1.68453 10.3571 2.30965C10.9822 2.93478 11.3334 3.78262 11.3334 4.66668V7.33334" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg> 
  `;

  const iconSvg = isLocked ? lockClosedSvg : lockOpenSvg;

  return `data:image/svg+xml;base64,${btoa(iconSvg)}`;
}

const LockButton: React.FC<LockButtonProps> = ({ x, y, hexColor, isLocked }) => {
  const setCursor = useStoreActions((actions) => actions.app.setCursor);
  const [lockImage] = useImage(
    getColoredLockIconURL(hexColor, isLocked),
    isLocked ? 'Unlock' : 'Lock'
  );
  const mode = useStoreState((store) => store.boardSettings.mode);

  return (
    <Group
      name={LOCK_BUTTON_NAME}
      onMouseEnter={() => setCursor(AppCursor.POINTER)}
      onMouseLeave={() => setCursor(mode === BoardMode.MOVE ? AppCursor.CURSOR : AppCursor.DEFAULT)}
      width={30}
      height={30}
      x={x}
      y={y}
    >
      <Rect name={LOCK_BUTTON_NAME} width={30} height={30} />
      <Image name={LOCK_BUTTON_NAME} image={lockImage} width={16} height={16} y={12} x={12} />
    </Group>
  );
};

export default LockButton;
