import { BoardElementLinkType, Rectangle } from 'shared';
import useStage from 'hooks/useStage';
import { getLinkColor, getVisualLinks } from 'lib/Links.helper';
import { useEffect, useState } from 'react';
import { Line } from 'react-konva';
import { Position } from 'store/app';
import { useStoreState } from 'store/hooks';
import { UIBoardElement } from 'types/index';

function useCardPosition({ cardId }: { cardId: string }) {
  const stage = useStage();
  const targetElementNode = stage?.find(`#${cardId}`)[0];
  const storedCardPosition = useStoreState(
    (store) => store.board.boardElements.find((element) => element.id === cardId)?.shape
  );

  const [currentPosition, setCurrentPosition] = useState<Position>({
    x: targetElementNode?.parent?.x() ?? 0,
    y: targetElementNode?.parent?.y() ?? 0,
  });

  useEffect(() => {
    if (storedCardPosition?.x && storedCardPosition.x !== currentPosition.x) {
      setCurrentPosition((pos) => ({ ...pos, x: storedCardPosition.x }));
    }
    if (storedCardPosition?.y && storedCardPosition.y !== currentPosition.y) {
      setCurrentPosition((pos) => ({ ...pos, y: storedCardPosition.y }));
    }
    // we only want to update the locally saved position when the card position changed
    // in the store (e.g. someone via sockets updated it or undo/redo)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedCardPosition?.x, storedCardPosition?.y]);

  useEffect(() => {
    if (targetElementNode?.parent) {
      setCurrentPosition(targetElementNode?.parent?.position() ?? { x: 0, y: 0 });
    }
  }, [targetElementNode?.parent]);

  useEffect(() => {
    if (!targetElementNode || !targetElementNode?.parent) return () => {};

    const setLocalPosition = () => {
      setCurrentPosition(targetElementNode.parent?.position() ?? { x: 0, y: 0 });
    };

    // we need to listen to the dragmove event on the parent (group)
    // because we only update the position of the cards
    // as soon as it's dropped (dragend)
    targetElementNode.parent.on('dragmove', setLocalPosition);
    return () => {
      targetElementNode.parent?.off('dragmove', setLocalPosition);
    };
  }, [cardId, targetElementNode]);

  return { currentPosition, konvaCardNode: targetElementNode };
}

interface CardLinkProps {
  targetCardId: string;
  parentShape: Omit<Rectangle, 'angle'>;
  linkType: BoardElementLinkType;
}

function CardLink({ parentShape, targetCardId, linkType }: CardLinkProps) {
  const { currentPosition, konvaCardNode } = useCardPosition({ cardId: targetCardId });

  if (!konvaCardNode) return null;

  return (
    <Line
      stroke={getLinkColor(linkType)}
      width={2}
      dash={[10, 10]}
      points={[
        parentShape.x + parentShape.width / 2,
        parentShape.y + parentShape.height / 2,
        currentPosition.x + (konvaCardNode.attrs.width ?? 0) / 2,
        currentPosition.y + (konvaCardNode.attrs.height ?? 0) / 2,
      ]}
    />
  );
}

interface BoardElementLinksProps {
  activeLinkCard: UIBoardElement;
}

export function BoardElementLinks({ activeLinkCard }: BoardElementLinksProps) {
  const { currentPosition, konvaCardNode } = useCardPosition({ cardId: activeLinkCard.id });

  if (!activeLinkCard || !activeLinkCard.links || !konvaCardNode) return null;
  return (
    <>
      {getVisualLinks(activeLinkCard.links).map((link) => (
        <CardLink
          key={link._id}
          parentShape={{
            x: currentPosition.x,
            y: currentPosition.y,
            width: activeLinkCard.shape.width ?? 0,
            height: activeLinkCard.shape.height ?? 0,
          }}
          targetCardId={link.targetElement.id}
          linkType={link.type}
        />
      ))}
    </>
  );
}
