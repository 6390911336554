/* eslint-disable no-param-reassign */
import { computed, Computed } from 'easy-peasy';
import { PublicStoreModel } from 'store/index';
import { BoardElementState, UIBoardElement } from 'types/index';
import { BoardStoreModel } from '..';
import { BoardMode } from '../settings';

export interface ComputedElementsModel {
  hasResizableElements: Computed<BoardStoreModel, boolean>;
  hasSelectedElements: Computed<BoardStoreModel, boolean>;
  hasElementsInEditState: Computed<BoardStoreModel, boolean>;
  selectedBoardElements: Computed<BoardStoreModel, UIBoardElement[]>;
  defaultBoardElements: Computed<BoardStoreModel, UIBoardElement[], PublicStoreModel>;
}

const computedElementsStore: ComputedElementsModel = {
  hasResizableElements: computed((state) =>
    state.boardElements.some((element) => element.state === BoardElementState.RESIZABLE)
  ),
  hasElementsInEditState: computed((state) =>
    state.boardElements.some((element) => element.state === BoardElementState.INEDIT)
  ),
  hasSelectedElements: computed((state) =>
    state.boardElements.some(
      (element) =>
        element.state === BoardElementState.SELECTED || element.state === BoardElementState.FOCUSED
    )
  ),
  selectedBoardElements: computed((state) =>
    state.boardElements.filter(
      (currentElement) => currentElement.state === BoardElementState.SELECTED
    )
  ),
  defaultBoardElements: computed(
    [
      (_, globalStore) => globalStore.boardSettings.mode,
      (state) => state.boardElements,
      (state) => state.timeTravelBoardElements,
    ],
    (mode, boardElements, timeTravelBoardElements) => {
      if (mode === BoardMode.TIMETRAVEL) {
        return timeTravelBoardElements;
      }
      return boardElements.filter(
        (currentElement) => currentElement.state !== BoardElementState.SELECTED
      );
    }
  ),
};

export { computedElementsStore };
