import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';
import { useRef } from 'react';

interface Props {
  isOpen: boolean;
  onAccept: () => void;
  onDecline: () => void;
  setIsTitleEditing: (newIsTitleEditing: boolean) => void;
}

const CloseConfirmation = ({ isOpen, onAccept, onDecline, setIsTitleEditing }: Props) => {
  const cancelRef = useRef();

  const onConfirm = () => {
    onAccept();
    onDecline();
  };

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef as any}
      onClose={onDecline}
      data-testid="close-confirmation"
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Close Detail View
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure you want to close the Detail View? Unsaved changes will be lost.
          </AlertDialogBody>

          <AlertDialogFooter display="flex" flexDirection="row-reverse">
            <Button ref={cancelRef as any} onClick={onDecline}>
              Stay here
            </Button>
            <Button
              colorScheme="red"
              onClick={() => {
                setIsTitleEditing(false);
                onConfirm();
              }}
              mr={3}
            >
              Close anyways
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default CloseConfirmation;
