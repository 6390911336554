import { trackPlausibleEvent } from 'lib/plausible';
import { CLIENT_PLAUSIBLE_EVENTS } from 'lib/Types';

type IconPosition = {
  iconPosition: number;
};

type IconPositionWithCount = {
  iconPosition: number;
  countPosition: number;
};

export const ICON_COUNT_POSITION_INCREMENT = 22;
export const NEXT_ICON_INCREMENT = 28;
export const NEXT_ICON_WITH_COUNT_INCREMENT = 38;

export const getCardIconPositions = (
  commentCount: number,
  attachmentCount: number,
  isWatching: boolean,
  linkCount: number,
  taskCount: number
) => {
  // Calculate positions of card icons depending on the position of their preceding elements
  // e.g. attachments icon shall be leftmost and use up free space when there are no comments
  const showDetailsIconPosition = 11;
  let nextIconPosition = 48;
  let commentsIcon: IconPositionWithCount | null = null;
  let isWatchingIcon: IconPosition | null = null;
  let attachmentIcon: IconPosition | null = null;
  let linkIcon: IconPositionWithCount | null = null;
  let taskIcon: IconPositionWithCount | null = null;

  if (commentCount > 0) {
    commentsIcon = {
      iconPosition: nextIconPosition,
      countPosition: nextIconPosition + ICON_COUNT_POSITION_INCREMENT,
    };
    nextIconPosition += NEXT_ICON_WITH_COUNT_INCREMENT;
  }

  if (linkCount > 0) {
    linkIcon = {
      iconPosition: nextIconPosition,
      countPosition: nextIconPosition + ICON_COUNT_POSITION_INCREMENT,
    };
    nextIconPosition += NEXT_ICON_WITH_COUNT_INCREMENT;
  }

  if (isWatching) {
    isWatchingIcon = { iconPosition: nextIconPosition };
    nextIconPosition += NEXT_ICON_INCREMENT;
  }

  if (taskCount > 0) {
    taskIcon = {
      iconPosition: nextIconPosition,
      countPosition: nextIconPosition + ICON_COUNT_POSITION_INCREMENT,
    };
    nextIconPosition += NEXT_ICON_WITH_COUNT_INCREMENT * 1.5;
  }

  if (attachmentCount > 0) {
    attachmentIcon = { iconPosition: nextIconPosition };
    nextIconPosition += NEXT_ICON_INCREMENT;
  }

  const variableIcons = { commentsIcon, linkIcon, isWatchingIcon, taskIcon, attachmentIcon };
  const visibleVariableIcons = Object.values(variableIcons).filter((v) => v != null);
  const hasIcons = visibleVariableIcons.length > 0;

  // there should be a maximum of 3 icons visible
  if (visibleVariableIcons.length > 3) {
    // Priority: comments, links, isWatching, checkboxes, attachments
    return {
      showDetailsIconPosition,
      hasIcons,
      ...Object.fromEntries(
        Object.entries(variableIcons)
          .filter(([, value]) => value != null)
          .slice(0, 3)
      ),
    };
  }

  return {
    showDetailsIconPosition,
    commentsIcon,
    isWatchingIcon,
    attachmentIcon,
    linkIcon,
    taskIcon,
    hasIcons,
  };
};

export function trackShowDetailsIconClick() {
  return trackPlausibleEvent(CLIENT_PLAUSIBLE_EVENTS.OPEN_CARD_DETAILS, { source: 'Icon' });
}

export function trackShowDetailsTextClick() {
  return trackPlausibleEvent(CLIENT_PLAUSIBLE_EVENTS.OPEN_CARD_DETAILS, { source: 'Text' });
}
