/* eslint-disable react/no-children-prop */
import { useState } from 'react';
import fetcher from 'lib/Fetch';
import { useRouter } from 'next/router';
import { Box, Button, useToast } from '@chakra-ui/react';
import { CopyShareIcon } from 'lib/icons';
import { usePlausible } from 'next-plausible';
import { CLIENT_PLAUSIBLE_EVENTS } from 'lib/Types';

const BoardShare: React.FC<{ boardId?: string }> = ({ boardId }) => {
  const plausible = usePlausible();
  const toast = useToast();
  const router = useRouter();
  const [fetchedUrl, setFetchedUrl] = useState<string>();
  const boardIdToShare = boardId ?? router.query.boardId;

  const handleCopy = (urlToCopy: string) => {
    if (typeof navigator !== 'undefined') {
      navigator.clipboard.writeText(urlToCopy);
      toast({
        status: 'success',
        duration: 3000,
        position: 'bottom',
        title: 'Sucessfully copied the URL to the clipboard!',
        description: 'The URL will be valid for 24h.',
      });
      plausible(CLIENT_PLAUSIBLE_EVENTS.COPY_SHARE_LINK);
    }
  };

  const handleClick = async () => {
    if (!fetchedUrl) {
      const { url } = await fetcher<{ url: string }>('share', {
        method: 'POST',
        body: {
          boardId: boardIdToShare,
        },
      });
      setFetchedUrl(url);
      handleCopy(url);
    } else {
      handleCopy(fetchedUrl);
    }
  };

  return (
    <Box>
      <Button
        leftIcon={<CopyShareIcon />}
        color="yoBrand.600"
        variant="ghost"
        _hover={{ background: 'transparent' }}
        onClick={handleClick}
      >
        Copy Share Link
      </Button>
    </Box>
  );
};

export default BoardShare;
